import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { dealPhotoFormClickTrack } from 'metrika/deal';
import { toggleCameraPopup, togglePhotoPopup } from 'store/deal';
import { getFilesPhoto, getPhotoUrl, canUploadPhoto } from '../../../Loan/Loan.helpers';

export const useUserWidgetAvatarPresenter = ({ canEdit }) => {
  const params = useParams();
  const { path } = useRouteMatch();
  const rootDispatch = useDispatch();
  const deal = useSelector((store) => store.deal);
  const dealerName = useSelector((store) => store.startPage?.application?.dealer?.name);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isOptionsShown, setIsOptionsShown] = useState(false);

  const presenter = {
    params,
    path,
    photoUrl,
    setPhotoUrl,
    isOptionsShown,
    setIsOptionsShown,

    get isLoan() {
      return presenter.path === '/worksheet/:id/loan-issue';
    },

    get isCreateNew() {
      return presenter.isLoan && presenter.params?.id === 'create-new';
    },

    get shouldShowChangePhoto() {
      return shouldShowChangePhoto;
    },

    handleClick() {
      dealPhotoFormClickTrack('popup_open', deal, deal?.credit?.providerName, dealerName);
      setIsOptionsShown(true);
    },

    hideOptions() {
      setIsOptionsShown(false);
    },

    handleTakePhoto() {
      dealPhotoFormClickTrack('shoot', deal, deal?.credit?.providerName, dealerName);
      rootDispatch(toggleCameraPopup(true));
      presenter.hideOptions();
    },

    handleUploadPhoto() {
      dealPhotoFormClickTrack('upload', deal, deal?.credit?.providerName, dealerName);
      rootDispatch(togglePhotoPopup(true));
      presenter.hideOptions();
    },
  };

  const shouldShowChangePhoto = useMemo(() => {
    if (deal.id && presenter.isLoan && !presenter.isCreateNew) {
      return canEdit && canUploadPhoto(deal?.state);
    }
    return deal && canEdit;
  }, [deal, canEdit, presenter.isLoan, presenter.isCreateNew]);

  useEffect(() => {
    if (deal) {
      const photos = getFilesPhoto(deal.files);
      if (photos && photos.length > 0) {
        setPhotoUrl(getPhotoUrl(photos[0]));
      } else {
        setPhotoUrl(null);
      }
    }
  }, [deal]);

  return presenter;
};

import React from 'react';
import { useEpointsSMSVerificationPresenter } from './EPointsSMSVerification.presenter';

import { secondsToTimerString } from 'utils/common';

import * as S from './EPointsSMSVerification.styles';

const fn = () => {};

export const EPointsSMSVerification = ({
  className,
  codeSize = 6,
  resendTimeout = 120,
  onHandleOpened = fn,
}) => {
  const presenter = useEpointsSMSVerificationPresenter(codeSize, resendTimeout, onHandleOpened);

  return (
    <S.Container className={className}>
      <S.StyledCelluarInput
        code={presenter.code}
        setCode={presenter.setCode}
        disabled={presenter.isRequested}
        resended={presenter.isRecended}
        error={presenter.SMSError}
        onSubmit={presenter.handleInputChange}
      />

      {presenter.timer.seconds !== 0 ? (
        <S.StyledButton disabled>
          Отправить повторно через {secondsToTimerString(presenter.timer.seconds)}
        </S.StyledButton>
      ) : (
        <S.ButtonsWrapper>
          <S.StyledButton onClick={presenter.onResendCodeHandler}>
            Отправить код еще раз
          </S.StyledButton>
          <S.Description>
            Если не удаётся получить СМС-код, обратитесь в чат техподдержки
          </S.Description>
        </S.ButtonsWrapper>
      )}
    </S.Container>
  );
};

import React from 'react';
import { InputMask } from 'utils/inputMask';
import * as S from './style';

const fn = () => {};

const AddressLegal = ({
  value = '',
  inputType = 'address',
  inputName = 'addressLegal',
  inputLabel = 'Адрес',
  disabled = false,
  error = '',
  onSelect = fn,
}) => {
  return (
    <S.AddressInput
      required
      unmask
      type={inputType}
      name={inputName}
      label={inputLabel}
      disabled={disabled}
      mask={InputMask.ADDRESS_RUS}
      value={value}
      error={error}
      onSelect={onSelect}
    />
  );
};

export default AddressLegal;

import styled from 'styled-components';

import { FormGridRowBase } from '../style';
import * as UK from 'components/primitives/content';
import { PrinterGreenSvg } from 'assets/img';

export const GridStyled = styled(FormGridRowBase)`
  background-color: var(--color-white);
  grid-template-areas:
    '. h h h h h h .'
    '. go go go go go go .'
    '. al al al fup fup fup .'
    '. go2 go2 go2 go2 go2 go2 .';

  > div {
    opacity: ${(p) => (p.disabled ? 0.3 : 1)};

    &:first-child {
      opacity: 1;
    }
  }
`;

export const DocSignHelp = styled.span`
  grid-area: go;
  font-weight: 400;
  font-size: 12px;
  margin: 19px 0 19px 0;
  text-align: center;
`;

export const DocSignHelp2 = styled.span`
  grid-area: go2;
  font-weight: 400;
  font-size: 12px;
  margin: 19px 0 30px 0;
  text-align: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  grid-area: go2;
  margin: 19px 0 30px 0;
  justify-content: center;
`;

export const DocLink = styled(UK.Link)`
  width: 150px;
  margin: 0px 20px;
  font-size: 12px;
  text-align: center;
  color: var(--color-black);

  &:first-child {
    text-align: right;
  }

  &:last-child {
    text-align: left;
  }
`;

export const AgreementLoadArea = styled.div`
  grid-area: al;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e9f3e9;
  border: 1px solid #228b22;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

export const PrinterGreenSvgStyled = styled(PrinterGreenSvg)`
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;

import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'components/primitives/container';
import { AppVersionReloader } from 'components/common/AppVersionReloader/AppVersionReloader';
import {
  ContentWrapper,
  GridStyled,
  LeftPanel,
  RightPanel,
  Sticky,
  StickyRight,
  Wrapper,
} from './style';

export const Layout = ({ leftChildren, rigthChildren, mainChildren }) => {
  const slref = useRef();
  const srref = useRef();
  const prref = useRef();
  const plref = useRef();
  const [rw, setRw] = useState(false);
  const [lw, setLw] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [stickyHeight, setStickyHeight] = useState(0);
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
  };

  const observerRight = new MutationObserver(() => {
    const rect = srref.current.getBoundingClientRect();
    if (rect.height !== stickyHeight) {
      setStickyHeight(rect.height);
    }
    if (window.scrollY !== scrollY) {
      setScrollY(window.scrollY);
    }
  });

  const resizeHandler = () => {
    if (!prref || !plref || !prref.current || !plref.current) {
      return;
    }
    setRw(prref.current.offsetWidth);
    setLw(plref.current.offsetWidth);
    setScrollY(window.scrollY);
  };

  const scrollHandler = () => {
    if (window.scrollY !== scrollY) {
      setScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (srref && srref.current) {
      observerRight.disconnect();
      observerRight.observe(srref.current, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srref]);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler, false);
    window.addEventListener('scroll', scrollHandler, false);
    return () => {
      observerRight.disconnect();
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <GridStyled>
        <AppVersionReloader />
        <LeftPanel ref={plref}>
          <Sticky ref={slref} awidth={lw}>
            {leftChildren
              .filter((child) => child)
              .map((child, i) => (
                <Wrapper key={`left-${i}`}>{child}</Wrapper>
              ))}
          </Sticky>
        </LeftPanel>
        <ContentWrapper>{mainChildren}</ContentWrapper>
        <RightPanel ref={prref}>
          <StickyRight ref={srref} awidth={rw}>
            {rigthChildren.map((child, i) => (
              <Wrapper key={`rigth-${i}`}>{child}</Wrapper>
            ))}
          </StickyRight>
        </RightPanel>
      </GridStyled>
    </Container>
  );
};

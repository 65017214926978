import styled from 'styled-components';

import { CheckBox } from 'components/primitives/check-box';
import { InputMask } from 'components/primitives/input-mask-a';
import { Select } from 'components/primitives/select';
import { DaData } from 'components/primitives/dadata';
import { SelectSearch } from 'components/primitives/select-search';
import CollapsePanel from 'components/primitives/collapse-panel';
import { Grid } from 'components/primitives/grid';
import { ChevronSvg } from 'assets/img';

import ReactTooltip from 'react-tooltip';
import DaDataFio from 'components/common/DaDataFio';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const NewsArea = styled.div`
  background: #d3e8d3;
  border: 2px solid #228b22;
  font-weight: 500;
  font-size: 12px;
  margin: 24px 0px;
  padding: 14px 116px;
  span {
    text-decoration: line-through;
  }
`;

export const AnketaCheckBox = styled(CheckBox)`
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

export const AnketaSelect = styled(Select)`
  margin-bottom: 29px;
  > div:first-child {
    min-height: 56px;
  }
  .select_prompt {
    top: 58px;
  }
  .anketa_select_dropdown {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .anketa_select_dropdown_list {
  }
  .anketa_select_value {
    top: 27px;
  }
`;

export const AnketaInputMask = styled(InputMask)`
  height: 56px;
  margin-bottom: 20px;
  border-radius: 5px;
  .select_prompt {
    top: 58px;
  }
  .anketa_select_value {
    padding-top: 34px;
  }
`;

export const AnketaDaData = styled(DaData)`
  height: 56px;
  margin-bottom: 29px;
  border-radius: 5px;
  .select_prompt {
    top: 58px;
  }
  .anketa_select_value {
    padding-top: 30px !important;
  }
  .anketa_dadata_input_mask {
    height: 56px;
    border-radius: 5px;
    .anketa_select_value {
      padding-top: 34px !important;
    }
  }
`;

export const AnketaFio = styled(DaDataFio)`
  margin-bottom: 20px;
`;

export const AnketaSelectSearch = styled(SelectSearch)`
  height: 56px;
  .anketa-value {
    margin-top: 4px;
  }
  mark: {
    background: none;
  }
`;

export const AnketaFormPanel = styled(CollapsePanel)`
  width: 100%;

  > div:first-child {
    align-items: center;
    justify-content: center;

    > div:first-child {
      width: auto;
      margin-left: -28px;
    }
  }
`;

export const AnketaFormContainer = styled(Grid)`
  margin-top: ${(props) => (props.first ? 0 : 41)}px;
  width: 100%;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
`;

export const AnketaFormHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 18px;
  height: 56px;

  > h3 {
    margin: unset;
    font-weight: 500;
    font-size: 15px;
  }

  .chevron {
    transform: ${(p) => (p.collapsed ? 'rotate(0)' : 'rotate(90deg)')};
  }

  .check {
    margin-left: 15px;
    margin-right: 10px;
    fill: var(--color-grey-4) !important;
  }

  .alert {
    margin-left: 15px;
    margin-right: 10px;
    fill: var(--color-red-2) !important;
  }
`;

export const AnketaChevronStyled = styled(ChevronSvg)`
  margin-left: 2px;
  margin-right: 15px;
  transition: all 0.1s ease-out;

  path {
    fill: var(--color-grey-4) !important;
  }
`;

export const AreaInfo = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
  font-weight: normal;
  font-style: normal;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  font-size: 12px !important;
  text-align: start;
  padding: 5px 10px !important;
  border-radius: 0 !important;
  border: 1px solid var(--color-grey-4) !important;
  transition: opacity 0.3s !important;
  box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4) !important;

  ::before {
    max-height: ${(p) => (p.place === 'top' || p.place === 'top') && '6px'};
    max-width: ${(p) => (p.place === 'right' || p.place === 'bottom') && '6px'};
  }
`;

export const SpecialСonditions = styled(CheckBox)`
  justify-content: flex-start;
  align-items: center;
`;

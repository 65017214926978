import styled from 'styled-components';

import * as UK from 'components/primitives/content';
import { RefreshSvg } from 'assets/img';
import { CelluarInput } from 'components/uikit/CelluarInput/CelluarInput';
import { Button } from 'components/uikit/Button/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Input = styled(CelluarInput)`
  width: 100%;
  margin-bottom: 15px;
`;

export const Resend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 0px;
  }
`;

export const ResendLink = styled(UK.Link)`
  color: var(--color-black);
  display: flex;
  align-items: center;
  line-height: 18px;
`;

export const RefreshIcon = styled(RefreshSvg)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

export const StyledButton = styled(Button).attrs({ size: 'l' })`
  width: 100%;
`;

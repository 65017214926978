import React from 'react';
import { useScrollContainerPresenter } from './ScrollContainer.presenter';

import { BanksLogoWraper } from '../InfoCard.styles';

export const ScrollContainer = ({ children }) => {
  const presenter = useScrollContainerPresenter();

  return <BanksLogoWraper ref={presenter.scrollRef}>{children}</BanksLogoWraper>;
};

import md5 from 'blueimp-md5';

export const getUser = (state) => state.user || {};

export const getDealer = (state) =>
  state.user?.dealers?.find((d) => d.id === state.user?.dealer) || {};

export const getDealers = (state) => state.user?.dealers || [];

export const isUsePEP = (state) => getDealer(state).usePEPAnketa || false;

export const isUseNewCalculator = (state) => getDealer(state).useNewCalculator || false;

export const isViewClientWithApprovalCheckbox = (state) =>
  getDealer(state).clientWithApproval === undefined ? true : getDealer(state).clientWithApproval;

export const isAdmin = (state) =>
  !!(state.user?.roles || []).find((r) => r.sysname === 'administrator');

export const getBankLimitedList = (state) =>
  (state.user?.attributes || {})['product.credit.bank.limited-list'] || [];

export const selectCarfinParams = (state) => {
  const dealer = getDealer(state);
  return {
    userId: dealer.carfinId || 0,
    token: dealer.carFinDealerToken || '',
  };
};

export const selectCarfinToken = (state) => {
  const now = new Date();
  const ref = 'e-credit';
  const { token } = selectCarfinParams(state);
  const solt = now.toLocaleDateString('ru', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  if (ref && token && solt) {
    return md5(ref + token + solt);
  } else {
    return '';
  }
};

export const getUserRoleName = (state) => {
  if (state.user && state.user.role && state.user.roles) {
    const roleName = state.user.roles.filter((r) => r.id === state.user.role)[0];
    if (roleName && roleName.name) {
      return roleName.name;
    }

    return '';
  }

  return '';
};

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const LinkWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
`;

export const Link = styled.a`
  color: var(--color-blue-3);
  text-decoration: none;

  font: var(--font-large-13);

  &:hover {
    color: var(--color-blue-3);
  }
`;

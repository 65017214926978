import { useEffect, useRef } from 'react';

export const useScrollContainerPresenter = () => {
  const scrollRef = useRef(null);

  const presenter = {
    scrollRef,
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;

    const handleWheel = (e) => {
      e.preventDefault();
      scrollElement.scrollLeft += e.deltaY;
    };

    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      scrollElement.classList.add('dragging');
      startX = e.pageX - scrollElement.offsetLeft;
      scrollLeft = scrollElement.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      scrollElement.classList.remove('dragging');
    };

    const handleMouseUp = () => {
      isDown = false;
      scrollElement.classList.remove('dragging');
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollElement.offsetLeft;
      const walk = (x - startX) * 3;
      scrollElement.scrollLeft = scrollLeft - walk;
    };

    scrollElement.addEventListener('wheel', handleWheel);
    scrollElement.addEventListener('mousedown', handleMouseDown);
    scrollElement.addEventListener('mouseleave', handleMouseLeave);
    scrollElement.addEventListener('mouseup', handleMouseUp);
    scrollElement.addEventListener('mousemove', handleMouseMove);

    return () => {
      scrollElement.removeEventListener('wheel', handleWheel);
      scrollElement.removeEventListener('mousedown', handleMouseDown);
      scrollElement.removeEventListener('mouseleave', handleMouseLeave);
      scrollElement.removeEventListener('mouseup', handleMouseUp);
      scrollElement.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return presenter;
};

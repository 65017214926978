import React from 'react';

import { TextInput } from 'components/uikit/Form/TextInput/TextInput';
import { Checkbox } from 'components/uikit/Form/Checkbox/Checkbox';
import { ReadonlyInput } from 'components/common/ReadonlyInput/ReadonlyInput';
import { CrossSIcon } from 'assets/icons';

import { convertTelforInput } from 'utils/convertTelephone';

import * as S from '../PopupChildren.styles';

export const RegistrationStart = ({ presenter }) => {
  return (
    <S.FormWrapper>
      <S.Title>Регистрация в программе лояльности еКредит</S.Title>
      <S.InfoWrapper>
        <S.Description>
          Проверьте свои данные и ознакомьтесь с условиями программы лояльности{' '}
          <S.Link href="https://www.ecredit.one/loyalty" target="_blank" rel="noopener noreferrer">
            по ссылке
          </S.Link>
        </S.Description>
        <TextInput
          size={'l'}
          theme={'2'}
          value={presenter.ePointsAnketa.fio}
          label={'Фамилия и Имя'}
          error={presenter.inputErrors.fio}
          ClearButton={CrossSIcon}
          disabled={presenter.isRequested}
          onChange={presenter.onFioChangeHandler}
        />
        <ReadonlyInput
          type="email"
          size={'l'}
          theme={'2'}
          value={presenter.ePointsAnketa.email}
          label={'Электронная почта'}
          error={presenter.inputErrors.email}
          disabled={presenter.isRequested}
        />
        <TextInput
          type="tel"
          size={'l'}
          theme={'2'}
          value={convertTelforInput(presenter.ePointsAnketa.phoneNumber)}
          label={'Номер телефона'}
          error={presenter.inputErrors.phone}
          ClearButton={CrossSIcon}
          disabled={presenter.isRequested}
          onChange={presenter.onPhoneNumberChangeHandler}
        />
        {presenter.registrationError && <S.Error>{presenter.registrationError}</S.Error>}
        <S.CheckboxWrapper hasError={presenter.registrationError}>
          <Checkbox
            value={presenter.ePointsAnketa.acceptTenderOffer}
            error={presenter.inputErrors.acceptTenderOffer}
            onChange={presenter.onCheckedHandler}
            disabled={presenter.isRequested}
          >
            <S.Description>
              Я принимаю{' '}
              <S.Link
                href="https://storage.yandexcloud.net/img-mainpage.e-credit.one/soglasie/Правила_программы_лояльности_екредит_11_11.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                правила программы лояльности
              </S.Link>
            </S.Description>
          </Checkbox>
        </S.CheckboxWrapper>
      </S.InfoWrapper>
      <S.StyledButton
        onClick={presenter.onConfirmHandler}
        disabled={presenter.disabled || presenter.isRequested}
      >
        Продолжить
      </S.StyledButton>
    </S.FormWrapper>
  );
};

import styled from 'styled-components';

import { Button } from 'components/uikit/Button/Button';
import { Icon } from 'components/uikit/Icon/Icon';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 68px;
  z-index: 999;
  padding: 12px 12px 12px 16px;
  background-color: var(--color-blue-6);
  border-radius: 16px;
  margin-bottom: 12px;
  grid-area: bn;
`;

export const Title = styled.h2`
  font: var(--font-large-17);
  margin-bottom: 0px;
`;

export const Text = styled.p`
  font: var(--font-regular-15);
  color: var(--color-grey-11_60);
  margin-bottom: 0px;
`;

export const ReloadButton = styled(Button).attrs(() => ({ theme: 5, size: 'm' }))`
  margin-left: auto;
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledIcon = styled(Icon).attrs({ theme: '4' })``;

export const ImageBox = styled.div`
  background-color: var(--color-white);
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 22px;
  gap: 10px;
  margin-right: 12px;
`;

import styled from 'styled-components';

export const InfoContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: var(--color-grey-1);
  padding: 16px;
  border-radius: 16px;
  min-width: 100%;
  gap: 16px;
`;

export const COLOR = {
  white: 'var(--color-white)',
  yellow: 'var(--color-yellow-1)',
  blue: 'var(--color-blue-3)',
  red: 'var(--color-red-2)',
  green: 'var(--color-green-2)',
  grayLight: 'var( --color-grey-4 )',
  grayDark: 'var( --color-grey-8 )',
};

export const statusColorMapReverse = () => {
  const statusColorMap = {};

  for (let stateKey in CREDIT_STATUS_PARAMS) {
    const { status } = CREDIT_STATUS_PARAMS[stateKey];
    status.forEach(({ statusName }) => {
      statusColorMap[statusName] = stateKey;
    });
  }

  return statusColorMap;
};

export const IN_WORK = 'in-work';
export const LOST = 'lost';
export const LIST = 'list';
export const SEARCH = 'search';
export const LastState = {
  'in-work': [
    '4',
    '5',
    '6',
    '7',
    '8',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ],
  'list': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '24',
    '28',
    '29',
  ],
  'lost': ['7', '8'],
  'search': ['*'],
};

export const LastStateVisible = {
  'in-work': ['4', '5', '6', '7', '10', '11', '12', '13', '14', '15', '24'],
  'list': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '24',
    '28',
    '29',
  ],
  'lost': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '28',
    '29',
  ],
  'search': ['*'],
};

export const LastStateMutable = {
  'in-work': ['4', '5', '6', '7', '8', '10', '11', '12', '13', '14', '15', '24'],
  'list': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '24',
    '28',
    '29',
  ],
  'lost': [''],
  'search': ['*'],
};

export const FrontProxy = {
  'in-work': '',
  'list': '',
  'lost': 1,
  'search': '',
};

export const DealerMap = {
  'in-work': ['*'],
  'list': ['current'],
  'lost': ['*'],
  'search': ['*'],
};

export const applicationsRolesMap = {
  'lost': [3213, 93, 3197, 3370],
  'list': [3406, 3213, 93, 91, 90, 3197, 3370],
  'in-work': [3406, 3213, 93, 91, 3197, 3370],
  'search': [3406, 3213, 93, 91, 90, 3197, 3370],
};

export const MERCURY_HEARTBEAT = 300000;

export const APPLICATION_SOURCE_UNDEFINED = 100;

export const APPLICATION_SOURCE = {
  0: 'Отдел кредитования',
  1: 'Кредит онлайн',
  2: 'Отдел продаж (наличные)',
  3: 'Отдел продаж',
  4: 'Встречный поток от банков',
  APPLICATION_SOURCE_UNDEFINED: 'Неопределено',
};

export const newAnketaDealers = [527, 526];
export const newAnketaRoles = [91];
export const providerRoles = [91, 3197, 3213];
export const creditSpecRoles = [90, 91, 3197];
export const noReportSpecRoles = [90];
export const noAllReportSpecRoles = [90, 91];
export const restrictedRoles = [90, 127];

export const carMapper = {
  'LADA (ВАЗ)': 272,
};

export const DEFAULT_ERROR_MESSAGE = 'Попробуйте еще раз или обратитесь в службу поддержки';

export const GLOBAL_ANKETA_CALC_OPTIONS = ['Полный расчёт', 'Предварительный расчёт'];

export const MNEMONICS_HTML = {
  '&amp;': '&',
  '&AMP;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&QUOT;': '"',
  '&#039;': "'",
};

export const ASSISTANCE_COLOR_STATE = {
  [COLOR.white]: {
    status: [
      { statusName: 'new' },
      { statusName: 'offers_received' },
      { statusName: 'offers_requested' },
      { statusName: 'payment_link' },
      { statusName: 'cancellation_requested' },
    ],
    secondColor: COLOR.grayLight,
    fontColor: COLOR.grayDark,
  },
  [COLOR.yellow]: {
    status: [
      { statusName: 'agreement_requested' },
      { statusName: 'offer_selected' },
      { statusName: 'waiting_signing_code' },
    ],
    secondColor: COLOR.yellow,
    fontColor: COLOR.grayDark,
  },
  [COLOR.blue]: {
    status: [
      { statusName: 'agreement_error' },
      { statusName: 'issue_error' },
      { statusName: 'offers_error' },
      { statusName: 'cancellation_error' },
    ],
    secondColor: COLOR.blue,
    fontColor: COLOR.white,
  },
  [COLOR.red]: {
    status: [
      { statusName: 'agreement_declined' },
      { statusName: 'canceled' },
      { statusName: 'client_decline' },
      { statusName: 'issue_decline' },
      { statusName: 'offers_declined' },
    ],
    secondColor: COLOR.red,
    fontColor: COLOR.white,
  },
  [COLOR.green]: {
    status: [
      { statusName: 'agreement_succeed' },
      { statusName: 'code_confirmed' },
      { statusName: 'issue_requested' },
      { statusName: 'issued' },
    ],
    secondColor: COLOR.green,
    fontColor: COLOR.white,
  },
};

export const INSURANCE_COLOR_STATE = {
  [COLOR.white]: {
    status: [
      { statusName: 'new' },
      { statusName: 'offers_received' },
      { statusName: 'offers_requested' },
      { statusName: 'payment_link' },
      { statusName: 'cancellation_requested' },
    ],
    secondColor: COLOR.grayLight,
    fontColor: COLOR.grayDark,
  },
  [COLOR.yellow]: {
    status: [
      { statusName: 'agreement_requested' },
      { statusName: 'offer_selected' },
      { statusName: 'agreement_succeed' },
    ],
    secondColor: COLOR.yellow,
    fontColor: COLOR.grayDark,
  },
  [COLOR.blue]: {
    status: [
      { statusName: 'reload_documents_error' },
      { statusName: 'agreement_error' },
      { statusName: 'offers_error' },
      { statusName: 'cancellation_error' },
    ],
    secondColor: COLOR.blue,
    fontColor: COLOR.white,
  },
  [COLOR.red]: {
    status: [
      { statusName: 'agreement_declined' },
      { statusName: 'issue_error' },
      { statusName: 'canceled' },
      { statusName: 'client_decline' },
      { statusName: 'issue_decline' },
      { statusName: 'offers_declined' },
    ],
    secondColor: COLOR.red,
    fontColor: COLOR.white,
  },
  [COLOR.green]: {
    status: [
      { statusName: 'reload_documents_requested' },
      { statusName: 'issue_requested' },
      { statusName: 'issued' },
    ],
    secondColor: COLOR.green,
    fontColor: COLOR.white,
  },
};

// baseStatus, fundedStatus - статусы для отображения в списке заявок
// finalStatus - статусы заявок, которые необходимо отображать в столбце кредит вместо кружков
export const CREDIT_STATUS_PARAMS = {
  [COLOR.white]: {
    status: [
      { statusName: 'awaiting_manual_final_params', finalStatus: false, baseStatus: false },
      { statusName: 'final_parameters', finalStatus: false, baseStatus: false },
      { statusName: 'recalculation_desired', finalStatus: false, baseStatus: false },
      { statusName: 'new-credit-calculation', finalStatus: false, baseStatus: true },
      { statusName: 'new', finalStatus: false, baseStatus: true },
    ],
    title: 'Доступные для подачи банки',
    secondColor: COLOR.grayLight,
    fontColor: COLOR.grayDark,
  },

  [COLOR.green]: {
    status: [
      { statusName: 'agreement_succeed', finalStatus: false, baseStatus: false },
      { statusName: 'approval_request_received', finalStatus: false, baseStatus: false },
      { statusName: 'approval_requested', finalStatus: false, baseStatus: false },
      { statusName: 'approved-with-if', finalStatus: false, baseStatus: true },
      { statusName: 'approved', finalStatus: false, baseStatus: true },
      { statusName: 'archive-financed', finalStatus: true, baseStatus: true },
      { statusName: 'client-invited-deal', finalStatus: true, baseStatus: true },
      { statusName: 'closed', finalStatus: true, baseStatus: false, fundedStatus: true },
      { statusName: 'contract-issue', finalStatus: true, baseStatus: true },
      { statusName: 'documentation_received', finalStatus: false, baseStatus: false },
      { statusName: 'documentation_requested', finalStatus: false, baseStatus: false },
      { statusName: 'documents_prepared', finalStatus: false, baseStatus: false },
      { statusName: 'finance_requested', finalStatus: false, baseStatus: false },
      { statusName: 'financed', finalStatus: true, baseStatus: false, fundedStatus: true },
      { statusName: 'link_sent', finalStatus: false, baseStatus: false },
      { statusName: 'phone_verification', finalStatus: false, baseStatus: false },
      { statusName: 'reapproval_requested', finalStatus: false, baseStatus: false },
      { statusName: 'request-credit-agreement', finalStatus: true, baseStatus: true },
      { statusName: 'request-for-loan-issue', finalStatus: true, baseStatus: true },
    ],
    title: 'Одобрено',
    secondColor: COLOR.green,
    fontColor: COLOR.white,
  },

  [COLOR.blue]: {
    status: [
      { statusName: 'hash_check_failed', finalStatus: false, baseStatus: false },
      { statusName: 'request-info-deal', finalStatus: true, baseStatus: true },
      { statusName: 'request-info-financed', finalStatus: true, baseStatus: true },
      { statusName: 'request-info-underwriting', finalStatus: false, baseStatus: true },
    ],
    title: 'Запрос банка',
    secondColor: COLOR.blue,
    fontColor: COLOR.white,
  },
  [COLOR.yellow]: {
    status: [
      { statusName: 'contract_received', finalStatus: false, baseStatus: false },
      { statusName: 'contract_requested', finalStatus: false, baseStatus: false },
      { statusName: 'contract_sign', finalStatus: false, baseStatus: false },
      { statusName: 'phone_verification_received', finalStatus: false, baseStatus: false },
      { statusName: 'phone_verification_requested', finalStatus: false, baseStatus: false },
      { statusName: 'queue-agreement-error', finalStatus: true, baseStatus: false },
      { statusName: 'queue-agreement', finalStatus: true, baseStatus: true },
      { statusName: 'queue-financed-error', finalStatus: true, baseStatus: false },
      { statusName: 'queue-financed', finalStatus: true, baseStatus: true },
      { statusName: 'queue', finalStatus: false, baseStatus: true },
      { statusName: 'reload_documents_required', finalStatus: false, baseStatus: false },
      { statusName: 'request_on_approval', finalStatus: false, baseStatus: false },
      { statusName: 'request_on_documents', finalStatus: false, baseStatus: false },
      { statusName: 'request_on_finance', finalStatus: false, baseStatus: false },
      { statusName: 'request_on_reapproval', finalStatus: false, baseStatus: false },
      { statusName: 'sent-to-the-bank', finalStatus: false, baseStatus: true },
    ],
    title: 'Ожидание',
    secondColor: COLOR.yellow,
    fontColor: COLOR.grayDark,
  },
  [COLOR.red]: {
    status: [
      { statusName: 'archive', finalStatus: true, baseStatus: true },
      { statusName: 'bank_decline', finalStatus: false, baseStatus: false },
      { statusName: 'customer_decline', finalStatus: false, baseStatus: false },
      { statusName: 'customer-decline-after', finalStatus: false, baseStatus: true },
      { statusName: 'customer-decline-before', finalStatus: false, baseStatus: true },
      { statusName: 'declined', finalStatus: false, baseStatus: true },
      { statusName: 'phone_verification_declined', finalStatus: false, baseStatus: false },
      { statusName: 'reapproval_bank_decline', finalStatus: false, baseStatus: false },
    ],
    title: 'Отказ',
    secondColor: COLOR.red,
    fontColor: COLOR.white,
  },
};

import commonReducer from './common';
import userReducer from './user';
import insuranceReducer from './insurance';
import applicationsReducer from './applications';
import notifications from './notifications';
import dealsReducer from './deals';
import filtersReducer from './filters';
import anketaReducer from './anketa';
import referenceBooks from './referenceBooks';
import fileRecognizer from './fileRecognizer/reducers';
import assistanceAnketaReducer from './assistanceAnketa';
import assistanceCalculationReducer from './assistanceCalculation';
import insuranceAnketaReducer from './insuranceAnketa';
import insuranceCalculationReducer from './insuranceCalculation';
import carInstance from './carInstance';
import carReference from './carReference';
import confirmSMS from './confirmSMS';
import startPage from './startPage';
import deal from './deal';
import calculator from './calculator';
import app from './app';
import vinCheck from './vinCheck';
import ePoints from './ePoints';
import toasts from './toasts';

export const customReducers = {
  app,
  common: commonReducer,
  user: userReducer,
  insurance: insuranceReducer,
  applications: applicationsReducer,
  notifications,
  deals: dealsReducer,
  applicFilters: filtersReducer,
  anketa: anketaReducer,
  referenceBooks: referenceBooks,
  fileRecognizer: fileRecognizer,
  assistanceAnketa: assistanceAnketaReducer,
  assistanceCalculation: assistanceCalculationReducer,
  insuranceAnketa: insuranceAnketaReducer,
  insuranceCalculation: insuranceCalculationReducer,
  carInstance: carInstance,
  carReference: carReference,
  confirmSMS,
  startPage,
  deal,
  calculator,
  vinCheck,
  ePoints,
  toasts,
};

import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

export const LegalCheckbox = ({ checked = false, name, label, disabled, onSwitch }) => (
  <S.Switch>
    <S.CheckBoxStyled
      name={name}
      label={label}
      checked={checked}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onSwitch(!checked);
        }
      }}
    />
  </S.Switch>
);

LegalCheckbox.propTypes = {
  checked: PropTypes.bool,
  onSwitch: PropTypes.func,
};

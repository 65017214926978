import React from 'react';
import { useReadonlyInputPresenter } from './ReadonlyInput.presenter';

import { TextInput } from 'components/uikit/Form/TextInput/TextInput';

import * as S from './ReadonlyInput.styles';

export const ReadonlyInput = (props) => {
  const { title = '', link = '', disabled } = props;

  const presenter = useReadonlyInputPresenter(link, disabled);

  return (
    <S.Container>
      <TextInput {...props} readOnly />
      <S.LinkWrapper>
        <S.Link onClick={presenter.onHandleClick} disabled={disabled}>
          {title}
        </S.Link>
      </S.LinkWrapper>
    </S.Container>
  );
};

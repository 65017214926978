import React from 'react';

import { FormContainer } from '../style';
import { useSpecialСonditionsPresenter } from './SpecialСonditions.presenter';
import { CheckBox, FormGridRow, Header } from './SpecialСonditions.style';

export const SpecialСonditions = ({ isPickUp }) => {
  const presenter = useSpecialСonditionsPresenter();

  return (
    <FormContainer id={presenter.id}>
      <FormGridRow>
        <Header>Специальный клиент</Header>
        <CheckBox
          value={presenter.specialСonditions}
          onChange={presenter.setSpecialСonditions}
          disabled={isPickUp}
        >
          Заявка будет отправлена на рассмотрение только в выбранные вами банки
        </CheckBox>
      </FormGridRow>
    </FormContainer>
  );
};

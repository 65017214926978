import styled from 'styled-components';

import { Icon } from 'components/uikit/Icon/Icon';

export const Container = styled.span`
  display: flex;
  align-items: center;
  width: max-content;
  gap: 4px;

  cursor: pointer;
`;

export const TrueTextWrapper = styled.span`
  font: var(--font-large-15_2);
`;

export const FalseTextWrapper = styled.span`
  font: var(--font-regular-15);
`;

export const StyledTooltipTitle = styled.span`
  white-space: pre-wrap;
`;

export const StyledIcon = styled(Icon).attrs({ theme: '6' })``;

import { useSelector, useDispatch } from 'react-redux';

import * as ePointsStore from 'store/ePoints';

export const useEPointsPopupPresenter = () => {
  const rootDispatch = useDispatch();

  const { showEPointsPopup } = useSelector((store) => store.ePoints);

  const presenter = {
    showEPointsPopup,

    onCloseEPointsPopupHandler() {
      rootDispatch(ePointsStore.hideEPointsPopup());
      rootDispatch(ePointsStore.setEmailError(''));
      rootDispatch(ePointsStore.setResetPopupFrame());
    },
  };

  return presenter;
};

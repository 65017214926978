import React, { useEffect, useState } from 'react';
import { InputMask } from 'utils/inputMask';
import { useSelector } from 'react-redux';
import { CheckSvg, AlertSvg } from 'assets/img';
import { schemaAddressFiasValue } from '../../../../Application/validation';

import {
  dadataAddressToState,
  addressToState,
  indexToState,
} from 'store/anketa/mappers/abstractAddress';
import {
  realtyStateOwn,
  realtyStateWithRelatives,
} from 'store/anketa/mappers/livingAddressProperty';
import { isDocumentClassExists } from 'utils/dataMappers/mappers';

import { AnketaChevronStyled, ReactTooltipStyled, AreaInfo } from '../../../style';
import { FormSubheader } from '../../style';
import * as S from './style';

const addressDocs = ['passport_registration', 'passport_registration_handwritten'];

export const AddressForm = ({
  onChange,
  errors,
  persona,
  validate,
  anketaSave,
  setFocusAt,
  confidenceErrors,
  clearConfidenceErrors,
  disabled = false,
}) => {
  const keyComp = 'addressReg';
  const keyCompAddressLive = 'addressLive';
  const keyCompAddressLiveProp = 'livingAddressProperty';
  const [values, setValues] = useState(null);
  const [valuesAddressLive, setValuesLivingAdress] = useState(null);
  const [valuesLivingAdressProp, setValuesLivingAdressProp] = useState(null);
  const [error, setError] = useState({});
  const { documents } = useSelector((state) => state.anketa);
  const [showMore, setShowMore] = useState(false);

  const changeHandler = (val) => {
    let stateVal = { ...values };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }
    if (val.livingAddressEquals === false) {
      setShowMore(true);
    }
    setValues(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyComp });
  };

  const changeHandlerAddressLive = (val) => {
    let stateVal = { ...valuesAddressLive };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...valuesAddressLive, ...val };
    }
    setValuesLivingAdress(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyCompAddressLive });
  };

  const changeHandlerLivingAdressProp = (val) => {
    let stateVal = { ...valuesLivingAdressProp };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...valuesLivingAdressProp, ...val };
    }
    setValuesLivingAdressProp(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyCompAddressLiveProp });
  };

  const validation = (path) => {
    setError({ ...error, [path]: errors[path] || null });
    clearConfidenceErrors(path);
    anketaSave();
  };

  const getError = (path) => {
    if (validate) {
      return errors[path] || error[path];
    }
    return error[path];
  };

  const getConfidenceError = (path) => {
    return Object.prototype.hasOwnProperty.call(confidenceErrors, path);
  };

  useEffect(() => {
    if (persona && values !== persona[keyComp]) {
      setValues(persona[keyComp]);
    }
    if (persona && values !== persona[keyCompAddressLive]) {
      setValuesLivingAdress(persona[keyCompAddressLive]);
    }
    if (persona && values !== persona[keyCompAddressLiveProp]) {
      setValuesLivingAdressProp(persona[keyCompAddressLiveProp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  useEffect(() => {
    if (!validate) {
      setError(
        Object.keys(error).reduce((obj, key) => ({ ...obj, [key]: errors[key] || null }), {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  const isAddressError = () => {
    if (!errors || !error || !confidenceErrors) return false;

    const errs = Object.keys({ ...errors, ...error, ...confidenceErrors }).map((f) =>
      f.replaceAll(/\[\d\]/gu, '')
    );
    return errs.some((ef) => ef.includes('addressReg') || ef.includes('addressLive'));
  };

  useEffect(() => {
    if (
      documents &&
      isDocumentClassExists(documents, addressDocs) &&
      values &&
      (errors || error || confidenceErrors)
    ) {
      if (isAddressError() === true) {
        if (showMore === false) {
          setShowMore(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, error, confidenceErrors]);

  return (
    <>
      {values && (
        <S.FormGridRow id={keyComp}>
          <S.FormHeader
            onClick={() => setShowMore(!showMore)}
            collapsed={!showMore}
            data-test={`${keyComp} ${!showMore ? 'collapsed' : 'expanded'}`}
          >
            <AnketaChevronStyled className="chevron" />
            <h3>Адрес регистрации</h3>

            {!disabled && (
              <>
                {isDocumentClassExists(documents, addressDocs) === false &&
                  isAddressError() === true && (
                    <>
                      <AreaInfo>
                        <AlertSvg className="alert" data-tip="" data-for="addressAlertInfo" />
                        <ReactTooltipStyled id="addressAlertInfo" place="right">
                          <span>Загрузите документ</span>
                        </ReactTooltipStyled>
                      </AreaInfo>
                    </>
                  )}
                {values.houseNumber &&
                  (values.livingAddressEquals || valuesAddressLive.houseNumber) &&
                  isDocumentClassExists(documents, addressDocs) === true &&
                  isAddressError() === false && (
                    <>
                      <AreaInfo>
                        <CheckSvg className="check" data-tip="" data-for="addressCheckInfo" />
                        <ReactTooltipStyled id="addressCheckInfo" place="right">
                          <span>распознан</span>
                        </ReactTooltipStyled>
                      </AreaInfo>
                    </>
                  )}
              </>
            )}
          </S.FormHeader>
          {showMore && (
            <>
              <S.Address
                id={`${keyComp}_fiasValue`}
                tabIndex="20"
                required
                label="Адрес"
                type="address"
                defaultValue={values.fiasValue}
                onChange={(obj) => {
                  if (obj.data) {
                    const address = dadataAddressToState(obj.data, obj.value);
                    changeHandler(address);
                    schemaAddressFiasValue
                      .validate({ ...address }, { abortEarly: false })
                      .then(() => {
                        if (address.apartmentNumber) setFocusAt(`${keyComp}_regDate_input`);
                      })
                      .catch(() => {});
                  } else {
                    changeHandler(addressToState(obj.value));
                  }
                }}
                onBlur={() => validation(`${keyComp}.fiasValue`)}
                error={getError(`${keyComp}.fiasValue`)}
                confidenceError={values.fiasValue && getConfidenceError(`${keyComp}.fiasValue`)}
                disabled={disabled}
              />
              {!values.isDadataIndexValue &&
                values.fiasValue &&
                values.houseNumber &&
                values.isDadataValue && (
                  <S.Index
                    id={`${keyComp}_index`}
                    mask={InputMask.INDEX}
                    unmask
                    tabIndex="22"
                    required
                    label="Индекс"
                    type="address"
                    val={values.index}
                    defaultValue={values.index}
                    onChange={(val) => changeHandler(indexToState(val))}
                    onBlur={() => validation(`${keyComp}.index`)}
                    error={getError(`${keyComp}.index`)}
                    disabled={disabled}
                  />
                )}
              <S.RegDate
                id={`${keyComp}_regDate`}
                tabIndex="21"
                required
                name="regDate"
                label="Дата регистрации"
                defaultValue={values.regDate}
                val={values.regDate}
                mask={InputMask.DATE_BEFORE_TODAY}
                unmask
                onChange={({ regDate }) => {
                  const date = regDate.length === 2 ? '' : regDate;
                  changeHandler({ regDate: date });
                }}
                onBlur={() => validation(`${keyComp}.regDate`)}
                error={getError(`${keyComp}.regDate`)}
                confidenceError={values.regDate && getConfidenceError(`${keyComp}.regDate`)}
                dataTest="registrationDate"
                disabled={disabled}
              />
              {!values.livingAddressEquals && (
                <>
                  <FormSubheader style={{ marginTop: '0' }}>Адрес проживания</FormSubheader>
                  <S.AddressLive
                    id={`${keyCompAddressLive}_fiasValue`}
                    tabIndex="23"
                    required
                    label="Адрес"
                    type="address"
                    defaultValue={valuesAddressLive.fiasValue}
                    onChange={(obj) => {
                      if (obj.data) {
                        changeHandlerAddressLive(dadataAddressToState(obj.data, obj.value));
                      } else {
                        changeHandlerAddressLive(addressToState(obj.value));
                      }
                    }}
                    onBlur={() => validation(`${keyCompAddressLive}.fiasValue`)}
                    error={getError(`${keyCompAddressLive}.fiasValue`)}
                    disabled={disabled}
                  />
                  {!valuesAddressLive.isDadataIndexValue &&
                    valuesAddressLive.fiasValue &&
                    valuesAddressLive.houseNumber &&
                    valuesAddressLive.isDadataValue && (
                      <S.IndexLive
                        id={`${keyCompAddressLive}_index`}
                        mask={InputMask.INDEX}
                        unmask
                        tabIndex="24"
                        required
                        label="Индекс"
                        type="address"
                        defaultValue={valuesAddressLive.index}
                        val={valuesAddressLive.index}
                        onChange={(val) => changeHandlerAddressLive(indexToState(val))}
                        onBlur={() => validation(`${keyCompAddressLive}.index`)}
                        error={getError(`${keyCompAddressLive}.index`)}
                        disabled={disabled}
                      />
                    )}
                </>
              )}
            </>
          )}
          <S.LivingAddressEqualsToogle
            name="livingAddressEquals"
            onChange={() => {
              changeHandler({ livingAddressEquals: !values.livingAddressEquals });
              if (!values.livingAddressEqual) {
                setFocusAt(`${keyCompAddressLive}_fiasValue_input`);
              }
            }}
            value={values.livingAddressEquals}
            disabled={disabled}
            dataTest="livingAddressEquals"
          >
            Совпадает с адресом проживания
          </S.LivingAddressEqualsToogle>
          <S.RealtyStateToogle
            name="realtyState"
            onChange={() => {
              changeHandlerLivingAdressProp({
                realtyState:
                  valuesLivingAdressProp.realtyState?.id !== realtyStateOwn.id
                    ? realtyStateOwn
                    : realtyStateWithRelatives,
              });
              anketaSave();
            }}
            value={valuesLivingAdressProp.realtyState?.id === realtyStateOwn.id}
            disabled={disabled}
            dataTest="realtyState"
          >
            Недвижимость в собственности
          </S.RealtyStateToogle>
        </S.FormGridRow>
      )}
    </>
  );
};

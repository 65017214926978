import styled, { css } from 'styled-components';
import { DoubleArrowSvg } from 'assets/img';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-grey-4);
  margin-bottom: 15px;
  height: calc(100% - 240px);

  canvas {
    width: auto !important;
    height: ${(p) => `${p.height - 326}px`} !important;
  }
`;

export const View = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: calc(100% - 20px);
`;

export const Control = styled.div`
  width: 14px;
`;

const navStyles = css`
  display: flex;
  align-items: center;
  width: 30px;
  padding: 0 5px;
  cursor: pointer;
`;

export const NavLeft = styled.div`
  ${navStyles};
  justify-content: flex-end;
  transform: rotate(180deg);
`;

export const NavRight = styled.div`
  ${navStyles};
  justify-content: flex-end;
`;

export const Icon = styled(DoubleArrowSvg)`
  width: 14px;
  height: 18px;
`;

export const Info = styled.p`
  text-align: center;
`;

import React from 'react';

import { LoginFrom } from './components/LoginFrom';
import { isDefault, isGeely } from 'utils/personalization';
import { AuthBkg, PersGeelyBkg } from 'assets/img';

import { ImageBanner } from './components/ImageBanner';
// import { ElfSightBanner } from './components/ElfSightBanner';

import * as S from './styles';

export const Auth = () => {
  return (
    <S.Container>
      <S.Banner>
        {isGeely() && <ImageBanner img={PersGeelyBkg} dataTest={'login.banner'} />}
        {isDefault() && (
          <ImageBanner img={AuthBkg} link="https://www.ecredit.one" dataTest={'login.banner'} />
          /* <ElfSightBanner bannerId="elfsight-app-d597d862-a45c-4b4d-9e39-d4ad3630de32" /> */
        )}
      </S.Banner>

      <S.Form>
        <LoginFrom />
      </S.Form>
    </S.Container>
  );
};

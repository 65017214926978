import React from 'react';

import * as S from './Button.styles';

const fn = () => {};

export const Button = (props) => {
  const {
    className,
    children,
    Icon = null,
    iconPosition = 'left',
    Badge = null,
    name = '',
    theme = '1',
    size = 'm',
    disabled = false,
    onClick = fn,
    onBlur = fn,
    onFocus = fn,
    dataTest = '',
  } = props;

  return (
    <S.Container
      className={className}
      name={name}
      theme={theme}
      size={size}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      data-test={dataTest}
    >
      {Badge && <S.BudgeWrapper>{Badge}</S.BudgeWrapper>}
      {Icon && iconPosition === 'left' && (
        <S.StyledIcon Icon={Icon} size={size} disabled={disabled} />
      )}
      <S.Title>{children}</S.Title>
      {Icon && iconPosition === 'right' && (
        <S.StyledIcon Icon={Icon} size={size} disabled={disabled} />
      )}
    </S.Container>
  );
};

import styled from 'styled-components';

const themes = {
  1: {
    colorBkg: 'var(--color-white_10)',
    colorText: 'var(--color-white_50)',
    borderRadius: '4px',
    fontSize: '15px',
  },
};

export const Container = styled.div`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --border-radius: ${(p) => (themes[p.theme] || themes['1']).borderRadius};
  --font-size: ${(p) => (themes[p.theme] || themes['1']).fontSize};

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius);
  background-color: var(--color-bkg);
  color: var(--color-text);
  font-size: var(--font-size);
  font-weight: 700;
  line-height: 1;
`;

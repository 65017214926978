import React from 'react';

import * as S from './Skeleton.styles';

export const Skeleton = (props) => {
  const { className, theme = '1', width = '', height = '', dataTest = '' } = props;

  return (
    <S.Container
      className={className}
      theme={theme}
      width={width}
      height={height}
      data-test={dataTest}
    />
  );
};

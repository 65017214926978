import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPLOAD_EPOINTS_ANKETA:
      return { ...state, ePointsAnketa: action.payload };

    case actions.GET_EPOINTS_MEMBERSHIP:
      return { ...state, isEPointsDigitalDealMember: action.payload };

    case actions.GET_EPOINTS_BALANCE:
      return { ...state, totalPoints: action.payload };

    case actions.SHOW_EPOINTS_POPUP:
      return { ...state, showEPointsPopup: true };

    case actions.HIDE_EPOINTS_POPUP:
      return { ...state, showEPointsPopup: false };

    case actions.SET_ACTIVE_POPUP_FRAME:
      return {
        ...state,
        popupFrames: {
          ...state.popupFrames,
          [action.payload.removed]: false,
          [action.payload.added]: true,
        },
      };

    case actions.SET_RESET_POPUP_FRAME:
      return {
        ...state,
        popupFrames: {
          ...initialState.popupFrames,
        },
      };

    case actions.SET_EMAIL_ERROR:
      return { ...state, emailValidationError: action.payload };

    default:
      return state;
  }
}

export function loadEPointsInfo() {
  return { type: actions.LOAD_EPOINTS_INFO };
}

export function uploadEPointsAnketa(payload) {
  return { type: actions.UPLOAD_EPOINTS_ANKETA, payload };
}

export function getEPointsMembership(payload) {
  return { type: actions.GET_EPOINTS_MEMBERSHIP, payload };
}

export function getEPointsBalance(payload) {
  return { type: actions.GET_EPOINTS_BALANCE, payload };
}

export function showEPointsPopup() {
  return { type: actions.SHOW_EPOINTS_POPUP };
}

export function hideEPointsPopup() {
  return { type: actions.HIDE_EPOINTS_POPUP };
}

export function setActivePopupFrame(payload) {
  return { type: actions.SET_ACTIVE_POPUP_FRAME, payload };
}

export function setResetPopupFrame() {
  return { type: actions.SET_RESET_POPUP_FRAME, payload: {} };
}

export function setEmailError(payload) {
  return { type: actions.SET_EMAIL_ERROR, payload };
}

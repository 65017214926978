import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ClockLightSvg, CheckCircleSvg } from 'assets/img';

export const Container = styled.div`
  position: relative;
  flex: 1;

  &:before {
    content: '';
    position: sticky;
    display: block;
    top: 75px;
    width: 100%;
    height: 40px;
    margin-bottom: -40px;
    z-index: 9;
    background-color: var(--color-white);
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid var(--color-grey-4);
  border-radius: 8px;
`;

export const Row = styled.div`
  display: grid;
  min-height: 75px;
  grid-template-columns: 0.5fr 0.4fr 0.3fr 0.4fr 0.3fr 0.3fr 0.3fr 0.1fr;
  padding: 0px 10px;
  line-height: 1.3;
  position: relative;
  align-items: center;

  * + & {
    border-top: 1px solid var(--color-grey-4);
  }
`;

export const EmptyRow = styled(Row)`
  grid-template-columns: 1fr;
  text-align: center;
`;

export const HeadRow = styled(Row)`
  position: sticky;
  top: 100px;
  z-index: 9;
  margin: -1px;
  border: 1px solid var(--color-grey-4);
  background-color: var(--color-grey-1);

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const Cell = styled.div`
  display: flex;
  padding: 16px 10px;
  text-align: ${(p) => (p.txtCenter ? 'center' : 'left')};
  font-weight: ${(p) => (p.txtBold ? '500' : '400')};
  text-transform: ${(p) => (p.txtCap ? 'capitalize' : 'none')};
  cursor: ${(p) => (p.isClickable ? 'pointer' : 'default')};
  color: ${(p) => (p.isGrey ? 'var(--color-grey-5)' : 'var(--color-grey-8)')};
  z-index: ${(p) => (p.zIndex ? '2' : 'auto')};
`;

export const HeadCell = styled(Cell)`
  color: var(--color-grey-5);
`;

export const PaginationWrapper = styled.div`
  margin-top: 25px;
`;

export const PageItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  color: ${(p) => (p.active ? 'var(--color-white)' : 'var(--color-grey-4)')};
  cursor: ${(p) => (p.active ? 'default' : 'pointer')};
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
  will-change: transform;
  transform-origin: center;
  transition: transform 20ms linear;
  user-select: none;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${(p) => (p.active ? 'var(--color-blue-4)' : '')};
  ${(p) =>
    p.clear &&
    css`
      border: 0;
      color: inherit;
    `};
  &:not(:last-child) {
    margin-right: 10px;
  }
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.7;
    `}
  ${(p) =>
    !p.disabled &&
    !p.active &&
    css`
      &:hover {
        background-color: ${(p) => (!p.clear ? 'var(--color-grey-2)' : 'transparent')};
        color: var(--color-black);
      }
      &:active {
        transform: scale(0.94);
      }
    `}
`;

export const PageIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 18px;
  color: var(--color-grey-4);
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

export const CheckIcon = styled(CheckCircleSvg)`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  z-index: 1;

  > path {
    fill: var(--color-green-1);
  }
`;

export const ClockIcon = styled(ClockLightSvg)`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  z-index: 1;

  > path {
    fill: var(--color-yellow-1);
  }
`;

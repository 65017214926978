import React from 'react';
import { useEPointsBannerPresenter } from './EPointsBanner.presenter';

import * as S from './EPointsBanner.styles';

export const EPointsBanner = () => {
  const presenter = useEPointsBannerPresenter();

  if (!presenter.hasBanner) {
    return null;
  }

  return (
    <S.MainContainer>
      <S.BannerLogoWrapper />
      <S.StringContainer>
        <S.BoldRow>Дарим сертификаты всем участникам программы лояльности</S.BoldRow>
        <S.Row>Регистрируйся, чтобы принять участие</S.Row>
      </S.StringContainer>
      <S.Button onClick={presenter.onClickEPointsHandler} data-test="startEPointsRegistration">
        Зарегистрироваться
      </S.Button>
    </S.MainContainer>
  );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { SEARCH } from 'constants/index';
import { FilterGreySvg } from 'assets/img';

import { Dealer } from './components/Dealer';
import { Kso } from './components/Kso';
import { Mop } from './components/Mop';

import * as localStorage from 'utils/local-storage';
import * as S from './style';

export const Filters = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const { user } = useSelector((state) => state);

  const [isShowFilters, setIsShowFilters] = useState(() => {
    let savedFiltersState = localStorage.getItem('applicFiltersShow');

    if (savedFiltersState === null) {
      localStorage.saveItem('applicFiltersShow', { value: true });

      savedFiltersState = localStorage.getItem('applicFiltersShow');
    }

    return savedFiltersState?.value && !user?.isDealersFetch;
  });

  if (pathname === `${url}/${SEARCH}`) {
    return null;
  }

  const toggleFilterHandler = () => {
    setIsShowFilters((prev) => {
      const newFiltersState = { value: !prev };
      localStorage.saveItem('applicFiltersShow', newFiltersState);

      return newFiltersState.value;
    });
  };

  return (
    <S.Container data-test="filterBlock">
      <Dealer isVisible={isShowFilters} />
      <Kso isVisible={isShowFilters} />
      <Mop isVisible={isShowFilters} />

      <S.FilterButton
        dataTest="filterButton"
        isActive={isShowFilters}
        onClick={toggleFilterHandler}
      >
        <FilterGreySvg />
      </S.FilterButton>
    </S.Container>
  );
};

import React from 'react';
import { useTooltipCellPresenter } from './TooltipCell.presenter';

import { TooltipHover } from 'components/uikit/Tooltip/TooltipHover/TooltipHover';
import { AlertTriangleSIcon } from 'assets/icons';

import * as S from './TooltipCell.styles';

export const TooltipCell = ({ cellType, price, error, children }) => {
  const presenter = useTooltipCellPresenter();

  return (
    <>
      {cellType === 'vinNumber' && (
        <S.StyledVinNumberCell price={price}>
          {error ? (
            <S.Wrapper>
              <span>{children}</span>
              <span ref={presenter.cellRef}>
                <S.StyledIcon Icon={AlertTriangleSIcon} dataTest="vinErrorIcon" />
              </span>
              <S.StyledTooltip targetRef={presenter.cellRef} theme="2" dataTest="vinErrorTooltip">
                <S.StyledTitle>
                  <span>
                    {
                      'VIN не найден. Уточните\nправильность номера и\u00A0запустите\nповторную проверку'
                    }
                  </span>
                </S.StyledTitle>
              </S.StyledTooltip>
            </S.Wrapper>
          ) : (
            <span>{children}</span>
          )}
        </S.StyledVinNumberCell>
      )}

      {cellType === 'modelName' && (
        <S.StyledModelNameCell ref={presenter.cellRef}>
          {presenter.isTruncated ? (
            <span>
              <span>{children}</span>
              <TooltipHover targetRef={presenter.cellRef} theme="2">
                <S.StyledTitle>{children}</S.StyledTitle>
              </TooltipHover>
            </span>
          ) : (
            <span>{children}</span>
          )}
        </S.StyledModelNameCell>
      )}

      {cellType === 'modelPrice' && (
        <S.StyledModelPriceCell ref={presenter.cellRef} price={price}>
          {presenter.isTruncated ? (
            <span>
              <span>{children}</span>
              <TooltipHover targetRef={presenter.cellRef} theme="2">
                <S.StyledTitle>{children}</S.StyledTitle>
              </TooltipHover>
            </span>
          ) : (
            <span>{children}</span>
          )}
        </S.StyledModelPriceCell>
      )}
    </>
  );
};

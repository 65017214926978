import React from 'react';
import Contacts from './components/Contacts';
import Passport from './components/Passport';
import Address from './components/Address';
import { PersonalInfo } from '../PersonalInfo';

import * as Form from '../Form/style';
import LegalInfo from './components/LegalInfo';
import { LegalCheckbox } from './components/LegalCheckbox';

const fn = () => {};

const Insurer = ({ anketaState, scrollTo, onBlur = fn, onScroll = fn, onIsLegalSwitch = fn }) => {
  const innerProps = {
    data: anketaState,
    scrollTo,
    errors: anketaState.errors,
    disabled: anketaState.isDisabled,
    onBlur,
    onScroll,
  };
  const isLegal = innerProps.data?.isLegal;

  return (
    <Form.BlockWrapper>
      <Form.Header>Анкета Клиента</Form.Header>
      <LegalCheckbox
        name="isLegal"
        label="Юридическое лицо"
        disabled={innerProps.disabled}
        checked={isLegal}
        onSwitch={onIsLegalSwitch}
      />

      {isLegal ? (
        <LegalInfo {...innerProps} />
      ) : (
        <>
          <PersonalInfo {...innerProps} title="Личная информация" />
          <Contacts {...innerProps} />
          <Passport {...innerProps} />
          <Address {...innerProps} />
        </>
      )}
    </Form.BlockWrapper>
  );
};

export default Insurer;

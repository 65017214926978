import styled from 'styled-components';

const themes = {
  1: { color: 'var(--color-white)' },
  2: { color: 'var(--color-white_68)' },
  3: { color: 'var(--color-black)' },
  4: { color: 'var(--color-blue-3)' },
  5: { color: 'var(--color-red-2)' },
  6: { color: 'var(--color-orange-1)' },
};

const sizes = {
  s: { width: '16px', height: '16px' },
  m: { width: '24px', height: '24px' },
};

export const Container = styled.span`
  --color: ${(p) => (themes[p.theme] || themes['1']).color};
  --width: ${(p) => (sizes[p.size] || sizes['m']).width};
  --height: ${(p) => (sizes[p.size] || sizes['m']).height};

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: var(--width);
  height: var(--height);

  & > * {
    fill: var(--color);
    max-width: var(--width);
    max-height: var(--height);
  }
`;

import React from 'react';

import * as S from './TableHeader.styles';

export const TableHeader = () => {
  return (
    <S.Container>
      <S.FirstCell>VIN</S.FirstCell>
      <S.FlexCell>Автомобиль</S.FlexCell>
      <S.Cell>Макс. стоимость</S.Cell>
      <S.Cell>Залог</S.Cell>
      <S.Cell>Такси/каршеринг</S.Cell>
      <S.Cell>Ограничения</S.Cell>
      <S.Cell>ДТП</S.Cell>
      <S.LastCell></S.LastCell>
    </S.Container>
  );
};

export const APPLICATIONS_URL = 'api/applications';
export const DEALERS_URL = 'api/dealers';
export const DEALER_URL = 'api/dealer';
export const CAR_URL = 'api/car/instance';
export const STATES_URL = 'api/applications/states';
export const STATUS_CHAT_URL = (dealId) => `/api/deals/${dealId}/workflow`;
export const DEALS_COMMISSIONS_URL = '/api/request-deals-commissions';
export const POST_APPLICATION_COMMENT_URL = '/api/post-comment-requests';

export const ASSISTANCE_GET_APPLICATIONID = '/api/credit';
export const ASW_GET_APPLICATION_URL = '/api/assistance/application';
export const ASW_SELECT_OFFER_URL = '/api/assistance/select-offer';
export const ASW_CALCULATE_URL = '/api/assistance/calculate';
export const ASW_AGREEMENT_URL = '/api/assistance/agreement';
export const ASW_GET_POLICY_URL = '/api/assistance/get-policy';
export const ASW_CLIENT_DECLINE_URL = '/api/assistance/client-decline';
export const ASSISTANCE_ADD_FILE_URL = '/api/assistance/add-application-file';
export const ASSISTANCE_DEL_FILE_URL = '/api/assistance/delete-application-file';
export const ASSISTANCE_REQUEST_PRECALCULATIONS = '/api/assistance/pre-calculate';
export const ASSISTANCE_SAVE_PARAMS = '/api/assistance/save-params';
export const ASSISTANCE_POST_NEW_CALCULATION = '/api/assistance/new-calculation';
export const ASSISTANCE_REQUEST_SIGNING = '/api/assistance/request-signing';
export const ASSISTANCE_CONFIRM_SIGNING = '/api/assistance/confirm-signing';

export const INSURANCE_GET_APPLICATIONID = '/api/credit';
export const EOW_GET_APPLICATION_URL = '/api/insurance/application';
export const EOW_SELECT_OFFER_URL = '/api/insurance/select-offer';
export const EOW_CALCULATE_URL = '/api/insurance/calculate';
export const EOW_AGREEMENT_URL = '/api/insurance/agreement';
export const EOW_GET_POLICY_URL = '/api/insurance/get-policy';
export const EOW_CLIENT_DECLINE_URL = '/api/insurance/client-decline';
export const INSURANCE_ADD_FILE_URL = '/api/insurance/add-required-document';
export const INSURANCE_DEL_FILE_URL = '/api/insurance/delete-required-document';
export const INSURANCE_REQUEST_PRECALCULATIONS = '/api/insurance/pre-calculate';
export const INSURANCE_SAVE_PARAMS = '/api/insurance/save-params';
export const INSURANCE_POST_NEW_CALCULATION = '/api/insurance/new-calculation';
export const INSURANCE_REQUEST_SIGNING = '/api/insurance/request-signing';
export const INSURANCE_CONFIRM_SIGNING = '/api/insurance/confirm-signing';

export const GET_DEALER_INFO_URL = (dealerId) => `/api/dealers/${dealerId}/legals`;
export const DEAL_GET_URL = (id) => `/api/v1/loan-issue/applications/${id}/full`;
export const DEAL_GETSHORT_URL = (id) => `/api/v1/loan-issue/applications/${id}/short`;
export const DEAL_CREDIT_FUNDS_RECIPIENTS = (dealerId, type, providerId) => {
  let url = `/api/v2/provider-parameters/${dealerId}/funds-recipients?provider_type=${type}`;

  if (providerId) {
    url += `&accred_bank_id=${providerId}&accred_state=accredited`;
  }

  return url;
};
export const DEAL_CREDIT_RECIPIENT_URL = (bankId, dealerId, providerId, providerCodes) => {
  let url = `/api/provider-parameters/bank-accreditations?perPage=500`;
  if (dealerId) {
    url += `&dealerId=${dealerId}`;
  }
  if (bankId) {
    url += `&bankId=${bankId}`;
  }
  if (providerId) {
    url += `&providerId[]=${providerId}`;
  }
  if (providerCodes && providerCodes instanceof Array) {
    providerCodes.forEach((providerCode) => {
      url += `&providerCode[]=${providerCode}`;
    });
  }
  return url;
};
export const DEAL_SERVICE_RECIPIENT_URL = (id, carInstanceId) =>
  `/api/provider-parameter/payment-details?carInstanceId=${carInstanceId}&productId=${id}`;
export const DEAL_ADD_FILE_URL = () => `/api/v1/loan-issue/add-application-file`;
export const DEAL_ADD_FILES_URL = () =>
  `/api/v1/loan-issue/add-or-update-application-file-collection`;
export const DEAL_DEL_FILE_URL = () => `/api/v1/loan-issue/delete-application-file`;
export const DEAL_CHANGE_DEAL_URL = () => `/api/v1/loan-issue/final-parameters`;
export const RECALCULATION_FINISHED_URL = () => `/api/v1/loan-issue/recalculation-finished`;
export const DEAL_APPROVAL_URL = () => `/api/v1/loan-issue/request-approval`;
export const DEAL_CONFIRM_URL = () => `/api/v1/loan-issue/confirm-approval`;
export const DEAL_CONFIRM_MANUAL_FINANCED_URL = () => `/api/v1/loan-issue/manual-financed`;
export const DEAL_REAPPROVAL_URL = () => `/api/v1/loan-issue/reapproval-requested`;
export const DEAL_DOC_APPROVAL_URL = () => `/api/v1/loan-issue/documents-approval-requests`;
export const DEAL_FIN_APPROVAL_URL = () => `/api/v1/loan-issue/request-financed`;
export const DEAL_GEN_APPROVAL_URL = () => `/api/v1/loan-issue/prepared-documents`;
export const DEAL_DECLINE_URL = () => `/api/v1/loan-issue/customer-decline`;
export const DEAL_RESET_TO_NEW_URL = () => `/api/v1/loan-issue/reset-application`;
export const DEAL_CLOSE_URL = () => `/api/v1/loan-issue/close-application`;
export const DEAL_REQUEST_DOCUMENT_FINANCED_URL = () =>
  `/api/v1/loan-issue/request-document-financed`;

export const DEAL_CHANGE_PERSONAL_DATA = () => `/api/v1/loan-issue/change-personal-data`;
export const DEAL_RECALCULATION_DESIRED_URL = () => `/api/v1/loan-issue/recalculation-desired`;
export const DEAL_CHANGE_MANUAL_FINAL_PARAMS = () =>
  `/api/v1/loan-issue/change-manual-final-parameters`;
export const DEAL_CONFIRM_FINAL_PARAMS_URL = () => `/api/v1/loan-issue/confirm-final-parameters`;
export const DEAL_SAVE_FINAL_PARAMS_URL = () => `/api/v1/loan-issue/save-final-parameters`;
export const DEAL_CONFIRM_MANUAL_FINAL_PARAMS_URL = () =>
  `/api/v1/loan-issue/confirm-manual-final-parameters`;
export const DEAL_CONFIRM_RECALCULATION_DESIRED_URL = () =>
  `/api/v1/loan-issue/confirm-recalculation-desired`;
export const DEAL_GET_REQUESTED_PARAMS = (id) => `/api/loan-params/${id}/requested`;
export const DEAL_GET_REQUESTED_TERMS = (id) => `/api/loan-params/${id}/terms`;
export const DEAL_GET_APPROVED_PARAMS = (id) => `/api/loan-params/${id}/approved`;
export const DEAL_GET_FINAL_PARAMS = (id) => `/api/loan-params/${id}/final`;
export const DEAL_GET_FINANCED_PARAMS = (id) => `/api/loan-params/${id}/financed`;

export const DEAL_REQUEST_CONFIRM_SMS = 'api/v1/loan-issue/request-phone-verification';
export const DEAL_CONFIRM_CODE_VERIVICATION = 'api/v1/loan-issue/confirm-code-verification';
export const DEAL_GET_REQUIRED_DOCUMENT_TYPES = (id) =>
  `/api/v1/loan-issue/required-documents-types/${id}`;
export const DEAL_CHECK_DOCUMENTS = () => `/api/v1/loan-issue/check-documents`;
export const DEAL_CHECK_COMPATIBILITY = () => `/api/v1/loan-issue/check-compatibility`;

export const POST_FILE_URL = () => '/api/file/upload';
// todo: отрефактрить методы где это используется
export const GET_FILE_URL = (id) => `${process.env.REACT_APP_API_ENTRYPOINT}/files/${id}`;

export const GET_DETAIL_PARAMS_URL = (id) => `/api/loan-issue/calculation/${id}`;
export const GET_DEAL_APROVED_INFO_URL = (id) =>
  `/api/credit-parameters-storage/credit-approved/${id} `;
export const GET_DEAL_STATUS_URL = (id) => `api/deals/${id}/workflow?page=1`;
export const GET_CAR_INSTANCE_URL = (id) => `api/car-instance/${id}`;
export const GET_DEAL_TIMER = (id) => `api/v1/loan-issue/applications/${id}/timers`;
export const GET_DEAL_FILE_ID_BY_TYPE = (type, id) => `api/v1/loan-issue/files/${type}/${id}`;

export const POST_CAR_INFO = 'api/car/info';

export const CHECK_PTS_STATUS_URL = `/api/v1/loan-issue/pts-status`;

export const HELP_URLS = Object.freeze({
  osago: 'https://insurance.ecredit.one/help',
});

export const GET_LOST_DEALS_URL = 'worksheets/xlsx';

export const PRINT_DOCUMENT_URL = `${process.env.REACT_APP_API_ENTRYPOINT}/api/pdf`;

export const SUBSIDY_TYPES_URL = '/api/calculation/state-programs';

export const SUBSIDY_CALCULATION_URL = (id, modelId, price) =>
  `/api/calculation/state-programs/${id}/subsidy?modelId=${modelId}&price=${price}`;

export const NOTIFICATIONS_GET_URL = (userId) => `/api/notifications/right/${userId}`;
export const NOTIFICATIONS_MAINPAGE_GET_URL = (userId, isEmailBank) =>
  `/api/notifications/right/${userId}?isEmailBank=${isEmailBank}&types[]=approved&types[]=customer-decline-after&perPage=9999`;
export const NOTIFICATIONS_READ_URL = (id) => `/api/notifications/read/${id}`;
export const DEAL_LOG_URL = () => `/api/v1/loan-issue/log`;
export const ISSUED_PRODUCTS_URL = (productId, applicationId) =>
  `/api/${productId}/${applicationId}/issued-data`;

export const QR_CODE_FILE_UPLOAD_URL = '/api/v1/loan-issue/upload-qr';

export const FINAL_PARAMS_GET_DEALS_URL = (applicationId) =>
  `/api/v1/application/${applicationId}/deals/getdata`;
export const FINAL_PARAMS_GET_DATA_URL = (dealId) => `/api/v1/loan-approval-deal/${dealId}/getdata`;
export const FINAL_PARAMS_SET_DATA_URL = (dealId) => `/api/v1/loan-approval-deal/${dealId}/setdata`;

export const GET_EPOINTS_USER_INFO = '/api/v1/epoints/user/info';
export const EPOINTS_REGISTRATION = '/api/v1/epoints/user/registration/epoints_digital_deal';
export const EPOINTS_VALIDATE_EMAIL = '/api/v1/epoints/user/email/validate';
export const EPOINTS_PHONENUMBER_CONFIRM = '/api/v1/epoints/user/phone-number/confirm';
export const EPOINTS_PHONENUMBER_RESEND_CODE =
  '/api/v1/epoints/user/phone-number/resend-confirm-code';

export const PICKER_CHECK_URL = (id) => `api/v1/application/status/${id}`;
export const PPS_DEALERS_INFO = `api/pps/dealers`;

export const POST_CAR_VIN_INFO = '/api/v1/car/scoring';
export const GET_VIN_HISTORY = (userId, page, perPage) =>
  `/api/v1/car/scoring/list?userId=${userId}&page=${page}&perPage=${perPage}`;

export const FINANCE_THE_APPLICATION = '/api/bankbus/status/financed';

import styled from 'styled-components';

import { MainAnketa } from 'components/modules/MainAnketa';
import { Button } from 'components/uikit/Button/Button';
import { Badge } from 'components/uikit/Badge/Badge';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 20px;
  grid-template-areas:
    'ma ma ma ma ma ma ma ma'
    '. sa sa sb sb sb sb .'
    '. . . dt dt dt dt .';
  padding-bottom: 180px;
`;

export const MainAnketaStyled = styled(MainAnketa)`
  grid-area: ma;
`;

export const SendButton = styled(Button)`
  grid-area: sb;
`;

export const SaveButton = styled(Button)`
  grid-area: sa;
`;

export const StyledBadge = styled(Badge)`
  position: absolute;
  top: -10px;
  right: 0;
`;

export const SubscribeDocTooltip = styled.div`
  display: flex;
  justify-content: center;
  grid-area: dt;
  font-size: 15px;
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  width: 250px;
`;

export const Error = styled.div`
  text-align: center;
  font-size: 24px;
  margin-top: 150px;
`;

export const rplParams = {
  rpl: 'bank_title,bank_id,bank_id,bank_title,calculation',
  calculation: 'interest_rate,life_term,payment,payment2',
};

export const DISPLAYED_BANK_GROUP = 5;
export const PAYMENT_TYPE = 1;
export const INTEREST_RATE_TYPE = 2;
export const OVERPAY_TYPE = 3;

export const CREDIT_SUBTYPE_CLASSIC = 1;
export const CREDIT_SUBTYPE_GOS = 2;
export const CREDIT_SUBTYPE_REST = 3;
export const CREDIT_SUBTYPE_MANUFACTURER = 4;
export const CREDIT_SUBTYPE_MANUFACTURER_DISCOUNT = 5;
export const CREDIT_SUBTYPE_DIRECT_DISCOUNT = 10;

export const sortingField = {
  [PAYMENT_TYPE]: 'payment',
  [INTEREST_RATE_TYPE]: 'interestRate',
  [OVERPAY_TYPE]: 'overpayment',
};

export const PRINT_TYPE_PAYMENTS_GRAPH = 'payments';
export const PRINT_TYPE_CREDIT = 'credit';

export const printDocumentsSettings = [
  { id: PRINT_TYPE_PAYMENTS_GRAPH, value: 'График платежей' },
  { id: PRINT_TYPE_CREDIT, value: 'Кредитный расчет' },
];

export const DEALER_DOP_OTHERS_ID = -10;

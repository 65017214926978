import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';

import { InputMask } from 'utils/inputMask';
import { FIELDS, DISCOUNTS } from 'store/carInstance/constants';
import Confirm from 'components/primitives/confirm';
import { convertPriceToString } from 'utils/convertPrice';
import { onlyNumber } from 'utils/stringUtils';

import { AUTO_PARAMS as auto, BLOCK_MAIN } from '../../constants';
import * as Helper from './helper';
import * as S from './style';

const DEFAULT_VALUE = {
  id: null,
  value: null,
  disabled: true,
  loading: true,
  discount: 0,
  discountDirect: 0,
};

const fn = () => {};

const BlockMain = ({
  scrollTo,
  carInstance,
  patchIsNew,
  hiddenFields,
  disableFields,
  errors,
  brands,
  models,
  versions,
  brandsFetch,
  modelsFetch,
  versionsFetch,
  allFetch,
  carInfoFetch,
  isInCredit,
  creditBanksList,
  creditBankId,
  mileage,
  setMileage,
  setImage = fn,
  onScroll = fn,
  onChange = fn,
  onSetCarInstance = fn,
  onPatch = fn,
  onBlur = fn,
  onSetYear = fn,
  onChangeCarModel = fn,
  onBankChange = fn,
  onClickIsInCredit = fn,
  onClickFullCalc = null,
  showRestrictions = false,
  disabled = false,
  requiredFields = [],
}) => {
  const ref = useRef();
  const currentYear = new Date().getFullYear();
  const [yearList, setYearList] = useState(
    Helper.getYearList(carInstance.isNew ? currentYear - 2 : 1990)
  );
  const [brand, setBrand] = useState(DEFAULT_VALUE);
  const [model, setModel] = useState(DEFAULT_VALUE);
  const [version, setVersion] = useState(DEFAULT_VALUE);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [offerPrice, setOfferPrice] = useState('');
  const [yearId, setYearId] = useState(Helper.findYearId(yearList, carInstance.year));
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const isShowDiscountMfrCheckBox =
    !hiddenFields.includes(FIELDS.offerPrice) && carInstance.isNew && version.discount > 0;
  const isShowDiscountDirectCheckBox =
    !hiddenFields.includes(FIELDS.offerPrice) && carInstance.isNew && version.discountDirect > 0;
  const isShowOfferPrice =
    (isShowDiscountMfrCheckBox && carInstance.discountType === DISCOUNTS.mfr) ||
    (isShowDiscountDirectCheckBox && carInstance.discountType === DISCOUNTS.direct);
  const isMoreRows = Boolean(
    onClickFullCalc || showRestrictions || isShowDiscountMfrCheckBox || isShowDiscountDirectCheckBox
  );

  const wrappedSetBrand = (value) => {
    const isEqual = JSON.stringify(value) === JSON.stringify(brand);
    if (!patchIsNew && !isEqual) {
      setBrand(value);
      return true;
    }

    return false;
  };

  const wrappedSetModel = (value) => {
    const isEqual = JSON.stringify(value) === JSON.stringify(model);
    if (!patchIsNew && !isEqual) {
      setModel(value);
      return true;
    }

    return false;
  };

  const wrappedSetVersion = (value) => {
    const isEqual = JSON.stringify(value) === JSON.stringify(version);
    if (!patchIsNew && !isEqual) {
      setVersion(value);
      return true;
    }

    return false;
  };

  useEffect(() => {
    onClickIsInCredit({ isInCredit });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ref && ref.current && scrollTo === BLOCK_MAIN.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    if (carInstance.id) {
      const year = Helper.getYearList(carInstance.isNew ? currentYear - 2 : 1990);
      setYearList(year);
    }

    wrappedSetBrand({ ...DEFAULT_VALUE });
    wrappedSetModel({ ...DEFAULT_VALUE });
    wrappedSetVersion({ ...DEFAULT_VALUE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance.isNew, carInstance.id]);

  useEffect(() => {
    const brand = carInstance.brand;

    const selected = Helper.getItemByValue(brands, brand);

    wrappedSetBrand({
      id: selected.id,
      value: selected.value || null,
      disabled: false,
      loading: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance.brand, patchIsNew]);

  useEffect(() => {
    const model = carInstance.model;

    const selected = Helper.getItemByValue(models, model);

    wrappedSetModel({
      id: selected.id,
      value: selected.value || null,
      disabled: false,
      loading: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models, carInstance.model, patchIsNew]);

  useEffect(() => {
    const version = carInstance.version;
    const selected = Helper.getItemByValue(versions, version);

    wrappedSetVersion({
      id: selected.id,
      value: selected.value || null,
      disabled: false,
      loading: false,
      discount: Number(selected.discount || 0),
      discountDirect: Number(selected.discountDirect || 0),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions, carInstance.model, carInstance.version, patchIsNew]);

  useEffect(() => {
    if (model.value) {
      setImage(models.find((item) => item.value === model.value) || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    const price = Number(carInstance.price || 0);
    const offerPrice = Number(carInstance.offerPrice || 0);

    setSelectedPrice(String(price || ''));
    setOfferPrice(String(offerPrice || price || ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance.price, carInstance.offerPrice]);

  useEffect(() => {
    setYearId(Helper.findYearId(yearList, carInstance.year));
  }, [yearList, carInstance.year]);

  // onChange
  const brandsChangeHandler = ({ brand: selected }) => {
    const changed = wrappedSetBrand({ value: selected.value, disabled: false, loading: false });

    if (changed) {
      wrappedSetModel({ ...DEFAULT_VALUE });
      wrappedSetVersion({ ...DEFAULT_VALUE });

      onPatch(
        {
          ...carInstance,
          brand: Helper.getListValueById(brands, selected.id) || selected.value,
          model: '',
          version: '',
          price: Number(selectedPrice),
          offerPrice: Number(selectedPrice),
          defaultPrice: 0,
          enginePower: carInstance.enginePower,
          discountType: '',
        },
        true,
        ['model', 'version'],
        { brandId: selected.id, vehicleStatus: carInstance.isNew ? 'new' : 'old' }
      );
    }
  };

  const modelsChangeHandler = ({ model: selected }) => {
    const changed = wrappedSetModel({ value: selected.value, disabled: false, loading: false });

    if (changed) {
      onChangeCarModel();
      wrappedSetVersion({ ...DEFAULT_VALUE });

      onPatch(
        {
          ...carInstance,
          model: Helper.getListValueById(models, selected.id) || selected.value,
          version: '',
          price: Number(selectedPrice),
          offerPrice: Number(selectedPrice),
          defaultPrice: 0,
          enginePower: carInstance.enginePower,
          discountType: '',
        },
        true,
        ['version'],
        {
          brandId: brand.id,
          modelId: selected.id,
          vehicleStatus: carInstance.isNew ? 'new' : 'old',
        }
      );
    }
  };

  const configChangeHandler = ({ version: selected }) => {
    const selectedVersion = Helper.getListValueById(versions, selected.id) || selected.value;
    const enginePower = selected.enginePower || '';
    const price = selected.price || 0;

    setSelectedPrice(String(price));
    setOfferPrice(String(price));
    onSetCarInstance({ ...carInstance, enginePower });

    onPatch({
      ...carInstance,
      enginePower,
      price,
      offerPrice: price,
      defaultPrice: price,
      version: selectedVersion,
      discountType: '',
    });
  };

  const mileageChangeHandler = ({ mileage }) => {
    setMileage(mileage);
    onChange('mileage');
  };

  const mileageBlurHandler = (item) => {
    onBlur({ mileage: Number(onlyNumber(item.mileage) || 0) || null });
  };

  const yearChangeHandler = ({ year }) => {
    setYearId(year);
    onSetYear({ year });
    onChange('year');
  };

  const changePriceHandler = (value) => {
    const discount = Helper.getDiscount(carInstance.discountType, version);

    setSelectedPrice(value.price);
    setOfferPrice(String(Helper.getOfferPrice(value.price, discount, discount > 0)));
    onChange('price');
  };

  const priceBlurHandler = (item) => {
    const price = Number(onlyNumber(item.price) || 0) || 0;
    const discount = Helper.getDiscount(carInstance.discountType, version);

    onBlur({
      price,
      offerPrice: Helper.getOfferPrice(price, discount, discount > 0),
    });
  };

  const confirmHandler = () => {
    onClickFullCalc({ fullCalculation: false });
    setIsShowConfirm(false);
  };

  const declineHandler = () => {
    setIsShowConfirm(false);
  };

  const discountMfrHandler = (check) => {
    const discountType = check ? DISCOUNTS.mfr : '';
    const offerPrice = Helper.getOfferPrice(selectedPrice, version.discount, check);

    setOfferPrice(String(offerPrice));
    onPatch({ ...carInstance, offerPrice, discountType });
  };

  const discountDirectHandler = (check) => {
    const discountType = check ? DISCOUNTS.direct : '';
    const offerPrice = Helper.getOfferPrice(selectedPrice, version.discountDirect, check);

    setOfferPrice(String(offerPrice));
    onPatch({ ...carInstance, offerPrice, discountType });
  };

  const fetchBrands = carInfoFetch || allFetch || brandsFetch;
  const fetchModels = carInfoFetch || allFetch || brandsFetch || modelsFetch;
  const fetchVersions = carInfoFetch || allFetch || brandsFetch || modelsFetch || versionsFetch;

  return (
    <>
      <S.GridStyled ref={ref} moreRow={isMoreRows}>
        <S.Brands
          name="brand"
          label="Марка"
          disabled={fetchBrands || disabled}
          id="brand"
          onlyid={false}
          defaultValue={brand}
          value={brand}
          list={brands}
          fetch={fetchBrands}
          onChange={brandsChangeHandler}
          error={errors.brand}
          search
          required={requiredFields?.includes('brand')}
        />
        <S.Models
          name="model"
          label="Модель"
          disabled={fetchModels || !brand.value || disabled}
          id="model"
          onlyid={false}
          value={model}
          defaultValue={model}
          list={models}
          fetch={fetchModels}
          onChange={modelsChangeHandler}
          error={errors.model}
          search
          required={requiredFields.includes('model')}
        />
        {!hiddenFields.includes(FIELDS.version) && (
          <S.Config
            name="version"
            label="Комплектация"
            disabled={
              fetchVersions || !model.value || disabled || disableFields.includes('version')
            }
            id="version"
            onlyid={false}
            value={version}
            defaultValue={version}
            list={versions}
            fetch={fetchVersions}
            onChange={configChangeHandler}
            error={errors.version}
          />
        )}

        <S.Year
          required={requiredFields.includes('year')}
          name="year"
          id="year"
          label="Год выпуска"
          list={yearList}
          onlyid={false}
          defaultValue={yearId}
          value={yearId}
          error={errors.year}
          onChange={yearChangeHandler}
          disabled={disabled}
        />
        {!hiddenFields.includes(FIELDS.price) && (
          <S.Price
            required={requiredFields.includes('price')}
            name="price"
            id="price"
            label="Стоимость автомобиля"
            value={selectedPrice}
            defaultValue={selectedPrice}
            onBlur={priceBlurHandler}
            onChange={changePriceHandler}
            error={errors.price}
            disabled={disabled}
            dataTest="carPrice"
          />
        )}
        {isShowOfferPrice && (
          <S.OfferPrice
            name="offerPrice"
            id="offerPrice"
            label="Стоимость авто с учётом скидки"
            value={offerPrice}
            defaultValue={offerPrice}
            disabled={true}
            dataTest="carOfferPrice"
          />
        )}
        {!hiddenFields.includes(FIELDS.mileage) && !carInstance.isNew && (
          <S.Mileage
            required={requiredFields.includes('mileage')}
            isControlledComponent
            name={auto.mileage}
            id={auto.mileage}
            label="Пробег, км"
            val={mileage}
            mask={InputMask.MILEAGE}
            error={errors[auto.mileage]}
            onChange={mileageChangeHandler}
            onBlur={mileageBlurHandler}
            disabled={disabled}
          />
        )}

        {onClickFullCalc && (
          <S.FullCalculationCheckBox
            name="fullCalculation"
            label={<span>Есть данные VIN и ПТС/ЭПТС</span>}
            checked
            size={19}
            borderRadius={5}
            disabled
          />
        )}
        {isShowDiscountMfrCheckBox && (
          <S.DiscountCheckBox
            name="isSetDiscountMfr"
            value={carInstance.discountType === DISCOUNTS.mfr}
            onChange={() => discountMfrHandler(carInstance.discountType !== DISCOUNTS.mfr)}
            disabled={disabled}
            dataTest="discount-mfr"
          >
            {
              <span>
                {'Скидка производителя '}
                <span style={{ whiteSpace: 'nowrap' }}>
                  {convertPriceToString(version.discount)} ₽
                </span>
              </span>
            }
          </S.DiscountCheckBox>
        )}
        {isShowDiscountDirectCheckBox && (
          <S.DiscountDirectCheckBox
            name="isSetDiscountDirect"
            value={carInstance.discountType === DISCOUNTS.direct}
            onChange={() => discountDirectHandler(carInstance.discountType !== DISCOUNTS.direct)}
            disabled={disabled}
            dataTest="discount-direct"
          >
            <span>
              {'Скидка Direct '}
              <span style={{ whiteSpace: 'nowrap' }}>
                {convertPriceToString(version.discountDirect)} ₽
              </span>
            </span>
          </S.DiscountDirectCheckBox>
        )}
        {showRestrictions && (
          <>
            <S.CreditCheckBox
              name="isInCredit"
              label={<span style={{ fontSize: 15 }}>Авто в кредит</span>}
              checked={isInCredit}
              onClick={onClickIsInCredit}
              size={19}
              borderRadius={5}
              disabled={disabled}
            />

            {isInCredit && (
              <S.CreditBanks
                id="bank"
                name="bank"
                label="Банк"
                onlyid={false}
                value={creditBankId}
                list={creditBanksList}
                onChange={onBankChange}
                search
                error={errors.selectedBank}
                required={true}
                disabled={disabled}
              />
            )}
          </>
        )}
      </S.GridStyled>

      <Confirm
        title={
          <>
            Продолжить заполнение Анкеты
            <br />
            без указания полных данных по автомобилю?
          </>
        }
        description={
          <>
            Без указания VIN и ПТС/ЭПТС возможно получить только предварительный расчёт.
            <br />
            Для оформления полиса потребует внести эти данные на следующем шаге.
          </>
        }
        isShow={isShowConfirm}
        onConfirm={confirmHandler}
        onDecline={declineHandler}
      />
    </>
  );
};

export default BlockMain;

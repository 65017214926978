import styled from 'styled-components';

import { MenuItem } from 'components/uikit/MenuItem/MenuItem';
import { Menu } from 'components/uikit/Menu/Menu';
import { TextInput } from 'components/uikit/Form/TextInput/TextInput';

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: -12px;
  margin-right: -10px;
`;

export const DealerGroup = styled(Group)`
  flex: 1;
  overflow: hidden;
`;

export const GroupTitle = styled.h6`
  margin: 0px;
  padding: 0px 10px 0px 12px;
  font: var(--font-caption-11);
  color: var(--color-white_50);
`;

export const DealerMenu = styled(Menu)`
  flex: 1;
  overflow-y: auto;
`;

export const SubMenuItem = styled(MenuItem)`
  width: auto;
  padding-left: 24px;
`;

export const SearchInput = styled(TextInput)`
  margin: 8px 10px 8px 12px;
`;

export const SearchInfo = styled.div`
  padding: 8px 12px 8px 24px;
  font: var(--font-regular-11);
  color: var(--color-white_68);
`;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { toggleOpen } from 'store/applications';
import { autoScrollVertical } from 'utils/common';
import { applicationOpenTrack } from 'metrika/applications';
import { getShortFio } from 'utils/stringUtils';

import { ApplicationDetail } from '../ApplicationDetail';
import { KaskoLampItem } from './components/KaskoLampItem';
import { AssistanceLampItem } from './components/AssistanceLampItem';
import { Semaphore } from './components/Semaphore';
import { OpenButton } from './components/OpenButton';

import * as help from '../../helpers.js';
import * as S from '../../style.js';

export const ApplicationRow = ({ application, showOpenButton }) => {
  const rootDispatch = useDispatch();

  const open = useSelector(({ applications }) => applications.opened.includes(application.id));

  const creditApp = help.getCreditApps(application)?.[0]?.body || null;
  const source = help.applicationSource(application.source);
  let insuranceApp = null;
  let assistanceApp = null;

  if (application?.ids?.insurance) {
    insuranceApp = (help.getInsuranceApps(application) || []).find(
      (a) => String(a?.id) === String(application.ids.insurance)
    )?.body;
  } else {
    insuranceApp = (help.getInsuranceApps(application) || []).sort(
      (a1, a2) => Number(a1?.id) - Number(a2.id)
    )[0]?.body;
  }

  if (application?.ids?.assistance) {
    assistanceApp = (help.getAssistanceApps(application) || []).find(
      (a) => String(a?.id) === String(application.ids.assistance)
    )?.body;
  } else {
    assistanceApp = (help.getAssistanceApps(application) || []).sort(
      (a1, a2) => Number(a1?.id) - Number(a2.id)
    )[0]?.body;
  }

  const date = moment(application?.date || '', 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY');
  const dealerName = application?.dealer?.name || '';
  const customerName = help.getCustomerName(application?.customer);
  let ksName = '';

  if (creditApp?.ks) {
    ksName = getShortFio(creditApp.ks);
  } else if (assistanceApp?.fiManager) {
    ksName = getShortFio(assistanceApp.fiManager);
  } else if (insuranceApp?.fiManager) {
    ksName = getShortFio(insuranceApp.fiManager);
  }

  const clickHandler = () => {
    rootDispatch(toggleOpen(application.id));
    setTimeout(() => {
      if (!open) {
        applicationOpenTrack();
        autoScrollVertical(application.id, -170);
      }
    }, 700);
  };

  return (
    <>
      <S.Row
        data-test="applicationListDeal"
        id={application.id}
        showOpenButton={showOpenButton}
        onClick={clickHandler}
      >
        <S.Cell isGrey isClickable>
          {dealerName}
        </S.Cell>

        <S.Cell isGrey txtCap isClickable>
          {ksName}
        </S.Cell>

        <S.Cell isGrey isClickable data-test="applicationDate">
          {date}
        </S.Cell>

        <S.Cell txtBold txtCap isClickable>
          {customerName}
        </S.Cell>

        <S.Cell>
          <Semaphore
            application={application}
            closeReason={creditApp?.closeReason}
            isShowEmpty={!creditApp}
          />
        </S.Cell>

        <S.Cell txtCenter>
          <AssistanceLampItem product={assistanceApp} source={source} />
          <KaskoLampItem product={insuranceApp} />
        </S.Cell>

        {showOpenButton && (
          <S.Cell zIndex={true}>
            <OpenButton application={application} />
          </S.Cell>
        )}
      </S.Row>

      {open && <ApplicationDetail application={application} showOpenButton={showOpenButton} />}
    </>
  );
};

import styled from 'styled-components';

export const Count = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 15px;
  height: 15px;
  background-color: var(--color-grey-8);
  color: var(--color-white);
  border-radius: 25px;
  font-size: 11px;
  padding: 0 2px;
`;

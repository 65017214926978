import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { LogoSmallImg, LogoBigImg, EPointsLogoSImg } from 'assets/images';
import { Menu } from 'components/uikit/Menu/Menu';
import { Avatar } from 'components/uikit/Avatar/Avatar';

import { UserPanel } from './UserPanel/UserPanel';
import { useMainMenuPresenter } from './MainMenu.presenter';

import * as S from './MainMenu.styles';

export const MainMenu = ({ isOpen, onToggle }) => {
  const presenter = useMainMenuPresenter(isOpen, onToggle);
  const toggleItem = presenter.toggleItem;
  const newsItem = presenter.newsItem;
  const userItem = presenter.userItem;
  const ePointsItem = presenter.ePointsItem;

  const UserTitle = (
    <S.UserBlock>
      <Avatar name={userItem.title} theme="1" />
      {isOpen && (
        <S.UserInfo>
          <S.UserNameInfo>{userItem.title}</S.UserNameInfo>
          <S.UserMoreInfo>{userItem.dealer}</S.UserMoreInfo>
        </S.UserInfo>
      )}
    </S.UserBlock>
  );

  return (
    <OutsideClickHandler onOutsideClick={presenter.onCloseSubMenu}>
      <S.Container isOpen={isOpen} data-test={`mainMenu ${isOpen ? 'expanded' : 'collapsed'}`}>
        {ePointsItem.isEPointsDigitalDealMember && (
          <S.EPointsItem isOpen={isOpen}>
            {ePointsItem.title}
            <S.EPointsWrapper isOpen={isOpen}>
              <EPointsLogoSImg />
              {isOpen && ePointsItem.balance}
            </S.EPointsWrapper>
          </S.EPointsItem>
        )}

        <S.Group>
          <S.Logo isOpen={isOpen} data-test="openMainPage" onClick={presenter.onLogoClick}>
            {isOpen ? <LogoBigImg /> : <LogoSmallImg />}
          </S.Logo>

          {userItem.isVisible && (
            <S.UserMenuItem
              title={UserTitle}
              tooltip={userItem.tooltip}
              theme="1"
              isActive={userItem.isActive}
              dataTest="openProfileMenu"
              onClick={userItem.onClick}
            >
              {isOpen && <S.UserBlockIcon isActive={userItem.isActive} />}
            </S.UserMenuItem>
          )}

          {newsItem.isVisible && (
            <S.TopMenuItem
              title={newsItem.title}
              tooltip={newsItem.tooltip}
              Icon={newsItem.Icon}
              theme="1"
              dataTest="openNewsPage"
              onClick={newsItem.onClick}
            >
              {isOpen && <S.NewsIcon />}
            </S.TopMenuItem>
          )}
        </S.Group>

        <S.Group>
          <Menu items={presenter.menuItems} MenuItemComponent={S.TopMenuItem} theme="1" />
        </S.Group>

        <S.Group>
          {toggleItem.isVisible && (
            <S.TopMenuItem
              title={toggleItem.title}
              tooltip={toggleItem.tooltip}
              Icon={toggleItem.Icon}
              theme="2"
              dataTest="mainMenuToggleButton"
              onClick={toggleItem.onClick}
            />
          )}
        </S.Group>
      </S.Container>

      {presenter.isShowUserPanel && (
        <S.SubPanelContainer isOpen={isOpen}>
          <UserPanel onClose={presenter.onCloseSubMenu} />
        </S.SubPanelContainer>
      )}
    </OutsideClickHandler>
  );
};

import { useState, useLayoutEffect, useEffect } from 'react';

const TOOLTIP_MARGIN = 15;

export const useTooltipPresenter = (tooltipRef, targetRef, startPosition, showElement) => {
  const [position, setPosition] = useState(startPosition);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [isShow, setIsShow] = useState(showElement);

  const presenter = {
    coordinates,
    position,
    isShow,

    calculatePosition(position) {
      if (!tooltipRef.current) {
        return;
      }

      const tooltip = tooltipRef.current.getBoundingClientRect();

      if (position === 'left' && tooltip.x < TOOLTIP_MARGIN) {
        setPosition('right');
        return;
      }

      if (position === 'right' && tooltip.x + tooltip.width > window.innerWidth - TOOLTIP_MARGIN) {
        setPosition('left');
        return;
      }

      if (position === 'top' && tooltip.y < TOOLTIP_MARGIN) {
        setPosition('bottom');
        return;
      }

      if (
        position === 'bottom' &&
        tooltip.y + tooltip.height > window.innerHeight - TOOLTIP_MARGIN
      ) {
        setPosition('top');
        return;
      }
    },

    calculateCoordinates(position) {
      if (!targetRef?.current) {
        return;
      }

      const target = targetRef.current.getBoundingClientRect();
      switch (position) {
        case 'left': {
          setCoordinates({ x: target.x, y: target.y + target.height / 2 });
          return;
        }
        case 'right': {
          setCoordinates({ x: target.x + target.width, y: target.y + target.height / 2 });
          return;
        }
        case 'top': {
          setCoordinates({ x: target.x + target.width / 2, y: target.y });
          return;
        }
        case 'bottom': {
          setCoordinates({ x: target.x + target.width / 2, y: target.y + target.height });
          return;
        }
        default: {
          // do nothing
        }
      }
    },
  };

  useEffect(() => {
    setIsShow(showElement);
  }, [showElement]);

  useEffect(() => {
    const targetElement = targetRef?.current;

    if (!targetElement) {
      return;
    }
    const hideTooltipHandle = () => setIsShow(false);
    window.addEventListener('scroll', hideTooltipHandle);
    window.addEventListener('resize', hideTooltipHandle);

    return () => {
      targetElement.removeEventListener('scroll', hideTooltipHandle);
      targetElement.removeEventListener('resize', hideTooltipHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (showElement) {
      presenter.calculateCoordinates(position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showElement, position]);

  useLayoutEffect(() => {
    presenter.calculatePosition(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, startPosition]);

  return presenter;
};

import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import * as urls from 'constants/url';
import { configWithHeadersLdJSON } from './common';
import { ASW_NAMEPLATES } from 'store/assistanceCalculation/resources';
import { changeColorInState } from 'utils/getStatusInfo';

export const getOrderById = async (id) => {
  try {
    const { data } = await request.get(`/api/ors/orders/${id}`).catch(throwCustomError);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getAssistanceCalcApplicatioIdByOrders = async ({
  carInstanceId,
  dealerId,
  customerId,
}) => {
  try {
    const { data } = await request
      .post('/api/ors/orders/assistance', {
        carInstanceId,
        dealerId,
        customerId,
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const aswAddNewOrder = async ({ carInstanceId, dealerId, customerId }) => {
  try {
    const data = await request
      .post('/api/ors/orders/assistance/add', {
        carInstanceId,
        dealerId,
        customerId,
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getApplicationDataRequest = async (applicationId) => {
  try {
    const url = urls.ASW_GET_APPLICATION_URL;

    const { data } = await request
      .post(url, { applicationId }, configWithHeadersLdJSON)
      .catch(throwCustomError);

    if (!data?.application) {
      return {
        applicationParams: {},
        offers: [],
        nameplate: ASW_NAMEPLATES.unknown,
      };
    }

    const applicationData = data.application;

    if (!applicationData.applicationParams) {
      applicationData.applicationParams = {};
    }

    if (!applicationData.offers) {
      applicationData.offers = [];
    }

    if (!applicationData?.state.sysName) {
      applicationData.state = ASW_NAMEPLATES.unknown;
    } else {
      applicationData.state = changeColorInState(applicationData.state, 'assistance');
    }

    return applicationData;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const aswCalculateRequest = async (params) => {
  try {
    const url = urls.ASW_CALCULATE_URL;

    const response = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealerLegalsRequest = async (dealerId) => {
  try {
    const { data } = await request.get(urls.GET_DEALER_INFO_URL(dealerId)).catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const selectOfferRequest = async (params) => {
  try {
    const url = urls.ASW_SELECT_OFFER_URL;

    const { data } = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const agreementRequest = async (params) => {
  try {
    const url = urls.ASW_AGREEMENT_URL;

    const { data } = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getPolicyRequest = async (params) => {
  try {
    const url = urls.ASW_GET_POLICY_URL;

    const { data } = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const addFileASWRequest = async (applicationId, fileId, fileType, ext) => {
  try {
    const data = { applicationId, fileId, fileType, ext };

    const res = await request.post(urls.ASSISTANCE_ADD_FILE_URL, data).catch(throwCustomError);

    return res.data?.result || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const delFileASWRequest = async (fileId) => {
  try {
    const data = { fileId };

    await request.post(urls.ASSISTANCE_DEL_FILE_URL, data).catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const saveParams = async (params) => {
  try {
    const url = urls.ASSISTANCE_SAVE_PARAMS;

    const response = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const aswClientDeclineRequest = async ({ applicationId }) => {
  try {
    const url = urls.ASW_CLIENT_DECLINE_URL;

    const { data } = await request
      .post(url, { applicationId }, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const postNewCalculation = async ({ applicationId }) => {
  try {
    const url = urls.ASSISTANCE_POST_NEW_CALCULATION;

    const { data } = await request
      .post(url, { applicationId }, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const requestSigning = async (body) => {
  try {
    const url = urls.ASSISTANCE_REQUEST_SIGNING;

    const { data } = await request.post(url, body).catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const confirmSigning = async (body) => {
  try {
    const url = urls.ASSISTANCE_CONFIRM_SIGNING;

    const { data } = await request.post(url, body).catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const aswCancellationRequest = async (applicationId) => {
  const url = 'api/assistance/cancelation';
  const res = await request.post(url, { applicationId }).catch(throwCustomError);

  return res.data || null;
};

import * as yup from 'yup';
import moment from 'moment';
import { requiredTest } from 'utils/validationUtils/validators';
import { MESS_REQUIRED_FIELD, MESS_NEED_TO_CHANGE_PASSPORT } from 'utils/validationUtils/messages';
import { validateEmail } from 'utils/validationUtils/validators';
import { driverLicenseRegexp, fioRegexp } from './regexps';

// Personal info

export const fio = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test('incorrect', 'Некорректное ФИО', (value) => fioRegexp.test(value));

export const bday = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test(
    'incorrect_date',
    'Неверный формат даты',
    (value) => value && value.indexOf('_') === -1 && moment(value, 'DDMMYYYY').isValid()
  )
  .test('incorrect_date', 'Неверный формат даты', (value) => value && value.length === 8)
  .test('incorrect_date', 'Меньше 18 лет', (value) => {
    const lastValidDate = moment().subtract(18, 'years');
    const bd = moment(value, 'DDMMYYYY');

    const diff = lastValidDate.diff(bd);

    return diff >= 0;
  });

// Contacts

export const insurerPhone = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test('required_field', MESS_REQUIRED_FIELD, (value) => value !== '+7 (___) ___-__-__')
  .test(
    'incorrect_date',
    'Неверный формат телефона',
    (value) => value && value.indexOf('_') === -1
  );

export const insurerEmail = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test('incorrect_date', 'Неверный адрес', validateEmail);

// Passport

export const insurerPassportSerialNum = yup
  .string()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .matches(/\d{10}/, 'Неверный номер');

export const insurerPassportDate = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test(
    'incorrect_date',
    'Неверный формат даты',
    (value) => value && value.indexOf('_') === -1 && moment(value, 'DDMMYYYY').isValid()
  )
  .test('incorrect_date', 'Неверный формат даты', (value) => value && value.length === 8)
  .test('incorrect_date', 'Не ранее 14 лет', function (value) {
    const { bday } = this.parent;

    const lastValidDate = moment(bday, 'DDMMYYYY').add(14, 'years');

    const date = moment(value, 'DDMMYYYY');
    const diff = lastValidDate.diff(date);

    return !bday || `${bday}`.length < 8 || !value || diff <= 0;
  })
  .test('incorrect_date', MESS_NEED_TO_CHANGE_PASSPORT, function () {
    const { bday, insurerPassportDate } = this.parent;

    const now = moment().subtract(90, 'days');
    const bd = moment(bday, 'DD.MM.YYYY');
    const id = moment(insurerPassportDate, 'DD.MM.YYYY');

    const age = now.diff(bd, 'years', true);
    const passportAge = id.diff(bd, 'years', true);

    if (age >= 20 && passportAge < 20) return false;
    if (age >= 45 && passportAge < 45) return false;
    return true;
  });

export const insurerIssuerCode = yup
  .string()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .matches(/\d{3}-\d{3}/, 'Неверный формат кода');

export const insurerIssuer = yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const insurerBirthPlace = yup.string();

// Address

export const insurerIndex = yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest);
export const insurerAddress = yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest);

// Driver's lisence

const wrongDriverLicense = 'неверный номер удостоверения';

export const prevLicenseSeriaNum = yup
  .mixed()
  .test(
    'wrongPrevDriverLicense',
    wrongDriverLicense,
    (value) => !value || driverLicenseRegexp.test(value)
  );

export const licenseSeriaNum = yup
  .mixed()
  .test('required', MESS_REQUIRED_FIELD, requiredTest)
  .test('wrongDriverLicense', wrongDriverLicense, (value) => driverLicenseRegexp.test(value));

export const licenseDate = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test(
    'incorrect_date',
    'Неверный формат даты',
    (value) => value && value.indexOf('_') === -1 && moment(value, 'DDMMYYYY').isValid()
  )
  .test('incorrect_date', 'Неверный формат даты', (value) => value && value.length === 8)
  .test('incorrect_date', 'Не может превышать 10 лет + 3 года продления', function (value) {
    const lastValidDate = moment().subtract(10, 'years');
    const currentLicenseDate = moment(value, 'DDMMYYYY');
    // Срок действия водительских удостоверений продлен на три года
    const extension = [3, 'years'];
    const extensionLicenseDate = currentLicenseDate.clone().add(...extension);
    // Продление начало действовать на водительске удостовереня выпущенные с 01.01.2012
    const FIRST_EXTENSION_DATE = moment('01/01/2012', 'DD/MM/YYYY');
    const isValidLicense = FIRST_EXTENSION_DATE.diff(currentLicenseDate) <= 0;

    const diff = lastValidDate.diff(extensionLicenseDate);

    return diff <= 0 && isValidLicense;
  })
  .test('incorrect_date', 'Не может быть меньше 16 лет', function (value) {
    const { bday } = this.parent;

    const lastValidDate = moment(bday, 'DDMMYYYY').add(16, 'years');
    const currentLicenseDate = moment(value, 'DDMMYYYY');

    const diff = lastValidDate.diff(currentLicenseDate);

    return !bday || `${bday}`.length < 8 || !value || diff <= 0;
  })
  .test('incorrect_date', 'Не может быть раньше, чем Дата выдачи первого ВУ', function (value) {
    const { prevLicenseDate } = this.parent;

    const lastValidDate = moment(prevLicenseDate, 'DDMMYYYY');
    const currentLicenseDate = moment(value, 'DDMMYYYY');

    const diff = lastValidDate.diff(currentLicenseDate);

    return !prevLicenseDate || `${prevLicenseDate}`.length < 8 || !value || diff <= 0;
  });

export const prevLicenseDate = yup
  .mixed()
  .test('required', MESS_REQUIRED_FIELD, requiredTest)
  .test(
    'incorrect_date',
    'Неверный формат даты',
    (value) => !value || (value.indexOf('_') === -1 && moment(value, 'DDMMYYYY').isValid())
  )
  .test('incorrect_date', 'Неверный формат даты', (value) => !value || value.length === 8)
  .test('incorrect_date', 'Не может быть меньше 16 лет', function (value) {
    const { bday } = this.parent;

    const lastValidDate = moment(bday, 'DDMMYYYY').add(16, 'years');
    const currentLicenseDate = moment(value, 'DDMMYYYY');

    const diff = lastValidDate.diff(currentLicenseDate);

    return !bday || `${bday}`.length < 8 || !value || diff <= 0;
  })
  .test('incorrect_date', 'Не может быть позже, чем Дата выдачи', function (value) {
    const { licenseDate } = this.parent;

    const lastValidDate = moment(licenseDate, 'DDMMYYYY');
    const currentLicenseDate = moment(value, 'DDMMYYYY');

    const diff = lastValidDate.diff(currentLicenseDate);

    return !licenseDate || `${licenseDate}`.length < 8 || !value || diff >= 0;
  });

export const schemaDriver = yup.object({
  fio,
  bday,
  licenseSeriaNum,
  licenseDate,
  isFirstLicense: yup.boolean(),
  prevLicenseSeriaNum: yup.mixed().when('isFirstLicense', {
    is: false,
    then: prevLicenseSeriaNum,
  }),
  prevLicenseDate: yup.mixed().when('isFirstLicense', {
    is: false,
    then: prevLicenseDate,
  }),
});

// full schema of anketa insurance

export const schemaInsurance = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerEmail,
  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,
  insurerBirthPlace,
  insurerIndex,
  insurerAddress,
  licenseSeriaNum,
  licenseDate,
  isFirstLicense: yup.boolean(),
  prevLicenseSeriaNum: yup.mixed().when('isFirstLicense', {
    is: false,
    then: prevLicenseSeriaNum,
  }),
  prevLicenseDate: yup.mixed().when('isFirstLicense', {
    is: false,
    then: prevLicenseDate,
  }),
  drivers: yup.array().of(schemaDriver),
});

export const schemaInsuranceIsNotDriver = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerEmail,
  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,
  insurerBirthPlace,
  insurerIndex,
  insurerAddress,
  drivers: yup.array().of(schemaDriver),
});

// --

export const schemaOnlyInsurerIsNotDriver = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerEmail,
  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,
  insurerBirthPlace: insurerBirthPlace.test('required_field', MESS_REQUIRED_FIELD, requiredTest),
  insurerIndex,
  insurerAddress,
});

export const schemaOnlyInsurer = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerEmail,
  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,
  insurerBirthPlace,
  insurerIndex,
  insurerAddress,
  licenseSeriaNum,
  licenseDate,
  isFirstLicense: yup.boolean(),
  prevLicenseSeriaNum: yup.mixed().when('isFirstLicense', {
    is: false,
    then: prevLicenseSeriaNum,
  }),
  prevLicenseDate: yup.mixed().when('isFirstLicense', {
    is: false,
    then: prevLicenseDate,
  }),
});

// --

export const schemaInsurerIsNotDriver = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerEmail,
  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,
  insurerBirthPlace,
  insurerIndex,
  insurerAddress,
  licenseSeriaNum: yup.mixed().when('isDriver', {
    is: true,
    then: licenseSeriaNum,
  }),
  licenseDate: yup.mixed().when('isDriver', {
    is: true,
    then: licenseDate,
  }),
  drivers: yup.array().of(schemaDriver),
});

export const schemaInsuranceNew = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerEmail,
  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,
  insurerBirthPlace: insurerBirthPlace.test('required_field', MESS_REQUIRED_FIELD, requiredTest),
  insurerIndex,
  insurerAddress,
  licenseSeriaNum,
  licenseDate,
});

export const schemaOnlyInsurerNew = yup.object({
  fio,
  bday,
  insurerPhone,
  insurerIndex,
  insurerAddress,
  insurerEmail: yup
    .mixed()
    .test(
      'incorrect_email',
      'Некорректный адрес',
      (value) => value.trim().length === 0 || (value.includes('@') && value.includes('.'))
    ),
});

export const schemaCalculation = yup.object({
  isInCredit: yup.boolean(),
  selectedBank: yup.mixed().when('isInCredit', {
    is: true,
    then: (schema) => schema.test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    otherwise: yup.mixed(),
  }),
});

export const schemaInsuranceBeforeSave = yup.object({
  anketa: schemaInsuranceNew,
  calculation: schemaCalculation,
});

export const schemaInsuranceBeforeSaveIsNotDriver = yup.object({
  anketa: schemaInsurerIsNotDriver,
  calculation: schemaCalculation,
});

export const schemaInsuranceBeforeSaveInsurerIsNotDriver = yup.object({
  anketa: schemaOnlyInsurerIsNotDriver,
  calculation: schemaCalculation,
});

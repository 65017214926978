import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { setIsChangeValues, setIsPEPSignature } from 'store/anketa';
import { getDocumentsByHash } from 'data-providers/mainAnketaProvider';

import { SMSVerification } from './SMSVerification';
import DocumentsLinks from './DocumentsLinks';
import { BadgeStatus } from './BadgeStatus/BadgeStatus';
import { FinalModal } from './FinalModal/FinalModal';

import * as help from './helpers';
import * as S from './styles';

const DOC_TYPES = {
  'pep-agreement': {
    name: 'Соглашение об использовании простой электронной подписи',
    type: 'electronic-document',
  },
  'pep-sopd': {
    name: 'Согласие на обработку персональных данных',
    type: 'personal-data',
  },
  'pep-bki': {
    name: 'Согласие на взаимодействие с бюро кредитных историй',
    type: 'credit-history-data',
  },
};

export const SignPEP = ({ anketaData, anketaErrors, onSave }) => {
  const rootDispatch = useDispatch();
  const { addToast, removeAllToasts } = useToasts();

  const { isPEPSignature, pdf, otpDocLink } = useSelector((state) => state.anketa || {});

  const [data, setData] = useState({ agreementPEP: true, agreementProc: true, agreementBKI: true });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const hasAnketaErrors = useMemo(() => help.hasAnketaErrors(anketaErrors), [anketaErrors]);
  const customer = useMemo(() => help.getCustomer(anketaData), [anketaData]);

  const getDocLabel = (type) => {
    if (!isPEPSignature || !otpDocLink[DOC_TYPES[type].type]) {
      return DOC_TYPES[type]?.name || '';
    }

    return (
      <>
        <S.DocLink href="#" onClick={(e) => docHandler(e, type)} data-test={`link ${type}`}>
          {DOC_TYPES[type]?.name || ''}
        </S.DocLink>
      </>
    );
  };

  const docHandler = async (event, type) => {
    event.stopPropagation();
    event.preventDefault();

    if (!anketaData?.application?.id || !DOC_TYPES[type] || !customer) {
      return;
    }

    addToast('Загрузка документа', {
      appearance: 'success',
      autoDismiss: true,
    });

    await getDocumentsByHash(otpDocLink[DOC_TYPES[type].type]);
  };

  const changeHandler = (field) => {
    setData((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const signHandler = () => {
    if (!isDisabled) {
      setIsVerification(true);
    }
  };

  const verificationCloseHandler = () => {
    setIsVerification(false);
  };

  const verificationSuccessHandler = () => {
    onSave(true);
    rootDispatch(setIsPEPSignature(true));
    rootDispatch(setIsChangeValues(false));
    setIsVerification(false);
    setIsSuccess(true);
  };

  const closeSuccessPopupHandler = () => {
    setIsSuccess(false);
  };

  useEffect(() => {
    setIsDisabled(
      !data.agreementPEP ||
        !data.agreementProc ||
        !data.agreementBKI ||
        hasAnketaErrors ||
        isPEPSignature
    );
  }, [hasAnketaErrors, data, isPEPSignature]);

  useEffect(() => {
    if (pdf) {
      removeAllToasts();
      help.saveDocument(pdf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  return (
    <>
      <S.Container>
        <S.TitleWrap>
          <S.Title>Подписание согласия у клиента</S.Title>
          <BadgeStatus />
        </S.TitleWrap>
        <S.Text>Отправьте клиенту смс с ссылкой на документы и кодом для их подписания</S.Text>

        <S.Content>
          <S.CheckBox
            name="agreementPEP"
            disabled={isPEPSignature}
            value={data['agreementPEP']}
            onChange={() => changeHandler('agreementPEP')}
            dataTest="agreementPEP"
          >
            {getDocLabel('pep-agreement')}
          </S.CheckBox>

          <S.CheckBox
            name="agreementProc"
            disabled={isPEPSignature}
            value={data['agreementProc']}
            onChange={() => changeHandler('agreementProc')}
            dataTest="agreementProc"
          >
            {getDocLabel('pep-sopd')}
          </S.CheckBox>

          <S.CheckBox
            name="agreementBKI"
            disabled={isPEPSignature}
            value={data['agreementBKI']}
            onChange={() => changeHandler('agreementBKI')}
            dataTest="agreementBKI"
          >
            {getDocLabel('pep-bki')}
          </S.CheckBox>
        </S.Content>

        {!isPEPSignature && (
          <S.SignButton size={'l'} onClick={signHandler} disabled={isDisabled}>
            Отправить на подписание
          </S.SignButton>
        )}
        <DocumentsLinks />
      </S.Container>

      {isVerification && (
        <SMSVerification
          appId={anketaData?.application?.id}
          dealerId={anketaData?.application?.dealer?.id}
          customer={customer}
          onClose={verificationCloseHandler}
          onSuccess={verificationSuccessHandler}
        />
      )}

      {isSuccess && <FinalModal onClose={closeSuccessPopupHandler} />}
    </>
  );
};

import React, { useState, useEffect, createContext, useContext } from 'react';
import { Chart, Pie } from 'react-chartjs-2';
import { LINKS } from './constants';
import { WidgetWrapper, ChartWrapper, LinkContainer, LinkStyled, CheckImg } from './style';

export const AnketaProgressContext = createContext({});

export const AnketaProgressProvider = ({ children }) => {
  const [showErrors, setShowErrors] = useState([]);
  const [collapsed, setCollapsed] = useState(
    LINKS.reduce((obj, item) => ({ ...obj, [item.href]: true }), {})
  );

  const updateErrors = (obj) => setShowErrors(obj);
  const updateCollapsed = (obj) => setCollapsed(obj);

  return (
    <AnketaProgressContext.Provider
      value={{ showErrors, collapsed, updateErrors, updateCollapsed }}
    >
      {children}
    </AnketaProgressContext.Provider>
  );
};

export const AnketaProgress = ({ errors }) => {
  Chart.defaults.global.legend.display = false;
  const [calcData, setCalcData] = useState({ links: LINKS, count: 0 });
  const { showErrors, collapsed, updateCollapsed, updateErrors } =
    useContext(AnketaProgressContext);

  const checkError = (keys, err) =>
    keys.reduce(
      (count, key) =>
        count + Object.keys(err).reduce((c, k) => (k.indexOf(key) === 0 ? c + 1 : c), 0),
      0
    );

  const calcPercent = (count, total) => {
    const percent = Math.ceil((100 * count) / total);
    return [100 - percent, percent];
  };

  useEffect(() => {
    if (errors) {
      console.log('AnketaProgress errors => ', errors);
      setCalcData(
        LINKS.reduce(
          ({ links, count }, link) => {
            const calc = checkError(link.keys, errors);
            return {
              links: [
                ...links,
                {
                  ...link,
                  count: calc,
                },
              ],
              count: calc > 0 ? count + 1 : count,
            };
          },
          { links: [], count: 0 }
        )
      );
    }
  }, [errors]);

  const linkCLickHandler = (id) => {
    const keys = LINKS.find((f) => f.href === id).keys;
    let strID = Object.keys(errors).find((eKey) => keys.some((key) => eKey.indexOf(key) === 0));
    strID = strID
      ? `#${strID.replace(/\./g, '_').replace(/\[/g, '_').replace(/\]/g, '_')}`
      : `#${id}`;

    const block = document.querySelector(`#${id}`);
    const control = document.querySelector(strID);
    const element = block || control;

    if (element) {
      updateCollapsed({ ...collapsed, [id]: true });
      updateErrors([...showErrors.filter((item) => item !== id), id]);

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const dataPie = {
    datasets: [
      {
        data: calcPercent(calcData.count, LINKS.length),
        backgroundColor: [' #228B22', '#e9e9e9'],
      },
    ],
    labels: ['Верно заполнено', 'Ошибка заполнения'],
  };

  return (
    <WidgetWrapper data-test="anketaProgressWidget">
      <ChartWrapper data-test="anketaProgressDiagram">
        <Pie data={dataPie} />
      </ChartWrapper>
      <LinkContainer>
        {calcData.links.map(({ title, href, count }, l) => (
          <LinkStyled
            key={`_link_${l + 1}`}
            status={count > 0}
            onClick={() => linkCLickHandler(href)}
          >
            <CheckImg error={count !== 0} />
            {`${title} ${(count > 0 && `(${count})`) || ''}`}
          </LinkStyled>
        ))}
      </LinkContainer>
    </WidgetWrapper>
  );
};

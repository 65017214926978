import request from 'utils/request';
import HttpError from 'utils/errors/HttpError';
import throwCustomError from 'utils/throwCustomError';

import * as urls from 'constants/url';

export const getEPointsUserInfo = async () => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.GET_EPOINTS_USER_INFO}`;

    const res = await request
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ePointsRegistration = async (ePointsAnketa) => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.EPOINTS_REGISTRATION}`;

    const res = await request.post(
      url,
      {
        firstName: ePointsAnketa.firstName,
        lastName: ePointsAnketa.lastName,
        patronymicName: ePointsAnketa.patronymicName,
        phoneNumber: ePointsAnketa.phoneNumber,
        email: ePointsAnketa.email,
        acceptTenderOffer: ePointsAnketa.acceptTenderOffer,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    );

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ePointsValidateEmail = async (email) => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.EPOINTS_VALIDATE_EMAIL}`;

    const res = await request.post(
      url,
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    );

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ePointsPhoneNumberConfirm = async (confirmCode) => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.EPOINTS_PHONENUMBER_CONFIRM}`;

    const res = await request.post(
      url,
      {
        confirmCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    );

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ePointsPhoneNumberResend = async () => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.EPOINTS_PHONENUMBER_RESEND_CODE}`;

    const res = await request.post(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

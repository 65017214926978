import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import * as S from './UserWidgetAvatar.styles';
import { useUserWidgetAvatarPresenter } from './UserWidgetAvatar.presenter';

export const UserWidgetAvatar = ({ avatarSize = 64, iconSize = 24, canEdit = true }) => {
  const presenter = useUserWidgetAvatarPresenter({ canEdit });

  return (
    <OutsideClickHandler onOutsideClick={presenter.hideOptions}>
      <S.Wrapper size={avatarSize}>
        {presenter.photoUrl && <img src={presenter.photoUrl} alt="avatar" />}
        {!presenter.photoUrl && <S.AvatarSvgStyled size={avatarSize} />}
        {presenter.shouldShowChangePhoto && (
          <S.CameraWrapper>
            <S.CameraSvgStyled size={iconSize} onClick={presenter.handleClick} />
            {presenter.isOptionsShown && (
              <S.UploadOptions>
                <S.UploadOption onClick={presenter.handleTakePhoto}>
                  <S.PhotoOptionIcon /> Сфотографировать
                </S.UploadOption>
                <S.UploadOption onClick={presenter.handleUploadPhoto}>
                  <S.UploadOptionIcon /> Загрузить
                </S.UploadOption>
              </S.UploadOptions>
            )}
          </S.CameraWrapper>
        )}
      </S.Wrapper>
    </OutsideClickHandler>
  );
};

import { removeExtraSpaces, firstCharUpper } from 'utils/stringUtils';

export const sortItems = (items) =>
  items.slice().sort((item1, item2) => item1.value.localeCompare(item2.value));

export const getSelectedIds = (items) =>
  items.filter((item) => item.checked).map((item) => item.id);

export const setItemsSelection = (items, selectionIds) => {
  const existItems = items.filter((item) => !!item?.id);
  const isChackedAll = selectionIds.length === 0 || selectionIds.length === existItems.length;

  return existItems.map((item) => ({
    ...item,
    checked: isChackedAll ? false : selectionIds.includes(String(item.id)),
  }));
};

export const getShortName = (firstName, lastName) => {
  let name = String(firstName || '')
    .trim()
    .charAt(0);

  name = name ? `${name}.` : '';
  return firstCharUpper(removeExtraSpaces(`${lastName} ${name}`).toLowerCase());
};

export const getDealers = (storeDealersFilter, userDealerId) => {
  const dealers = storeDealersFilter.filter((dealer) => !!dealer?.id);
  const isAllChecked = dealers.filter((dealer) => !!dealer.checked).length === 0;

  return dealers.map((dealer) => ({
    id: String(dealer.id),
    value: String(dealer.label),
    description: String(dealer.id) === String(userDealerId) ? 'Мой ДЦ' : '',
    checked: isAllChecked || Boolean(dealer.checked),
  }));
};

export const getWorkers = (storeWorkers, userId) => {
  const workers = storeWorkers.filter((worker) => !!worker?.id);
  const isAllChecked = workers.filter((worker) => !!worker.checked).length === 0;

  return workers.map((worker) => ({
    id: String(worker.id),
    value: getShortName(worker.firstname, worker.surname),
    description: String(worker.id) === String(userId) ? 'Я' : '',
    checked: isAllChecked || Boolean(worker.checked),
  }));
};

export const ASW_ANKETA_SET_MAIN_FETCH = 'ASW_ANKETA_SET_MAIN_FETCH';
export const ASSISTANCE_SET_ERROR = 'assistance/anketa/set-error';
export const ASSISTANCE_DELETE_ERROR = 'assistance/anketa/delete-error';
export const ASSISTANCE_SET_INSURER_DADATA_INFO = 'assistance/anketa/set-insurer-dadata-info';
export const ASSISTANCE_CHANGE_BY_KEY = 'assistance/anketa/chnage-by-key';
export const ASSISTANCE_CHANGE_DRIVER = 'assistance/anketa/chnage-driver';
export const ASSISTANCE_ADD_DRIVER = 'assistance/anketa/add-driver';
export const ASSISTANCE_ADD_CONFIDANT = 'assistance/anketa/add-confidant';
export const ASSISTANCE_DELETE_DRIVER = 'assistance/anketa/delete-driver';
export const ASSISTANCE_DROP_ALL_ERRORS = 'assistance/anketa/drop-all-errors';
export const ASSISTANCE_DROP_ALL = 'assistance/anketa/drop-all';
export const ASSISTANCE_SET_ERRORS = 'assistance/anketa/set-errors';
export const ASSISTANCE_SET_CUSTOMER = 'assistance/anketa/set-customer';
export const ASSISTANCE_GET_CUSTOMERS_IDS = 'assistance/anketa/get-customers-ids';
export const ASSISTANCE_SET_CUSTOMERS_FETCH = 'assistance/anketa/set-customers-fetch';
export const ASSISTANCE_EXTRACT_DRIVER = 'assistance/anketa/extract-driver';
export const ASSISTANCE_CLEAR_EMPTY_DRIVERS = 'assistance/anketa/clear-empty-drivers';
export const ASSISTANCE_PATCH_CAR_INSTANCE = 'assistance/anketa/patch-car-instance';
export const ASSISTANCE_ANKETA_SET_INSURER_DADATA_INFO =
  'ASSISTANCE_ANKETA_SET_INSURER_DADATA_INFO';
export const ASSISTANCE_ANKETA_UPD_INSURER_DADATA_INFO =
  'ASSISTANCE_ANKETA_UPD_INSURER_DADATA_INFO';
export const ASSISTANCE_ANKETA_GET_LEGAL_DATA = 'ASSISTANCE_ANKETA_GET_LEGAL_DATA';

export const ASSISTANCE_SWITCH_INSURER_DRIVER_CHECKBOX =
  'assistance/anketa/switch-insurer-driver-checkbox';
export const ASSISTANCE_SET_NODES = 'assistance/anketa/set-nodes';
export const ASSISTANCE_SET_CURRENT_SAVED_DATA = 'assistance/anketa/set-current-saved-data';
export const ASSISTANCE_SET_NETWORK_ERROR = 'assistance/anketa/set-network-error';

export const ASSISTANCE_SCROLL_TO = 'assistance/anketa/scroll-to';
export const ASSISTANCE_CLEAR_SCROLL_TO = 'assistance/anketa/clear-scroll-to';
export const ASSISTANCE_SWITCH_CALC_OPTION = 'assistance/anketa/switch-calc-option';

export const ASSISTANCE_CLEAR_ALL_DRIVERS = 'assistance/calculation/clear-all-drivers';
export const ASSISTANCE_RETURN_SAVED_DRIVERS = 'assistance/calculation/return-saved-drivers';

export const ASSISTANCE_SET_ENABLED = 'assistance/calculation/set-enabled';
export const ASSISTANCE_SET_DISABLED = 'assistance/calculation/set-disabled';

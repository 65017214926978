import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { BUBBLES } from 'constants/infoBubble';
import * as localStorage from 'utils/local-storage';
import * as userSelectors from 'store/user/selectors';

import {
  validBubbleTypes,
  defaultModalBubbleData,
  defaultTargetBubbleData,
} from './InfoBubbles.constats';

const DISPLAY_TIMEOUT = 1000;

export const useInfoBubblesPresenter = () => {
  const isAuth = useSelector(({ user }) => !!user?.id);
  const dealer = useSelector(userSelectors.getDealer);
  const dealers = useSelector(userSelectors.getDealers);

  const displayedBubblesIds = useRef([]);
  const [bubble, setBubble] = useState(null);

  const presenter = {
    bubble,

    init() {
      const bubblesSavedInfo = localStorage.getItem('infoBubbles');
      displayedBubblesIds.current = Array.from(bubblesSavedInfo?.displayedIds || []);
    },

    initBubble() {
      if (!isAuth) {
        setBubble(null);
        return;
      }

      const validBubbles = BUBBLES.filter((b) => {
        return (
          b?.id &&
          validBubbleTypes.includes(b.type) &&
          !displayedBubblesIds.current.includes(b.id) &&
          (typeof b.isVisible === 'function' ? b.isVisible({ dealer, dealers }) : true)
        );
      });

      const bubble = validBubbles[0];
      switch (bubble?.type) {
        case 'modal':
          setBubble({ ...defaultModalBubbleData, ...bubble });
          return;
        case 'target':
          setBubble({ ...defaultTargetBubbleData, ...bubble });
          return;
        default:
          setBubble(null);
          return;
      }
    },

    onCloseBubble() {
      if (!bubble) {
        return;
      }

      setBubble(null);
      displayedBubblesIds.current = [...displayedBubblesIds.current, bubble.id];
      localStorage.saveItem('infoBubbles', { displayedIds: displayedBubblesIds.current });
    },
  };

  useEffect(() => {
    presenter.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const displayTimeoutId = setTimeout(() => {
      presenter.initBubble();
    }, DISPLAY_TIMEOUT);

    return () => {
      clearTimeout(displayTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedBubblesIds.current, isAuth, dealers]);

  return presenter;
};

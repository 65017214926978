import React, { useRef } from 'react';

import { TooltipHover } from '../Tooltip/TooltipHover/TooltipHover';
import * as S from './MenuItem.styles';

export const MenuItem = (props) => {
  const {
    className,
    children,
    title = '',
    tooltip = '',
    theme = '1',
    Icon = null,
    isActive = false,
    dataTest = '',
    onClick = () => {},
  } = props;

  const ref = useRef();

  return (
    <>
      <S.Container
        className={className}
        ref={ref}
        theme={theme}
        isActive={isActive}
        data-test={dataTest}
        onClick={onClick}
      >
        {Icon && <S.MenuIcon Icon={Icon} />}
        {title && <S.Title>{title}</S.Title>}
        {children}
      </S.Container>

      {tooltip && (
        <TooltipHover targetRef={ref} theme="1" size="s" position="right">
          {tooltip}
        </TooltipHover>
      )}
    </>
  );
};

import { get, post } from 'data-providers/common';
import { sortArrayByConst } from 'utils/common';
import { traefikRequest } from 'utils/request';
import refBooks from 'utils/constants/referenceBooks';
import sleep from 'utils/sleep';
import { isFillCustomer } from 'utils/helpers';
import { downloadDocument } from 'components/modules/Applications/helpers';

export const postProfile = async (payload) => {
  try {
    const data = await post('applications-customers/profile', payload);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getProfile = async (applicationId) => {
  try {
    const data = await get(`applications-customers/${applicationId}`);

    data.forEach(({ customer }) => {
      if (!isFillCustomer(customer.personalInformation)) {
        customer.personalInformation.name = '';
        customer.personalInformation.surname = '';
        customer.personalInformation.middleName = '';
      }

      // DEV-416 fix
      var confidants = customer.confidants;
      if (confidants && confidants.lenght > 1) {
        customer = {
          ...customer,
          confidants: [confidants[0]],
        };
      }
    });

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const uploadFiles = async ({ applicationId, formData }) => {
  try {
    const data = await post(`applications/${applicationId}/documents`, formData, {
      'content-type': 'multipart/form-data; boundary=boundary',
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getGuides = async () => {
  try {
    const result = await get('guides');
    return Object.keys(result.data).reduce((obj, key) => {
      if (['companyCountList'].includes(key)) {
        return {
          ...obj,
          [key]: sortArrayByConst(result.data[key], 'id', refBooks[key]),
        };
      }
      return { ...obj, [key]: result.data[key] };
    }, {});
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getWorkExpirience = async () => {
  try {
    const result = await get('reference/general_work_experience/items?page=1&perPage=10');
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getCharacterPositions = async () => {
  try {
    // const now = moment().format('YYYY.MM.DD');
    // const lsName = 'characterPositions';

    // const lsItem = getItem(lsName);
    // if (lsItem) {
    //   if (lsItem.date !== now) {
    //     removeItem(lsName);
    //   } else {
    //     return lsItem.list;
    //   }
    // }
    const perPage = 300;
    const totalCount = 3000;
    let items = [];
    let promises = [];
    for (let page = 1; page <= totalCount / perPage; page++) {
      promises.push(getCharacterPositionsByPage(items, page, perPage));
    }
    await Promise.all(promises);
    // saveItem(lsName, {
    //   list: items,
    //   date: now,
    // });
    return items.sort((a, b) => (a.value > b.value ? 1 : -1));
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getCharacterPositionsByPage = async (items, page, perPage) => {
  try {
    const result = await get(`reference/250/items?page=${page}&perPage=${perPage}`);
    result.forEach((p) => items.push(p));
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getCharacterPositionType = async (name) => {
  try {
    const result = await get(`reference/character-position/item?position=${name}`);
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getPdf = async (applicationId, payload, param = null) => {
  try {
    const data = await post(
      `dgs/applications-customers/${applicationId}${(param && `?${param}`) || ''}`,
      payload
    );
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDocumentTypes = async () => {
  try {
    const data = await get('doctypes', { perPage: 1000 });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getAnketaTypes = async (applicationId) => {
  try {
    const data = await get(`applications/${applicationId}/anketa/types`);
    return data.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getProfitsApproved = async () => {
  try {
    const data = await get('reference/profits-approved-rus/items');
    return data.map((item) => ({
      ...item,
      name: item.value,
      id: item.sysName,
    }));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const mergeFiles = async ({ id = '0', type = 'passport', format = 'pdf' }) => {
  try {
    const data = await get(`applications/${id}/documents/merge/${type}/${format}`);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const isPEPSignature = async (appId) => {
  try {
    const result = await traefikRequest.get(`/api/v1/otp/status/${appId}`);
    if (result?.status !== 200 || result.data.result.statusCode !== 2) {
      return false;
    }

    return result.data.result;
  } catch (error) {
    return false;
  }
};

export const checkSmsCode = async (otpId, code) => {
  try {
    const result = await traefikRequest.get(`api/v1/otp/check/${otpId}/${code}`);

    if (result && result.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    if (error.response.status !== 404 && error.response.status !== 426) {
      return { otherError: true, type: 'otherError' };
    }
    if (error.response.status === 426) {
      return { otherError: true, type: 'limitError' };
    }
  }
};

export const sendSmsCode = async (appId, dealerId, data, attempt = 0) => {
  try {
    const result = await get(
      `otps/sms/send/${appId}` +
        `?dealerId=${dealerId}` +
        `&phone=${encodeURIComponent(data.phone)}` +
        `&fullName=${encodeURIComponent(data.fullName)}` +
        `&inn=${encodeURIComponent(data.inn)}` +
        `&snils=${encodeURIComponent(data.snils)}` +
        `&serie_and_number=${encodeURIComponent(data.serieAndNumber)}` +
        `&issued_by=${encodeURIComponent(data.issuedBy)}` +
        `&issued_date=${encodeURIComponent(data.issuedDate)}` +
        `&subdivision_code=${encodeURIComponent(data.subdivisionCode)}` +
        `&date_of_birth=${encodeURIComponent(data.dateOfBirth)}` +
        `&place_of_birth=${encodeURIComponent(data.placeOfBirth)}` +
        `&registration_address=${encodeURIComponent(data.registrationAddress)}`
    );

    if (!result.error && result.result) {
      return result.result;
    }

    if (attempt >= 2) {
      return null;
    }

    await sleep(3000);
    return await sendSmsCode(appId, dealerId, data, attempt + 1);
  } catch (error) {
    if (attempt >= 2) {
      return null;
    }

    return await sendSmsCode(appId, dealerId, data, attempt + 1);
  }
};

export const generateAnketaDoc = async (appId) => {
  try {
    const data = await post(`applications/${appId}/documents/pep`, {});
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDocumentsByHash = async (hash) => {
  const fileUrl = `${process.env.REACT_APP_API_ENTRYPOINT}/files/${hash}`;
  try {
    window.open(fileUrl, '_blank').focus();
  } catch {
    downloadDocument(fileUrl);
  }
};

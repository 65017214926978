import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid
    ${(p) => (!p.checked || p.disabled ? 'var(--color-grey-4)' : 'var(--color-blue-4)')};
  border-radius: 50%;
  ${(p) =>
    p.required &&
    !p.checked &&
    css`
      border-color: var(--color-red-2);
    `}
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(p) => (p.disabled ? 'var(--color-grey-4)' : 'var(--color-blue-4)')};
    will-change: transform;
    transition: ${(p) => (p.checked ? '30ms transform linear' : null)};
    transform: scale(${(p) => (p.checked ? '1' : '0')});
  }
`;

export const Label = styled.div`
  margin-left: 10px;
  user-select: none;
`;

export const RadioButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  opacity: ${(p) => (p.disabled ? '0.7' : '1')};
  pointer-events: ${(p) => (p.disabled ? 'none' : null)};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  margin-right: 20px;

  &:focus {
    border: 1px dotted var(--color-blue-4);
  }

  &:last-child {
    margin-right: 0;
  }

  ${(p) =>
    p.type &&
    p.type !== 'default' &&
    css`
      flex-direction: column;
      padding: ${(p) => (p.rounded ? '8px 15px' : '15px 20px')};
      user-select: none;
      background-color: ${(p) => (p.checked ? 'var(--color-blue-4)' : 'transparent')};
      border: 1px solid ${(p) => (p.checked ? 'var(--color-blue-4)' : 'var(--color-grey-4)')};
      border-radius: ${(p) => (p.rounded ? '40px' : '5px')};
      font-size: ${(p) => (p.rounded ? '12px' : '14px')};
      line-height: ${(p) => (p.rounded ? '14px' : '18px')};
      ${(p) =>
        p.required &&
        !p.checked &&
        css`
          border-color: var(--color-red-2);
        `}
    `}
`;

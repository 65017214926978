import React from 'react';
import { useDealersBanksPresenter } from './DealersBanks.presenter';

import * as S from './DealersBanks.styles';

export const DealersBanks = () => {
  const presenter = useDealersBanksPresenter();

  return (
    <S.DealersBanksWrapper>
      <S.Title>Мои банки</S.Title>
      {presenter.insuranceBanks.map((bank) => (
        <S.BanksContainer key={bank.id}>
          <S.BankItem key={bank.id} id={bank.id} type="pps" title={bank.value} />
          <S.BankName>{bank.value}</S.BankName>
        </S.BanksContainer>
      ))}
    </S.DealersBanksWrapper>
  );
};

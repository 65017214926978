import styled from 'styled-components';
import { EPointsBannerL } from 'assets/images';

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px 12px;
  min-height: 80px;
  max-height: 80px;
  background-color: var(--color-black);
  border-radius: 24px;
`;

export const StringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-white);
  margin-left: 200px;
  z-index: 1;
`;

export const BoldRow = styled.div`
  margin-bottom: 4px;

  font: var(--font-heading-large-20_2);
`;

export const Row = styled.div`
  font: var(--font-regular-15);
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 188px;
  height: 44px;
  border-radius: 12px;
  margin-right: 16px;
  padding: 0 20px;
  background-color: var(--color-white);
  color: var(--color-black);
  z-index: 1;

  font: var(--font-large-15);
  cursor: pointer;
`;

export const BannerLogoWrapper = styled(EPointsBannerL)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  z-index: 0;
`;

// НЕ ИСПОЛЬЗОВАТЬ КАК ПРОВАЙДЕР ДЛЯ REACT_ADMIN !!!
import { stringify } from 'query-string';

import request, { traefikRequest } from 'utils/request';
import HttpError from 'utils/errors/HttpError';
import sleep from 'utils/sleep';
import { isFillCustomer } from 'utils/helpers';
import { changeColorInState, getInfoByStatus } from 'utils/getStatusInfo';

import { get } from 'data-providers/common';
import { statusColorMapReverse } from 'constants/index';
import {
  APPLICATIONS_URL,
  CAR_URL,
  STATES_URL,
  DEALS_COMMISSIONS_URL,
  GET_LOST_DEALS_URL,
  GET_DETAIL_PARAMS_URL,
  GET_DEAL_APROVED_INFO_URL,
  GET_DEAL_STATUS_URL,
  GET_CAR_INSTANCE_URL,
  PICKER_CHECK_URL,
  PPS_DEALERS_INFO,
  FINANCE_THE_APPLICATION,
} from 'constants/url';

import * as Helper from './helper';

const mapper = (applic) => {
  const revMap = statusColorMapReverse();

  applic.date = applic.createdAt;
  applic.carInstance = {
    ...applic.carInstance,
    brand: applic?.carInstance?.brandName,
    model: applic?.carInstance?.modelName,
    isNew: +applic?.carInstance?.newStatus,
    version: '',
    year: applic?.carInstance?.productionYear,
  };
  applic.customer = {
    ...applic.client,
    birthDate: '',
    homePhone: applic.client.phone,
    id: applic.client.customerId,
    middleName: applic.client.middleName,
    mobilePhone: applic.client.phone,
    name: applic.client.firstName,
    surname: applic.client.lastName,
  };

  if (applic.orders.length) {
    const creditOrder = applic.orders.find((order) => order.productId === 'credit');
    const loanOrder = applic.orders.find((order) => order.productId === 'loan_issue');
    const creditOrderState = creditOrder?.body?.applicationState?.state;
    const isLoanOrderPriority =
      !creditOrder ||
      (creditOrder && !['archive', 'closed', 'financed'].includes(creditOrderState));

    if (creditOrder) {
      applic.ids = {
        credit: creditOrder.body.applicationId,
      };
      applic.originDealStates = JSON.parse(JSON.stringify(creditOrder.body?.dealStates));
      applic.dealStatesByName = getStateByName(creditOrder.body?.dealStates);
      applic.dealStates = reduceColorState(creditOrder.body, revMap);
      applic.pv = creditOrder.body.pv;
      applic.term = creditOrder.body.term;
      applic.ks = creditOrder.body.ks;
      applic.mop = creditOrder.body.mop;
      applic.lastState = creditOrder.body?.applicationState;
    }

    if (loanOrder && isLoanOrderPriority) {
      applic.lastState = {
        state: loanOrder.body?.applicationState?.name,
        stateName: loanOrder.body?.applicationState?.value,
        stateSecondName: loanOrder.body?.applicationState?.value,
      };
    }

    const assistanceTypeIndex = applic.orders.findLastIndex(
      (order) => order.productId === 'assistance'
    );
    if (assistanceTypeIndex !== -1) {
      applic.ids = {
        ...applic.ids,
        assistance: applic.orders[assistanceTypeIndex].id,
      };
      applic.assistanceState = applic.orders[assistanceTypeIndex]?.body.applicationState;
      applic.assistanceState = changeColorInState(applic.assistanceState, 'assistance');
    }

    const insuranceTypeIndex = applic.orders.findLastIndex(
      (order) => order.productId === 'insurance'
    );
    if (insuranceTypeIndex !== -1) {
      applic.ids = {
        ...applic.ids,
        insurance: applic.orders[insuranceTypeIndex].id,
      };
      applic.osagoState = applic.orders[insuranceTypeIndex]?.body.applicationState;
      applic.osagoState = changeColorInState(applic.osagoState, 'insurance');
    }

    const loanTypeIndex = applic.orders.findIndex((order) => order.productId === 'loan_issue');
    if (loanTypeIndex !== -1 && applic.orders[loanTypeIndex]?.body?.applicationId) {
      applic.ids = {
        ...applic.ids,
        loan: applic.orders[loanTypeIndex].body.applicationId,
      };
      const { color, fontColor, secondColor } = getInfoByStatus(
        applic.orders[loanTypeIndex]?.body?.applicationState.name,
        'credit'
      );
      applic.loanState = applic.orders[loanTypeIndex]?.body.applicationState;
      applic.loanState.color = color;
      applic.loanState.fontColor = fontColor;
      applic.loanState.secondColor = secondColor;
    }
  }

  return applic;
};

export const getWorksheetByApplicationId = async (aplicationId) => {
  try {
    const url = `api/ors/credit/${aplicationId}/worksheet`;

    let { status, data } = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return mapper(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getStateByName = (dealStates) => {
  const states = dealStates.reduce((acc, curr) => ({ ...acc, [curr.state.state]: curr.banks }), {});

  return states;
};

// TODO: remove to the helper.js or some
const reduceColorState = (application, colorMap) => {
  let dealState = application.dealStates.reduce(
    (acc, curr) => {
      const key = colorMap[curr.state.state];
      if (key) {
        acc[key].banks = [...new Set([...acc[key].banks, ...curr.banks])];
      }
      return acc;
    },
    {
      'var(--color-green-2)': { banks: [] },
      'var(--color-red-2)': { banks: [] },
      'var(--color-blue-3)': { banks: [] },
      'var(--color-yellow-1)': { banks: [] },
      'var(--color-white)': { banks: [] },
    }
  );

  let notWhite = Object.keys(dealState).reduce(
    (acc, key) => (key !== 'var(--color-white)' ? [...acc, ...dealState[key].banks] : acc),
    []
  );
  notWhite = [...new Set(notWhite)].map((bank) => bank.toUpperCase());
  dealState['var(--color-white)'].banks = dealState['var(--color-white)'].banks.filter(
    (bank) => !notWhite.includes(bank.toUpperCase())
  );

  return dealState;
};

// eslint-disable-next-line no-unused-vars
export const getApplications = async (params, isSearch = false) => {
  try {
    /*
    const url = `${APPLICATIONS_URL}?${stringify(
      Helper.getParamsWithoutArgumentsWithBrackets(params),
      {
        arrayFormat: 'bracket',
      }
    )}${Helper.stringifyPartWithBrackets(params)}`;

		let { status, data } = await request.get(url, {
			headers: {
				'content-type': 'application/ld+json',
				accept: 'application/ld+json',
			},
		});
    */

    const _url = `api/ors/worksheets?${stringify(
      Helper.getParamsWithoutArgumentsWithBrackets(params),
      {
        arrayFormat: 'bracket',
      }
    )}${Helper.stringifyPartWithBrackets(params)}`;
    let flow = await request.get(_url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });

    flow.data['hydra:member'].forEach((applic) => {
      mapper(applic);
    });

    //return data;
    return flow.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getApplicationsCloseReasonsCatalog = async (params) => {
  try {
    const url = `${APPLICATIONS_URL}/states`;

    let { status, data } = await request.get(`${url}${params ? '?' : ''}${stringify(params)}`, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getApplication = async ({ applicationId }) => {
  try {
    const url = `${APPLICATIONS_URL}/${applicationId}`;

    let { status, data } = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    data.originDealStates = JSON.parse(JSON.stringify(data.dealStates));
    data.dealStatesByName = getStateByName(data.dealStates);
    const revMap = statusColorMapReverse();
    const dealStates = reduceColorState(data, revMap);
    data.dealStates = dealStates;
    data.customer = data.generalCustomer.customer;

    if (!isFillCustomer(data.customer)) {
      data.customer.name = '';
      data.customer.surname = '';
      data.customer.middleName = '';
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getApplicationsDeals = async ({ params, applicId }) => {
  try {
    if (!applicId) {
      throw new Error('missed params applicId');
    }

    const url = `${APPLICATIONS_URL}/${applicId}/deals?${stringify(params)}`;
    let { status, data } = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    // нет продукта *** temp
    // eslint-disable-next-line no-unused-vars
    data['hydra:member'].forEach((deal) => {
      deal = { ...deal, product: {} };
    });

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealsCommissions = async ({ dealer, payload }) => {
  if (!payload) {
    return [];
  }

  const deals = payload.deals.map((d) => d.id);

  try {
    const url = `${DEALS_COMMISSIONS_URL}`;
    let { status, data } = await request.post(
      url,
      {
        dealer,
        deals,
      },
      {
        headers: {
          'content-type': 'application/ld+json',
          'accept': 'application/ld+json',
        },
      }
    );
    if (status !== 201) {
      throw new HttpError(`status ${status}`);
    }
    return data.commissions;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getKSODict = async (userId) => {
  try {
    const { status, data } = await request.get(`/api/users/${userId}/role/91`);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }
    if (!Array.isArray(data.data)) {
      return [];
    }

    return data.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getMOPDict = async (userId) => {
  try {
    const { status, data } = await request.get(`/api/users/${userId}/role/90`);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }
    if (!Array.isArray(data.data)) {
      return [];
    }

    return data.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getMOPWithPagination = async ({ page = 1, perPage = 10000, params }) => {
  try {
    let urlParams = `page=${page}&perPage=${perPage}`;
    if (params) {
      Object.keys(params).forEach((p) => {
        urlParams += `&${p}=${params[p]}`;
      });
    }
    const { status, data } = await request.get(`/api/roles/90/users?${urlParams}`, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return {
      data: data['hydra:member'],
      total: data['hydra:totalItems'],
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getStateDict = async () => {
  try {
    const url = `${STATES_URL}`;
    const { status, data } = await request.get(url);
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    const filtred = data.filter((item) => item.state !== 'archive');

    return filtred;
    // return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getCarIntance = async (carId) => {
  try {
    if (!carId) {
      throw new Error('missed params carId');
    }
    const url = `${CAR_URL}/${carId}`;
    const { status, data } = await request.get(url);
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getSatutsMessagesLastPage = async ({ dealId }) => {
  try {
    const url = `/api/deals/${dealId}/workflow`;
    let { status, data } = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    const lastPage = data['hydra:view'] ? data['hydra:view']['hydra:last'].split('=')[1] : 1;

    return lastPage;
  } catch (err) {
    console.log(err);
  }
};

export const postComment = async ({ applicationId, comment }) => {
  try {
    const url = `/api/ors/worksheets/${applicationId}/comment`;
    let { status } = await request.patch(
      url,
      {
        comment,
      },
      {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      }
    );
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }
    return comment;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const customQuery = async (query) => {
  try {
    const { status, data } = await request.get(query);
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getFileApplications = async (checkedDealer) => {
  const params = {
    dealer: checkedDealer,
    isFrontProxy: 1,
    lastState: [7, 8],
    page: 1,
    perPage: 20,
    sort: JSON.stringify([{ property: 'created', direction: 'DESC' }]),
  };
  const url = `${GET_LOST_DEALS_URL}?${stringify(
    Helper.getParamsWithoutArgumentsWithBrackets(params),
    {
      arrayFormat: 'bracket',
    }
  )}`;

  try {
    const data = await get(url);
    if (data && data.id) {
      return data;
    }
    return null;
  } catch {
    //no processing
  }
};

export const getOrders = async (id) => {
  try {
    const data = await get(`ors/orders/${id}`);

    if (data) {
      return data;
    }
    return null;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getWorksheetById = async (id) => {
  try {
    const url = `api/ors/worksheets/${id}`;

    let { status, data } = await request.get(url);
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return mapper(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDetailParams = async (id) => {
  try {
    const url = GET_DETAIL_PARAMS_URL(id);

    let data = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getCarInstanceData = async (id) => {
  try {
    const url = GET_CAR_INSTANCE_URL(id);

    let data = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealAprovedInfo = async (dealId) => {
  try {
    const url = GET_DEAL_APROVED_INFO_URL(dealId);

    let data = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });

    if (data) {
      return data;
    }
    return null;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// Fix server asynchrony bug
export const getDealAprovedInfoPersistently = async (dealId, attempt = 0) => {
  const data = await getDealAprovedInfo(dealId);

  if (attempt < 2 && (!Array.isArray(data?.data) || data.data.length === 0)) {
    await sleep(1000);
    return await getDealAprovedInfoPersistently(dealId, attempt + 1);
  } else {
    return data;
  }
};

export const getDealStatus = async (dealId) => {
  try {
    const url = GET_DEAL_STATUS_URL(dealId);

    let data = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const sendOpenedDeal = async (dealsList) => {
  const path = `/deals/send/`;

  try {
    const url = `${path}?${stringify(dealsList, { arrayFormat: 'bracket' })}`;

    const results = await request.get(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      withCredentials: true,
    });
    return results.status;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const pickerCheck = async (id) => {
  try {
    const url = PICKER_CHECK_URL(id);

    const result = await traefikRequest.get(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
    });
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealersInfo = async (dealersList) => {
  const params = {
    dealerIds: dealersList,
  };

  try {
    const url = `${PPS_DEALERS_INFO}?${stringify(
      Helper.getParamsWithoutArgumentsWithBrackets(params),
      { arrayFormat: 'bracket' }
    )}`;

    const result = await request.get(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
    });
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const applicationFinance = async (params) => {
  try {
    let url = `${FINANCE_THE_APPLICATION}`;
    const result = await request.post(url, params, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      withCredentials: true,
    });
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

import styled, { css } from 'styled-components';

import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';
import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';

const getBorderColorForWrapper = (props) => {
  if (props.required) {
    return 'var(--color-red-2)';
  }

  if (props.focused) {
    return 'var(--color-blue-4)';
  }

  return 'var(--color-grey-4)';
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  background-color: #fff;
  height: 44px;
  border: 1px solid ${getBorderColorForWrapper};
  padding: 0 9px;
  cursor: pointer;
  min-width: 80px;
  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: var(--color-grey-1);
    `}
`;

export const InputStyled = styled.input`
  margin-top: ${(p) => (!p.placeholder ? '5px' : '0px')};
  width: 100%;
  border-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  background-color: transparent;
  color: var(--color-grey-8);
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${(p) =>
    !p.focused &&
    !p.placeholder &&
    css`
      margin-top: 0;
      font-size: 0;
      line-height: 0;
    `}

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-webkit-autofill::first-line {
    margin-top: ${(p) => (!p.placeholder ? '5px' : '0px')} !important;
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: var(--main-font-family) !important;
  }
`;

export const Label = styled(TextStyle1)`
  width: 100%;
  text-align: left;
  margin: 0;
  color: ${(p) => (p.required ? 'var(--color-red-2)' : 'var(--color-grey-4)')};
  ${(p) =>
    p.focused &&
    css`
      font-size: 12px;
      line-height: 14px;
    `}

  &:has(+ *:-internal-autofill-selected) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Prompt = styled(TextStyle3)`
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  margin: 0;
  color: var(--color-red-2);
`;

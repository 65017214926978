export const useNotificationPresenter = (data) => {
  const customerFullName = data.customer.split(' ');
  const customerFirstName = customerFullName[1];
  const customerLastName = customerFullName[0];

  const presenter = {
    customerFirstName,
    customerLastName,
  };

  return presenter;
};

import styled from 'styled-components';

import { Select } from 'components/primitives/content';
import { CheckBox as CheckBoxPrimitive } from 'components/primitives/check-box';

import { InnerGrid, InputMaskStyled } from '../../style';
import { CostField } from './components/CostField';
import { Checkbox } from 'components/uikit/Form/Checkbox/Checkbox';

export const GridStyled = styled(InnerGrid)`
  grid-template-areas:
    'brand brand model model config config'
    'year year price price offer-mileage offer-mileage'
    ${(props) => (props.moreRow ? "'fullcalc fullcalc disc-cred disc-cred bank bank '" : '')};
  grid-area: main;
`;

export const Brands = styled(Select)`
  grid-area: brand;
  height: 56px;
`;

export const Models = styled(Select)`
  grid-area: model;
  height: 56px;
`;

export const Config = styled(Select)`
  grid-area: config;
  height: 56px;
`;

export const Year = styled(Select)`
  grid-area: year;
  height: 56px;
`;

export const Price = styled(CostField)`
  grid-area: price;
`;

export const OfferPrice = styled(CostField)`
  grid-area: offer-mileage;
`;

export const Mileage = styled(InputMaskStyled)`
  grid-area: offer-mileage;
`;

export const FullCalculationCheckBox = styled(CheckBoxPrimitive)`
  grid-area: fullcalc;
`;

export const DiscountCheckBox = styled(Checkbox).attrs({ size: 'xs' })`
  grid-area: fullcalc;
`;

export const DiscountDirectCheckBox = styled(Checkbox).attrs({ size: 'xs' })`
  grid-area: disc-cred;
`;

export const CreditCheckBox = styled(CheckBoxPrimitive)`
  grid-area: disc-cred;
  height: 56px;
`;

export const CreditBanks = styled(Select)`
  grid-area: bank;
  height: 56px;
`;

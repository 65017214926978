import React from 'react';

import { convertCoinsToString } from 'utils/convertPrice';

import * as S from './style';

const getPopoverRow = (type, companyName, programName, price) => {
  return (
    <>
      <S.PopoverTypeItem>{type}</S.PopoverTypeItem>
      {companyName && <S.PopoverItem>{companyName}</S.PopoverItem>}
      {programName && <S.PopoverItem>{programName}</S.PopoverItem>}
      {price && <S.PopoverItem>{convertCoinsToString(price)}</S.PopoverItem>}
    </>
  );
};

export const ProductLampItem = ({
  state = null,
  title = null,
  color = null,
  fontColor = null,
  secondColor = null,
  Icon = null,
  offers = [],
  visibleStates = [],
}) => {
  if (!state || !title || !color || !visibleStates.includes(state)) {
    return <S.Lamp color="inherit" />;
  }

  return (
    <S.Lamp
      label={title}
      color={color}
      fontColor={fontColor}
      popoverColor={secondColor}
      value={<Icon />}
      popover={offers.map((o) => getPopoverRow(o.type, o.companyName, o.programName, o.price))}
      showPopoverCounter={false}
    />
  );
};

import React from 'react';
import { useTableItemPresenter } from './TableItem.presenter';

import { TooltipCell } from '../TooltipCell/TooltipCell';

import * as S from './TableItem.styles';

export const TableItem = ({ data, dataTest }) => {
  const presenter = useTableItemPresenter(data);

  return (
    <S.Container data-test={dataTest}>
      <TooltipCell cellType={'vinNumber'} error={presenter.vinError}>
        {presenter.vinNumber}
      </TooltipCell>
      <TooltipCell cellType={'modelName'}>{presenter.modelName}</TooltipCell>
      <TooltipCell cellType={'modelPrice'} price={data.maxPrice}>
        {presenter.modelMaxPrice}
      </TooltipCell>
      <S.Cell>{presenter.pledges()}</S.Cell>
      <S.Cell>{presenter.taxiAndCarSharing()}</S.Cell>
      <S.Cell>{presenter.restrictions()}</S.Cell>
      <S.Cell>{presenter.seriousDamage()}</S.Cell>
      <S.LastCell>
        <S.StyledButton theme="3" size="s" onClick={() => presenter.onHandleClick()}>
          Цифровая сделка
        </S.StyledButton>
      </S.LastCell>
    </S.Container>
  );
};

import React from 'react';
import { Tooltip } from '../Tooltip';
import { useTooltipClickPresenter } from './TooltipClick.presenter';

export const TooltipClick = (props) => {
  const {
    className,
    targetRef,
    children,
    theme = '1',
    size = 's',
    position = 'bottom',
    dataTest = '',
    hideTimeout = 5000,
  } = props;

  const presenter = useTooltipClickPresenter(targetRef, hideTimeout);

  return (
    <>
      <Tooltip
        className={className}
        targetRef={targetRef}
        theme={theme}
        size={size}
        position={position}
        dataTest={dataTest}
        isShow={presenter.isShow}
      >
        {children}
      </Tooltip>
    </>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideToast } from 'store/toasts';

export const useToastManagerPresenter = (onClick) => {
  const rootDispatch = useDispatch();

  const { toasts, isVisible } = useSelector((store) => store.toasts);

  const [isAnimating, setIsAnimating] = useState(isVisible);
  const [currentToast, setCurrentToast] = useState(null);

  const presenter = {
    currentToast,
    isAnimating,

    onAnimationEndHandler() {
      if (!isAnimating && currentToast?.id) {
        rootDispatch(hideToast(currentToast.id));
        setCurrentToast(null);
        onClick();
      }
    },

    onCloseHandler() {
      setIsAnimating(false);
    },

    onButtonClickHandler() {
      if (currentToast?.id) {
        presenter.onCloseHandler(currentToast.id);
        currentToast.onButtonClick();
      }
    },
  };

  useEffect(() => {
    if (toasts.length > 0 && !currentToast) {
      setCurrentToast(toasts[0]);
      setIsAnimating(true);
    }
  }, [toasts, currentToast]);

  useEffect(() => {
    if (currentToast?.timeout) {
      const timer = setTimeout(() => {
        presenter.onCloseHandler(currentToast.id);
      }, currentToast.timeout);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToast]);

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
      setTimeout(onClick, 500);
    }
  }, [isVisible, onClick]);

  return presenter;
};

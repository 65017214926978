import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { CheckBox } from 'components/primitives/check-box';
import { InputMaskStyled } from '../../../Form/style';
import Input from 'components/primitives/input-new';

export const FormContainer = styled(Grid)`
  margin-top: 41px;
  width: 100%;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    '. header header header header . . .'
    '. seria seria date date chk chk .'
    '. prevseria prevseria prevdate prevdate question . .';
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-area: header;
`;

export const SeriaNumber = styled(Input)`
  height: 56px;
  border-radius: 5px;
  margin-bottom: 15px;
  grid-area: seria;
`;

export const DateLicense = styled(InputMaskStyled)`
  margin-bottom: 15px;
  grid-area: date;
`;

export const IsFirstLicenseCheckbox = styled(CheckBox)`
  margin-bottom: 15px;
  grid-area: chk;
`;

export const PrevSeriaNumber = styled(Input)`
  height: 56px;
  border-radius: 5px;
  margin-bottom: 15px;
  grid-area: prevseria;
`;

export const PrevDateLicense = styled(InputMaskStyled)`
  margin-bottom: 15px;
  grid-area: prevdate;
`;

export const QuestionMarkWrapper = styled.div`
  grid-area: question;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const QuestionMark = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #b5b5b5;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
`;

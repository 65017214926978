import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { hidenFinancePopup, setAdminManualFinanceParams } from 'store/applications';
import { getBankName } from 'components/common/BankLogo/helper';
import { applicationFinance } from 'data-providers/applicationsProvider';

export const useFinancePopupPresenter = () => {
  const [inputValue, setInputValue] = useState('');
  const [validateError, setValidateError] = useState(null);
  const rootDispatch = useDispatch();

  const { showFinancePopup, adminManualFinanceParams } = useSelector(
    (store) => store?.applications
  );
  const bankName = getBankName(adminManualFinanceParams.bankId, 'pps');

  const presenter = {
    inputValue,
    validateError,
    showFinancePopup,
    bankName,

    get dealerName() {
      return adminManualFinanceParams.dealerName;
    },

    onCloseFinancePopupHandler() {
      setInputValue('');
      setValidateError(null);
      rootDispatch(hidenFinancePopup());
      rootDispatch(setAdminManualFinanceParams({ bankId: null, dealId: null }));
    },

    onChangeHandler(e) {
      const pureValue = e.target.value.replace(/[\sа-яА-Я]/g, '');
      setInputValue(pureValue);
      setValidateError(null);
    },

    onKeyPressHandler(event) {
      if (event.key === 'Enter') {
        presenter.confirmFinancing();
      }
    },

    async confirmFinancing() {
      if (!inputValue.length) {
        setValidateError('Заполните ExID');
        return;
      }

      const finansParams = {
        dealId: String(adminManualFinanceParams.dealId),
        bankId: String(adminManualFinanceParams.bankId),
        extId: inputValue,
      };
      try {
        const request = await applicationFinance(finansParams);
        if (request.status === 200 && request.data.meta.status === 'error') {
          setValidateError('ExID не найден на шине, проверьте корректность данных');
          return;
        }
        presenter.onCloseFinancePopupHandler();
      } catch {
        setValidateError('Произошла ошибка при обработке запроса, отправьте его повторно');
      }
    },
  };

  return presenter;
};

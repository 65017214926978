import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Bubble = styled.button`
  margin: 0;
  margin-right: 10px;
  margin-bottom: 8px;
  padding: 12px 15px;
  border: 1px solid var(--color-grey-4);
  border-radius: 20px;
  background-color: white;
  height: 40px;
  color: var(--color-grey-4);
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid var(--color-grey-4);
    `}
  ${(props) =>
    props.isActive &&
    css`
      background-color: var(--color-blue-4);
      color: var(--color-black);
      border-color: var(--color-blue-4);
    `}
    ${(props) => {
    if (props.clickable) {
      return css`
        cursor: pointer;
        &:hover,
        &:focus {
          ${props.allowHover &&
          css`
            filter: brightness(1.1);
            background-color: var(--color-blue-4);
            color: var(--color-black);
            border-color: var(--color-blue-4);
          `}
        }
      `;
    }

    return '';
  }}
`;

export const BubbleText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
`;

export const BubbleKBM = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
`;

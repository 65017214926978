import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  openCarInstanceDetails,
  closeCarInstanceDetails,
  dropError,
  setCarInstance,
  patchCarInstance,
} from 'store/carInstance';
import {
  insuranceSetIsInCredit,
  insuranceGetBanksList,
  insuranceSetSelectedBank,
} from 'store/insuranceCalculation';

import * as Form from '../Form/style';
import * as S from './style';

const CarForm = ({ id, isFullCalc = true, onClickFullCalc = null }) => {
  const rootDispatch = useDispatch();
  const { path } = useRouteMatch();
  const carInstance = useSelector((state) => state?.carInstance?.carInstance || {});
  const { errors, fetch } = useSelector((state) => state?.carInstance || {});
  const dealerId = useSelector((state) => state.user?.dealer);
  const { isInCredit, insuranceBanks, selectedBank } = useSelector(
    (state) => state.insuranceCalculation
  );

  const isInCreditHandler = ({ isInCredit }) => {
    rootDispatch(insuranceSetIsInCredit(isInCredit));
    if (!isInCredit) {
      rootDispatch(insuranceSetSelectedBank(null));
    }
    if (isInCredit && !insuranceBanks?.length) {
      rootDispatch(insuranceGetBanksList({ dealerId }));
    }
  };

  const setSelectedBank = ({ bank }) => {
    rootDispatch(insuranceSetSelectedBank(bank));
  };

  const changeVinHandler = (name, catalog = '') => {
    rootDispatch(dropError(name, catalog));
  };

  const blurVinHandler = async (items) => {
    rootDispatch(setCarInstance({ ...carInstance, ...items }));
    const instance = { ...JSON.parse(JSON.stringify(carInstance)), ...items };
    const loadImage = undefined;
    const phases = undefined;
    const phPayload = undefined;
    const isCustomer = path.includes('/worksheet/') || path.includes('/worksheet/create-new');
    rootDispatch(patchCarInstance(instance, id, loadImage, phases, phPayload, isCustomer));
    rootDispatch(
      setCarInstance({
        ...carInstance,
        ...items,
      })
    );
  };

  const steeringWheelChangeHandler = (value) => {
    rootDispatch(dropError('vin'));
    rootDispatch(dropError('registrationNumber'));
    const newData = {
      ...carInstance,
      steeringWheel: value ? 'right' : 'left',
      vin: '',
      registrationNumber: '',
      bodyNumber: '',
    };
    rootDispatch(setCarInstance(newData));
    const isCustomer = path.includes('/worksheet/') || path.includes('/worksheet/create-new');
    rootDispatch(patchCarInstance(newData, id, undefined, undefined, undefined, isCustomer));
  };

  useEffect(() => {
    const enginePower = Number(carInstance?.enginePower || 0);
    const ptsNumber = String(carInstance?.pts?.number || '').trim();
    const ptsDate = String(carInstance?.pts?.issueDate || '').trim();

    if (isNaN(enginePower) || enginePower <= 0 || !ptsNumber || !ptsDate) {
      rootDispatch(openCarInstanceDetails());
    } else {
      rootDispatch(closeCarInstanceDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance?.id]);

  return (
    <Form.BlockWrapper>
      <Form.Header>Автомобиль</Form.Header>
      {!fetch && (
        <S.CarHeadDetailWrapper>
          <div>
            <S.CarHeadDetail
              carInstance={carInstance}
              error={errors}
              onChange={changeVinHandler}
              onBlur={blurVinHandler}
              withSteeringWheel
              onSteeringWheelChange={steeringWheelChangeHandler}
            />
          </div>
        </S.CarHeadDetailWrapper>
      )}
      <S.CarDetail
        id={id}
        showRestrictions
        isFullCalc={isFullCalc}
        isInCredit={isInCredit}
        creditBanksList={insuranceBanks}
        creditBankId={selectedBank}
        onClickFullCalc={onClickFullCalc}
        onBankChange={setSelectedBank}
        onClickIsInCredit={isInCreditHandler}
      />
    </Form.BlockWrapper>
  );
};

export default CarForm;

const carInstance = {
  id: 0,
  isNew: true,
  brand: '',
  model: '',
  version: '',
  year: 0,
  price: 0,
  offerPrice: 0,
  defaultPrice: 0,
  enginePower: 0,
  engineVolume: 500,
  mileage: 0,
  carIssueDate: '',
  vin: '',
  pts: {
    series: '',
    number: '',
    issueDate: '',
  },
  diagnosticCard: {
    beginDate: '',
    endDate: '',
    number: '',
  },
  image: '',
  steeringWheel: 'left',
  discountType: '',
};

export const ptsErrors = {
  series: '',
  number: '',
  issueDate: '',
};

export const diagnosticCardErrors = {
  beginDate: '',
  endDate: '',
  number: '',
};

export const errors = {
  brand: '',
  model: '',
  version: '',
  year: '',
  price: '',
  enginePower: null,
  engineVolume: null,
  mileage: null,
  carIssueDate: '',
  vin: '',
  pts: { ...ptsErrors },
  diagnosticCard: { ...diagnosticCardErrors },
  permittedMaxWeight: '',
  engineNumber: '',
  bodyNumber: '',
  brandInPts: '',
  modelInPts: '',
};

const initialState = {
  carInstance,
  requiredFields: [],
  hiddenFields: [],
  disableFields: [],
  errors: { ...errors },
  fetch: true,
  carInfoFetch: false,
  isPatching: false,
  scrollTo: null,
  error: '',
  patchIsNew: false,
  declineDropErrors: false,
  dealersParams: {
    fetchDealersParams: 'wait', // wait, pending, done
    dealerId: null,
    newCars: false,
    usedCars: false,
  },
  isOpenBlock: false,
  isOpenDetails: false,
  isDisable: false,
  isCollapsable: true,
};

export default initialState;

import styled from 'styled-components';

import { CrossMIcon } from 'assets/icons';
import { IconButton } from '../IconButton/IconButton';

const themes = {
  1: {
    colorOverlay: 'var(--color-black_50)',
    colorBkg: 'var(--color-white)',
    borderRadius: '25px',
    padding: '32px',
    shadow: 'var(--shadow-4)',
  },
  2: {
    colorOverlay: 'var(--color-black_50)',
    colorBkg: 'var(--color-white)',
    borderRadius: '25px',
    padding: '24px',
    shadow: 'var(--shadow-4)',
  },
};

export const Overlay = styled.div`
  --color-overlay: ${(p) => (themes[p.theme] || themes['1']).colorOverlay};
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --border-radius: ${(p) => (themes[p.theme] || themes['1']).borderRadius};
  --padding: ${(p) => (themes[p.theme] || themes['1']).padding};
  --shadow: ${(p) => (themes[p.theme] || themes['1']).shadow};

  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background-color: var(--color-overlay);
`;

export const Container = styled.div`
  position: relative;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 600px;
  max-height: 85vh;
  padding: var(--padding);
  border-radius: var(--border-radius);
  background-color: var(--color-bkg);
  box-shadow: var(--shadow);
  overflow: hidden;
`;

export const CloseButton = styled(IconButton).attrs({ Icon: CrossMIcon, size: 'm', theme: '2' })`
  position: absolute;
  top: -40px;
  right: -40px;
`;

import styled from 'styled-components';

const themes = {
  1: {
    colorBkg: 'var(--color-white)',
    colorBkgDisabled: 'var(--color-grey-10_10)',
    colorBorder: 'var(--color-black)',
    colorBorderDisabled: 'var(--color-grey-11_60)',
    colorBorderError: 'var(--color-red-2)',
    colorBorderCell: 'var(--color-grey-11_60)',
    colorBorderFocusCell: 'var(--color-black)',
    colorText: 'var(--color-black)',
    colorError: 'var(--color-red-2)',
  },
};

const sizes = {
  s: { height: '32px', font: 'var(--font-regular-30)' },
  m: { height: '44px', font: 'var(--font-regular-30)' },
  l: { height: '56px', font: 'var(--font-regular-34)' },
  xl: { height: '64px', font: 'var(--font-regular-34)' },
};

export const Container = styled.div`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgDisabled};
  --color-border: ${(p) => (themes[p.theme] || themes['1']).colorBorder};
  --color-border-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBorderDisabled};
  --color-border-error: ${(p) => (themes[p.theme] || themes['1']).colorBorderError};
  --color-border-cell: ${(p) => (themes[p.theme] || themes['1']).colorBorderCell};
  --color-border-focus-cell: ${(p) => (themes[p.theme] || themes['1']).colorBorderFocusCell};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --color-error: ${(p) => (themes[p.theme] || themes['1']).colorError};
  --height: ${(p) => (sizes[p.size] || sizes['l']).height};
  --font: ${(p) => (sizes[p.size] || sizes['l']).font};

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--height);
  background-color: var(--color-bkg);
  margin-bottom: 8px;
  border: 2px solid
    ${(p) =>
      p.error
        ? 'var(--color-border-error)'
        : p.disabled
        ? 'var(--color-border-disabled)'
        : 'var(--color-border)'};
  border-radius: 16px;
  gap: 6px;

  font: var(--font);
`;

export const Cell = styled.input`
  height: 32px;
  width: 23px;
  text-align: center;
  margin-right: ${(p) => (p.index % 3 === 2 && p.codeSize % 3 === 0 ? '12px' : '0')};
  border: none;
  border-bottom: 2px solid ${(p) => (p.value ? 'transparent' : `var(--color-border-cell)`)};
  background-color: var(--color-bkg);

  &:focus {
    border-bottom: 2px solid var(--color-border-focus-cell);
  }
`;

export const Error = styled.span`
  color: var(--color-error);
  margin-right: auto;
  padding-left: 16px;

  font: var(--font-regular-13);
`;

import React, { useContext, useEffect } from 'react';

import { educationTypeHigh } from 'store/anketa/mappers/work';

import { WorkForm } from './WorkForm';
import { ProfitForm } from './ProfitForm';
import { AnketaProgressContext } from 'components/modules/Widgets/AnketaProgress';

import { InfoSvg } from 'assets/img';
import { ReactTooltipStyled, AreaInfo } from '../../style';
import { FormContainer, FormHeader, FormSubheader, GridRowSubHeader } from '../style';

export const Work = ({
  data,
  validate,
  firstTimeRender,
  onChange,
  errors,
  anketaSave,
  setFocusAt,
  disabled = false,
}) => {
  const creditId = data?.application.id;
  const id = 'work';
  const idForm = 'finance';
  const { persons, currentPersonId } = data;
  const { work } = persons[currentPersonId];
  const { showErrors } = useContext(AnketaProgressContext);

  const changeHandler = ({ values, key, id = currentPersonId }) => {
    onChange({ ...data, persons: { ...persons, [id]: { ...persons[id], [key]: values } } });
  };

  useEffect(() => {
    if (work.educationType && !work?.educationType?.id && educationTypeHigh) {
      work.educationType = { ...educationTypeHigh };
      changeHandler({ values: work, id: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work, educationTypeHigh]);

  return (
    <FormContainer id={id}>
      <GridRowSubHeader>
        <FormHeader>
          Работа и доход
          <FormSubheader>Место работы клиента</FormSubheader>
        </FormHeader>
      </GridRowSubHeader>
      <WorkForm
        keyComp={id}
        onChange={changeHandler}
        errors={errors}
        persona={persons[currentPersonId]}
        validate={validate || showErrors.some((key) => key === id)}
        firstTimeRender={firstTimeRender}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        disabled={disabled}
      />
      <GridRowSubHeader>
        <FormSubheader>
          Доход в месяц
          <AreaInfo>
            <InfoSvg data-tip="" data-for="profitFormInfo" data-test={`${id}InfoSvg`} />
            <ReactTooltipStyled id="profitFormInfo" place="right">
              Доход должен быть в 3 раза
              <br />
              больше ежемесячного платежа
            </ReactTooltipStyled>
          </AreaInfo>
        </FormSubheader>
      </GridRowSubHeader>
      <ProfitForm
        onChange={changeHandler}
        persona={persons[currentPersonId]}
        errors={errors}
        validate={validate || showErrors.some((key) => key === idForm)}
        firstTimeRender={firstTimeRender}
        anketaSave={anketaSave}
        creditId={creditId}
        disabled={disabled}
      />
    </FormContainer>
  );
};

import styled from 'styled-components';

import { FormGridRowBase, FormSubheader } from '../../style';
import { Radio } from 'components/primitives/radio';
import { H4 } from 'components/primitives/typography/headings/h4';
import { AnketaCheckBox, AnketaInputMask, AnketaDaData, AnketaFormHeader } from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. head head head head . . .'
    '. sn sn date date code code .'
    '. iss iss iss iss iss iss .'
    '. bp bp bp bp bd bd .'
    '. ge ge ge ge ge ge .'
    '. hide hide . . . . .'
    '. phm phm phm phm phm phm . ';
`;

export const FormHeader = styled(AnketaFormHeader)`
  grid-area: head;
  grid-template-areas: '. . . . . . . .';
`;

export const GridRowSubHeaderPassport = styled(FormGridRowBase)`
  grid-template-areas: '. ph ph ph . . . .';
`;

export const FormSubheaderPassport = styled(FormSubheader)`
  grid-area: ph;
  > svg {
    margin-left: 20px;
    width: 12px;
    height: 12px;
  }
  > span {
    margin-left: 5px;
    color: var(--color-green-1);
  }
`;

export const FormSubheaderPassportMVD = styled.div`
  grid-area: phm;
  text-align: start;
  > svg {
    margin-left: 0px;
    width: 12px;
    height: 12px;
    fill: var(--color-red-2);
  }
  > span {
    margin-left: 5px;
    color: var(--color-red-2);
  }
`;

export const Alert = styled.div`
  display: flex;

  > svg {
    margin-right: 10px;
    fill: var(--color-red-2);
  }
`;

export const AlertText = styled(H4)`
  font-size: 12px;
  color: var(--color-red-2);
`;

export const CitizenshipSelect = styled(AnketaCheckBox)`
  grid-area: chg;
  margin-top: 60px;
`;

export const SeriaNumber = styled(AnketaInputMask)`
  grid-area: sn;
`;

export const PassportDate = styled(AnketaInputMask)`
  grid-area: date;
`;

export const IssuerCode = styled(AnketaDaData)`
  grid-area: code;
`;

export const Issuer = styled(AnketaDaData)`
  grid-area: iss;
`;

export const BirthdayPlace = styled(AnketaInputMask)`
  grid-area: bp;
`;

export const ChangePassportToogle = styled(AnketaCheckBox)`
  grid-area: ch;
`;

export const Birthday = styled(AnketaInputMask)`
  grid-area: bd;
`;

export const Gender = styled(Radio)`
  grid-area: ge;
  margin-bottom: 15px;
`;

export const ShowMoreBtn = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100.4%;
  text-decoration-line: underline;
  color: #b5b5b5;
  margin-bottom: 15px;
  cursor: pointer;
`;

import * as yup from 'yup';

import * as fields from 'components/modules/Calculations/components/CarDetail/validator/validations';
import { MESS_REQUIRED_FIELD } from 'utils/validationUtils/messages';
import { requiredTest } from 'utils/validationUtils/validators';

import { getFilesDkp, getFilesPts } from '../../Loan.helpers';

export const carInstanceScheme = yup.lazy(() =>
  yup.object(
    {
      brand: fields.brand,
      model: fields.model,
      version: yup.mixed().when('isNew', {
        is: true,
        then: fields.version,
        otherwise: fields.customString,
      }),
      price: fields.price,
      year: fields.customNumber,
      engineVolume: fields.engineVolume,
      enginePower: fields.enginePower.test(
        'incorrect',
        'Значение не должно быть меньше 20',
        (value) => {
          const enginePower = Number(value || 0);
          return enginePower >= 20;
        }
      ),
      engineNumber: fields.engineNumber,
      bodyNumber: fields.customString
        .test('required', MESS_REQUIRED_FIELD, (vin) => requiredTest(vin))
        .when('steeringWheel', {
          is: (steeringWheel) => steeringWheel === 'right',
          then: fields.customString.test(
            'incorrect',
            'Минимально допустимое кол-во символов – 7',
            (val = '') => val.length >= 7
          ),
          otherwise: fields.bodyNumber,
        }),
      permittedMaxWeight: fields.permittedMaxWeight,
      pts: yup.object({
        number: yup.mixed().when(['status'], {
          is: 2,
          then: yup.mixed(),
          otherwise: fields.number,
        }),
        issueDate: yup.mixed().when(['status'], {
          is: 2,
          then: yup.mixed(),
          otherwise: fields.simpleDate,
        }),
      }),
      vin: yup.mixed().when(['steeringWheel'], {
        is: (steeringWheel) => steeringWheel === 'left',
        then: fields.vin.test('required', MESS_REQUIRED_FIELD, (vin) => requiredTest(vin)),
        otherwise: fields.customString,
      }),
      mileage: fields.customNumber.when(['isNew'], (isNew, schema) => {
        if (isNew) {
          return schema;
        } else {
          return schema
            .test(
              'required',
              MESS_REQUIRED_FIELD,
              (value) => requiredTest(value) && value !== '0' && value !== 0
            )
            .test(
              'incorrect',
              'Введите значение между 1001 и 1000000',
              (value) => value >= 1001 && value <= 1000000
            );
        }
      }),
    },
    ['vin']
  )
);

export const carMainScheme = yup.lazy(() =>
  yup.object({
    brand: fields.brand,
    model: fields.model,
    version: yup.mixed().when(['isNew'], {
      is: true,
      then: fields.version,
      otherwise: yup.mixed(),
    }),
    price: fields.price,
    year: fields.customNumber,
    engineVolume: fields.engineVolume,
    enginePower: fields.enginePower,
    engineNumber: fields.engineNumber,
    bodyNumber: fields.bodyNumber,
    permittedMaxWeight: fields.permittedMaxWeight,

    mileage: fields.customNumber.when(['isNew'], (isNew, schema) => {
      if (isNew) {
        return schema;
      } else {
        return schema.test(
          'required',
          MESS_REQUIRED_FIELD,
          (value) => requiredTest(value) && value !== '0' && value !== 0
        );
      }
    }),
  })
);

export const policeRecipientScheme = yup
  .object({
    id: yup.number().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    ndsFlag: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    ndsAmount: yup
      .mixed()
      .when(
        ['ndsFlag', '$bankSpecificRequiredFields'],
        (ndsFlag, bankSpecificRequiredFields = [], schema) => {
          if (ndsFlag && bankSpecificRequiredFields.includes('ndsAmount')) {
            return schema.test('required', MESS_REQUIRED_FIELD, (value) => requiredTest(value));
          } else {
            return schema;
          }
        }
      ),
  })
  .nullable()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const policeScheme = yup.lazy(() =>
  yup.object().shape({
    contractNumber: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    invoiceNumber: yup
      .mixed()
      .when('$bankSpecificRequiredFields', (bankSpecificRequiredFields = [], schema) => {
        if (bankSpecificRequiredFields.includes('invoiceNumber')) {
          return schema.test('required_field', MESS_REQUIRED_FIELD, requiredTest);
        } else {
          return schema;
        }
      }),
    invoiceDate: yup
      .mixed()
      .when('$bankSpecificRequiredFields', (bankSpecificRequiredFields = [], schema) => {
        if (bankSpecificRequiredFields.includes('invoiceDate')) {
          return fields.simpleDate;
        } else {
          return schema;
        }
      }),
    recipient: policeRecipientScheme,
  })
);

export const filesScheme = yup.lazy(() =>
  yup
    .array()
    .test('not_dkp_exist', 'ДКП не загружен', function (value) {
      const files = getFilesDkp(value);
      return files && files.length > 0
        ? true
        : this.createError({
            message: 'ДКП не загружен',
            path: 'dkp',
          });
    })
    .test('not_pts_exist', 'ПТС не загружен', function (value) {
      const files = getFilesPts(value);
      return files && files.length > 0
        ? true
        : this.createError({
            message: 'ПТС не загружен',
            path: 'pts',
          });
    })
);

export const carExtraDataScheme = yup.lazy(() =>
  yup
    .object()
    .shape({
      isEnabled: yup.boolean(),
      brandInPts: yup.mixed().when(['isEnabled'], {
        is: true,
        then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
        otherwise: yup.mixed(),
      }),
      modelInPts: yup.mixed().when(['isEnabled'], {
        is: true,
        then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
        otherwise: yup.mixed(),
      }),
    })
    .nullable()
);

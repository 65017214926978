import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 16px;
  flex-grow: 1;
`;

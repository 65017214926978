import styled, { keyframes, css } from 'styled-components';

const themes = {
  1: {
    position: {
      top: '40px',
      right: '40px',
    },
  },
};

export const slideDown = keyframes`
from {
  transform: translateY(-100%);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
`;

export const slideRight = keyframes`
from {
  transform: translateX(0);
  opacity: 1;
}
to {
  transform: translateX(100%);
  opacity: 0;
}
`;

export const Container = styled.div`
  position: fixed;
  top: ${(p) => (themes[p.theme] || themes['1']).position.top};
  right: ${(p) => (themes[p.theme] || themes['1']).position.right};
  z-index: 999999999;
  display: flex;
  align-items: center;
  gap: 8px;

  animation: ${(p) =>
    p.isVisible
      ? css`
          ${slideDown} 0.5s forwards
        `
      : css`
          ${slideRight} 0.5s forwards
        `};
`;

export const StyledImg = styled.img`
  height: auto;
  width: auto;
  max-width: 24px;
  max-height: 24px;
  flex-shrink: 0;
`;

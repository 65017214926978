import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { insuranceGetBanksList } from 'store/insuranceCalculation';

export const useDealersBanksPresenter = () => {
  const rootDispatch = useDispatch();

  const dealerId = useSelector((state) => state.user?.dealer);
  const { insuranceBanks } = useSelector((store) => store.insuranceCalculation);

  const presenter = {
    insuranceBanks,
  };

  useEffect(() => {
    rootDispatch(insuranceGetBanksList({ dealerId, priority: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId]);

  return presenter;
};

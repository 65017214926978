import React, { useState, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { getTextWidth } from 'utils/getTextWidth';

import * as PositionHelper from 'utils/documentPosition';
import * as S from './style';

const CURRENT_FONT = 'normal 12px auto';

const fn = () => {};

const Tooltip = ({
  isShow,
  text,
  minWidth = 0,
  maxWidth = 500,
  width,
  indent = 0,
  tooltipClassName = '',
  onMouseOver = fn,
}) => {
  const wrapperRef = useRef();
  const ref = useRef();
  const [height, setHeight] = useState(32);
  const textWidth = useMemo(() => {
    const ret = getTextWidth(text, CURRENT_FONT);
    return ret >= minWidth ? ret : minWidth;
  }, [text, minWidth]);

  const tooltipPosition = PositionHelper.getNodePosition(wrapperRef?.current);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const tooltipData = PositionHelper.getNodePosition(ref?.current);

    if (tooltipData?.height) {
      setHeight(tooltipData.height);
    }
  });

  return (
    <S.TooltipPlace ref={wrapperRef} onMouseMove={() => {}}>
      {isShow &&
        text &&
        createPortal(
          <S.Tooltip
            ref={ref}
            height={height}
            indent={indent}
            width={width || textWidth + 25}
            maxWidth={maxWidth}
            onMouseOver={onMouseOver}
            className={tooltipClassName}
            position={tooltipPosition}
            offset={window.pageYOffset}
          >
            <span>{text}</span>
          </S.Tooltip>,
          document.body
        )}
    </S.TooltipPlace>
  );
};

export default Tooltip;

export const getProductType = (product) => {
  let productType = null;
  switch (product?.type?.name) {
    case 'insurance_kasko':
      productType = 'kasko';
      break;
    case 'insurance_ppi':
      productType = 'life';
      break;
    case 'insurance_gap':
      productType = 'gap';
      break;
    case 'optional_equipment':
      productType = 'equipment';
      break;
    case 'dealer_dop':
      productType = 'dop';
      break;
    default:
      break;
  }

  return productType;
};

export const getProductBankData = (product, data) => {
  const type = getProductType(product);
  return (data?.[type] || []).find((i) => i.id === product?.serviceId);
};

import styled, { css } from 'styled-components';

import BankLogo from 'components/common/BankLogo';
import * as UK from 'components/primitives/content';
import { ClipboardCopy } from 'components/common/ClipboardCopy/ClipboardCopy';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 32px 20px 20px;
  max-width: calc(100% / 3);
  background-color: var(--color-white);
  border-bottom: 2px solid var(--color-grey-1);
  border-right: 2px solid var(--color-grey-1);
`;

export const Title = styled(UK.Title3)`
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--color-grey-7);
  br {
    display: none;
  }

  @media (max-width: 1650px) {
    height: 75px;
    br {
      display: inline;
    }
  }
`;

export const Description = styled(UK.Description)`
  display: flex;
  padding-bottom: 4px;
  align-items: center;
  justify-content: start;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  flex-wrap: wrap;

  & a {
    margin-left: auto;
    color: var(--color-grey-5);
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DigitalBankList = styled.div`
  display: flex;
  margin-right: 20px;
`;

export const BankItemContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 5px;
  padding: 5px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue-3);
    transition: all 0.2s ease-in-out;
  }

  &:hover div {
    display: flex;
  }
`;

export const BankName = styled.div`
  display: none;
  height: 23px;
  font-size: 12px;
  padding: 2px;
  margin-right: 5px;
  color: var(--color-white);
`;

export const BankItem = styled(BankLogo)`
  width: 23px;
  height: 23px;
`;

export const RightSideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 10px;
  margin-right: 10px;
  background-color: var(--color-blue-2);
  color: var(--color-white);
  border-radius: 5px;
  cursor: pointer;
`;

export const ApproveCreditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  min-height: 40px;
  border: 1px solid var(--color-red-2);
  border-radius: 8px;
  background-color: var(--color-white);
  color: var(--color-red-2);
  margin: 16px auto 0px;
  margin-top: auto;
  cursor: pointer;

  &:hover {
    background-color: var(--color-red-2);
    color: var(--color-white);
  }
  &:active {
    background-color: var(--color-red-1);
    color: var(--color-white);
    border-color: var(--color-red-1);
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--color-grey-2);
      color: var(--color-grey-4);
      border: 1px solid var(--color-grey-2);
      cursor: not-allowed;

      &:hover {
        background-color: var(--color-grey-2);
        color: var(--color-grey-4);
      }

      &:active {
        background-color: var(--color-grey-2);
        color: var(--color-grey-4);
        border-color: var(--color-grey-2);
        transform: none;
      }
    `}
`;

export const ClipboardCopyStyled = styled(ClipboardCopy)`
  --icon-margin: 10px;
`;

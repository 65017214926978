import React from 'react';
import { useTextInputPresenter } from './TextInput.presenter';

import * as S from './TextInput.styles';

const fn = () => {};

export const TextInput = (props) => {
  const {
    className,
    type = 'text',
    name = '',
    value = '',
    placeholder = '',
    label = '',
    description = '',
    error = '',
    maxlength,
    theme = '1',
    size = 'm',
    Icon = null,
    ClearButton = null,
    disabled = false,
    dataTest = '',
    onClick = fn,
    onChange = fn,
    onBlur = fn,
    onFocus = fn,
    onKeyDown = fn,
  } = props;

  const presenter = useTextInputPresenter(
    value,
    placeholder,
    label,
    error,
    onChange,
    onBlur,
    onFocus
  );

  return (
    <S.Container
      className={className}
      theme={theme}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      <S.InputWrapper>
        {label && (
          <S.Label
            value={value}
            isFocused={presenter.isActiveLabel}
            error={presenter.inputError}
            hasIcon={!!Icon}
            disabled={disabled}
          >
            {label}
          </S.Label>
        )}

        <S.Input
          type={type}
          size={size}
          name={name}
          value={value}
          error={presenter.inputError}
          placeholder={presenter.placeholder}
          maxlength={maxlength}
          ref={presenter.inputRef}
          disabled={disabled}
          label={label}
          hasIcon={!!Icon}
          hasClearButton={!!ClearButton}
          data-test={dataTest}
          onChange={onChange}
          onBlur={presenter.onHandleBlur}
          onFocus={presenter.onHandleFocus}
          onKeyDown={onKeyDown}
          autocomplete="off"
        />

        {Icon && (
          <S.InputIcon Icon={Icon} value={value} error={presenter.inputError} disabled={disabled} />
        )}
        {ClearButton && value && (
          <S.ClearButton Icon={ClearButton} onClick={presenter.onHandleClear} disabled={disabled} />
        )}
      </S.InputWrapper>

      {error && (
        <S.Error error={error} data-test={`${dataTest}ErrorMessage`}>
          {error}
        </S.Error>
      )}

      {!error && description && <S.Description disabled={disabled}>{description}</S.Description>}
    </S.Container>
  );
};

import styled, { css } from 'styled-components';

export const Text = styled.a`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-black);
  cursor: pointer;
  text-decoration-line: underline;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      color: var(--color-grey-4);
    `};
`;

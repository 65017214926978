import { useEffect } from 'react';

const Styles = () => {
  useEffect(() => {
    const loadStyles = async () => {
      await import('./styles/base.css');
    };
    loadStyles();
  }, []);

  return null;
};

export default Styles;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { onBlurBlockHelper } from '../../helpers/blockHelpers';
import { DRIVERS_OVERFLOW_ERR_MESSAGE, MAX_DRIVERS } from '../../constants';
import {
  insAnketaChangeByKey,
  insAnketaClearEmptyDrivers,
  insAnketaDeleteError,
  insAnketaInsurerChangeByKey,
  insAnketaSwitchInsurerIsDriverCheckbox,
} from 'store/insuranceAnketa';
import Insurer from './Insurer';

const fn = () => {};

const InsurerWrapper = ({
  anketaState,
  onScroll = fn,
  validate = fn,
  clearDriverLicense = fn,
  returnSavedDriverLicense = fn,
}) => {
  const rootDispatch = useDispatch();
  const { addToast, toastStack } = useToasts();

  const { isInsurer, isDriver, kaskoInsurerIsDriver, drivers, scrollTo, currentSavedData } =
    anketaState;
  const { currentMultidrive } = useSelector((store) => store.insuranceCalculation);
  const allDriversLimit = MAX_DRIVERS - (Number(isDriver) || Number(kaskoInsurerIsDriver));

  const createToast = () => {
    if (!toastStack.length) {
      addToast(DRIVERS_OVERFLOW_ERR_MESSAGE, { appearance: 'success', autoDismiss: false });
    }
  };

  const blurHandler = async ({ value, state }) => {
    const key = Object.keys(value)[0];
    const targetAction = isInsurer ? insAnketaChangeByKey : insAnketaInsurerChangeByKey;

    if (key === 'gender') {
      return rootDispatch(targetAction({ key: 'gender', value: value.gender }));
    }

    const { values, errors } = await onBlurBlockHelper({ value, state });

    if (!values && !errors) {
      return;
    }

    rootDispatch(targetAction(values));

    validate({ value, state });
  };

  const insurerIsDriverSwitchHandler = () => {
    rootDispatch(insAnketaClearEmptyDrivers());

    if (!kaskoInsurerIsDriver && drivers.length >= allDriversLimit) {
      return createToast();
    }

    if (kaskoInsurerIsDriver) {
      returnSavedDriverLicense();
    }

    rootDispatch(insAnketaSwitchInsurerIsDriverCheckbox());
  };

  const switchDriverLicenseHandler = ({ isFirstLicense }) => {
    const keys = ['prevLicenseSeriaNum', 'prevLicenseDate'];

    if (isFirstLicense) {
      clearDriverLicense();
    } else {
      keys.forEach((key) => {
        if (currentSavedData) {
          rootDispatch(insAnketaChangeByKey({ key, value: currentSavedData[key] }));
        }
        rootDispatch(insAnketaDeleteError({ key }));
      });
    }

    rootDispatch(insAnketaChangeByKey({ key: 'isFirstLicense', value: isFirstLicense }));
  };

  return (
    <Insurer
      anketaState={anketaState}
      multidrive={currentMultidrive}
      scrollTo={scrollTo}
      onScroll={onScroll}
      onSwitchInsurerIsDriver={insurerIsDriverSwitchHandler}
      onSwitchDriverLicense={switchDriverLicenseHandler}
      onBlur={blurHandler}
    />
  );
};

export default InsurerWrapper;

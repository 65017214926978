import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Panel, Header, Content, ChevronStyled } from './style';

export const CollapsePanel = forwardRef((props, ref) => {
  const {
    collapsed,
    disabled,
    children,
    title,
    icon,
    className,
    onCollapse,
    contentId,
    contentStyle,
    topware,
    middleware,
    isControlledComponent,
    headerIsHidden = false,
    collapsable = true,
    dataTest,
  } = props;
  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    setIsCollapse(collapsed);
  }, [collapsed]);

  const currentIsCollapse = isControlledComponent ? collapsed : isCollapse;

  const handleCollapse = (e) => {
    e.stopPropagation();
    if (!disabled && collapsable) {
      setIsCollapse(!currentIsCollapse);
      onCollapse(!currentIsCollapse);
    }
  };

  return (
    <Wrapper className={className} ref={ref}>
      {topware}
      <Panel
        className={`${currentIsCollapse ? 'collapsed' : ''}`}
        data-test={dataTest}
        disabled={disabled}
      >
        <Header
          disabled={disabled}
          onClick={(e) => handleCollapse(e)}
          collapsed={currentIsCollapse}
          isHidden={headerIsHidden}
          collapsable={collapsable}
        >
          {icon && icon}
          {title && title}
        </Header>
      </Panel>
      {middleware}
      {currentIsCollapse && (
        <Content style={contentStyle} id={contentId}>
          {children}
        </Content>
      )}
    </Wrapper>
  );
});

CollapsePanel.propTypes = {
  icon: PropTypes.objectOf(PropTypes.any),
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  title: PropTypes.any,
  topware: PropTypes.any,
  middleware: PropTypes.any,
  // className: '',
  onCollapse: PropTypes.func,
  contentId: PropTypes.string,
  contentStyle: PropTypes.object,
  isControlledComponent: PropTypes.bool,
};

CollapsePanel.defaultProps = {
  icon: <ChevronStyled />,
  collapsed: false,
  isControlledComponent: false,
  disabled: false,
  children: PropTypes.any,
  title: null,
  // className: PropTypes.string,
  onCollapse: () => {},
  topware: null,
  middleware: null,
};

CollapsePanel.displayName = 'CollapsePanel';

export default CollapsePanel;

import styled, { css } from 'styled-components';
import { IMaskInput } from 'react-imask';
import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';

const getBorderColorForWrapper = (props) => {
  if (props.required) {
    return 'var(--color-red-2)';
  }
  if (props.focused) {
    return 'var(--color-grey-4)';
  }
  if (props.error) {
    return 'var(--color-red-2)';
  }

  return 'var(--color-grey-4)';
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  height: 60px;
  border: 1px solid ${getBorderColorForWrapper};
  background-color: #fff;
  position: relative;
  cursor: pointer;
  min-width: 80px;
  border-radius: 5px;

  & > div {
    width: 100%;
  }

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: var(--color-grey-1);
    `}
`;

export const InputStyled = styled(IMaskInput)`
  line-height: 18px;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  padding: 0 9px;
  border-width: 0;
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(p) => (p.disabled ? 'transparent' : 'transparent')};
  color: var(--color-grey-8);
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  ${(props) =>
    props.ishidden === 'true' &&
    css`
      line-height: 0;
      font-size: 0;
      padding: 0;
      height: 0;
    `}
`;

export const Label = styled.div`
  ${(props) => props.isHidden && 'display: none;'}
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  text-align: left;
  line-height: 16px;
  width: 100%;
  margin-left: 9px;
  margin-bottom: 5px;
  margin-top: 7px;
  color: ${(p) => (p.required ? 'var(--color-red-2)' : 'var(--color-grey-4)')};

  ${(props) =>
    props.focused &&
    css`
      margin-top: -8px;
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    `}
`;

export const Prompt = styled(TextStyle3)`
  ${(props) => props.isHidden && 'display: none;'}
  width: max-content;
  position: absolute;
  left: 0;
  bottom: -15px;
  margin: 0;
  color: var(--color-red-2);
`;

export const PulseLoaderStyled = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d8d8d875;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
`;

const throwCustomError = (err) => {
  const customError = new Error();
  customError.customDescription = '';

  if (err?.response?.data?.error) {
    customError.errorTraefikData = { ...err.response.data.error };
  }

  if (err?.response?.data['hydra:description']) {
    try {
      const { detail = '' } = JSON.parse(err.response.data['hydra:description']);
      customError.customDescription = detail;
    } catch (_e) {
      customError.customDescription = err.response.data['hydra:description'];
    }
  } else if (err?.response?.data['detail']) {
    customError.customDescription = err.response.data['detail'];
  } else if (err?.response?.data['error']) {
    customError.customDescription =
      err.response.data['error']?.message || err.response.data['error'];
  }

  throw customError;
};

export default throwCustomError;

import * as yup from 'yup';

const transformToType = (type, defaultValue) => (value) =>
  typeof value === type ? value : defaultValue;

const customNumber = yup
  .mixed()
  .test(
    'not_number_not_null',
    'не верное значение',
    (value) => typeof Number(value) === 'number' || value == null
  )
  .transform(transformToType('number', null));
const customBool = yup.bool().transform(transformToType('boolean', false));
const customString = yup.string().transform(transformToType('string', ''));

const schema = yup.object().shape({
  id: yup.number().required(),
  brand: customString,
  model: customString,
  year: customNumber,
  vin: customString,
  enginePower: customNumber,
  engineNumber: customString,
  mileage: customNumber,
  bodyNumber: customString,
  permittedMaxWeight: customNumber,
  engineVolume: customString,
  glonasNumber: customString,
  carIssueDate: customString,
  version: customString,
  isNew: customBool,
  price: customNumber,
  offerPrice: customNumber,
  defaultPrice: customNumber,
  pts: yup
    .object()
    .shape({
      series: customString,
      number: customString,
      issueDate: customString,
    })
    .required(),
  diagnosticCard: yup
    .object()
    .shape({
      beginDate: customString,
      endDate: customString,
      number: customString,
    })
    .required(),
});

export default schema;

import styled from 'styled-components';

import { Popup } from 'components/uikit/Popup/Popup';
import { Button } from 'components/uikit/Button/Button';

export const Container = styled(Popup).attrs({ theme: '1' })`
  --padding: 0px;
`;

export const MediaContainer = styled.div`
  position: relative;
  margin-bottom: -25px;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  padding: 32px;
  background-color: var(--color-white);
  border-radius: 25px;
`;

export const Title = styled.h3`
  margin-bottom: 16px;
  font: var(--font-caption-28);
  letter-spacing: 0.672px;
`;

export const Text = styled.p`
  margin: 0px;
  font: var(--font-regular-17);
`;

export const CloseButton = styled(Button).attrs({ theme: '1', size: 'm' })`
  width: 100%;
  margin-top: 32px;
`;

export const Video = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

export const Image = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

import styled from 'styled-components';
import { InputMaskDaData } from 'components/primitives/input-mask-dadata-new';
import { InputMaskStyled } from '../../../Form/style';

export const LegalWrap = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const LegalInput = styled(InputMaskDaData)`
  border-radius: 5px;
  margin-bottom: 15px;
  height: 56px;
`;

export const Phone = styled(InputMaskStyled)`
  margin-bottom: 15px;
  padding-right: 9px;
  align-self: flex-start;
`;

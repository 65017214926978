import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getShortFio } from 'utils/stringUtils';
import * as userStore from 'store/user';
import * as assistanceStore from 'store/assistanceCalculation';
import * as insuranceStore from 'store/insuranceCalculation';
import * as startPageStore from 'store/startPage';
import * as carInstanceStore from 'store/carInstance';
import * as calculatorStore from 'store/calculator';
import * as dealStore from 'store/deal';
import * as anketaStore from 'store/anketa';
import * as assistanceAnketaStore from 'store/assistanceAnketa';
import * as insuranceAnketaStore from 'store/insuranceAnketa';
import * as userSelectors from 'store/user/selectors';
import * as ePointsStore from 'store/ePoints';
import * as mainpageMetrica from 'metrika/mainpage';
import * as mainmenuMetrica from 'metrika/mainmenu';
import * as localStorage from 'utils/local-storage';
import { creditSpecRoles, providerRoles, noReportSpecRoles } from 'constants/index';
import {
  ChevronLeftSIcon,
  ChevronRightSIcon,
  ListSIcon,
  ChartSIcon,
  MegaphoneSIcon,
  CalculatorSIcon,
  RequisitesSmallSIcon,
} from 'assets/icons';

import * as help from './MainMenu.helpers';

export const useMainMenuPresenter = (isOpen, onToggle) => {
  const rootDispatch = useDispatch();
  const history = useHistory();

  const dealer = useSelector(userSelectors.getDealer);
  const user = useSelector(userSelectors.getUser);
  const { totalPoints, isEPointsDigitalDealMember } = useSelector((store) => store.ePoints);

  const [activeItem, setActiveItem] = useState(null);

  const presenter = {
    get userRole() {
      return Number(user.role);
    },

    get menuItems() {
      const items = [
        {
          id: 'new-app',
          Icon: CalculatorSIcon,
          title: isOpen ? 'Расчёт кредита' : '',
          tooltip: !isOpen ? 'Расчёт кредита' : '',
          isActive: activeItem === 'new-app',
          isVisible: creditSpecRoles.includes(presenter.userRole),
          dataTest: 'openCreditCreationPage',
          onClick: presenter.onNewAppClick,
        },
        {
          id: 'list-apps',
          Icon: ListSIcon,
          title: isOpen ? 'Список заявок' : '',
          tooltip: !isOpen ? 'Список заявок' : '',
          isActive: activeItem === 'list-apps',
          isVisible: true,
          dataTest: 'openListAppsPage',
          onClick: presenter.onListAppsClick,
        },
        {
          id: 'details',
          Icon: RequisitesSmallSIcon,
          title: isOpen ? 'Реквизиты' : '',
          tooltip: !isOpen ? 'Реквизиты' : '',
          isActive: activeItem === 'details',
          isVisible: providerRoles.includes(presenter.userRole),
          dataTest: 'openDetailsPage',
          onClick: presenter.onDetailsClick,
        },
        {
          id: 'reports',
          Icon: ChartSIcon,
          title: isOpen ? 'Отчёты' : '',
          tooltip: !isOpen ? 'Отчёты' : '',
          isActive: activeItem === 'reports',
          isVisible: !noReportSpecRoles.includes(presenter.userRole),
          dataTest: 'openReportsPage',
          onClick: presenter.onReportsClick,
        },
      ];

      return items.filter((item) => item.isVisible);
    },

    get newsItem() {
      return {
        title: isOpen ? 'Новости' : '',
        tooltip: !isOpen ? 'Новости' : '',
        Icon: MegaphoneSIcon,
        isVisible: false,
        onClick: presenter.onCloseSubMenu,
      };
    },

    get toggleItem() {
      return {
        title: isOpen ? 'Свернуть' : '',
        tooltip: !isOpen ? 'Развернуть' : '',
        Icon: isOpen ? ChevronLeftSIcon : ChevronRightSIcon,
        isVisible: true,
        onClick: presenter.onToggleItemClick,
      };
    },

    get userItem() {
      return {
        title: getShortFio({ lastName: user.lastname, firstName: user.firstname }, false),
        tooltip: !isOpen ? 'Профиль' : '',
        dealer: dealer?.name || '',
        isActive: activeItem === 'user',
        isVisible: true,
        onClick: () => {
          mainmenuMetrica.mainMenuOpenProfileTrack();
          presenter.onMenuItemClick('user');
        },
      };
    },

    get ePointsItem() {
      return {
        id: 'epoints',
        title: isEPointsDigitalDealMember ? (isOpen ? 'Баллы: ' : '') : null,
        isEPointsDigitalDealMember: isEPointsDigitalDealMember,
        balance: totalPoints,
        dataTest: 'epointsBalance',
      };
    },

    get isShowUserPanel() {
      return activeItem === 'user';
    },

    onMenuItemClick(id) {
      setActiveItem(activeItem !== id ? id : null);
    },

    onNewAppClick() {
      rootDispatch(dealStore.resetDeal());
      rootDispatch(carInstanceStore.clearCarInstance());
      rootDispatch(startPageStore.clearCustomer());
      rootDispatch(calculatorStore.dropLastCalculationData());
      localStorage.removeItem('carinstanceId');
      localStorage.removeItem('customerId');
      rootDispatch(insuranceStore.insuranceAllToInitState());
      rootDispatch(assistanceStore.assistanceCalcAllToInitState());

      mainmenuMetrica.mainMenuOpenCalculationTrack();
      presenter.onMenuItemClick('new-app');
      history.push('/worksheet/create-new/calculator');
    },

    onListAppsClick() {
      mainmenuMetrica.mainMenuOpenListTrack();
      presenter.onMenuItemClick('list-apps');
      history.push(`/applications/${help.getListAppsUrl(presenter.userRole)}`);
    },

    onDetailsClick() {
      rootDispatch(anketaStore.clearAnketa());
      rootDispatch(assistanceAnketaStore.assistanceClearAnketa());
      rootDispatch(insuranceAnketaStore.insAnketaClear());

      mainmenuMetrica.mainMenuOpenDetailsTrack();
      presenter.onMenuItemClick('details');
      history.push('/provider/all');
    },

    onReportsClick() {
      mainmenuMetrica.mainMenuOpenReportsTrack();
      const url = `${process.env.REACT_APP_REPORTS_URL}/${help.getReportsUrl(presenter.userRole)}`;

      presenter.onMenuItemClick('reports');
      window.open(url, '_blank').focus();
    },

    onLogoClick() {
      mainpageMetrica.toMainPageTrack();

      history.push('/start');
      presenter.onCloseSubMenu();
    },

    onToggleItemClick() {
      if (isOpen) {
        mainmenuMetrica.mainMenuCollapseTrack();
      } else {
        mainmenuMetrica.mainMenuExpandTrack();
      }

      onToggle(!isOpen);
      presenter.setMenuState();
    },

    onCloseSubMenu() {
      setActiveItem(null);
    },

    isMenuOpen() {
      const menuOpen = localStorage.getItem('menuOpen') || {};
      return menuOpen[user.id] || false;
    },

    setMenuState() {
      const menuOpenUser = localStorage.getItem('menuOpen') || {};
      menuOpenUser[user.id] = !isOpen;
      localStorage.saveItem('menuOpen', menuOpenUser);
    },
  };

  useEffect(() => {
    rootDispatch(userStore.getUser());
    onToggle(presenter.isMenuOpen());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    if (user.role && user.role !== -1) {
      rootDispatch(userStore.getDealers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.role]);

  useEffect(() => {
    if (user.id) {
      rootDispatch(ePointsStore.loadEPointsInfo());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return presenter;
};

import React from 'react';

import * as S from './style';

export const ImageBanner = ({ className, img, link, dataTest }) => {
  if (link) {
    return (
      <S.LinkWrapper
        className={className}
        img={img}
        href={link}
        target="_blank"
        data-test={dataTest}
      />
    );
  }

  return <S.Wrapper className={className} img={img} data-test={dataTest} />;
};

import * as actions from './constants';
import { initialState } from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_VIN_HISTORY: {
      return {
        ...state,
        vinHistory: action.payload.history,
        loadedPages: [...state.loadedPages, action.payload.page],
        totalItems: action.payload.total,
      };
    }

    case actions.FETCH_VIN_HISTORY: {
      return {
        ...state,
        fetchHistory: action.payload,
      };
    }

    case actions.ADD_VIN_ITEM: {
      return {
        ...state,
        vinHistory: [{ ...action.payload }, ...state.vinHistory],
        totalItems: state.totalItems + 1,
      };
    }

    case actions.UPDATE_VIN_ITEM: {
      return {
        ...state,
        vinHistory: state.vinHistory.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
      };
    }

    case actions.SHOW_VIN_HISTORY: {
      return { ...state, isHistoryOpened: true };
    }

    case actions.HIDE_VIN_HISTORY: {
      return { ...state, isHistoryOpened: false };
    }

    case actions.SET_VIN_FETCH_ERROR: {
      return { ...state, fetchError: true };
    }

    case actions.RESET_VIN_FETCH_ERROR: {
      return { ...state, fetchError: false };
    }

    default:
      return state;
  }
}

export function loadVinHistory(payload) {
  return {
    type: actions.LOAD_VIN_HISTORY,
    payload,
  };
}

export function setVinHistory(payload) {
  return {
    type: actions.SET_VIN_HISTORY,
    payload,
  };
}

export function fetchVinHistory(payload) {
  return {
    type: actions.FETCH_VIN_HISTORY,
    payload,
  };
}

export function addNewVinItem(payload) {
  return {
    type: actions.ADD_VIN_ITEM,
    payload,
  };
}

export function updateVinItem(payload) {
  return {
    type: actions.UPDATE_VIN_ITEM,
    payload,
  };
}

export function showVinHistory() {
  return {
    type: actions.SHOW_VIN_HISTORY,
  };
}

export function hideVinHistory() {
  return {
    type: actions.HIDE_VIN_HISTORY,
  };
}

export function setVinFetchError() {
  return {
    type: actions.SET_VIN_FETCH_ERROR,
  };
}

export function resetVinFetchError() {
  return {
    type: actions.RESET_VIN_FETCH_ERROR,
  };
}

import styled from 'styled-components';

import { CrossSIcon } from 'assets/icons';
import { IconButton } from 'components/uikit/IconButton/IconButton';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 360px;
  height: 100%;
  padding: 16px;
  background-color: var(--color-grey-8);
`;

export const Title = styled.h3`
  margin: 0px;
  font: var(--font-large-17);
  color: var(--color-white_50);
`;

export const CloseButton = styled(IconButton).attrs({ Icon: CrossSIcon, size: 's', theme: '1' })`
  position: absolute;
  top: 11px;
  right: 7px;
`;

import schema from './validation';

const horsePowerRegexps = [/\(\d{2,3}\s*[Hh]\s*[Pp]\)/, /\(\d{2,3}\s*(л.)\s*(с.)\)/];
const regexpNumber = /\d+/;

export const parseEnginePower = (str) => {
  const executed = horsePowerRegexps.reduce((acc, regexp) => (acc ? acc : regexp.exec(str)), null);

  if (executed === null) {
    return '';
  }

  return +regexpNumber.exec(executed[0])[0];
};

export const prepareCarInstanceData = (carInstance) => {
  try {
    return schema.validateSync(carInstance);
  } catch (_e) {
    return carInstance;
  }
};

import styled from 'styled-components';

import Link from 'components/primitives/file-link';
import { Date } from 'components/primitives/content';
import { CheckSvg } from 'assets/img';

import { InnerGrid, BlockHeader, InputMaskStyled } from '../../style';

export const GridStyled = styled(InnerGrid)`
  grid-template-areas:
    'adh adh adh adh adh adh'
    'pt pt pd pd . .'
    'dpt dpt . . . .';
  grid-area: docs;
  margin-bottom: 20px;
`;

export const AutoDocsHeader = styled(BlockHeader)`
  grid-area: adh;
`;

export const Pts = styled(InputMaskStyled)`
  grid-area: pt;
`;

export const PtsDate = styled(Date)`
  grid-area: pd;
`;

export const PtsDownload = styled(Link).attrs({ color: 'var(--color-blue-5)' })`
  grid-area: dpt;
  padding: 0px 20px;
`;

export const PtsDownloadImg = styled(CheckSvg)`
  width: 15px;
  height: auto;
  margin-right: -5px;
  & > path {
    fill: var(--color-green-1);
  }
`;

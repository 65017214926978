import { noAllReportSpecRoles, applicationsRolesMap } from 'constants/index';

export const getListAppsUrl = (userRole) => {
  if (applicationsRolesMap['in-work'].includes(userRole)) {
    return 'in-work';
  }

  return 'list';
};

export const getReportsUrl = (userRole) => {
  if (!noAllReportSpecRoles.includes(userRole)) {
    return 'sales-performance';
  }

  return 'sales-reconciliation';
};

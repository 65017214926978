export const ASW_STATES = {
  unknown: 'unknown',
  new: 'new',
  offers_requested: 'offers_requested',
  offers_received: 'offers_received',
  offer_selected: 'offer_selected',
  offers_declined: 'offers_declined',
  offers_error: 'offers_error',
  agreement_requested: 'agreement_requested',
  agreement_succeed: 'agreement_succeed',
  agreement_declined: 'agreement_declined',
  agreement_error: 'agreement_error',
  waiting_signing_code: 'waiting_signing_code',
  code_confirmed: 'code_confirmed',
  issue_requested: 'issue_requested',
  issued: 'issued',
  issue_decline: 'issue_decline',
  issue_error: 'issue_error',
  client_decline: 'client_decline',
  declined: 'declined',
  error: 'error',
  cancelation_requested: 'cancelation_requested',
  canceled: 'canceled',
  cancelation_error: 'cancelation_error',

  get allowSelectOffer() {
    return new Set([this.offers_requested, this.offers_received, this.offer_selected]);
  },

  get showOffers() {
    return new Set([
      this.unknown,
      this.new,
      this.offers_requested,
      this.offers_received,
      this.offer_selected,
      this.offers_declined,
      this.offers_error,
    ]);
  },

  get enabledCarInstanceAnketa() {
    return new Set([
      this.new,
      this.offers_requested,
      this.offers_received,
      this.offer_selected,
      this.offers_declined,
      this.offers_error,
    ]);
  },

  get skipConfirmPopup() {
    return new Set([this.code_confirmed, this.agreement_error, this.issue_error]);
  },

  get policyStates() {
    return new Set([
      this.issue_requested,
      this.issued,
      this.issue_decline,
      this.issue_error,
      this.cancelation_requested,
      this.cancelled,
      this.canceled,
      this.cancelation_error,
    ]);
  },

  get hiddenDeclineBtn() {
    return new Set([
      this.offers_declined,
      this.offers_error,
      this.issue_decline,
      this.client_decline,
      this.declined,
    ]);
  },

  get showOnlyRefreshBtn() {
    return new Set([
      this.issue_decline,
      this.issue_error,
      this.client_decline,
      this.declined,
      this.error,
    ]);
  },

  get showOnlyDeclineBtn() {
    return new Set([
      this.issue_requested,
      this.cancelation_requested,
      this.canceled,
      this.cancelation_error,
    ]);
  },

  get disabledCalcBtn() {
    return new Set([...this.showOffers, this.waiting_signing_code]);
  },

  get showCancellationBtn() {
    return new Set([this.issued, this.cancelation_error]);
  },
};

export const ASW_NAMEPLATES = {
  [ASW_STATES.unknown]: {
    value: '',
    name: ASW_STATES.unknown,
    sysName: ASW_STATES.unknown,
    color: 'var(--color-white)',
  },
  [ASW_STATES.offer_selected]: {
    value: 'Консультация',
    name: ASW_STATES.offer_selected,
    sysName: ASW_STATES.offer_selected,
    color: 'var(--color-yellow-1)',
  },
  [ASW_STATES.offers_requested]: {
    value: 'Расчет',
    name: ASW_STATES.offers_requested,
    sysName: ASW_STATES.offers_requested,
    color: 'var(--color-white)',
  },
};

export const fetchNames = {
  calculate: 'calculate',
  selectOffer: 'selectOffer',
  agreement: 'agreement',
  getPolicy: 'getPolicy',
  clientDecline: 'clientDecline',
  addingNewApllication: 'addingNewApllication',
};

import styled from 'styled-components';

import { ChevronSvg } from 'assets/img';

export const Wrapper = styled.div`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
`;

export const Panel = styled.div`
  display: flex;
  // cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  // width: 100%;
  cursor: pointer;
  ${(props) => props.isHidden && 'display: none;'}

  > svg {
    transform: ${(p) => (p.collapsed ? 'rotate(90deg)' : 'rotate(0)')};
    display: ${(p) => (p.collapsable ? 'block' : 'none')};
  }
`;

export const ChevronStyled = styled(ChevronSvg)`
  margin-left: 2px;
  margin-right: 15px;
  transition: all 0.1s ease-out;

  path {
    fill: var(--color-grey-4) !important;
  }
`;

export const Content = styled.div``;

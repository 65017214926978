import React from 'react';
import { Tooltip } from '../Tooltip';
import { useTooltipHoverPresenter } from './TooltipHover.presenter';

export const TooltipHover = (props) => {
  const {
    className,
    targetRef,
    children,
    theme = '1',
    size = 's',
    position = 'bottom',
    dataTest = '',
    delay = 0,
  } = props;

  const presenter = useTooltipHoverPresenter({ targetRef, delay });

  return (
    <>
      <Tooltip
        className={className}
        targetRef={targetRef}
        theme={theme}
        size={size}
        position={position}
        dataTest={dataTest}
        isShow={presenter.isShow}
      >
        {children}
      </Tooltip>
    </>
  );
};

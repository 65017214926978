import styled from 'styled-components';

import * as UK from 'components/primitives/content';
import { FormGridRowBase, FormHeader } from '../style';
import { Button } from 'components/uikit/Button/Button';
import { Checkbox } from 'components/uikit/Form/Checkbox/Checkbox';

export const Container = styled(FormGridRowBase)`
  grid-template-areas:
    '. hd hd hd hd hd hd .'
    '. txt txt txt txt txt txt .'
    '. cn cn cn cn cn cn .'
    '. sb sb sb sb sb sb .'
    '. dl dl dl dl dl dl .';
`;

export const Title = styled(FormHeader)`
  width: auto;
  text-align: left;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  grid-area: hd;
  margin-bottom: 16px;
  gap: 12px;
`;

export const Text = styled.p`
  grid-area: txt;
  margin-bottom: 16px;
  font: var(--font-regular-17);
`;

export const Content = styled.div`
  display: grid;
  gap: 12px;
  grid-area: cn;
`;

export const CheckBox = styled(Checkbox).attrs({ size: 's' })``;

export const SignButton = styled(Button)`
  grid-area: sb;
  margin-top: 24px;
`;

export const DocLink = styled(UK.Link)`
  &:hover {
    color: var(--color-grey-11_60);
  }
`;

export const DocumentsLinksWrap = styled.div`
  display: flex;
  justify-content: center;
  grid-area: dl;
`;

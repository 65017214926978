import styled from 'styled-components';

import { ChevronRightSIcon, SisclosureSIcon } from 'assets/icons';
import { Icon } from 'components/uikit/Icon/Icon';
import { MenuItem } from 'components/uikit/MenuItem/MenuItem';

export const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  width: ${(p) => (p.isOpen ? '180px' : '48px')};
  height: 100%;
  background-color: var(--color-black);
  z-index: 999999;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 4px 4px 4px;

  & + & {
    border-top: 4px solid var(--color-white_20);
  }

  & + &:last-child {
    border: none;
    padding-bottom: 8px;
    margin-top: auto;
  }
`;

export const SubPanelContainer = styled.div`
  position: fixed;
  top: 0px;
  left: ${(p) => (p.isOpen ? '180px' : '48px')};
  height: 100%;
  z-index: 999998;
`;

export const UserMenuItem = styled(MenuItem)`
  align-items: start;
  height: auto;
  padding: 8px 10px;
`;

export const TopMenuItem = styled(MenuItem)`
  height: 36px;
  line-height: 1;
`;

export const Logo = styled.a`
  display: flex;
  justify-content: ${(p) => (p.isOpen ? 'start' : 'center')};
  align-items: center;
  width: 100%;
  height: 42px;
  padding: ${(p) => (p.isOpen ? '0px 12px' : '0px')};
`;

export const NewsIcon = styled(Icon).attrs({ Icon: ChevronRightSIcon, size: 's' })`
  --color: var(--color-white_50);
`;

export const UserBlock = styled.div`
  display: flex;
  gap: 6px;
`;

export const UserBlockIcon = styled(Icon).attrs({ Icon: SisclosureSIcon, size: 's' })`
  margin-right: 2px;
  --color: ${(p) => (p.isActive ? 'var(--color-white)' : 'var(--color-white_50)')};
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const UserNameInfo = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserMoreInfo = styled.span`
  width: 100%;
  color: var(--color-white_50);
  font: var(--font-regular-11);
  white-space: normal;
`;

export const EPointsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  width: 100%;
  color: var(--color-white);
  padding: ${(p) => (p.isOpen ? '10px' : '0px')};
  background-color: var(--color-red-2);
`;

export const EPointsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.isOpen ? 'normal' : 'center')};
  width: ${(p) => (p.isOpen ? 'auto' : '100%')};
  gap: 4px;
`;

import { useDispatch, useSelector } from 'react-redux';

import * as providers from 'data-providers/ePointsProvider';
import * as ePointsStore from 'store/ePoints';
import * as userSelectors from 'store/user/selectors';
import * as metrica from 'metrika/loyaltyPrograms';

export const useEPointsBannerPresenter = () => {
  const rootDispatch = useDispatch();

  const dealer = useSelector(userSelectors.getDealer);
  const { email } = useSelector((store) => store.user);
  const { isEPointsDigitalDealMember } = useSelector((store) => store.ePoints);

  const presenter = {
    get hasBanner() {
      return (
        dealer?.epointsDigitalDealMember &&
        typeof isEPointsDigitalDealMember === 'boolean' &&
        !isEPointsDigitalDealMember
      );
    },

    async onClickEPointsHandler() {
      rootDispatch(ePointsStore.showEPointsPopup());
      rootDispatch(ePointsStore.setEmailError(''));
      metrica.ePointsRegistrationButtonTrack();

      try {
        await providers.ePointsValidateEmail(email);
      } catch (err) {
        console.error(err);
        if (err.response.data) {
          rootDispatch(
            ePointsStore.setEmailError(
              err.response.data.errors?.find((el) => el.property === 'email').message ||
                err.response.data.error?.message ||
                err.response.data.error
            )
          );
        }
      }
    },
  };

  return presenter;
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCommentary } from 'utils/handleCommentary';
import { AreaInfo, ReactTooltipStyled } from 'components/modules/MainAnketa/style';

import {
  DealItemApprovedContainer,
  DealItemStatusContainer,
  DetailCell,
  BankLogoIcon,
  DifferenceIco,
  SentBankIco,
  SentBankOkIco,
  TextWraper,
  InfoSvgStyled,
  FinancedButton,
} from './style';
import * as helpers from '../../helpers';
import ProductInfo from './ProductInfo';
import { BANK_MAX_SUM } from './constants';
import { convertCoinsToString } from 'utils/convertPrice';
import { setAdminManualFinanceParams, showFinancePopup } from 'store/applications';

export function DealItem({ deal, carInstance, applicationBody, color, secondColor }) {
  const itemParams = useSelector(
    (store) => store?.applications?.detailParams[applicationBody?.applicationId]
  );
  const rootDispatch = useDispatch();
  const isUserAdmin = useSelector((state) => state.user.role) === 3406;
  const [isShowButton, setShowButton] = useState(false);
  const { comment: statusComment, bank_msg } = deal?.AdditionalData?.statusData;
  const handleStatusСomment = handleCommentary(statusComment).replaceAll('<br>', ' ');

  const bankId = Number(deal.bank.id);
  const state = deal.state.state;
  const apprData = deal?.AdditionalData?.approvData?.data[0] || {};
  const isShowYear = helpers.isShowCarYear(carInstance, deal);
  const bankMaxSum = BANK_MAX_SUM.includes(bankId);

  const {
    comment: aprovComment,
    credit_sum,
    down_payment,
    term,
    monthly,
    max_limit,
    rate,
    bank_dop_sum,
    dealer_dop_sum,
    dealer_details = {},
    bank_details = {},
  } = apprData;
  const handleApproveComment = aprovComment
    ? handleCommentary(aprovComment).replaceAll('<br>', ' ')
    : '';

  const { boldPrice, boldInitialMoney, boldPeriod, boldRate, boldDealerSum } = helpers.compareObj(
    itemParams,
    apprData
  );
  const boldYear = deal.carYearMax > 0;
  const applicStatus = applicationBody?.applicationState?.state;
  const isShowFinancedButton =
    applicStatus &&
    isUserAdmin &&
    isShowButton &&
    !helpers.isApplicationInTheFinalStatus(applicStatus);

  const handleMouseEnter = () => {
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
  };

  const financedButtonClickHandler = () => {
    rootDispatch(showFinancePopup());
    rootDispatch(
      setAdminManualFinanceParams({
        bankId,
        dealId: deal.id,
        dealerName: applicationBody.dealerName,
      })
    );
  };

  return (
    <>
      {!helpers.checkApproveObj(apprData) ? (
        <DealItemStatusContainer
          isShowButton={isShowFinancedButton}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          color={color}
          secondColor={secondColor}
        >
          {isShowFinancedButton && (
            <FinancedButton onClick={financedButtonClickHandler}>Профинансировать</FinancedButton>
          )}
          <DetailCell>
            <BankLogoIcon type="cps" id={bankId} />
            {deal.bank.name || '—'}
          </DetailCell>
          {state === 'sent-to-the-bank' || state === 'queue' ? (
            bank_msg ? (
              <DetailCell>
                <SentBankOkIco />
                <TextWraper>
                  {handleCommentary(bank_msg).replaceAll('<br>', ' ') || helpers.checkStatus(state)}
                </TextWraper>
              </DetailCell>
            ) : (
              <DetailCell>
                <SentBankIco /> <TextWraper>{helpers.checkStatus(state)}</TextWraper>
              </DetailCell>
            )
          ) : (
            <DetailCell>
              {state === 'new'
                ? helpers.checkStatus(state)
                : handleApproveComment || handleStatusСomment || helpers.checkStatus(state)}
            </DetailCell>
          )}
        </DealItemStatusContainer>
      ) : (
        <DealItemApprovedContainer
          isShowButton={isShowFinancedButton}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          color={color}
          secondColor={secondColor}
          hasCarYear={isShowYear}
        >
          {isShowFinancedButton && (
            <FinancedButton onClick={financedButtonClickHandler}>Профинансировать</FinancedButton>
          )}
          <DetailCell>
            <BankLogoIcon type="cps" id={bankId} /> {deal?.bank.name || '—'}
          </DetailCell>

          <DetailCell bold={boldPrice}>
            {helpers.convertValueToStandartNumber(credit_sum)}
            {boldPrice && !bankMaxSum && <DifferenceIco />}
            {bankMaxSum && (
              <>
                <AreaInfo>
                  <InfoSvgStyled data-tip="" data-for="credit_sum" />
                  <ReactTooltipStyled id="credit_sum" place="top">
                    <span>{`Максимальная сумма кредита ${
                      max_limit ? convertCoinsToString(max_limit, false) : 'не определена'
                    }`}</span>
                  </ReactTooltipStyled>
                </AreaInfo>
              </>
            )}
          </DetailCell>

          <DetailCell bold={boldInitialMoney}>
            {helpers.convertValueToStandartNumber(down_payment)}{' '}
            {boldInitialMoney && <DifferenceIco />}
          </DetailCell>

          <DetailCell bold={boldPeriod}>
            {term || '—'} {boldPeriod && <DifferenceIco />}
          </DetailCell>

          <DetailCell bold={boldRate}>
            {rate ? `${rate}%` : '—'} {boldRate && <DifferenceIco />}
          </DetailCell>

          <DetailCell>{helpers.convertValueToStandartNumber(monthly)}</DetailCell>

          <DetailCell bold={boldDealerSum}>
            {helpers.convertValueToStandartNumber(dealer_dop_sum)}
            {boldDealerSum && Object.keys(dealer_details).length <= 1 && <DifferenceIco />}
            {Object.keys(dealer_details).length > 1 && (
              <ProductInfo id={`${deal.id}_dealerDetail`} productList={dealer_details} />
            )}
          </DetailCell>

          <DetailCell>
            {helpers.convertValueToStandartNumber(bank_dop_sum)}
            {Object.keys(bank_details).length > 1 && (
              <ProductInfo
                id={`${deal.id}_bankDetail`}
                productList={bank_details}
                bankProduct={true}
              />
            )}
          </DetailCell>

          {isShowYear && (
            <DetailCell bold={boldYear}>
              {deal.carYearMax || '—'}
              {boldYear && <DifferenceIco />}
            </DetailCell>
          )}
        </DealItemApprovedContainer>
      )}
    </>
  );
}

import React from 'react';

import { MenuItem } from '../MenuItem/MenuItem';
import { defaultMenuItem } from './Menu.constants';
import * as S from './Menu.styles';

export const Menu = (props) => {
  const { className, items, theme = '1', dataTest = '', MenuItemComponent = MenuItem } = props;

  return (
    <S.Container className={className} theme={theme} data-test={dataTest}>
      {items.map((item) => {
        const fullItem = { ...defaultMenuItem, ...item };

        return (
          <MenuItemComponent
            key={fullItem.id}
            title={fullItem.title}
            tooltip={fullItem.tooltip}
            theme={theme}
            Icon={fullItem.Icon}
            isActive={fullItem.isActive}
            dataTest={fullItem.dataTest}
            onClick={fullItem.onClick}
          >
            {item.Children}
          </MenuItemComponent>
        );
      })}
    </S.Container>
  );
};

import React from 'react';

import * as S from './Popup.styles';

export const Popup = (props) => {
  const {
    className,
    children,
    isOpen = true,
    isCloseByOverlay = true,
    theme = '1',
    dataTest = '',
    onClose = () => {},
  } = props;

  if (!isOpen) {
    return null;
  }

  const onOverlayClick = () => {
    if (isCloseByOverlay) {
      onClose();
    }
  };

  const onPopupClick = (event) => {
    event.stopPropagation();
  };

  return (
    <S.Overlay theme={theme} onClick={onOverlayClick}>
      <S.Container>
        <S.CloseButton onClick={onClose} />

        <S.Popup className={className} data-test={dataTest} onClick={onPopupClick}>
          {children}
        </S.Popup>
      </S.Container>
    </S.Overlay>
  );
};

import React, { useState, useCallback, useEffect } from 'react';
import { InputMask } from 'utils/inputMask';

import * as S from './style';
import { debounce } from 'lodash';
import {
  assistanceAnketaGetLegalData,
  assistanceAnketaSetInsurerDadataInfo,
  assistanceAnketaUpdateInsurerDadataInfo,
  assistanceChangeByKey,
  assistanceDeleteError,
} from 'store/assistanceAnketa';
import { useDispatch } from 'react-redux';
import { LegalCheckbox } from '../LegalCheckbox';
import AddressLegal from '../AddressLegal';

const LegalInfo = ({ data: anketaState, disabled = false, errors = {} }) => {
  const rootDispatch = useDispatch();
  const [phonesValue, setPhonesValue] = useState('');
  const [legalsDataList, setLegalsDataList] = useState([]);

  const legalInputValue = anketaState?.insurerDadataInfo?.name?.short_with_opf || anketaState?.fio;
  const addressLegalValue =
    anketaState?.insurerDadataInfo?.address?.unrestricted_value ||
    anketaState?.insurerAddress ||
    '';

  const getLegalData = useCallback(
    debounce(async ({ legal }) => {
      rootDispatch(assistanceAnketaGetLegalData(legal?.query, setLegalsDataList));
    }, 500),
    []
  );

  useEffect(() => {
    const phone = anketaState?.insurerDadataInfo?.phones || anketaState?.insurerPhone;
    if (!phonesValue && phone) {
      setPhonesValue(phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anketaState?.insurerDadataInfo]);

  const onIisLivingAddressEqualsSwitch = () => {
    rootDispatch(
      assistanceChangeByKey({
        key: 'legalActualAddress',
        value: anketaState?.insurerDadataInfo?.address?.unrestricted_value || '',
      })
    );
    rootDispatch(
      assistanceChangeByKey({
        key: 'isLivingAddressEquals',
        value: !anketaState?.isLivingAddressEquals,
      })
    );
  };

  const onBlurPhoneHandler = ({ phones }) => {
    rootDispatch(assistanceAnketaUpdateInsurerDadataInfo({ phones }));
    if (phones) {
      rootDispatch(assistanceDeleteError({ id: 'insurerDadataInfo', key: 'phones' }));
    }
  };

  const onLegalAddressSelect = ({ addressLegal: { unrestricted_value } }) => {
    rootDispatch(
      assistanceAnketaUpdateInsurerDadataInfo({
        address: { ...anketaState?.insurerDadataInfo?.address, unrestricted_value },
      })
    );
    if (unrestricted_value) {
      rootDispatch(assistanceDeleteError({ id: 'insurerDadataInfo', key: 'address' }));
    }
  };

  const onLegalActualAddressSelect = ({ addressLegal }) => {
    rootDispatch(
      assistanceChangeByKey({ key: 'legalActualAddress', value: addressLegal?.unrestricted_value })
    );
  };

  return (
    <S.LegalWrap>
      <S.LegalInput
        unmask
        dropdownList={legalsDataList}
        type="legal"
        name="legal"
        label="Юридическое название или ИНН организации"
        disabled={disabled}
        value={legalInputValue}
        onChange={getLegalData}
        onSelect={({ legal }) => {
          rootDispatch(assistanceAnketaSetInsurerDadataInfo(legal.data));
          if (legal.data?.name?.short_with_opf) {
            rootDispatch(assistanceDeleteError({ id: 'insurerDadataInfo', key: 'name' }));
            rootDispatch(assistanceDeleteError({ id: 'insurerDadataInfo', key: 'address' }));
          }
        }}
        error={errors?.insurerDadataInfo?.name?.short_with_opf}
      />
      <S.Phone
        required
        isControlledComponent
        val={phonesValue}
        name="phones"
        label="Телефон организации"
        mask={InputMask.CELLPHONE_PRIVATE_NEW}
        disabled={disabled}
        onChange={({ phones }) => {
          setPhonesValue(phones);
        }}
        onBlur={onBlurPhoneHandler}
        error={errors?.insurerDadataInfo?.phones}
      />
      <AddressLegal
        inputLabel="Юридический адрес организации"
        onSelect={onLegalAddressSelect}
        value={addressLegalValue}
        disabled={disabled}
        error={errors?.insurerDadataInfo?.address?.unrestricted_value}
      />
      <LegalCheckbox
        name="isLivingAddressEquals"
        label="Совпадает с фактическим адресом"
        disabled={disabled}
        checked={anketaState?.isLivingAddressEquals}
        onSwitch={onIisLivingAddressEqualsSwitch}
      />
      {!anketaState?.isLivingAddressEquals && (
        <AddressLegal
          inputLabel="Фактический адрес организации"
          onSelect={onLegalActualAddressSelect}
          value={anketaState?.legalActualAddress}
          disabled={disabled}
        />
      )}
    </S.LegalWrap>
  );
};

export default LegalInfo;

import React from 'react';

import * as S from './SubPanel.styles';

export const SubPanel = ({ title, children, dataTest, onClose }) => {
  return (
    <S.Container data-test={dataTest}>
      <S.Title>
        {title}
        <S.CloseButton dataTest={`${dataTest}CloseButton`} onClick={onClose} />
      </S.Title>

      {children}
    </S.Container>
  );
};

import { BANKS } from 'components/common/BankLogo/constants';

export const onlyCyrillc = (str) => str && str.replace(/[^ А-Яа-яёЁ,. \- 0-9 №/]/g, '');

export const passportCode = (str) => {
  if (str && str.length === 6 && str.indexOf('-') === -1) {
    return `${str.slice(0, 3)}-${str.slice(3, str.length)}`;
  }
  if (str && str.length < 6 && str.indexOf('-') > -1) {
    return str.replace('-', '');
  }
  if (str && str.length > 7 && str.indexOf('-') > -1) {
    return str.slice(0, 7);
  }
  return str;
};

export const addressIndex = (str) => str && str.slice(0, 6).replace(/[^0-9]/g, '');

export const onlyNumber = (str) => String(str).replace(/[^0-9]/g, '');

export const onlyDecimal = (str) => {
  const part = String(str).split('.');
  part[0] = onlyNumber(part[0]);
  part[0] = part[0].length > 0 ? part[0] : '0';
  part[1] = onlyNumber(part[1] || '');
  part[1] = part[1].length > 0 ? `.${part[1]}` : '';

  return part[0] + part[1];
};

export const withOutSpace = (str) => str.replace(/ /g, '');

export const removeExtraSpaces = (str = '') => str.replace(/\s+/g, ' ').trim();

export const carNumber = (str) => {
  const strEdit = str
    .replace(/[^А-Яа-яёЁ0-9]/g, '')
    .slice(0, 9)
    .toUpperCase();
  if (strEdit.length >= 8) {
    return `${strEdit.slice(0, 1).replace(/[^А-Яа-яёЁ]/g, '')} ${strEdit
      .slice(1, 4)
      .replace(/[^0-9]/g, '')} ${strEdit.slice(4, 6).replace(/[^А-Яа-яёЁ]/g, '')} ${strEdit
      .slice(6, 9)
      .replace(/[^0-9]/g, '')}`;
  }
  return strEdit;
};

export const firstCharUpper = (str) =>
  str
    .split(' ')
    .map((sp) => sp.charAt(0).toUpperCase() + sp.slice(1))
    .join(' ');

export const swapNameSurname = (str) => {
  let [a, b] = str.trim().split(' ');
  return `${b} ${a}`;
};

export const splitFio = (str) => {
  const fio = str.trim().split(' ');
  return fio.reduce((acc, item, i) => {
    if (item.trim()) {
      let field = i === 0 ? 'surname' : 'name';
      field = i > 1 ? 'middleName' : field;
      acc[field] = acc[field] ? `${acc[field]} ${item.trim()}` : item.trim();
    }

    return acc;
  }, {});
};

export const getPhoneNumber = (phones) => {
  const correctPhones = phones.reduce((total, { value }) => {
    const rectifiedPhone = onlyNumber(value);
    if (rectifiedPhone.length === 11) {
      total.push(rectifiedPhone);
    }

    return total;
  }, []);

  return correctPhones.length ? correctPhones[0] : '';
};

export const clearPhoneNumber = (phone) => {
  const clearPhone = onlyNumber(phone);
  if (clearPhone.length === 11) {
    return '+' + clearPhone;
  }

  return null;
};

export const capitalize = ([first, ...rest]) =>
  `${first.toUpperCase()}${rest.join('').toLowerCase()}`;

export const capitalizeAll = (str = '') => {
  return capitalize(str.toLowerCase()).replace(/[\s-][а-яa-z]/g, (match) => match.toUpperCase());
};

export const findCarfinIdByCpsId = (cpsId) => {
  const bank = BANKS.find((item) => Number(item.cpsId) === cpsId);
  return bank ? bank.carfinId : null;
};

export const snakeAndKebabToCamel = (str) =>
  str.replace(/([-_][a-z])/gi, (group) => group.toUpperCase().replace(/[-_]/g, ''));

export const standartWhitespaceAndDashes = (str) => {
  return str
    .replace(/(\s+)-/g, '-')
    .replace(/-(\s+)/g, '-')
    .replace(/\s+/g, ' ');
};

export const getShortFio = (fio, fullName = true) => {
  const { lastName = '', firstName = '', middleName = '' } = fio || {};
  let name = firstName.trim().charAt(0);
  let patronymic = middleName.trim().charAt(0);
  name = name ? `${name}.` : '';
  patronymic = patronymic ? `${patronymic}.` : '';

  if (fullName) {
    return firstCharUpper(removeExtraSpaces(`${lastName} ${name} ${patronymic}`).toLowerCase());
  }

  return firstCharUpper(removeExtraSpaces(`${lastName} ${name}`).toLowerCase());
};

import styled from 'styled-components';
import { Popup } from 'components/primitives/popup';
import { LightBlueButton } from 'components/primitives/content';
import { Button } from 'components/uikit/Button/Button';

export const PopupStyled = styled(Popup)`
  & > div {
    width: 664px;
    min-height: 0px;
    padding: 32px;
    color: var(--color-black);
    border-radius: 8px;
    & > svg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--color-grey-1);
      top: 32px;
      right: 32px;
    }
  }
  z-index: 9999;
`;

export const Title = styled.h6`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: left;
  padding-right: 32px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 15px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

export const DeclineButton = styled(LightBlueButton)`
  margin-right: 20px;
`;

export const ConfirmButton = styled(Button)``;

export const UIDeclineButton = styled(Button)`
  margin-right: 20px;
`;

import styled from 'styled-components';

import { AvatarSvg, CameraSvg, CameraFlatSvg, UploadCloudSvg } from 'assets/img';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 10px;
  border-radius: 50%;
  position: relative;

  img {
    width: ${(p) => `${p.size}px`};
    height: ${(p) => `${p.size}px`};
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const AvatarSvgStyled = styled(AvatarSvg)`
  width: 100%;
  height: 100%;
`;

export const CameraWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

export const CameraSvgStyled = styled(CameraSvg)`
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};

  cursor: pointer;

  circle {
    fill: var(--color-blue-3);
  }

  path {
    fill: var(--color-grey-1);
  }
`;

export const UploadOptions = styled.div`
  position: absolute;
  top: 100%;
  z-index: 1000;
  border: 1px solid var(--color-grey-4);
  border-radius: 8px;
  background: white;
  padding: 0 15px 15px 15px;
`;

export const UploadOption = styled.div`
  padding-top: 15px;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--color-blue-3);
  }

  svg path {
    fill: var(--color-blue-3);
  }
`;

export const PhotoOptionIcon = styled(CameraFlatSvg)`
  width: 13px;
  height: 13px;
  margin-right: 7px;
`;

export const UploadOptionIcon = styled(UploadCloudSvg)`
  width: 13px;
  height: 13px;
  margin-right: 7px;
`;

import React, { useState, useEffect } from 'react';
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom';
import { ApplicationLayout } from 'components/modules/ApplicationLayout';
import { ApplicationClientLayout } from 'components/modules/ApplicationClientLayout';
import { uuidObject } from 'utils/uuid';

export const Application = () => {
  const params = useParams();
  const { path } = useRouteMatch();
  const [isCreateNewPage, setIsCreateNewPage] = useState(path.includes('/worksheet/create-new'));
  // eslint-disable-next-line no-unused-vars
  const [sessionId, setSessionId] = useState();

  useEffect(
    () => () => {
      uuidObject.set();
    },
    []
  );

  useEffect(() => {
    if (params?.id !== sessionId) {
      setSessionId(params?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (isCreateNewPage) {
      uuidObject.set();
    }
  }, [isCreateNewPage]);

  useEffect(() => {
    if (sessionId && !isCreateNewPage) {
      uuidObject.set();
      setIsCreateNewPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <>
      <Switch>
        <Route path={'/worksheet/:id/credit/anketa'} exact>
          <ApplicationLayout />
        </Route>
        <Route
          path={[
            '/worksheet/create-new/calculator',
            '/worksheet/create-new/osago',
            '/worksheet/:id/home',
            '/worksheet/:id/calculator',
            '/worksheet/:id/loan-issue',
            '/worksheet/:id/assistance',
            '/worksheet/:id/insurance',
            '/worksheet/:id',
          ]}
        >
          <ApplicationClientLayout />
        </Route>
      </Switch>
    </>
  );
};

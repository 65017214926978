import styled from 'styled-components';

const themes = {
  1: {
    colorBkg: 'transparent',
    colorBkgHover: 'var(--color-white_10)',
    colorBkgActive: 'var(--color-white_20)',
    colorBkgDisabled: 'transparent',
    colorIcon: 'var(--color-white_50)',
    colorIconHover: 'var(--color-white_68)',
    colorIconActive: 'var(--color-white_68)',
    colorIconDisabled: 'var(--color-white_20)',
    borderRadius: '50%',
  },
  2: {
    colorBkg: 'var(--color-black_50)',
    colorBkgHover: 'var(--color-black_70)',
    colorBkgActive: 'var(--color-black_50)',
    colorBkgDisabled: 'var(--color-black_30)',
    colorIcon: 'var(--color-white)',
    colorIconHover: 'var(--color-white)',
    colorIconActive: 'var(--color-white)',
    colorIconDisabled: 'var(--color-white_68)',
    borderRadius: '50%',
  },
  3: {
    colorBkg: 'transparent',
    colorBkgHover: 'transparent',
    colorBkgActive: 'transparent',
    colorBkgDisabled: 'transparent',
    colorIcon: 'var(--color-grey-11_60)',
    colorIconHover: 'var(--color-grey-11_60)',
    colorIconActive: 'var(--color-grey-11_60)',
    colorIconDisabled: 'var(--color-grey-11_60)',
    borderRadius: '50%',
  },
  4: {
    colorBkg: 'var(--color-grey-10_10)',
    colorBkgHover: 'var(--color-grey-10_10)',
    colorBkgActive: 'var(--color-grey-10_10)',
    colorBkgDisabled: 'var(--color-grey-10_10)',
    colorIcon: 'var(--color-grey-11_60)',
    colorIconHover: 'var(--color-grey-11_60)',
    colorIconActive: 'var(--color-grey-11_60)',
    colorIconDisabled: 'var(--color-grey-11_60)',
    borderRadius: '16px',
  },
};

const sizes = {
  s: {
    button: { width: '32px', height: '32px' },
    icon: { width: '16px', height: '16px' },
  },
  m: {
    button: { width: '44px', height: '44px' },
    icon: { width: '20px', height: '20px' },
  },
  l: {
    button: { width: '56px', height: '56px' },
    icon: { width: '24px', height: '24px' },
  },
  xl: {
    button: { width: '64px', height: '64px' },
    icon: { width: '20px', height: '20px' },
  },
};

export const Container = styled.button`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-hover: ${(p) => (themes[p.theme] || themes['1']).colorBkgHover};
  --color-bkg-active: ${(p) => (themes[p.theme] || themes['1']).colorBkgActive};
  --color-bkg-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgDisabled};
  --color-icon: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-icon-hover: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-icon-active: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-icon-disabled: ${(p) => (themes[p.theme] || themes['1']).colorIconDisabled};
  --border-radius: ${(p) => (themes[p.theme] || themes['1']).borderRadius};
  --width: ${(p) => (sizes[p.size] || sizes['m']).button.width};
  --height: ${(p) => (sizes[p.size] || sizes['m']).button.height};
  --width-icon: ${(p) => (sizes[p.size] || sizes['m']).icon.width};
  --height-icon: ${(p) => (sizes[p.size] || sizes['m']).icon.height};

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: var(--width);
  height: var(--height);
  padding: 0px;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--color-bkg);
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: var(--color-bkg-hover);
  }

  &:active {
    background-color: var(--color-bkg-active);
  }

  &:disabled {
    background-color: var(--color-bkg-disabled);
    cursor: default;
  }

  & svg {
    width: var(--width-icon);
    height: var(--height-icon);
    fill: var(--color-icon);
  }

  &:hover svg,
  &:focus svg,
  &:focus-visible svg {
    fill: var(--color-icon-hover);
  }

  &:active svg {
    fill: var(--color-icon-active);
  }

  &:disabled svg {
    fill: var(--color-icon-disabled);
  }
`;

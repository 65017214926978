import { useState, useRef, useEffect } from 'react';

export const useTooltipHoverPresenter = ({ targetRef, delay }) => {
  const [isShow, setIsShow] = useState();

  const showTimeoutId = useRef(null);

  const presenter = {
    isShow,

    show() {
      setIsShow(true);
    },

    hide() {
      setIsShow(false);
    },

    clearShowTimeout() {
      if (showTimeoutId.current) {
        clearTimeout(showTimeoutId.current);
        showTimeoutId.current = null;
      }
    },

    onMouseEnter() {
      if (showTimeoutId.current) {
        return;
      }

      showTimeoutId.current = setTimeout(() => {
        presenter.clearShowTimeout();
        presenter.show();
      }, delay);
    },

    onMouseLeave() {
      presenter.clearShowTimeout();
      presenter.hide();
    },
  };

  useEffect(() => {
    const targetElement = targetRef?.current;
    const mouseEnterHandler = presenter.onMouseEnter;
    const mouseLeaveHandler = presenter.onMouseLeave;

    if (!targetElement) {
      return;
    }

    targetElement.addEventListener('mouseenter', mouseEnterHandler);
    targetElement.addEventListener('mouseleave', mouseLeaveHandler);

    return () => {
      targetElement.removeEventListener('mouseenter', mouseEnterHandler);
      targetElement.removeEventListener('mouseleave', mouseLeaveHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return presenter;
};

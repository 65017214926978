import styled from 'styled-components';

import * as UK from 'components/primitives/content';
import { LogoSvg } from 'assets/img';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  margin-top: -100px;
`;

export const Form = styled(UK.Form).attrs({ cols: 1, rows: 3 })`
  width: 100%;
`;

export const Logo = styled(LogoSvg)`
  width: 150px;
  margin-bottom: 50px;
`;

export const Error = styled(UK.BkgBlock).attrs({ color: 'var(--color-red-3)' })`
  position: absolute;
  top: -50px;
  width: 100%;
  margin: 0px !important;
  padding: 10px;
  color: var(--color-red-2);
  transform: translateY(-100%);
`;

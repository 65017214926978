import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_CAR_INSTANCE: {
      const { carInstance } = action.payload;

      return {
        ...state,
        carInstance: {
          ...state.carInstance,
          ...carInstance,
        },
        fetch: false,
        isPatching: false,
      };
    }

    case actions.SET_FETCH_CAR_INSTANCE: {
      return { ...state, fetch: action.payload };
    }

    case actions.SET_FETCH_CAR_INFO: {
      return { ...state, carInfoFetch: action.payload };
    }

    case actions.SET_PATCHIG_CAR_INSTANCE: {
      return { ...state, isPatching: action.payload };
    }

    case actions.SET_ERROR_CAR_INSTANCE: {
      return { ...state, error: action.payload, fetch: false };
    }

    case actions.UPDATE_ERRORS_CAR_INSTANCE: {
      const { errors } = action.payload;

      return { ...state, errors: { ...state.errors, ...errors } };
    }

    case actions.DROP_ERRORS_CAR_INSTANCE: {
      return { ...state, errors: { ...initialState.errors } };
    }

    case actions.DROP_ONE_ERROR_CAR_INSTANCE: {
      const { catalog, name } = action.payload;
      const newErrors = catalog
        ? { [catalog]: { ...state.errors[catalog], [name]: '' } }
        : { [name]: '' };

      return { ...state, errors: { ...state.errors, ...newErrors } };
    }

    case actions.VALIDATE_CAR_INSTANCE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case actions.FORCE_VALIDATE_CAR_INSTANCE: {
      const forceValidate = state.forceValidate + 1;
      return {
        ...state,
        forceValidate,
      };
    }

    case actions.SCROLL_TO_INSTANCE: {
      return { ...state, scrollTo: action.payload.scrollTo };
    }

    case actions.CLEAR_SCROLL_TO_INSTANCE: {
      return { ...state, scrollTo: null };
    }

    case actions.CLEAR_CAR_INSTANCE: {
      return {
        ...initialState,
        requiredFields: [...state.requiredFields],
        hiddenFields: [...state.hiddenFields],
        isOpenBlock: state.isOpenBlock,
        isOpenDetails: state.isOpenDetails,
      };
    }

    case actions.UPDATE_CAR_INSTANCE_ID: {
      return {
        ...state,
        carInstance: { ...state.carInstance, id: action.payload },
      };
    }

    case actions.PATCH_IS_NEW: {
      return { ...state, patchIsNew: action.payload };
    }

    case actions.CHANGE_DEALERS_PARAMS: {
      return {
        ...state,
        dealersParams: {
          ...state.dealersParams,
          ...action.payload.dealersParams,
        },
      };
    }

    case actions.SET_FETCH_DEALERS_PARAMS: {
      return {
        ...state,
        dealersParams: {
          ...state.dealersParams,
          fetchDealersParams: action.payload,
        },
      };
    }

    case actions.SET_IMAGE: {
      const image = action.payload.image ? action.payload.image : null;

      return {
        ...state,
        carInstance: { ...state.carInstance, image },
      };
    }

    case actions.DECLINE_DROP_ERRORS: {
      return { ...state, declineDropErrors: action.payload.declineDropErrors };
    }

    case actions.OPEN_BLOCK: {
      return { ...state, isOpenBlock: true };
    }

    case actions.CLOSE_BLOCK: {
      return { ...state, isOpenBlock: false };
    }

    case actions.OPEN_DETAILS: {
      return { ...state, isOpenDetails: true };
    }

    case actions.CLOSE_DETAILS: {
      return { ...state, isOpenDetails: false };
    }

    case actions.DISABLE: {
      return { ...state, isDisable: true };
    }

    case actions.ENABLE: {
      return { ...state, isDisable: false };
    }

    case actions.SET_COLLAPSABLE: {
      return { ...state, isCollapsable: action.payload };
    }

    case actions.SET_CAR_REQUIRED_FIELDS: {
      return { ...state, requiredFields: action.payload };
    }

    case actions.SET_CAR_HIDDEN_FIELDS: {
      return { ...state, hiddenFields: action.payload };
    }
    case actions.SET_CAR_DISABLE_FIELDS: {
      return { ...state, disableFields: action.payload };
    }

    default:
      return state;
  }
}

export function getCarInstance(id) {
  return {
    type: actions.GET_CAR_INSTANCE,
    payload: { id },
  };
}

export function patchCarInstance(
  carInstance,
  id,
  loadImage,
  phases,
  phPayload,
  customerCarInstance = false
) {
  return {
    type: actions.PATCH_CAR_INSTANCE,
    payload: {
      carInstance,
      id,
      loadImage,
      phases,
      phPayload,
      customerCarInstance,
    },
  };
}

export function dropErrors() {
  return {
    type: actions.DROP_ERRORS_CAR_INSTANCE,
  };
}

export function dropError(name, catalog = '') {
  return {
    type: actions.DROP_ONE_ERROR_CAR_INSTANCE,
    payload: { catalog, name },
  };
}

export function updateErrors(errors) {
  return {
    type: actions.UPDATE_ERRORS_CAR_INSTANCE,
    payload: { errors },
  };
}

export function forceValidateCarInstance(forceValidate = false) {
  return {
    type: actions.VALIDATE_CAR_INSTANCE,
    payload: { forceValidate },
  };
}

export function forceValidateComplete() {
  return {
    type: actions.FORCE_VALIDATE_CAR_INSTANCE,
  };
}

export function setCarInstance(carInstance) {
  return {
    type: actions.SET_CAR_INSTANCE,
    payload: { carInstance },
  };
}

export function scrollTo(scrollTo) {
  return {
    type: actions.SCROLL_TO_INSTANCE,
    payload: { scrollTo },
  };
}

export function clearScroll() {
  return {
    type: actions.CLEAR_SCROLL_TO_INSTANCE,
  };
}

export function clearCarInstance() {
  return {
    type: actions.CLEAR_CAR_INSTANCE,
  };
}

export function clearError() {
  return {
    type: actions.SET_ERROR_CAR_INSTANCE,
    payload: '',
  };
}

export function getCarInstanceById(id, wsid) {
  return {
    type: actions.GET_CAR_INSTANCE_BY_ID,
    payload: { id, wsid },
  };
}

export function updateCarInstanceId(id) {
  return {
    type: actions.UPDATE_CAR_INSTANCE_ID,
    payload: id,
  };
}

export function changeCarInstance(carInstance, id, loadImage, phases, phPayload) {
  return {
    type: actions.CHANGE_CAR_INSTANCE,
    payload: { carInstance, id, loadImage, phases, phPayload },
  };
}

export function getDealersParams(currentDealerId) {
  return {
    type: actions.GET_DEALERS_PARAMS,
    payload: { currentDealerId },
  };
}

export function setImage(model) {
  return {
    type: actions.SET_IMAGE,
    payload: { image: model.image },
  };
}

export function declineDropErrors(declineDropErrors = true) {
  return {
    type: actions.DECLINE_DROP_ERRORS,
    payload: { declineDropErrors },
  };
}

export function openCarInstance() {
  return { type: actions.OPEN_BLOCK, payload: {} };
}

export function closeCarInstance() {
  return { type: actions.CLOSE_BLOCK, payload: {} };
}

export function openCarInstanceDetails() {
  return { type: actions.OPEN_DETAILS, payload: {} };
}

export function closeCarInstanceDetails() {
  return { type: actions.CLOSE_DETAILS, payload: {} };
}

export function disableCarInstance() {
  return { type: actions.DISABLE, payload: {} };
}

export function enableCarInstance() {
  return { type: actions.ENABLE, payload: {} };
}

export function setCarInstanceCollapsable(value) {
  return { type: actions.SET_COLLAPSABLE, payload: value };
}

export function setCarRequiredFields(fields) {
  return { type: actions.SET_CAR_REQUIRED_FIELDS, payload: fields };
}

export function setCarHiddenFields(fields) {
  return { type: actions.SET_CAR_HIDDEN_FIELDS, payload: fields };
}
export function setCarDisableFields(fields) {
  return { type: actions.SET_CAR_DISABLE_FIELDS, payload: fields };
}

import React, { useRef } from 'react';

import { useTargetBubblePresenter } from './TargetBubble.presenter';
import * as S from './TargetBubble.styles';

export const TargetBubble = ({ data, onClose }) => {
  const bubbleRef = useRef();
  const presenter = useTargetBubblePresenter(bubbleRef, data, onClose);

  if (!presenter.isShow) {
    return null;
  }

  return (
    <S.Overlay>
      <S.Container ref={bubbleRef} style={presenter.position}>
        <S.Content>
          {data.title && <S.Title>{data.title}</S.Title>}
          {data.text && <S.Text>{data.text}</S.Text>}
        </S.Content>

        <S.CloseButton onClick={presenter.onClose}>Понятно</S.CloseButton>
      </S.Container>
    </S.Overlay>
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EPointsMenuItem } from 'components/common/EPointsMenuItem/EPointsMenuItem';
import { mainMenuChooseRoleTrack, mainMenuChoseDealerTrack } from 'metrika/mainmenu';

import * as logoutApi from 'data-providers/authProvider.js';
import * as filtersStore from 'store/filters';
import * as userStore from 'store/user';
import * as userSelectors from 'store/user/selectors';

import * as help from '../MainMenu.helpers';

export const useUserPanelPresenter = (onClose) => {
  const rootDispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(userSelectors.getUser);
  const { isEPointsDigitalDealMember } = useSelector((store) => store.ePoints);

  const [searchValue, setSearchValue] = useState('');
  const [searchDealerItems, setSearchDealerItems] = useState([]);

  const presenter = {
    searchValue,

    get userRole() {
      return Number(user.role);
    },

    get userDealer() {
      return Number(user.dealer);
    },

    get menuRoleItems() {
      return (user.roles || []).map((role) => {
        const isActive = role.id === presenter.userRole;
        return {
          id: role.id,
          title: role.name,
          dataTest: `setRoleButton-${role.id}${isActive ? ' selected' : ''}`,
          isActive,
          onClick: () => {
            mainMenuChooseRoleTrack();
            presenter.onChangeRole(role.id);
          },
        };
      });
    },

    get menuDealerItems() {
      return (user.dealers || [])
        .map((dealer) => {
          const isActive = dealer.id === presenter.userDealer;
          return {
            id: dealer.id,
            serchTitle: dealer.name,
            title: dealer.epointsDigitalDealMember ? (
              <EPointsMenuItem
                title={dealer.name}
                ePointsLogo={
                  dealer.epointsDigitalDealMember
                    ? isEPointsDigitalDealMember
                      ? 'active'
                      : 'disabled'
                    : null
                }
              />
            ) : (
              dealer.name
            ),
            dataTest: `setDealerButton-${dealer.id}${isActive ? ' selected' : ''}`,
            isActive: dealer.id === presenter.userDealer,
            onClick: () => {
              mainMenuChoseDealerTrack();
              presenter.onChangeDealer(dealer.id);
            },
          };
        })
        .sort((a, b) => b.isActive - a.isActive);
    },

    get searchDealerItems() {
      if (!searchValue.trim()) {
        return presenter.menuDealerItems;
      }

      return searchDealerItems;
    },

    resetStore() {
      localStorage.removeItem('worksheet');
      localStorage.removeItem('carinstanceId');
      localStorage.removeItem('customerId');
    },

    searchDealers(searchValue) {
      const searchStr = searchValue.trim().toLowerCase();

      if (!searchStr) {
        setSearchDealerItems([]);
        return;
      }

      setSearchDealerItems(
        presenter.menuDealerItems.filter((item) =>
          item.serchTitle.toLowerCase().includes(searchStr)
        )
      );
    },

    onChangeRole(id) {
      rootDispatch(userStore.changeActiveRole(id));
      presenter.resetStore();

      history.replace(`/applications/${help.getListAppsUrl(id)}`);
    },

    onChangeDealer(id) {
      rootDispatch(userStore.changeActiveDealer(id));
      presenter.resetStore();
    },

    onLogoutClick() {
      onClose();
      rootDispatch(filtersStore.setSearch(''));
      rootDispatch(userStore.userLogout());
      logoutApi.logout();
    },

    onSearchValueChange(event) {
      setSearchValue(event.target.value);
    },
  };

  useEffect(() => {
    presenter.searchDealers(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, user.dealer]);

  return presenter;
};

import styled from 'styled-components';

import { Button } from 'components/uikit/Button/Button';

export const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999;
`;

export const Container = styled.div.attrs({ theme: '1' })`
  position: absolute;
  max-width: 300px;
  background-color: var(--color-blue-3);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--shadow-4);
  transform: translateY(-50%);
`;

export const Content = styled.div`
  padding: 20px;
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font: var(--font-caption-15);
  color: var(--color-white);
`;

export const Text = styled.p`
  margin: 0px;
  font: var(--font-regular-15);
  color: var(--color-white);
`;

export const CloseButton = styled(Button).attrs({ theme: '2', size: 'm' })`
  --button-height: 40px;
  --color-text: var(--color-white);
  --border-radius: 0px 0px 12px 12px;
  width: 100%;
  border-top: 1px solid var(--color-white_30) !important;
`;

import React from 'react';

import * as S from './Avatar.styles';

export const Avatar = (props) => {
  const { className, name, theme = '1', dataTest = '' } = props;

  return (
    <S.Container className={className} theme={theme} data-test={dataTest}>
      {name.charAt(0).toUpperCase()}
    </S.Container>
  );
};

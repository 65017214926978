import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Carrotquest } from 'components/modules/Widgets/External/Carrotquest';

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
    },
    appFrame: {
      height: '100%',
      minHeight: '100%',
    },
    content: {
      height: '100%',
      minHeight: '100%',
    },
    contentWithClosedSidebar: {
      marginLeft: 48,
    },
    contentWithOpenedSidebar: {
      marginLeft: 180,
    },
  });

class Layout extends Component {
  state = {
    hasError: false,
    errorMessage: null,
    errorInfo: null,
    isOpenSidebar: false,
  };

  constructor(props) {
    super(props);

    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  onToggleSidebar(isOpen) {
    this.setState({ isOpenSidebar: isOpen });
  }

  render() {
    const { children, classes, className, error, sidebar: Sidebar, title } = this.props;

    const contetnClassName = classnames(classes.content, {
      [classes.contentWithClosedSidebar]: !this.state.isOpenSidebar,
      [classes.contentWithOpenedSidebar]: this.state.isOpenSidebar,
    });

    return (
      <div className={classnames('layout', classes.root, className)}>
        <div className={classes.appFrame}>
          <Sidebar
            isOpen={this.state.isOpenSidebar}
            onToggle={(isOpen) => this.onToggleSidebar(isOpen)}
          />

          <main className={classes.content}>
            <div className={contetnClassName}>
              {this.state.hasError
                ? createElement(error, {
                    error: this.state.errorMessage,
                    errorInfo: this.state.errorInfo,
                    title,
                  })
                : children}
            </div>
          </main>
          <Carrotquest />
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  history: PropTypes.object.isRequired,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.node,
};

const EnhancedLayout = compose(
  connect(),
  withRouter,
  withStyles(styles, { name: 'ecred-layout' })
)(Layout);

export default EnhancedLayout;

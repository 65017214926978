const initialState = {
  isEPointsDigitalDealMember: null,
  totalPoints: null,
  ePointsAnketa: {
    firstName: '',
    lastName: '',
    patronymicName: '',
    email: '',
    phoneNumber: '',
    acceptTenderOffer: false,
  },
  showEPointsPopup: false,
  popupFrames: {
    default: false,
    registrationStart: true,
    smsConfirm: false,
    registrationFinal: false,
  },
  emailValidationError: '',
};

export default initialState;

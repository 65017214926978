import styled, { css } from 'styled-components';

import { CheckSvg } from 'assets/img';

export const WidgetWrapper = styled.div`
  background: #f7f7f7;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-radius: 5px;
  ${({ stick }) =>
    stick
      ? css`
          position: fixed;
          top: 50px;
        `
      : ''}
`;

export const ChartWrapper = styled.div`
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @media (min-width: 1366px) {
    padding: 0 9px 0 12px;
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 110%;
  margin-bottom: 14px;

  &:hover {
    color: #000000;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: underline;
  color: ${(p) => (p.status ? 'var(--color-red-2)' : 'var(--color-green-1)')};

  &:hover {
    color: ${(p) => (p.status ? 'var(--color-red-2)' : 'var(--color-green-1)')};
  }
`;

export const CheckImg = styled(CheckSvg)`
  margin-right: 10px;
  > path {
    fill: ${(p) => (p.error ? 'var(--color-grey-3)' : 'var(--color-green-1)')};
  }
`;

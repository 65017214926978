import styled, { css } from 'styled-components';

export const Button = styled.button`
  margin: 20px auto 0px auto;
  background: var(--color-blue-5);
  border: 1px solid var(--color-blue-4);
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 160px;
  min-height: 34px;
  transition: all 0.5s ease;
  cursor: pointer;
  ${(props) =>
    props.isRedButton &&
    css`
      background: rgba(205, 38, 38, 0.45);
      border-color: var(--color-red-2);
    `}
  &:hover {
    background: var(--color-blue-4);
    ${(props) =>
      props.isRedButton &&
      css`
        background: var(--color-red-2);
      `}
  }
`;

export const Cost = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: ${(props) => (props.isGray ? 'var(--color-grey-4)' : 'var(--color-black)')};
`;

export const CompanyName = styled.div`
  font-size: 12px;
  line-height: 1;
  color: var(--color-grey-4);
`;

export const Offer = styled.div`
  font-size: 12px;
  line-height: 1;
  color: var(--color-grey-4);
  margin-bottom: 10px;
`;

import {
  abstractAddressFromAPIToState,
  abstractAddressFromStateToAPI,
  abstractAddressInitialState,
} from './abstractAddress';
import { convertDate } from '../../../utils/dataMappers/mappers';

export const registrationAddressInitialState = {
  addressReg: {
    ...abstractAddressInitialState,
    regDate: '',
    livingAddressEquals: true,
  },
};

export const registrationAddressFromAPIToState = (customer) => ({
  addressReg: {
    ...abstractAddressFromAPIToState(customer.registrationAddress.address),
    regDate: convertDate(customer.registrationAddress.registrationDate),
    livingAddressEquals: customer.livingAddressEquals,
    isRegistrationAddressFromDadata: customer.registrationAddress.isRegistrationAddressFromDadata,
  },
});

export const registrationAddressFromStateToAPI = (person) => ({
  registrationAddress: {
    registrationDate: person.addressReg.regDate || '',
    address: abstractAddressFromStateToAPI(person.addressReg),
    livingAddressEquals: person.addressReg.livingAddressEquals,
    isRegistrationAddressFromDadata: person.addressReg.isRegistrationAddressFromDadata,
  },
  livingAddressEquals: person.addressReg.livingAddressEquals,
});

import * as actions from './constants';
import { initialState } from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SHOW_TOAST: {
      const newToast = {
        id: Date.now(),
        ...action.payload,
      };

      return {
        ...state,
        toasts: [...state.toasts, newToast],
        isVisible: true,
      };
    }
    case actions.HIDE_TOAST: {
      const newToasts = state.toasts.filter((toast) => toast.id !== action.payload);

      return {
        ...state,
        toasts: newToasts,
        isVisible: newToasts.length > 0,
      };
    }
    default:
      return state;
  }
}

export const showToast = (toast) => ({
  type: actions.SHOW_TOAST,
  payload: toast,
});

export const hideToast = (id) => ({
  type: actions.HIDE_TOAST,
  payload: id,
});

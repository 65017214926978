/* eslint-disable indent */
import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { Mask } from 'components/primitives/content';
import { Radio } from 'components/primitives/radio';
import { CheckBox } from 'components/primitives/content';

export const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

export const GridStyled = styled(Grid)`
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    '. cs cs cs . . . .'
    '. space0 space0 space0 space0 space0 space0 .'
    '. carnum carnum . . . . .'
    '. space1 space1 space1 space1 space1 space1 .'
    '. main main main main main main .'
    '. space2 space2 space2 space2 cb cb .'
    '. params params params params params params .'
    '. space3 space3 space3 space3 space3 space3 .'
    '. docs docs docs docs docs docs .'
    '. . . . . sd sd .';
`;

export const InnerGrid = styled(Grid)`
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  row-gap: 18px;
`;

export const CarState = styled(Radio)`
  grid-area: cs;
  cursor: not-allowed;
`;

export const InputMaskStyled = styled(Mask)``;

export const CarNumber = styled(InputMaskStyled)`
  grid-area: carnum;
`;

export const BlockHeader = styled.div`
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 20px;
  line-height: 22px;
`;

export const BlankSpace = styled.div`
  height: ${(props) => (props.height ? props.height : 15)}px;
`;

export const Space0 = styled(BlankSpace)`
  grid-area: space0;
`;

export const Space1 = styled(BlankSpace)`
  grid-area: space1;
`;

export const Space2 = styled(BlankSpace)`
  grid-area: space2;
`;

export const Space3 = styled(BlankSpace)`
  grid-area: space3;
`;

export const HideButton = styled.button`
  grid-area: cb;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  background-color: white;
  border: 0;
  color: #a8a8a8;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.5s ease;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  margin: 0;
`;

export const SteeringWheelCheckbox = styled(CheckBox)`
  margin-left: 20px;
`;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const statusParams = {
  awaits: {
    color: 'var(--color-blue-5)',
    fontColor: 'var(--color-blue-3)',
    title: 'Ожидает подписания',
  },
  signed: { color: 'var(--color-green-4)', fontColor: 'var(--color-green-2)', title: 'Подписано' },
  dataСhanged: {
    color: 'var(--color-orange-2)',
    fontColor: 'var(--color-orange-1)',
    title: 'Данные изменены',
  },
};

export const useBadgeStatusPresenter = () => {
  const { isPEPSignature, isChangeValues } = useSelector((state) => state.anketa || {});
  const [status, setStatus] = useState(statusParams.awaits);

  const presenter = {
    status,
  };

  useEffect(() => {
    let status = {};
    if (isPEPSignature) {
      status = statusParams.signed;
    } else {
      isChangeValues ? (status = statusParams.dataСhanged) : (status = statusParams.awaits);
    }
    setStatus(status);
  }, [isChangeValues, isPEPSignature]);

  return presenter;
};

import styled from 'styled-components';

import { Button } from 'components/uikit/Button/Button';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 32px;
  margin-top: 20px;
  gap: 2px;
`;

export const FirstCell = styled.div`
  flex: 0 0 260px;
  min-width: 260px;
  padding: 4px 12px 4px 0;
  color: var(--color-black);

  font: var(--font-regular-15);
`;

export const Cell = styled.div`
  flex: 1;
  min-width: 80px;
  min-height: 20px;
  padding: 4px 12px 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);

  font: var(--font-regular-15);
`;

export const LastCell = styled.div`
  flex: 0 0 142px;
  min-width: 142px;
  padding: 0;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const FalseState = styled.span`
  font: var(--font-regular-15);
`;

export const FinallyEmptyState = styled.span`
  color: var(--color-grey-11_60);

  font: var(--font-regular-15);
`;

import React from 'react';
import { ReactTooltipStyled } from 'components/modules/MainAnketa/style';
import { Wrap, NewFeatureIcon } from './styles';

export default function NewFeatureTooltip(ChildrenComponent, position) {
  const NewFeatureTooltip = (props) => {
    return (
      <Wrap>
        <ChildrenComponent {...props} />

        <NewFeatureIcon data-tip="" data-for={`Advice`} position={position} />
        <ReactTooltipStyled id={`Advice`} place="top" arrowColor={'var(--color-grey-4)'}>
          <div>Попробуйте новый функционал</div>
        </ReactTooltipStyled>
      </Wrap>
    );
  };
  NewFeatureTooltip.displayName = `NewFeatureTooltip`;

  return NewFeatureTooltip;
}

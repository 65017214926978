import { useEffect, useRef, useState } from 'react';

export const useTextInputPresenter = (
  value,
  placeholder,
  label,
  error,
  onChange,
  onBlur,
  onFocus
) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [inputError, setInputError] = useState('');

  const presenter = {
    inputRef,
    inputError,
    isActiveLabel: isFocused || value !== '',

    get placeholder() {
      return label ? (isFocused ? placeholder : '') : placeholder;
    },

    onHandleBlur(event) {
      setIsFocused(false);
      onBlur(event);
    },

    onHandleFocus(event) {
      setIsFocused(true);
      onFocus(event);
    },

    onHandleClear(event) {
      event.stopPropagation();

      if (inputRef.current) {
        inputRef.current.value = '';
        setInputError('');
      }

      onChange({ target: { value: '' } });
    },
  };

  useEffect(() => {
    setInputError(error);
  }, [error]);

  return presenter;
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadNotificationsMainPage, resetNotifications } from 'store/notifications';

export const useMainPageNotifications = () => {
  const rootDispatch = useDispatch();

  const { id: userId } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.notifications.fetch);
  const list = useSelector((state) => state.notifications.appsMainPage);
  const [sortedList, setSortedList] = useState([]);

  const now = new Date();
  const lastDay = new Date(now);

  const presenter = {
    sortedList,
    isLoading,
  };

  useEffect(() => {
    lastDay.setDate(lastDay.getDate() - 1);
    const filteredList = Object.values(list).filter((item) => new Date(item.date) > lastDay);
    const newSortedList = filteredList.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    setSortedList(newSortedList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (userId) {
      rootDispatch(loadNotificationsMainPage({ userId, isEmailBank: 0 }));
    } else {
      rootDispatch(resetNotifications());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return presenter;
};

import styled from 'styled-components';

import { CloudDragSvg, CheckSvg, MinusSolidSvg } from 'assets/img';

export const WrapperStyled = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--color-grey-1);
  border: 2px dashed ${(p) => (p.dragover ? 'var(--color-green-1)' : 'var(--color-grey-4)')};
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const InputStyled = styled.input`
  height: 0;
  width: 0;
  border: 0;
  opacity: 0;
`;

export const Label = styled.div`
  display: flex;
  height: 85px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  text-align: ${(p) => (p.centered ? 'center' : 'left')};
`;

export const ImgLoader = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 30px;
`;

export const Text = styled.div`
  flex-grow: ${(p) => (p.centered ? '0' : '10')};
`;

export const Header = styled.h4`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin: 0;
`;

export const HeadeDownLoad = styled.h4`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const Desc = styled.p`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  margin-top: 12px;
  color: #808080;
  text-align: left;
`;
export const Error = styled.p`
  color: var(--color-red-2);
`;

export const CloudDragStyled = styled(CloudDragSvg)`
  margin-right: 10px;
  height: 18px !important;
  width: 30px !important;
  min-height: 18px !important;
  min-width: 30px !important;
`;

export const CheckSvgStyled = styled(CheckSvg)`
  margin-left: 12px;
  margin-right: 12px;
  height: 18px !important;
  width: 18px !important;
  min-height: 18px !important;
  min-width: 18px !important;
`;

export const MinusSolidSvgStyled = styled(MinusSolidSvg)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  min-height: 15px;
  min-width: 15px;
  fill: #b5b5b5;
  cursor: pointer;
`;

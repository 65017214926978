import React from 'react';

import { Wrapper } from './style';

const State = ({ className = '', state = {} }) => {
  const { value, color, fontColor, secondColor } = state;

  if (!value || !color) {
    return null;
  } else {
    return (
      <Wrapper
        className={className}
        color={color}
        text={value}
        title={value}
        fontColor={fontColor}
        secondColor={secondColor}
      />
    );
  }
};

State.displayName = 'State';

export default State;

import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { sendOpenedDeal } from 'data-providers/applicationsProvider';

import * as S from './style';
import { sendClickTrack } from 'metrika/applications';

const loader = <PulseLoader size={11} color={'var(--color-black)'} loading={true} />;

export default function SendButton({ dealsList, pickUpStatus }) {
  const [load, setLoad] = useState(false);
  const [isButtonDiasable, setIsButtonDiasable] = useState(true);

  useEffect(() => {
    if (pickUpStatus.data) {
      setIsButtonDiasable(pickUpStatus.data.running);
    }
  }, [pickUpStatus]);

  const dealsStatusNew = dealsList.filter((deal) => deal.state.state === 'new');
  const dealsId = dealsStatusNew.map((deal) => deal.id);
  const sendDealHandler = async () => {
    setLoad(true);
    try {
      const response = await sendOpenedDeal({ deals: dealsId });
      if (response) {
        setLoad(false);
      }
    } catch (err) {
      setLoad(false);
    }
    sendClickTrack();
  };

  return (
    <S.Button
      onClick={sendDealHandler}
      dataTest="sendDealOpened"
      disabled={load || isButtonDiasable}
    >
      {load ? loader : 'Отправить'}
    </S.Button>
  );
}

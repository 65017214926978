import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { InputMask } from 'utils/inputMask';
import { InfoSvg, AlertSvg, CheckSvg } from 'assets/img';
import { isDocumentExists } from 'utils/dataMappers/mappers';

import { AnketaChevronStyled, ReactTooltipStyled, AreaInfo } from '../../../style';
import { FormGridRow, FormHeader, SecDocInput, DriverLicenseDate, PassportDate } from './style';
import * as help from './helpers';

const SECOND_DOC_TRUST = [
  'driving-licence',
  'snils',
  'inn',
  'international-passport',
  'military-id',
];

export const SecondDocForm = ({
  onChange,
  errors,
  persona,
  validate,
  anketaSave,
  contentHidden = false,
  disabled = false,
}) => {
  const keyComp = 'secondDocument';
  const [values, setValues] = useState(null);
  const [error, setError] = useState({});
  const params = useParams();
  const { secondDocumentTypesList } = useSelector((state) => state.referenceBooks);
  const [secondDocType, setSecondDocType] = useState(null);
  const [secDocValue, setSecDocValue] = useState(null);
  const [updatedFromDoc, setUpdatedFromDoc] = useState(false);
  const { documents } = useSelector((state) => state.anketa);
  const [showMore, setShowMore] = useState(false);
  // const [focusTimer, setFocusTimer] = useState(null);

  const { service } = params;

  const changeHandler = (val) => {
    let stateVal = { ...values };

    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }

    const docDataFieldName = help.getDocTypeName(stateVal.currentSecondDocumentType);

    setValues(stateVal);
    onChange({
      id: persona.id,
      key: keyComp,
      values: {
        ...help.getDefaultValue(),
        currentSecondDocumentTypeSysName: stateVal.currentSecondDocumentType.sysName,
        currentSecondDocumentType: stateVal.currentSecondDocumentType,
        [docDataFieldName]: stateVal[docDataFieldName],
      },
    });
  };

  const validation = (path, withSave = true) => {
    setError({ ...error, [path]: errors[path] || null });
    if (withSave) anketaSave();
  };

  const getError = (path) => {
    if (validate) {
      return errors[path];
    }
    return error[path];
  };

  useEffect(() => {
    if (persona && values !== persona[keyComp]) {
      setValues({ ...help.getDefaultValue(), ...persona[keyComp] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  useEffect(() => {
    if (values) {
      const [doc, number] = help.getDocTypeField(values.currentSecondDocumentType).split('.');
      const secDocType = secondDocumentTypesList.find(
        (s) => s.sysName === values.currentSecondDocumentType.sysName
      );
      if (secondDocType && values.currentSecondDocumentType.sysName !== secondDocType.sysName) {
        setUpdatedFromDoc(true);
      }
      setSecondDocType(secDocType);
      setSecDocValue(values[doc][number].replaceAll('-', '').replaceAll(' ', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!validate) {
      setError(
        Object.keys(error).reduce((obj, key) => ({ ...obj, [key]: errors[key] || null }), {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  useEffect(() => {
    if (service) {
      changeHandler({
        currentSecondDocumentType: secondDocumentTypesList.find((o) => o.sysName === '2nd_driver'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShowDate = () => {
    return (
      secondDocType?.sysName === '2nd_foreign_passport' || secondDocType?.sysName === '2nd_driver'
    );
  };

  const changeSerialNumber = (val, mask) => {
    if (!values) return;
    const cmask = mask.name;
    const value = val.toUpperCase();
    const secDocType = secondDocumentTypesList.find((s) => s.sysName === cmask);

    setSecondDocType(secDocType);
    setSecDocValue(value);

    const series4 = value.slice(0, 4);
    const number4 = value.slice(4, value.length);
    const series2 = value.slice(0, 2);
    const number2 = value.slice(2, value.length);
    const part1 = value.slice(0, 3);
    const part2 = value.slice(3, 6);
    const part3 = value.slice(6, 9);
    const part4 = value.slice(9, 11);

    changeHandler({
      ...help.getDefaultValue(),
      currentSecondDocumentTypeSysName: secDocType.sysName,
      currentSecondDocumentType: { ...secDocType },
      driverLicense: {
        ...values.driverLicense,
        seriesNumber: value,
        series: (series4 && series4.toUpperCase()) || '',
        number: (number4 && number4.toUpperCase()) || '',
      },
      internationalPassport: {
        ...values.internationalPassport,
        seriesNumber: value,
        series: series2 || '',
        number: number2 || '',
      },
      militaryID: {
        ...values.militaryID,
        seriesNumber: value,
        series: (series2 && series2.toUpperCase()) || '',
        number: (number2 && number2.toUpperCase()) || '',
      },
      taxRegistration: {
        ...values.taxRegistration,
        inn: value,
      },
      snils: {
        ...values.snils,
        number: `${part1 || ''}-${part2 || ''}-${part3 || ''} ${part4 || ''}`,
      },
    });
  };

  const isSecDocError = () => {
    if (!errors) return false;
    const errs = Object.keys(errors).map((f) => f.replaceAll(/\[\d\]/gu, ''));
    return errs.some((ef) => ef.includes('secondDocument'));
  };

  useEffect(() => {
    if (documents && isDocumentExists(documents, SECOND_DOC_TRUST) && values && errors) {
      if (isSecDocError() === true) {
        if (showMore === false) {
          setShowMore(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (showMore && contentHidden) {
      setShowMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore, contentHidden]);

  return (
    <>
      {values && (
        <FormGridRow id={keyComp}>
          <FormHeader
            onClick={() => !contentHidden && setShowMore(!showMore)}
            collapsed={contentHidden || !showMore}
            data-test={`${keyComp} ${contentHidden || !showMore ? 'collapsed' : 'expanded'}`}
          >
            <AnketaChevronStyled className="chevron" />
            <h3>
              {(secondDocType?.value &&
                (secondDocType?.value === 'Свидетельство о регистрации в налоговом органе'
                  ? 'ИНН'
                  : secondDocType?.value)) ||
                'Второй документ'}
            </h3>
            {!disabled && (
              <>
                {isDocumentExists(documents, SECOND_DOC_TRUST) === false && (
                  <>
                    <AreaInfo>
                      <AlertSvg className="alert" data-tip="" data-for="secDocAlertInfo" />
                      <ReactTooltipStyled id="secDocAlertInfo" place="right">
                        <span>Загрузите документ</span>
                      </ReactTooltipStyled>
                    </AreaInfo>
                  </>
                )}
                {isDocumentExists(documents, SECOND_DOC_TRUST) === true &&
                  isSecDocError() === false &&
                  secondDocType?.value && (
                    <>
                      <AreaInfo>
                        <CheckSvg className="check" data-tip="" data-for="secDocCheckInfo" />
                        <ReactTooltipStyled id="secDocCheckInfo" place="right">
                          <span>распознан</span>
                        </ReactTooltipStyled>
                      </AreaInfo>
                    </>
                  )}
              </>
            )}
          </FormHeader>
          {!contentHidden && showMore && (
            <>
              <SecDocInput
                id={`SecDocInput`}
                tabIndex="42"
                name="SecDocInput"
                label="Серия, номер"
                defaultValue={secDocValue}
                required
                unmask
                mask={[
                  InputMask.ZPASSPORT_SERIA_NUMBER[0],
                  InputMask.ARMY_SERIAL_NUMBER[0],
                  InputMask.DRIVE_LICENSE_SERIES_NUMBER[0],
                  InputMask.SNILS[0],
                  InputMask.INN_PRIVATE[0],
                ]}
                val={secDocValue}
                onChange={(val, mask) => {
                  if (updatedFromDoc === true) {
                    setUpdatedFromDoc(false);
                  } else {
                    changeSerialNumber(val.SecDocInput, mask.masked.currentMask);
                  }
                }}
                onBlur={() => {
                  validation(`${keyComp}.currentSecondDocumentType.sysName`, false);
                  validation(`${keyComp}.${help.getDocTypeField(secondDocType)}`);
                }}
                error={
                  getError(`${keyComp}.currentSecondDocumentType.sysName`) ||
                  getError(`${keyComp}.${help.getDocTypeField(secondDocType)}`)
                }
                disabled={disabled}
              />
              <AreaInfo
                style={{
                  gridArea: getShowDate() ? 'is' : 'date',
                  marginTop: '19px',
                }}
              >
                <InfoSvg data-tip="" data-for="secDocInfo" data-test="secDocInfoSvg" />
                <ReactTooltipStyled id="secDocInfo" place="right">
                  Введите серию и номер документа согласно маске:
                  <br />
                  ВУ - 1234 123456
                  <br />
                  СНИЛС - 123-123-123 12
                  <br />
                  Загран паспорт - 12 1234567
                  <br />
                  Военный билет - ХХ 1234567
                  <br />
                  ИНН - 123456789012
                </ReactTooltipStyled>
              </AreaInfo>
              {secondDocType?.sysName === '2nd_foreign_passport' && (
                <PassportDate
                  id={`${keyComp}_internationalPassport_issueDate`}
                  tabIndex="30"
                  required
                  name="issueDate"
                  label="Дата выдачи"
                  defaultValue={values.internationalPassport.issueDate}
                  val={values.internationalPassport.issueDate}
                  mask={InputMask.DATE_BEFORE_TODAY}
                  unmask
                  onChange={({ issueDate }) => {
                    const date = issueDate.length === 2 ? '' : issueDate;
                    changeHandler({
                      internationalPassport: {
                        ...values.internationalPassport,
                        issueDate: date,
                      },
                    });
                  }}
                  onBlur={() => validation(`${keyComp}.internationalPassport.issueDate`)}
                  error={getError(`${keyComp}.internationalPassport.issueDate`)}
                  disabled={disabled}
                />
              )}
              {secondDocType?.sysName === '2nd_driver' && (
                <DriverLicenseDate
                  id={`${keyComp}_driverLicense_issueDate`}
                  tabIndex="25"
                  required
                  name="issueDate"
                  label="Дата выдачи"
                  defaultValue={values.driverLicense.issueDate}
                  val={values.driverLicense.issueDate}
                  mask={InputMask.DATE_BEFORE_TODAY}
                  unmask
                  onChange={({ issueDate }) => {
                    const date = issueDate.length === 2 ? '' : issueDate;
                    changeHandler({
                      driverLicense: {
                        ...values.driverLicense,
                        issueDate: date,
                      },
                    });
                  }}
                  onBlur={() => validation(`${keyComp}.driverLicense.issueDate`)}
                  error={getError(`${keyComp}.driverLicense.issueDate`)}
                  disabled={disabled}
                />
              )}
            </>
          )}
        </FormGridRow>
      )}
    </>
  );
};

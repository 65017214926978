import styled from 'styled-components';

import { Button } from 'components/uikit/Button/Button';
import { CelluarInput } from 'components/uikit/CelluarInput/CelluarInput';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
`;

export const StyledCelluarInput = styled(CelluarInput)`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const StyledButton = styled(Button).attrs({ size: 'l' })`
  width: 100%;
`;

export const Description = styled.p`
  margin: 8px 0 0 0;
  color: var(--color-grey-11_60);

  font: var(--font-regular-15);
`;

import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { uploadFiles, postProfile } from 'store/anketa';
import { spouseInitialState } from 'store/anketa/mappers/spouse';
import { getFio, getAdress } from 'data-providers/dadataProvider';
import { fetchAds } from 'data-providers/adsProvider';
import { generateAnketaDoc } from 'data-providers/mainAnketaProvider';
import { fileRecognize } from 'store/anketa/mappers/fileRecognize';
import { addCreditId } from 'store/startPage';
import { CarPreloaderGif } from 'assets/img';
import debounce from 'lodash/debounce';
import sleep from 'utils/sleep';
import { addParamsToUrl, extractParamsFromUrl } from 'utils/url';
import { convertSysNameToSelectOption, isPersonMerried } from 'utils/dataMappers/mappers';
import { dadataAddressToState } from 'store/anketa/mappers/abstractAddress';
import { useBeforeunload } from 'hooks/useBeforeunload';
import { useDocumentVisibility } from 'hooks/useDocumentVisibility';
// BNKAPI-1410
//import { anketaToAPI } from 'store/anketa/utils';
import { addClassifyFile } from 'store/fileRecognizer/actions';
import { MESS_SERNUMBER_MVD } from 'utils/validationUtils/messages';
import CheckAuthWithLoader from 'components/common/CheckAuthWithLoader';
import { hasAdsFetchCondition, updatePages } from 'utils/helpers';
import { getBankLimitedList, isUsePEP } from 'store/user/selectors';
import { getLoyaltyProgramsMembership } from 'utils/loyaltyPrograms';

import { schema } from './validation';
import { EPointsLogoSImg } from 'assets/images';

import * as S from './style';

const initState = {
  values: null,
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'values': {
      return { ...state, values: { ...state.values, ...action.payload } };
    }
    case 'errors_set': {
      return { ...state, errors: { ...action.payload } };
    }
    case 'errors_clear': {
      return { ...state, errors: {} };
    }
    default:
      throw new Error();
  }
};

const REDUIRED_FIELDS = ['personalInfo.fullName', 'contacts.mobilePhone'];

const SendButtonWithLoader = CheckAuthWithLoader(S.SendButton);

export const Application = ({ onErrors, onChange, redirectUrl, onRouteOut, isAnketa }) => {
  const rootDispatch = useDispatch();
  const params = useParams();
  const isDocumentHidden = useDocumentVisibility();
  const { addToast, removeAllToasts } = useToasts();
  const [state, dispatch] = useReducer(reducer, initState);

  const { anketa, referenceBooks } = useSelector((state) => state);
  const startPageStore = useSelector((state) => state.startPage);
  const { documents, documentTypes, anketaFetch, error, saved, isPEPSignature, specialСonditions } =
    anketa;
  const { values, errors } = state;
  const { persons, currentPersonId } = values || anketa;
  const { secondDocumentTypesList, genderList } = useSelector((state) => state.referenceBooks);
  const isPEP = useSelector(isUsePEP);
  const bankLimitedList = useSelector(getBankLimitedList);
  const role = useSelector((state) => state.user.role || -1);

  const [validateAll, setValidateAll] = useState(false);
  const [forcedValidation, setForcedValidation] = useState([]);
  const [prevDoc, setPrevDoc] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const [onlyMvdError, setOnlyMvdError] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [contentHidden, setContentHidden] = useState(false);
  const [moreFetching, setMoreFetching] = useState(false);
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const [documentStateChanged, setDocumentStateChanged] = useState(false);

  const isFetchAds = hasAdsFetchCondition(specialСonditions, !!bankLimitedList.length);

  useEffect(() => {
    setContentHidden([3406].includes(role));
  }, [role]);

  useEffect(() => {
    if (!startPageStore?.application?.ids?.credit && params?.id) {
      rootDispatch(addCreditId(params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPageStore?.application?.ids?.credit, params?.id]);

  const debouncedUpdateValues = useRef(
    debounce((values) => dispatch({ type: 'values', payload: values }, 500))
  );

  const debouncedValidate = useRef(
    debounce(
      (values, isPEP, isPEPSignature, callback) =>
        updateErrors(values, isPEP, isPEPSignature, callback),
      250
    )
  );

  const redirect = async (url) => {
    const urlData = extractParamsFromUrl(url, ['generateAnketaDoc', 'isFetchAds']);

    if (urlData.params.generateAnketaDoc) {
      setMoreFetching(true);
      await generateAnketaDoc(startPageStore.application.ids.credit);
      setMoreFetching(false);
    }

    if (urlData.params.isFetchAds) {
      setMoreFetching(true);
      await fetchAds(startPageStore.application.ids.credit);
      setMoreFetching(false);
    }

    onRouteOut(null);
    window.open(urlData.url, '_self');
  };

  const updateErrors = (values, isPEP, isPEPSignature, callback = () => {}) => {
    if (!values) return;
    values.spouse = {
      ...values.spouse,
      birthDate: values.spouse.birthDate || 0,
    };
    if (!values.documentsPages) {
      values.documentsPages = { ...values.documents };
      values.documents = [...documents];
    }
    schema
      .validate({ ...values }, { abortEarly: false })
      .then(() => {
        dispatch({ type: 'errors_set', payload: {} });
        callback();
      })
      .catch((err) => {
        let error =
          err.inner && err.inner.reduce((obj, item) => ({ ...obj, [item.path]: item.message }), {});

        if (values.selfAgreement) {
          const keys = ['personalInfo.fullName'];
          const personalKeys = ['addressReg', 'passport'];
          const personalKeysExclude = ['documentsPages.passport'];
          const check = Object.keys(error).filter(
            (eKey) =>
              keys.some((cKey) => cKey === eKey) ||
              personalKeys.some(
                (pKey) =>
                  eKey.includes(pKey) && !personalKeysExclude.some((pKey) => eKey.includes(pKey))
              )
          );
          error = check ? check.reduce((obj, key) => ({ ...obj, [key]: error[key] }), {}) : {};
        } else if (isPEP && isPEPSignature) {
          const keys = ['sign.personal-data.type.sysName'];
          const check = Object.keys(error).filter((eKey) => !keys.includes(eKey));
          error = check ? check.reduce((obj, key) => ({ ...obj, [key]: error[key] }), {}) : {};
        }

        dispatch({ type: 'errors_set', payload: error });
        callback();
      });
  };

  const handleSave = (isSignPEP = false) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    setValidateAll(true);

    if (!persons[currentPersonId].applicationParams?.lastModifiedAt) {
      setFirstTimeRender(true);
    }

    if (saved && isChange) {
      addToast('Сохранение анкеты', {
        appearance: 'success',
        autoDismiss: false,
      });

      // Temporary fix: cleaning confidants or spouse
      const persona = { ...persons[currentPersonId] };
      if (isPersonMerried(persona)) {
        persona.trustees = [];
      } else {
        persona.spouse = { ...spouseInitialState.spouse };
      }

      rootDispatch(
        postProfile({
          referenceBooks,
          persona,
          applicationId: parseInt(anketa.application.id),
          isSignPEP,
        })
      );
    } else {
      if (redirectUrl) {
        redirect(redirectUrl);
      } else {
        addToast('Изменений не найдено', { appearance: 'success' });
      }
    }
  };

  function saveAnketa() {
    // BNKAPI-1410
    //function saveAnketa(obj, withAnketaCheck = true) {
    // if (withAnketaCheck && isSavingDisabled()) return;
    // if (!obj.currentPersonId || !obj.persons) return;
    // setTimerId(null);
    // const persona = obj.persons[obj.currentPersonId];
    // if (isPersonMerried(persona)) {
    //   persona.trustees = [];
    // } else {
    //   persona.spouse = { ...spouseInitialState.spouse };
    // }
    // anketaToAPI({
    //   persona,
    //   applicationId: parseInt(anketa.application.id),
    // });
  }

  const handleSend = () => {
    const url =
      `${process.env.REACT_APP_STAGE_URL}/#application-details-new` +
      `?app_id=${startPageStore.application.ids.credit}` +
      `&tab=${3}`;
    const params = {
      generateAnketaDoc: isPEP ? 1 : null,
      isFetchAds: isFetchAds ? 1 : null,
      specialDistribution: 0,
    };
    onRouteOut(addParamsToUrl(url, params));
  };

  const updateState = (obj) => {
    const val = { ...values, ...obj };
    onChange(val);
    dispatch({ type: 'values', payload: val });
  };

  const updateByDoc = async () => {
    if (prevDoc && documents && prevDoc.length < documents.length) {
      const newDoc = documents.filter((d) => !prevDoc.some((p) => p.id === d.id));
      if (newDoc.length > 0) {
        const updatePerson = newDoc.reduce(
          (per, doc) => fileRecognize(doc, per, { secondDocumentTypesList, genderList }),
          { ...persons[currentPersonId] }
        );

        if (
          updatePerson.personalInfo.fullName &&
          newDoc.some((doc) => doc.type && doc.type.sysName === 'passport')
        ) {
          const obj = await getFio(updatePerson.personalInfo.fullName);
          const gender = convertSysNameToSelectOption(
            obj?.data?.gender && obj.data.gender !== 'UNKNOWN'
              ? obj?.data?.gender?.toLowerCase()
              : 'male',
            genderList
          );
          updatePerson.personalInfo = {
            ...updatePerson.personalInfo,
            gender: gender,
          };
        }

        if (
          updatePerson.addressReg.fiasValue &&
          newDoc.some(
            (doc) =>
              doc.type &&
              doc.type.sysName === 'passport' &&
              doc.classification &&
              (doc.classification.type === 'passport_registration' ||
                doc.classification.type === 'passport_registration_handwritten') &&
              doc.recognition &&
              doc.recognition.percent &&
              doc.recognition.percent >= 75
          )
        ) {
          const obj = await getAdress(updatePerson.addressReg.fiasValue);
          if (!obj) {
            updatePerson.addressReg = {
              ...updatePerson.addressReg,
              fiasValue: '',
            };
          } else {
            updatePerson.addressReg = {
              ...updatePerson.addressReg,
              ...dadataAddressToState(obj.data, obj.value),
            };
          }
        }

        updatePerson.documents = updatePages(documents, documentTypes);

        const newState = {
          ...values,
          persons: { ...persons, [currentPersonId]: updatePerson },
        };

        updateErrors(updatePerson, isPEP, isPEPSignature, () => {
          updateState(newState, true);
        });
        await sleep(1000);

        if (updatePerson.fieldsToValidate) {
          planAnketaSaving(newState, false, true);
          setForcedValidation(updatePerson.fieldsToValidate);
          delete updatePerson.fieldsToValidate;
        }

        setPrevDoc(documents);
        if (newDoc.some((doc) => doc.type && doc.type.sysName === 'customer-other-files')) {
          rootDispatch(addClassifyFile());
        }
      }
    } else {
      if (values && currentPersonId) {
        debouncedValidate.current(preparePerson(), isPEP, isPEPSignature, () => {});
      }
    }

    if (!prevDoc || prevDoc.length > documents.length) {
      setPrevDoc(documents);
    }
  };

  const isSavingDisabled = () => {
    if (isPEP && !isPEPSignature) {
      return true;
    }

    if (isChange) {
      const errs = Object.keys(errors).map((f) => f.replaceAll(/\[\d\]/gu, ''));
      return errs.some((ef) => REDUIRED_FIELDS.some((rf) => rf === ef));
    }

    return true;
  };

  const isSendDisabled = () => {
    if (isPEP && !isPEPSignature) return true;
    return (Object.keys(errors).length > 0 && !onlyMvdError) || moreFetching;
  };

  useEffect(() => {
    updateState(anketa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anketa.persons]);

  const preparePerson = () => {
    const persona = values.persons[currentPersonId];
    const { realestate, cars } = persona;
    var trustees = persona.trustees;
    if (trustees && trustees.length > 1) {
      trustees = [trustees[0]];
    }

    const preparedPerson = {
      ...persona,
      trustees: trustees && Object.keys(trustees).reduce((arr, id) => [...arr, trustees[id]], []),
      realestate:
        realestate &&
        Object.keys(persona.realestate).reduce((arr, id) => [...arr, realestate[id]], []),
      cars: cars && Object.keys(persona.cars).reduce((arr, id) => [...arr, cars[id]], []),
      documentsPages: updatePages(documents, documentTypes),
      documents: documents,
      sign: ['personal-data'].reduce(
        (obj, key) => ({
          ...obj,
          [key]: documents.find((doc) => doc.type && key === doc.type.sysName),
        }),
        {}
      ),
    };
    return preparedPerson;
  };

  useEffect(() => {
    if (
      values &&
      currentPersonId !== 0 &&
      values.persons[currentPersonId] &&
      anketaFetch &&
      documents &&
      !error
    ) {
      const objToArr = preparePerson();

      if (validateAll) {
        if (objToArr.applicationParams.lastModifiedAt) {
          debouncedValidate.current(objToArr, isPEP, isPEPSignature, () => {});
        } else {
          debouncedValidate.current(objToArr, isPEP, isPEPSignature, () => {
            dispatch({ type: 'errors_clear' });
            setForcedValidation([]);
          });
        }
      } else {
        debouncedValidate.current(objToArr, isPEP, isPEPSignature, () => {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anketaFetch, values && values.persons[currentPersonId], isPEP, isPEPSignature]);

  useEffect(() => {
    const persona = persons[currentPersonId];
    if (persona && persona.filesUpload) {
      // eslint-disable-next-line array-callback-return
      Object.keys(persons).reduce((_, num) => {
        const { filesUpload } = persons[num];
        if (filesUpload) {
          const formData = new FormData();
          filesUpload.forEach((file) => {
            formData.append('file[]', file);
          });
          rootDispatch(uploadFiles({ applicationId: parseInt(anketa.application.id), formData }));
          delete persons[num].filesUpload;
        }
      }, []);

      dispatch({ type: 'values', payload: { ...values, persons } });
    }
    setIsChange(
      JSON.stringify(anketa.persons[currentPersonId]) !== JSON.stringify(persons[currentPersonId])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persons[currentPersonId]]);

  useEffect(() => {
    if (anketaFetch && saved && isChange && !error && currentPersonId !== 0) {
      removeAllToasts();
      setIsChange(false);
      addToast('Анкета сохранена', { appearance: 'success' });

      if (redirectUrl) {
        const params = {
          isFetchAds: isFetchAds ? 1 : null,
          generateAnketaDoc: isPEP ? 1 : null,
          specialDistribution: 0,
        };
        redirect(addParamsToUrl(redirectUrl, params));
      }
    }
    if (error) {
      removeAllToasts();
      addToast('Ошибка', { appearance: 'warning' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved, anketaFetch, error]);

  useEffect(() => {
    if (documents && anketaFetch && currentPersonId) {
      updateByDoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, anketaFetch, currentPersonId]);

  useEffect(() => {
    if (redirectUrl && isAnketa) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl]);

  useEffect(() => {
    if (
      errors &&
      Object.keys(errors).length === 1 &&
      errors['passport.serianumber'] &&
      errors['passport.serianumber'] === MESS_SERNUMBER_MVD
    ) {
      setOnlyMvdError(true);
    } else {
      setOnlyMvdError(false);
    }
  }, [errors]);

  useEffect(() => {
    onErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (
      persons[currentPersonId].applicationParams?.lastModifiedAt &&
      errors &&
      Object.keys(errors).length > 0
    ) {
      setValidateAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const planAnketaSaving = (obj, withAnketaCheck = true, woDelay = false) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(saveAnketa, woDelay ? 0 : 1000, obj, withAnketaCheck);
    setTimerId(newTimerId);
  };

  useBeforeunload(() => {
    saveAnketa(values);
  });

  useEffect(() => {
    if (documentStateChanged && values && isDocumentHidden) {
      setDocumentStateChanged(false);
      planAnketaSaving(values);
    } else {
      setDocumentStateChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isDocumentHidden]);

  const setFocusAt = (id, withClick = false) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        withClick ? element.click() : element.focus();
      }
    }, 50);
  };

  return (
    <S.Wrapper>
      {(!anketaFetch || moreFetching) && (
        <S.LoaderWrapper>
          <S.Loader src={CarPreloaderGif} color="white" />
        </S.LoaderWrapper>
      )}
      {anketaFetch && !error && (
        <S.Container>
          {values && (
            <S.MainAnketaStyled
              data={values}
              referenceBooks={referenceBooks}
              errors={errors}
              params={params}
              setFocusAt={setFocusAt}
              validate={validateAll}
              firstTimeRender={firstTimeRender}
              forcedValidation={forcedValidation}
              contentHidden={contentHidden}
              onChange={(obj) => debouncedUpdateValues.current({ ...values, ...obj })}
              onSave={handleSave}
            />
          )}
          {!params.service && (
            <>
              <S.SaveButton
                theme={'3'}
                size={'l'}
                onClick={handleSave}
                disabled={isSavingDisabled()}
              >
                Сохранить
              </S.SaveButton>
              <SendButtonWithLoader
                size={'l'}
                onClick={handleSend}
                Badge={
                  getLoyaltyProgramsMembership('ePoints') && (
                    <S.StyledBadge size={'m'} Icon={EPointsLogoSImg}>
                      Проведите сделку и получите 500 баллов
                    </S.StyledBadge>
                  )
                }
                disabled={isSendDisabled()}
                dataTest="SendBanks"
              >
                Отправить заявку в банки
              </SendButtonWithLoader>
              {isPEP && !isPEPSignature && (
                <S.SubscribeDocTooltip>Требуется подписать согласие</S.SubscribeDocTooltip>
              )}
            </>
          )}
        </S.Container>
      )}
      {anketaFetch && error && <S.Error>Ошибка или доступ запрещен</S.Error>}
    </S.Wrapper>
  );
};

import React from 'react';

import { BlueButton, RedButton } from 'components/primitives/content';
import * as S from './style';

const fn = () => {};

const Confirm = ({
  className = '',
  title = '',
  description = '',
  confirmText = '',
  declineText = '',
  isShow = false,
  onConfirm = fn,
  onDecline = fn,
  hideConfirm = false,
  hideDecline = false,
  hideCloseBtn = false,
  confirmRed = false,
  confirmDisabled = false,
  UIKitButton = false,
  dataTest,
}) => {
  const declineHandler = (source) => onDecline(source);
  const confirmHandler = () => onConfirm();

  if (!isShow) {
    return null;
  }

  const ConfirmButton = confirmRed ? RedButton : BlueButton;

  return (
    <S.PopupStyled
      className={className}
      onClose={() => declineHandler('close')}
      hideCloseBtn={hideCloseBtn}
      dataTest={dataTest}
    >
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description>{description}</S.Description>}

      {(!hideDecline || !hideConfirm) && (
        <S.ButtonWrapper>
          {!hideDecline &&
            (UIKitButton ? (
              <S.UIDeclineButton theme={'3'} size={'l'} onClick={() => declineHandler('cancel')}>
                {declineText || 'Отмена'}
              </S.UIDeclineButton>
            ) : (
              <S.DeclineButton onClick={() => declineHandler('cancel')}>
                {declineText || 'Отмена'}
              </S.DeclineButton>
            ))}
          {!hideConfirm &&
            (UIKitButton ? (
              <S.ConfirmButton
                theme={confirmRed ? '4' : '1'}
                size={'l'}
                disabled={confirmDisabled}
                onClick={confirmHandler}
              >
                {confirmText || 'Продолжить'}
              </S.ConfirmButton>
            ) : (
              <ConfirmButton disabled={confirmDisabled} onClick={confirmHandler}>
                {confirmText || 'Продолжить'}
              </ConfirmButton>
            ))}
        </S.ButtonWrapper>
      )}
    </S.PopupStyled>
  );
};

export default Confirm;

import styled from 'styled-components';

export const Block = styled.div`
  * + & {
    margin-top: 60px;
  }
`;

export const BorderBlock = styled(Block)`
  padding: 20px;
  border: 1px solid var(--color-grey-4);
  border-radius: 8px;

  * + & {
    margin-top: 20px !important;
  }
  ${Block} + & {
    margin-top: 60px;
  }
  & + ${Block} {
    margin-top: 60px;
  }
`;

export const BkgBlock = styled(Block)`
  padding: 20px;
  background-color: ${(p) => (p.color ? p.color : 'var(--color-grey-4)')};
  border-radius: 8px;

  * + & {
    margin-top: 20px !important;
  }
  ${BorderBlock} + & {
    margin-top: 30px !important;
  }
  & + ${BorderBlock} {
    margin-top: 30px !important;
  }
  ${Block} + & {
    margin-top: 60px;
  }
  & + ${Block} {
    margin-top: 60px;
  }
`;

export const SubBlock = styled.div`
  * + & {
    margin-top: 25px;
  }
`;

export const Row = styled(SubBlock)`
  display: flex;

  & > * {
    flex: 1;
  }
  & > * + * {
    margin-left: 15px;
  }
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  margin: 25px 0px;
  background-color: var(--color-grey-1);
`;

export const List = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
`;

export const ListItem = styled.li`
  & + & {
    margin-top: 15px;
  }
`;

import styled from 'styled-components';
import { FormGridRowBase, FormHeader } from '../style';
import { Checkbox } from 'components/uikit/Form/Checkbox/Checkbox';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. h h h h h h . '
    '. cb cb cb cb cb cb . ';
`;
export const CheckBox = styled(Checkbox).attrs({ size: 's' })`
  grid-area: cb;
`;

export const Header = styled(FormHeader)`
  padding-bottom: 24px;
`;

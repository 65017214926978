import styled from 'styled-components';

import { ChevronSolidSvg } from 'assets/img';

import { HeadCell } from '../../../../style.js';

export const Cell = styled(HeadCell).attrs(() => ({ isClickable: true }))`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.txtCenter ? 'center' : 'left')};
`;

export const SortIcon = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 8px;
  margin-left: 5px;
`;

export const SortUpIcon = styled(ChevronSolidSvg)`
  width: 8px;
  height: auto;
  fill: ${(p) => (p.direction === 'asc' ? 'var(--color-grey-4)' : 'var(--color-grey-3)')};
  transform: rotate(180deg);
`;

export const SortDownIcon = styled(ChevronSolidSvg)`
  width: 8px;
  height: auto;
  margin-top: 2px;
  fill: ${(p) => (p.direction === 'desc' ? 'var(--color-grey-4)' : 'var(--color-grey-3)')};
`;

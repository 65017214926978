import React, { useRef } from 'react';

import * as S from './Tooltip.styles';
import { useTooltipPresenter } from './Tooltip.presenter';

export const Tooltip = (props) => {
  const {
    className,
    targetRef,
    children,
    theme = '1',
    size = 's',
    position = 'bottom',
    dataTest = '',
    isShow = false,
  } = props;

  const tooltipRef = useRef();
  const presenter = useTooltipPresenter(tooltipRef, targetRef, position, isShow);

  if (!presenter.isShow || !children) {
    return null;
  }

  return (
    <S.Container
      className={className}
      ref={tooltipRef}
      theme={theme}
      size={size}
      position={presenter.position}
      data-test={dataTest}
      style={{ left: presenter.coordinates.x, top: presenter.coordinates.y }}
    >
      {children}

      <S.Tail position={presenter.position} />
    </S.Container>
  );
};

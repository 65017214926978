import moment from 'moment';
import { removeExtraSpaces } from 'utils/stringUtils';
import { secondDocumentInitialState } from './secondDocument';
import { InputMask } from 'utils/inputMask';
import { getFileValidationFields } from '../utils';

const clearField = (sysName, type, person, secondDocumentTypesList) => {
  switch (true) {
    case sysName === 'passport':
      return {
        ...person,
        // ...passportInitialState,
        // ...personalInfoInitialState,
        // address: { ...person.address, fiasValue: '' },
      };
    case type === 'snils_front' || type === 'insurance_plastic':
      return {
        ...person,
        secondDocument: {
          ...secondDocumentInitialState.secondDocument,
          currentSecondDocumentType: secondDocumentTypesList.find(
            (o) => o.sysName === '2nd_pension'
          ),
          // snils: {
          //   ...person.secondDocument.snils,
          //   number: '',
          // },
        },
      };
    case sysName === 'driving-licence':
      return {
        ...person,
        secondDocument: {
          ...secondDocumentInitialState.secondDocument,
          currentSecondDocumentType: secondDocumentTypesList.find(
            (o) => o.sysName === '2nd_driver'
          ),
        },
      };
    case type === 'inn_person':
      return {
        ...person,
        secondDocument: {
          ...secondDocumentInitialState.secondDocument,
          currentSecondDocumentType: secondDocumentTypesList.find((o) => o.sysName === '2nd_tax'),
        },
      };
    case type === 'military_id':
      return {
        ...person,
        secondDocument: {
          ...secondDocumentInitialState.secondDocument,
          currentSecondDocumentType: secondDocumentTypesList.find(
            (o) => o.sysName === '2nd_military'
          ),
        },
      };
    case type === 'rus_passport_global_2007_main' || type === 'rus_passport_global_2014_main':
      return {
        ...person,
        secondDocument: {
          ...secondDocumentInitialState.secondDocument,
          currentSecondDocumentType: secondDocumentTypesList.find(
            (o) => o.sysName === '2nd_foreign_passport'
          ),
        },
      };
    default:
      return person;
  }
};

const checkString = (str, mask = null) => {
  let result = str && str.length > 0 ? str.toString() : null;
  if (!result || !mask) return result ? removeExtraSpaces(result) : result;
  return result.replace(mask, '');
};

const checkStringGender = (str, genderList) => {
  if (str) {
    return /МУЖ/.test(str.toUpperCase())
      ? genderList.find((f) => f.id === 45)
      : genderList.find((f) => f.id === 46);
  }

  return null;
};

const getMinDateDriverLicense = (obj) => {
  let currDate = null;
  Object.keys(obj)
    .filter((key) => key.indexOf('category_') > -1)
    .forEach((key) => {
      if (!currDate) {
        currDate = obj[key];
      }
      if (
        obj[key].length > 0 &&
        moment(obj[key], 'DD.MM.YYYY').diff(moment(currDate, 'DD.MM.YYYY')) < 0
      ) {
        currDate = obj[key];
      }
    });
  return currDate;
};

export const getFiasPlaceOfBirth = (obj) => {
  if (!obj || !obj.data || ['4', '5', '6', '7', '8', '9'].indexOf(obj.data.fias_level) === -1)
    return '';
  if (checkString(obj.data.city_with_type)) {
    return obj.data.city_with_type;
  }
  let address = '';
  if (checkString(obj.data.settlement_with_type)) {
    address += obj.data.settlement_with_type;
    if (checkString(obj.data.area_with_type)) {
      address += ' ' + obj.data.area_with_type;
      if (checkString(obj.data.region_with_type)) {
        address += ' ' + obj.data.region_with_type;
      }
    }
  }
  return address;
};

export const getValidDate = (dateString) => {
  let converted = moment(dateString, 'DD.MM.YYYY');
  if (converted.isValid()) return checkForFutureDate(converted);

  converted = moment(dateString, 'YYYY.MM.DD');
  if (converted.isValid()) return checkForFutureDate(converted);

  converted = moment(dateString, 'YYYY-MM-DD');
  if (converted.isValid()) return checkForFutureDate(converted);
  return '';
};

export const checkForFutureDate = (date) => {
  if (date > moment()) return '';
  return date.format('DD.MM.YYYY');
};

export const fileRecognize = (document, person, { secondDocumentTypesList, genderList }) => {
  if (!person || !document || !document.type) return person;
  const {
    type: { sysName },
    classification: { type },
    recognition: { data },
  } = document;
  const { passport, personalInfo, secondDocument, addressReg } = person;

  if (!document.recognition.percent || document.recognition.percent < 75) {
    return clearField(sysName, type, person, secondDocumentTypesList);
  }

  if (
    sysName === 'passport' &&
    type !== 'passport_registration' &&
    type !== 'passport_registration_handwritten'
  ) {
    const otherNames = checkString(data.other_names, /[^А-Яа-яёЁ ]/g) || personalInfo.middleName;
    return {
      ...person,
      fieldsToValidate: getFileValidationFields(person, ['pasport', 'personalInfo']),
      passport: {
        ...passport,
        serianumber:
          (checkString(data.series_and_number) && data.series_and_number.replace(/\s/g, '')) ||
          passport.serianumber,
        series:
          (checkString(data.ecr_number) &&
            data.ecr_number.replace(/\s/g, '').length === 10 &&
            data.ecr_number.replace(/\s/g, '').slice(0, 4)) ||
          passport.series,
        number:
          (checkString(data.ecr_number) &&
            data.ecr_number.replace(/\s/g, '').length === 10 &&
            data.ecr_number
              .replace(/\s/g, '')
              .slice(4, data.ecr_number.replace(/\s/g, '').length)) ||
          passport.number,
        issueDate:
          checkString(data.date_of_issue) &&
          data.date_of_issue.length === 10 &&
          getValidDate(data.date_of_issue)
            ? getValidDate(data.date_of_issue)
            : (passport && passport.issueDate) || '',
        issuerCode:
          checkString(data.ecr_issuerCode) ||
          checkString(data.subdivision_code) ||
          passport.issuerCode,
        issuer: checkString(data.issuing_authority, /[^А-Яа-яёЁ,.0-9№(\s)-]/g) || passport.issuer,
        birthPlace:
          checkString(data.place_of_birth, /[^А-Яа-яёЁ,.0-9№(\s)-]/g) || passport.birthPlace,
      },
      personalInfo: {
        ...personalInfo,
        gender: checkStringGender(data.sex, genderList) || personalInfo.gender,

        firstName: checkString(data.first_name, /[^А-Яа-яёЁ]/g) || personalInfo.firstName,
        lastName: checkString(data.surname, /[^А-Яа-яёЁ]/g) || personalInfo.lastName,
        middleName: removeExtraSpaces(otherNames),
        fullName: `${
          checkString(data.surname, /[^А-Яа-яёЁ]/g) || personalInfo.lastName
            ? `${checkString(data.surname, /[^А-Яа-яёЁ]/g) || personalInfo.lastName} `
            : ''
        }${
          checkString(data.first_name, /[^А-Яа-яёЁ]/g) || personalInfo.firstName
            ? `${checkString(data.first_name, /[^А-Яа-яёЁ]/g) || personalInfo.firstName} `
            : ''
        }${removeExtraSpaces(otherNames) ? `${removeExtraSpaces(otherNames)} ` : ''}`,
        birthDate: checkString(data.date_of_birth) || personalInfo.birthDate,
      },
    };
  } else if (type === 'passport_registration' || type === 'passport_registration_handwritten') {
    return {
      ...person,
      fieldsToValidate: getFileValidationFields(person, ['addressReg']),
      addressReg: {
        ...addressReg,
        fiasValue: checkString(data.address) || (addressReg && addressReg.fiasValue) || '',
        regDate:
          checkString(data.date) && data.date.length === 10 && getValidDate(data.date)
            ? getValidDate(data.date)
            : (addressReg && addressReg.regDate) || '',
      },
    };
  } else if (type === 'snils_front' || type === 'insurance_plastic') {
    const mask = InputMask.SNILS[0].blocks;
    const number = { tmp: '' };
    if (checkString(data.number)) {
      number.tmp = `${data.number}`;
    }
    if (number.tmp) {
      if (!number.tmp.replaceAll('-', '').replaceAll(' ', '').match(mask.N.definitions.$)) {
        number.tmp = '';
      }
    }
    return {
      ...person,
      fieldsToValidate: getFileValidationFields(person, ['secondDocument']),
      secondDocument: {
        ...secondDocument,
        currentSecondDocumentType: secondDocumentTypesList.find((o) => o.sysName === '2nd_pension'),
        snils: {
          ...secondDocument.snils,
          number: number.tmp || secondDocument.snils.number,
        },
      },
    };
  } else if (type === 'inn_person') {
    return {
      ...person,
      fieldsToValidate: getFileValidationFields(person, ['secondDocument']),
      secondDocument: {
        ...secondDocument,
        currentSecondDocumentType: secondDocumentTypesList.find((o) => o.sysName === '2nd_tax'),
        taxRegistration: {
          ...secondDocument.taxRegistration,
        },
      },
    };
  } else if (type === 'military_id') {
    return {
      ...person,
      secondDocument: {
        ...secondDocument,
        currentSecondDocumentType: secondDocumentTypesList.find(
          (o) => o.sysName === '2nd_military'
        ),
      },
    };
  } else if (type === 'rus_passport_global_2007_main' || type === 'rus_passport_global_2014_main') {
    return {
      ...person,
      fieldsToValidate: getFileValidationFields(person, ['secondDocument']),
      secondDocument: {
        ...secondDocument,
        currentSecondDocumentType: secondDocumentTypesList.find(
          (o) => o.sysName === '2nd_foreign_passport'
        ),
        internationalPassport: {
          ...secondDocument.internationalPassport,
        },
      },
    };
  } else if (sysName === 'driving-licence') {
    const mask = InputMask.DRIVE_LICENSE_SERIES_NUMBER[0].blocks;
    const seriesNumber = { tmp: '', series: '', number: '' };
    if (checkString(data.ecr_number) && data.ecr_number.replace(/\s/g, '').length === 10) {
      seriesNumber.tmp = data.ecr_number.replace(/\s/g, '');
    } else if (checkString(data.ecr_series) && data.ecr_series.replace(/\s/g, '').length === 10) {
      seriesNumber.tmp = data.ecr_series.replace(/\s/g, '');
    }
    if (seriesNumber.tmp) {
      if (
        seriesNumber.tmp.slice(0, 4).match(mask.N.definitions.$) &&
        seriesNumber.tmp.slice(4, 10).match(mask.L.definitions.$)
      ) {
        seriesNumber.series = seriesNumber.tmp.slice(0, 4);
        seriesNumber.number = seriesNumber.tmp.slice(4, 10);
      } else {
        seriesNumber.tmp = '';
      }
    }
    return {
      ...person,
      fieldsToValidate: getFileValidationFields(person, ['secondDocument']),
      secondDocument: {
        ...secondDocument,
        currentSecondDocumentType: secondDocumentTypesList.find((o) => o.sysName === '2nd_driver'),
        driverLicense: {
          ...secondDocument.driverLicense,
          issueDate:
            checkString(data.date_from) &&
            data.date_from.length === 10 &&
            getValidDate(data.date_from)
              ? getValidDate(data.date_from)
              : secondDocument?.driverLicense?.issueDate || '',
          issuer: checkString(data.issuer) || secondDocument.driverLicense.issuer,
          series: (seriesNumber && seriesNumber.series) || secondDocument.driverLicense.series,
          number: (seriesNumber && seriesNumber.number) || secondDocument.driverLicense.number,
          seriesNumber:
            (seriesNumber && seriesNumber.tmp) || secondDocument.driverLicense.seriesNumber,
          whereGivenOut:
            checkString(data.place_of_issue) || secondDocument.driverLicense.whereGivenOut,
          startDate: getMinDateDriverLicense(data) || secondDocument.driverLicense.startDate,
        },
      },
    };
  }

  return person;
};

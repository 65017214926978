import React from 'react';
import { useAttentionCardPresenter } from './AttentionCard.presenter';

import { InfoMIcon, CheckCircleMIcon, CrossSIcon } from 'assets/icons';

import * as S from './AttentionCard.styles';

const fn = () => {};

export const AttentionCard = (props) => {
  const {
    className,
    title = '',
    text = '',
    type = 'alert',
    theme = '1',
    size = 'm',
    direction = 'row',
    buttonTitle = '',
    buttonTheme = '1',
    onButtonClick = null,
    dataTest = '',
    onClick = fn,
  } = props;

  const presenter = useAttentionCardPresenter(onClick);

  return (
    <S.Container
      className={className}
      theme={theme}
      size={size}
      direction={direction}
      data-test={dataTest}
    >
      {direction === 'row' && (
        <S.RowWrapper>
          {type === 'alert' && <S.AttentionIcon Icon={InfoMIcon} />}
          {type === 'check' && <S.AttentionIcon Icon={CheckCircleMIcon} />}
          <S.RowTextWrapper>
            {title && <S.RowTitle>{title}</S.RowTitle>}
            {text && <S.Text>{text}</S.Text>}
          </S.RowTextWrapper>
          <S.ButtonsWrapper>
            {buttonTitle && (
              <S.StyledButton theme={buttonTheme} onClick={() => onButtonClick()}>
                {buttonTitle}
              </S.StyledButton>
            )}
            <S.RowCloseCrossWrapper onClick={presenter.onHandleClose}>
              <S.CloseCross Icon={CrossSIcon} dataTest={`${dataTest}CloseCross`} />
            </S.RowCloseCrossWrapper>
          </S.ButtonsWrapper>
        </S.RowWrapper>
      )}

      {direction === 'column' && (
        <S.ColumnWrapper>
          {type === 'alert' && <S.AttentionIcon Icon={InfoMIcon} />}
          {type === 'check' && <S.AttentionIcon Icon={CheckCircleMIcon} />}
          <S.ContentWrapper>
            <S.ColumnTextWrapper>
              {title && <S.ColumnTitle>{title}</S.ColumnTitle>}
              {text && <S.Text>{text}</S.Text>}
            </S.ColumnTextWrapper>
            {buttonTitle && (
              <S.StyledButton theme={buttonTheme} size={'s'} onClick={() => onButtonClick()}>
                {buttonTitle}
              </S.StyledButton>
            )}
          </S.ContentWrapper>
          <S.ColumnCloseCrossWrapper onClick={presenter.onHandleClose}>
            <S.CloseCross Icon={CrossSIcon} dataTest={`${dataTest}CloseCross`} />
          </S.ColumnCloseCrossWrapper>
        </S.ColumnWrapper>
      )}
    </S.Container>
  );
};

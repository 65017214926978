import { contactsInitialState } from './mappers/contacts';
import { passportInitialState } from './mappers/passport';
import { registrationAddressInitialState } from './mappers/registrationAddress';
import { livingAddressInitialState } from './mappers/livingAddress';
import { livingAddressPropertyInitialState } from './mappers/livingAddressProperty';
import { financeInitialState } from './mappers/finance';
import { secondDocumentInitialState } from './mappers/secondDocument';
import { trusteesInitialState } from './mappers/trustees';
import { spouseInitialState } from './mappers/spouse';
import { personalInfoInitialState } from './mappers/personalInfo';
import { propertyInitialState } from './mappers/property';
import { workInitialState } from './mappers/work';
import { detailsInitialState } from './mappers/details';

export const UPLOAD_COMMON = 'UPLOAD_COMMON';
export const UPLOAD_ERRORS = 'UPLOAD_ERRORS';
export const ADD_PERSONA = 'ADD_PERSONA';
export const UPDATE_PERSONA = 'UPDATE_PERSONA';
export const DELETE_PERSONA = 'DELETE_PERSONA';
export const UPDATE_PERSONA_BY_KEY = 'UPDATE_PERSONA_BY_KEY';
export const CHANGE_CURRENT_PERSONA = 'CHANGE_CURRENT_PERSONA';
export const ADD_TRUSTEES = 'ADD_TRUSTEES';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const UPDATE_SUBPART = 'UPDATE_SUBPART';
export const ADD_SUBPART = 'ADD_SUBPART';
export const DELETE_SUBPART = 'DELETE_SUBPART';
export const CHECK_CAR_NUMBER = 'CHECK_CAR_NUMBER';
export const SET_CARINFO = 'SET_CARINFO';
export const LOAD_BRANDS = 'LOAD_BRANDS';
export const BRANDS_LOADED = 'BRANDS_LOADED';
export const LOAD_MODELS = 'LOAD_MODELS';
export const MODELS_LOADED = 'MODELS_LOADED';
export const MODELS_FETCH = 'MODELS_FETCH';
export const CHECK_NUMBER_FETCH = 'CHECK_NUMBER_FETCH';
export const DELETE_TRUSTEES = 'DELETE_TRUSTEES';
export const ANKETA_FETCH = 'ANKETA_FETCH';
export const STORE_ANKETA = 'STORE_ANKETA';
export const SET_SAVED_STATUS = 'SET_SAVED_STATUS';
export const LOAD_ANKETA = 'LOAD_ANKETA';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const LOAD_FILE = 'LOAD_FILE';
export const SET_APPLICATION = 'SET_APPLICATION';
export const ANKETA_SAVING = 'ANKETA_SAVING';
export const ANKETA_ERROR = 'ANKETA_ERROR';
export const LOAD_ANKETA_PDF = 'LOAD_ANKETA_PDF';
export const SET_ANKETA_PDF = 'SET_ANKETA_PDF';
export const CLEAR_ANKETA = 'CLEAR_ANKETA';
export const SET_SHOW_SIGN_DOCUMENTS = 'SET_SHOW_SIGN_DOCUMENTS';
export const SET_IS_PEP_SIGNATURE = 'SET_IS_PEP_SIGNATURE';
export const SET_IS_CHANGE_VALUE = 'SET_IS_CHANGE_VALUE';
export const DELETE_DOC_TYPE = 'DELETE_DOC_TYPE';
export const SET_IS_PEP_INFO = 'SET_IS_PEP_INFO';
export const SET_IS_SPECIAL_CONDITIONS = 'SET_IS_SPECIAL_CONDITIONS';

export const initPersonState = {
  id: '',
  created: new Date().valueOf(),
  roles: [],

  selfAgreement: false,
  ...personalInfoInitialState,
  ...contactsInitialState,
  ...passportInitialState,
  ...registrationAddressInitialState,
  ...livingAddressInitialState,
  ...livingAddressPropertyInitialState,
  ...financeInitialState,
  ...secondDocumentInitialState,
  ...trusteesInitialState,
  ...spouseInitialState,
  ...propertyInitialState,
  ...workInitialState,
  ...detailsInitialState,

  citizenship: {
    ruOnlyTax: true,
    ruOnly: true,
  },
};

import styled from 'styled-components';

import { Icon } from '../Icon/Icon';

const themes = {
  1: {
    colorBkg: 'transparent',
    colorBkgHover: 'var(--color-white_10)',
    colorBkgActive: 'var(--color-white_20)',
    colorText: 'var(--color-white)',
    colorIcon: 'var(--color-white)',
    font: 'var(--font-regular-13)',
  },
  2: {
    colorBkg: 'transparent',
    colorBkgHover: 'var(--color-white_10)',
    colorBkgActive: 'var(--color-white_20)',
    colorText: 'var(--color-white_68)',
    colorIcon: 'var(--color-white_68)',
    font: 'var(--font-regular-13)',
  },
};

export const Container = styled.a`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-hover: ${(p) => (themes[p.theme] || themes['1']).colorBkgHover};
  --color-bkg-active: ${(p) => (themes[p.theme] || themes['1']).colorBkgActive};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --color-icon: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --font: ${(p) => (themes[p.theme] || themes['1']).font};

  display: flex;
  flex-shrink: 0;
  justify-content: start;
  align-items: center;
  gap: 8px;
  height: 32px;
  width: 100%;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: ${(p) => (p.isActive ? 'var(--color-bkg-active)' : 'var(--color-bkg)')};
  color: var(--color-text);
  cursor: pointer;
  font: var(--font);

  &:hover,
  &:focus-visible {
    background-color: ${(p) => (p.isActive ? 'var(--color-bkg-active)' : 'var(--color-bkg-hover)')};
    color: var(--color-text);
  }

  &:active {
    background-color: var(--color-bkg-active);
    color: var(--color-text);
  }
`;

export const MenuIcon = styled(Icon).attrs({ size: 's' })`
  --color: var(--color-icon);
`;

export const Title = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

import styled, { css } from 'styled-components';

import { ChevronSolidSvg } from 'assets/img';
import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';
import { CheckBox } from 'components/primitives/check-box';

export const Wrapper = styled.div`
  position: relative;
  height: 60px;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  background-color: var(--color-white);

  & > div {
    position: relative;
    height: 100%;
  }
`;

export const Selector = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid ${(p) => (p.required ? 'var(--color-red-2)' : 'var(--color-grey-4)')};
  border-radius: 5px;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: var(--color-grey-1);
    `}

  ${(p) =>
    p.focused === 'true' &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${(p) =>
    (p.focused === 'true' || p.select) &&
    css`
      display: block;
    `}

	svg {
    transform: ${(p) => (p.focused === 'true' ? 'rotate(180deg)' : 'rotate(0)')};
    path {
      fill: var(--color-grey-4);
    }
  }
`;

export const DropDown = styled.div`
  position: absolute;
  top: ${({ position, offset }) => (offset || 0) + (position?.top || 0) + position?.height}px;
  left: ${({ position }) => position?.left || 0}px;
  width: ${({ position }) => position.width || 0}px;
  height: auto;
  max-height: 300px;
  border: 1px solid var(--color-grey-4);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-x: hidden;
  background-color: var(--color-white);
  z-index: 9999999 !important;
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  left: 0;
  top: 43px;
`;

export const List = styled.ul`
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: ${(p) => (p.isOptional ? '0 10px 0 23px' : '4px 10px 4px 12px')};
  font-size: 12px;
  min-height: 20px;
  background-color: ${(p) => (p.selected ? 'var(--color-grey-2)' : 'var(--color-white)')};
  cursor: ${(p) => (p.isParentItem ? 'default' : 'pointer')};

  &:hover {
    background-color: ${(p) => (p.isParentItem ? 'var(--color-white)' : 'var(--color-grey-2)')};
  }
`;

export const CheckAllItem = styled.div`
  padding: 4px 10px 4px 12px;

  & > * {
    width: 100%;
  }
`;

export const Text = styled.p`
  margin: 0;
`;

export const Descr = styled.p`
  margin: 0;
  color: var(--color-grey-4);
`;

export const Prompt = styled(TextStyle3)`
  width: max-content;
  position: absolute;
  left: 0;
  top: 60px;
  margin: 0;
  color: var(--color-red-2);
`;

export const PulseLoaderStyled = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d8d8d875;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  margin: 0px !important;
  border-radius: 5px;
`;

export const RightIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
`;

export const ChevronSolidStyled = styled(ChevronSolidSvg)`
  cursor: pointer;
  transition: all 0.1s ease-out;
`;

export const SelectorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  padding-left: 10px;
  padding-top: 0;
  height: inherit;
`;

export const Check = styled(CheckBox).attrs(() => ({
  type: 'default',
  size: 20,
  borderRadius: 5,
}))`
  height: 32px;
  opacity: 1;
`;

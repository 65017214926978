import { useState, useEffect } from 'react';

const BUBBLE_WIDTH = 600;

export const useModalBubblePresenter = (data) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const presenter = {
    get isShow() {
      return isLoaded && (data.title || data.text);
    },

    get isShowVideo() {
      return !!data.video;
    },

    get isShowImage() {
      return !presenter.isShowVideo && !!data.image;
    },

    get isShowMedia() {
      return !!data.width && !!data.height && (presenter.isShowVideo || presenter.isShowImage);
    },

    get mediaSize() {
      const diff = BUBBLE_WIDTH / data.width;

      return {
        width: data.width * diff,
        height: data.height * diff,
      };
    },

    get textList() {
      if (!data.text) {
        return [];
      }

      return data.text.split('\n');
    },

    preloadVideo() {
      const video = document.createElement('video');

      video.src = data.video;
      video.onloadeddata = () => setIsLoaded(true);
      video.onerror = () => setIsLoaded(true);
    },

    preloadImage() {
      const image = document.createElement('img');

      image.src = data.image;
      image.onload = () => setIsLoaded(true);
      image.onerror = () => setIsLoaded(true);
    },
  };

  useEffect(() => {
    if (presenter.isShowVideo) {
      presenter.preloadVideo();
      setIsLoaded(false);
      return;
    }

    if (presenter.isShowImage) {
      presenter.preloadImage();
      setIsLoaded(false);
      return;
    }

    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return presenter;
};

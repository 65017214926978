import React from 'react';

import { useModalBubblePresenter } from './ModalBubble.presenter';
import * as S from './ModalBubble.styles';

export const ModalBubble = ({ data, onClose }) => {
  const presenter = useModalBubblePresenter(data);

  if (!presenter.isShow) {
    return null;
  }

  return (
    <S.Container isCloseByOverlay={false} onClose={onClose}>
      {presenter.isShowMedia && (
        <S.MediaContainer style={presenter.mediaSize}>
          {presenter.isShowVideo && (
            <S.Video
              src={data.video}
              playsInline={true}
              autoPlay={true}
              controls={false}
              loop={true}
              muted={true}
              preload="auto"
            />
          )}

          {presenter.isShowImage && <S.Image src={data.image} alt={data.title || data.text} />}
        </S.MediaContainer>
      )}

      <S.Content>
        {data.title && <S.Title>{data.title}</S.Title>}
        {presenter.textList.map((text) => (
          <S.Text key={text}>{text}</S.Text>
        ))}

        <S.CloseButton onClick={onClose}>Понятно</S.CloseButton>
      </S.Content>
    </S.Container>
  );
};

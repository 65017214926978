import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { getFileApplications } from 'data-providers/applicationsProvider';
import { getDocumentsByHash } from 'data-providers/mainAnketaProvider';
import { lostClickTrack } from 'metrika/applications';
import { LostReportIco, FlexWrap } from './style';

const loader = <ClipLoader size={20} color={'var(--color-black)'} loading={true} />;

export const Lostreport = () => {
  const [load, setLoad] = useState(false);
  const appFilters = useSelector((store) => store.applicFilters);

  const checkedDealer = useMemo(() => {
    if (appFilters.dealer && Array.isArray(appFilters.dealer)) {
      return appFilters.dealer.filter((f) => f?.checked).map((f) => f.name);
    }
    return [];
  }, [appFilters.dealer]);

  const loadReportHandler = async (e) => {
    e.preventDefault();
    lostClickTrack();
    setLoad(true);
    const report = await getFileApplications(checkedDealer);
    if (!report) {
      setLoad(false);
      return;
    }
    getDocumentsByHash(report.id);
    setLoad(false);
  };

  return <FlexWrap>{load ? loader : <LostReportIco onClick={loadReportHandler} />}</FlexWrap>;
};

import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const openDealConditionsClickTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_block_click', {
      block: 'terms_of_the_deal',
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDocumentUploadClickTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_download_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDocumentFileClickTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_doc_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDocumentDeleteTrack = (button, isInsideDoc, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_delete_click', {
      button_name: button,
      payload: {
        inside_doc: isInsideDoc,
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDocumentSelectFileClickTrack = (isAllSelected, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_download_select', {
      select_all: isAllSelected,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDocumentTypeSelectTrack = (type, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_download_type', {
      doc_type: type,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealSaveUploadedFilesTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_download_save', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCloseUploadedFilesTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_download_close', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCarCardCollapseTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_car_info_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCarCardStepTrack = (field, type = 'fill', deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_car_info_step_complete', {
      step: field,
      type: type, // 'fill' / 'skip' / 'error' / 'autofill'
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealAddProductClickTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_insurance_add_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealSaveProductClickTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_insurance_save_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCancelAddProductClickTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_insurance_cancel_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDeleteProductClickTrack = (service, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_insurance_delete_click', {
      service_name: service,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealProductCollapseTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_insurance_click', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealProductStepTrack = (
  field,
  value,
  type = 'fill',
  service,
  formType = 'full',
  deal,
  bank,
  dealerName
) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_insurance_step_complete', {
      form_type: formType,
      service_name: service,
      step: field,
      type: type, // 'fill' / 'skip' / 'error' / 'autofill'
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealParametersStepTrack = (field, type = 'fill', deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_parameters_step_complete', {
      step: field,
      type: type, // 'fill' / 'skip' / 'error' / 'autofill'
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCalcOpenTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_open', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealSendButtonClickTrack = (status, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_submit', {
      status: status,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealPhotoFormClickTrack = (type, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_load_issue_form_photo_click', {
      type: type,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealPhotoClickTrack = (status, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_photo_shoot', {
      status: status,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};
export const dealPhotoUploadClickTrack = (status, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_photo_upload', {
      status: status,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealDocsStepCompleteTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_step_complete', {
      step: 'no-pts',
      type: 'fill',
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCheckDocumentsPopupOpenTrack = (deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_check_popup_open', {
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

export const dealCheckDocumentsPopupUploadTrack = (docType, deal, bank, dealerName) => {
  isProd &&
    ym('reachGoal', 'vsa_ecredit_loan_issue_form_docs_check_popup_save', {
      doc_type: docType,
      payload: {
        bank: bank,
        dealer_name: dealerName,
        draft_id: deal?.id,
        deal_id: deal?.masterDealId,
      },
    });
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ApplicationsList, ApplicationsSearch } from 'components/modules/Applications';
import { ApplicationNotifications } from 'components/modules/ApplicationNotifications';
import { dropApplications } from 'store/applications';
import { clearCarInstance } from 'store/carInstance';
import { resetSortig } from 'store/filters';
import { findBanner } from 'utils/findBunner';

import { Navigation } from './components/Navigation';
import { Filters } from './components/Filters';
import { Search } from './components/Search';
import { EPointsBanner } from 'components/common/EPointsBanner/EPointsBanner';
import { AppVersionReloader } from 'components/common/AppVersionReloader/AppVersionReloader';
import { FinancedPopup } from 'components/modules/Applications/components/FinancedPopup/FinancedPopup';

import * as userSelectors from 'store/user/selectors';
import * as S from './style';

export const Applications = () => {
  const rootDispatch = useDispatch();

  const dealer = useSelector(userSelectors.getDealer);

  const [banner, setBanner] = useState(null);

  useEffect(() => {
    rootDispatch(dropApplications());
    rootDispatch(clearCarInstance());
    rootDispatch(resetSortig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let banner = null;

    if (EPointsBanner) {
      banner = <EPointsBanner />;
    } else if (dealer.id) {
      banner = findBanner(dealer.id);
    }

    setBanner(banner);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer.id]);

  return (
    <S.Container>
      {banner}
      <AppVersionReloader />
      <S.NavigationContainer>
        <S.Filters>
          <Navigation />
          <Filters />
        </S.Filters>
        <S.Search>
          <Search />
        </S.Search>
      </S.NavigationContainer>
      <S.ListContainer>
        <Switch>
          <Route path={['/applications/in-work', '/applications/list', '/applications/lost']}>
            <ApplicationsList />
          </Route>
          <Route path={'/applications/search'}>
            <ApplicationsSearch />
          </Route>
          <Route path={'/applications'}>
            <Redirect to={'/applications/list'} />
          </Route>
        </Switch>
        <S.NotificationContainer>
          <ApplicationNotifications />
        </S.NotificationContainer>
        <FinancedPopup />
      </S.ListContainer>
    </S.Container>
  );
};

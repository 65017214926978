import React from 'react';
import moment from 'moment';
import { useNotificationPresenter } from './Notification.presenter';

import WhatsapLink from 'components/common/WhatsapLink';
import { InfoCard } from '../InfoCard/InfoCard';

import { onlyNumber } from 'utils/stringUtils';
import * as metrica from 'metrika/mainpage';

import * as S from './Notification.styles';

export const Notification = ({ data }) => {
  const presenter = useNotificationPresenter(data);

  return (
    <S.Wrapper>
      <S.Description>
        {moment(data.date).format('DD.MM, HH:mm')}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <S.ClipboardCopyStyled
          value={data.applicationId}
          onClick={() => {
            metrica.applicationNumCopyTrack();
          }}
        >
          {`ID ${data.applicationId}`}
        </S.ClipboardCopyStyled>
      </S.Description>

      <S.Title>
        {presenter.customerLastName} <br />
        {presenter.customerFirstName}
      </S.Title>
      <WhatsapLink
        phone={onlyNumber(data.customerPhone)}
        onClick={() => {
          metrica.toWhatsAppTrack();
        }}
      />
      <InfoCard data={data} showedBanks={4} />

      <a
        href={`${process.env.REACT_APP_STAGE_URL}/#application-details-new?app_id=${
          data.applicationId
        }&tab=${3}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.ApproveCreditButton
          onClick={() => {
            metrica.giveCreditButtonTrack();
          }}
        >
          Оформить цифровую сделку
        </S.ApproveCreditButton>
      </a>
    </S.Wrapper>
  );
};

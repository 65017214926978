import { onlyNumber } from './stringUtils';

export const convertTel = (number) => {
  return number
    ? `+${number[0]} (${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(
        7,
        9
      )}-${number.slice(9)}`
    : '-';
};

export const convertTelforInput = (number) => {
  const cleaned = onlyNumber(number);

  let formatted = '+7';

  if (cleaned.length > 1) {
    formatted += ` ${cleaned.slice(1, 4)}`;
  }
  if (cleaned.length > 4) {
    formatted += ` ${cleaned.slice(4, 7)}`;
  }
  if (cleaned.length > 7) {
    formatted += ` ${cleaned.slice(7, 9)}`;
  }
  if (cleaned.length > 9) {
    formatted += ` ${cleaned.slice(9, 11)}`;
  }

  return formatted;
};

/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef, forwardRef } from 'react';

import PulseLoader from 'react-spinners/PulseLoader';
import PropTypes from 'prop-types';
import { AlertRoundMIcon } from 'assets/icons';

import * as S from './style';

export const InputMask = forwardRef((props) => {
  const {
    name,
    label,
    disabled,
    required,
    defaultValue = '',
    val = '',
    mask,
    unmask,
    onChange = () => {},
    onComplete = () => {},
    onBlur = () => {},
    onFocus = () => {},
    id,
    className,
    error,
    confidenceError = null,
    fetch = false,
    placeholder = '',
    dataTest,
  } = props;
  let inputRef = useRef();
  const [focused, setFocused] = useState(false);

  const handleBlur = (e) => {
    e.stopPropagation();
    if (!val || !val.toString() || !defaultValue.toString()) {
      setFocused(false);
    }
    onBlur(name ? { [name]: val || defaultValue } : val || defaultValue);
  };

  const handleChange = (value, mask) => {
    if (value !== undefined || (defaultValue !== null && value !== defaultValue.toString())) {
      onChange(name ? { [name]: value } : value, mask);
    }
  };

  const handleFocus = (e) => {
    e.stopPropagation();
    setFocused(true);
    onFocus();
  };

  const handleComplete = (value) => {
    onComplete(name ? { [name]: value } : value);
  };

  const wrapperClick = () => {
    if (!disabled) {
      inputRef && inputRef.current && inputRef.current.element.focus();
      setFocused(true);
    }
  };

  useEffect(() => {
    if (defaultValue !== null && defaultValue.toString().length > 0) {
      setFocused(true);
    }
  }, [defaultValue, placeholder]);

  return (
    <S.Wrapper
      id={id}
      required={required && error}
      disabled={disabled}
      isFocused={!!focused}
      onClick={wrapperClick}
      className={className}
    >
      {mask && (
        <S.InputStyled
          id={`${id}_input`}
          className="anketa_select_value"
          ref={inputRef}
          mask={mask}
          unmask={unmask}
          onAccept={handleChange}
          onComplete={handleComplete}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label={label}
          defaultValue={(defaultValue !== null && defaultValue.toString()) || ''}
          value={(val !== null && val.toString()) || ''}
          disabled={disabled}
          placeholder={placeholder}
          isFocused={focused}
          confidenceErrorNotification={confidenceError}
          data-test={error ? 'input_error' : dataTest}
        />
      )}
      {label && (
        <S.Label isFocused={!!focused} required={!disabled && required && error}>
          {label}
          {required && <sup>*</sup>}
        </S.Label>
      )}
      {!disabled && error && (
        <S.Prompt className="select_prompt">
          {error ? error.split('<br>').map((s, i) => <p key={`${id}_${s}_${i}`}>{s}</p>) : ''}
        </S.Prompt>
      )}
      {fetch && (
        <PulseLoader css={S['PulseLoaderStyled']} size={10} color={'#b5b5b5'} loading={true} />
      )}
      {confidenceError && <S.AttentionIcon Icon={AlertRoundMIcon} />}
    </S.Wrapper>
  );
});

InputMask.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  mask: PropTypes.any,
  unmask: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
};

import React from 'react';

import * as S from './ParamsCell.styles';

export function ParamsCell({ label, model, requested, approved, colorLabel }) {
  return (
    <S.ParamsCellWrapper>
      {label && <S.ParamsCellLabel>{label}</S.ParamsCellLabel>}
      {model && <S.ParamsCellValue>{model}</S.ParamsCellValue>}
      {approved && (
        <S.RequestedWrap>
          {requested && (
            <S.ParamsCellRequested>
              <del>{requested}</del>
            </S.ParamsCellRequested>
          )}
          <S.ParamsCellValue>
            {approved} {colorLabel}
          </S.ParamsCellValue>
        </S.RequestedWrap>
      )}
    </S.ParamsCellWrapper>
  );
}

import { put, takeLatest, select } from 'redux-saga/effects';

import * as provider from 'data-providers/notificationsProvider';
import { usePushAppNotification } from 'hooks/usePushAppNotification';

import * as actions from './constants';
import * as mapper from './mapper';

const addNotification = (acc, item) => {
  if (!acc[item.applicationId]) {
    return { ...acc, [item.applicationId]: item };
  }

  if (acc[item.applicationId].date < item.date) {
    return {
      ...acc,
      [item.applicationId]: {
        ...item,
        bankIds: [...item.bankIds, ...acc[item.applicationId].bankIds],
        onlineBanksIds: [...item.onlineBanksIds, ...acc[item.applicationId].onlineBanksIds],
      },
    };
  }

  return {
    ...acc,
    [item.applicationId]: {
      ...acc[item.applicationId],
      bankIds: [...acc[item.applicationId].bankIds, ...item.bankIds],
      onlineBanksIds: [...acc[item.applicationId].onlineBanksIds, ...item.onlineBanksIds],
    },
  };
};

function* loadNotifications({ payload }) {
  try {
    if (payload.isEmailBank === 0 || payload.isEmailBank === 1) {
      yield put({ type: actions.FETCH_START_NOTIFICATIONS, payload: {} });

      const data = yield provider.getNotifications(payload.userId, payload.isEmailBank);

      const notifications = data.reduce((acc, item) => {
        const notify = mapper.getAppNotification(item);
        if (!notify) {
          return acc;
        }
        return addNotification(acc, notify);
      }, {});

      yield put({ type: actions.SET_APPS_NOTIFICATIONS_MAINPAGE, payload: notifications });
    } else {
      yield put({ type: actions.FETCH_START_NOTIFICATIONS, payload: {} });

      const data = yield provider.getNotifications(payload.userId);

      const notifications = data.reduce((acc, item) => {
        const notify = mapper.getAppNotification(item);
        if (!notify) {
          return acc;
        }
        return addNotification(acc, notify);
      }, {});

      yield put({ type: actions.SET_APPS_NOTIFICATIONS, payload: notifications });
    }
  } catch (err) {
    yield put({ type: actions.SET_APPS_NOTIFICATIONS, payload: {} });
    yield put({ type: actions.SET_APPS_NOTIFICATIONS_MAINPAGE, payload: {} });
  } finally {
    yield put({ type: actions.FETCH_STOP_NOTIFICATIONS, payload: {} });
    yield put({ type: actions.CALC_UNREAD_COUNT_NOTIFICATIONS, payload: {} });
  }
}

function* readAppNotification({ payload }) {
  const notifications = yield select((store) => store?.notifications?.apps || {});
  const readingItem = notifications[payload];

  try {
    if (readingItem && !readingItem.isRead) {
      yield put({
        type: actions.SET_APPS_NOTIFICATIONS,
        payload: {
          ...notifications,
          [payload]: { ...readingItem, isRead: true },
        },
      });

      yield provider.readNotificationById(readingItem.id);
    }
  } catch (err) {
    yield put({
      type: actions.SET_APPS_NOTIFICATIONS,
      payload: {
        ...notifications,
        [payload]: { ...readingItem, isRead: false },
      },
    });
  } finally {
    yield put({ type: actions.CALC_UNREAD_COUNT_NOTIFICATIONS, payload: {} });
  }
}

function* addAppNotification({ payload }) {
  const notify = mapper.getAppNotification(payload);
  if (!notify) {
    return;
  }

  const notifications = yield select((store) => store?.notifications?.apps || {});
  const newNotifications = addNotification(notifications, notify);

  const notificationsMainPage = yield select((store) => store?.notifications?.appsMainPage || {});
  const newNotificationsMainPage = addNotification(notificationsMainPage, notify);

  yield put({ type: actions.SET_APPS_NOTIFICATIONS, payload: newNotifications });
  yield put({ type: actions.SET_APPS_NOTIFICATIONS_MAINPAGE, payload: newNotificationsMainPage });
  yield put({ type: actions.CALC_UNREAD_COUNT_NOTIFICATIONS, payload: {} });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  usePushAppNotification(notify);
}

function* calcUnreadCountNotifications() {
  const notifications = yield select((store) => store?.notifications?.apps || {});
  const unread = Object.values(notifications).filter((item) => !item.isRead);

  yield put({ type: actions.SET_UNREAD_COUNT_NOTIFICATIONS, payload: unread.length });
}

export function* watchLoadNotifications() {
  yield takeLatest(actions.LOAD_NOTIFICATIONS, loadNotifications);
}

export function* watchLoadNotificationsMainPage() {
  yield takeLatest(actions.LOAD_NOTIFICATIONS_MAINPAGE, loadNotifications);
}

export function* watchReadAppNotification() {
  yield takeLatest(actions.READ_APP_NOTIFICATION, readAppNotification);
}

export function* watchAddAppNotifications() {
  yield takeLatest(actions.ADD_APP_NOTIFICATION, addAppNotification);
}

export function* watchCalcUnreadCountNotifications() {
  yield takeLatest(actions.CALC_UNREAD_COUNT_NOTIFICATIONS, calcUnreadCountNotifications);
}

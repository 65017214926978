import set from 'lodash/set';

import { FILE_TYPE_PHOTO, FILE_TYPE_DKP, FILE_TYPE_PTS } from 'constants/deal';

import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_DEAL: {
      return { ...state, ...action.payload, shortInfo: { ...state.shortInfo } };
    }

    case actions.SET_POLICE: {
      return { ...state, credit: { ...action.payload } };
    }

    case actions.SET_CREDIT_FIELD: {
      return { ...state, credit: { ...state.credit, ...action.payload } };
    }

    case actions.SET_POLICE_ERRORS: {
      return { ...state, credit: { ...state.credit, errors: { ...action.payload } } };
    }

    case actions.SET_SERVICES: {
      return { ...state, services: [...action.payload] };
    }

    case actions.UPDATE_SERVICE: {
      const { id, field, value } = action.payload;
      const newServices = state.services.map((s) => {
        if (s.id === id) {
          set(s, field, value);
          return s;
        } else {
          return s;
        }
      });
      if (action.payload.callback) {
        action.payload.callback(newServices);
      }
      return { ...state, services: newServices };
    }

    case actions.RESET_DEAL: {
      return { ...initialState, shortInfo: { ...state.shortInfo } };
    }

    case actions.SET_EMAIL: {
      return { ...state, email: action.payload };
    }

    case actions.FETCH_START_DEAL: {
      return { ...state, fetch: true };
    }

    case actions.FETCH_STOP_DEAL: {
      return { ...state, fetch: false };
    }

    case actions.SET_ERROR_DEAL: {
      return { ...state, error: action.payload };
    }

    case actions.SET_DEAL_SHORT: {
      return { ...state, shortInfo: { ...state.shortInfo, ...action.payload } };
    }

    case actions.RESET_DEAL_SHORT: {
      return { ...state, shortInfo: { ...initialState.shortInfo } };
    }

    case actions.FETCH_START_DEAL_SHORT: {
      return { ...state, shortInfo: { ...state.shortInfo, fetch: true } };
    }

    case actions.FETCH_STOP_DEAL_SHORT: {
      return { ...state, shortInfo: { ...state.shortInfo, fetch: false } };
    }

    case actions.SET_ERROR_DEAL_SHORT: {
      return { ...state, shortInfo: { ...state.shortInfo, error: action.payload } };
    }

    case actions.TOGGLE_CAMERA_POPUP: {
      return { ...state, showCameraPopup: action.payload };
    }

    case actions.TOGGLE_PHOTO_POPUP: {
      return { ...state, showPhotoPopup: action.payload };
    }

    case actions.SET_DEAL_ERRORS: {
      return { ...state, errors: { ...action.payload } };
    }
    case actions.SHOW_VIN_RECOGNITION_POPUP: {
      return { ...state, showVinRecognitionPopup: true };
    }
    case actions.HIDE_VIN_RECOGNITION_POPUP: {
      return { ...state, showVinRecognitionPopup: false };
    }
    case actions.SET_VIN_RECOGNIZING: {
      return { ...state, isVinRecognizing: action.payload };
    }

    case actions.SET_TIMERS: {
      return { ...state, timers: [...action.payload] };
    }

    case actions.SET_CAR_EXTRA_FIELD: {
      return { ...state, carExtraData: { ...(state.carExtraData || {}), ...action.payload } };
    }

    default:
      return state;
  }
}

export function loadDeal(ids) {
  return { type: actions.LOAD_DEAL, payload: ids };
}

export function reloadDeal(id, newState) {
  return { type: actions.RELOAD_DEAL, payload: { id, newState } };
}

export function reloadDealDocuments(id) {
  return { type: actions.RELOAD_DEAL_DOCUMENTS, payload: { id } };
}

export function setDeal(state) {
  return { type: actions.SET_DEAL, payload: state };
}

export function setPolice(police) {
  return { type: actions.SET_POLICE, payload: police };
}

export function setCreditField(data) {
  return { type: actions.SET_CREDIT_FIELD, payload: data };
}

export function setPoliceErrors(errors) {
  return { type: actions.SET_POLICE_ERRORS, payload: errors };
}

export function setServices(services) {
  return { type: actions.SET_SERVICES, payload: services };
}

export function updateService(id, field, value, callback) {
  return { type: actions.UPDATE_SERVICE, payload: { id, field, value, callback } };
}

export function resetDeal() {
  return { type: actions.RESET_DEAL, payload: {} };
}

export function setFetchDeal(state) {
  return { type: state ? actions.FETCH_START_DEAL : actions.FETCH_STOP_DEAL, payload: {} };
}

export function setErrorDeal(error) {
  return { type: actions.SET_ERROR_DEAL, payload: error };
}

export function setCustomerPhotoDeal(id, fileId, ext) {
  return { type: actions.ADD_FILE_DEAL, payload: { id, fileId, typeId: FILE_TYPE_PHOTO, ext } };
}

export function setDkpScanDeal(id, fileId, ext) {
  return { type: actions.ADD_FILE_DEAL, payload: { id, fileId, typeId: FILE_TYPE_DKP, ext } };
}

export function setPtsScanDeal(id, fileId, ext) {
  return { type: actions.ADD_FILE_DEAL, payload: { id, fileId, typeId: FILE_TYPE_PTS, ext } };
}

export function addDealFile(id, fileId, type, ext, createdAt) {
  return { type: actions.ADD_FILE_DEAL, payload: { id, fileId, typeId: type, ext, createdAt } };
}
export function addDealFiles(applicationId, files) {
  return { type: actions.ADD_FILES_DEAL, payload: { applicationId, files } };
}

export function deleteFileDeal(fileIds) {
  return { type: actions.DEL_FILE_DEAL, payload: fileIds };
}

export function loadShortDeal(id) {
  return { type: actions.LOAD_DEAL_SHORT, payload: id };
}

export function setShortDeal(state) {
  return { type: actions.SET_DEAL_SHORT, payload: state };
}

export function resetShortDeal() {
  return { type: actions.RESET_DEAL_SHORT, payload: {} };
}

export function setFetchShortDeal(state) {
  return {
    type: state ? actions.FETCH_START_DEAL_SHORT : actions.FETCH_STOP_DEAL_SHORT,
    payload: {},
  };
}

export function setErrorShortDeal(error) {
  return { type: actions.SET_ERROR_DEAL_SHORT, payload: error };
}

export function approvalDeal(id, state, data, carData) {
  return { type: actions.APPROVAL_DEAL, payload: { id, state, data, carData } };
}

export function declineDeal(id) {
  return { type: actions.DECLINE_DEAL, payload: { id } };
}

export function dealRecalculationDesired(id) {
  return { type: actions.DEAL_RECALCULATION_DESIRED, payload: { id } };
}

export function closeDeal(id) {
  return { type: actions.CLOSE_DEAL, payload: { id } };
}

export function dealRequestDocumentFinanced(id) {
  return { type: actions.REQUEST_DOCUMENT_FINANCED, payload: { id } };
}

export function dealChangePersonalData(id) {
  return { type: actions.CHANGE_PERSONAL_DATA, payload: { id } };
}

export function saveLocalDeal(id) {
  return { type: actions.SAVE_LOCAL_DEAL, payload: { id } };
}

export function restoreLocalDeal(id) {
  return { type: actions.RESTORE_LOCAL_DEAL, payload: { id } };
}

export function toggleCameraPopup(state = false) {
  return { type: actions.TOGGLE_CAMERA_POPUP, payload: state };
}

export function togglePhotoPopup(state = false) {
  return { type: actions.TOGGLE_PHOTO_POPUP, payload: state };
}

export function saveRecalculation(calcData, carData, callback = null) {
  return { type: actions.SAVE_RECALCULATION, payload: { calcData, carData, callback } };
}

export function approveRecalculation(id, dealId, data, callback = null) {
  return { type: actions.APPROVE_RECALCULATION, payload: { id, dealId, data, callback } };
}

export function setDealErrors(errors) {
  return { type: actions.SET_DEAL_ERRORS, payload: errors };
}

export function saveFinalParams(id, dealId, data) {
  return { type: actions.SAVE_FINAL_PARAMS, payload: { id, dealId, data } };
}

export function setTimers(timers) {
  return { type: actions.SET_TIMERS, payload: timers };
}

export function showVinRecognitionPopup() {
  return { type: actions.SHOW_VIN_RECOGNITION_POPUP };
}

export function hideVinRecognitionPopup() {
  return { type: actions.HIDE_VIN_RECOGNITION_POPUP };
}

export function setUserEmail(email) {
  return { type: actions.SET_EMAIL, payload: email };
}

export function setVinRecognitionState(data) {
  return { type: actions.SET_VIN_RECOGNIZING, payload: data };
}

export function setCarExtraData(data) {
  return { type: actions.SET_CAR_EXTRA_FIELD, payload: data };
}

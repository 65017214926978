import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getCarInstanceById } from 'store/carInstance';
import { getCustomer, storeItem, dropClientId } from 'store/startPage';
import { changeActiveDealer } from 'store/user';

import { isFinalParamsState } from 'components/modules/Applications/helpers';
import { applicationRowOpenButtonTrack } from 'metrika/applications';

import * as S from './style';

export const OpenButton = ({ application }) => {
  const reduxDispatch = useDispatch();
  const history = useHistory();

  const dealer = useSelector((store) => store.user.dealers.find((d) => d.id === store.user.dealer));
  const appDealer = useSelector((store) =>
    store.user.dealers.find((d) => d.id === application.dealer.id)
  );

  const openCLickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    applicationRowOpenButtonTrack();

    localStorage.setItem('worksheet', JSON.stringify(application));
    // Fix problems OS-524, OS-526
    localStorage.removeItem('carinstanceId');
    localStorage.removeItem('customerId');

    if (application.dealer.id !== dealer) {
      reduxDispatch(changeActiveDealer(application.dealer.id));
    }

    reduxDispatch(getCarInstanceById(application.carInstance.id, application.id));
    reduxDispatch(getCustomer({ customerId: application.customer.id }));
    reduxDispatch(dropClientId());
    reduxDispatch(storeItem(application));

    let url = `/worksheet/${application.id}`;
    if (
      !application?.ids?.loan &&
      !!application.orders?.find(
        (el) => el.productId === 'credit' && isFinalParamsState(el.body?.applicationState?.state)
      )
    ) {
      url += '/final-params';
    } else if (application?.ids?.loan && appDealer?.useNewCalculator) {
      url += '/loan-issue';
    } else if (
      application?.orders.length === 1 &&
      application?.orders[0].productId === 'insurance'
    ) {
      url += '/insurance';
    } else {
      url += '/home';
    }

    return history.push(url);
  };

  return (
    <S.Button onClick={openCLickHandler} dataTest="listOpen">
      Открыть
    </S.Button>
  );
};

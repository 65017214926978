import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useParams, useLocation } from 'react-router-dom';
import animateScrollTo from 'animated-scroll-to';

import { ToMainLink } from 'components/modules/Widgets/ToMainLink';
import { UserWidget } from 'components/modules/Widgets/User';
import { CarWidget } from 'components/modules/Widgets/Car';
import Client from 'components/modules/Calculations/components/@Client';
import { Layout } from 'components/modules/Layout';
import { Loader } from 'components/primitives/loader';
import { Alert } from 'components/primitives/alert';
import AssistanceWidget from 'components/modules/Widgets/Assistance';
// Временно скрываем виджет каско ECCREDIT-3614
// import KaskoWidget from 'components/modules/Widgets/Kasko';
// import OmodaKasko from 'components/modules/Widgets/OmodaKasko';
import CreditWidget from 'components/modules/Widgets/Credit';
import {
  ElfSightBannerCommon,
  ElfSightBannerLoanIssue,
  ElfSightBannerCarCheck,
  ElfSightCherryBanner,
  ElfSightFocusGroupPangoCardBanner,
} from 'components/modules/Calculations/components/EOsagoBanner/ElfSightBanner';
import AssistancePopup from 'components/modules/Calculations/components/AssistancePopup';
import { getProfile } from 'store/anketa';
import { getCustomer, storeItem } from 'store/startPage';
import { getDealersParams, getCarInstanceById } from 'store/carInstance';
import { selectDealId } from 'store/deal/selectors';
import { getWorksheetById } from 'data-providers/applicationsProvider';
import { changeActiveDealer } from 'store/user';
import { Cherry, FocusGroupPangoCard, CarCheck } from 'constants/dealerCenters';

import { widgetButtonClickTrack } from 'metrika/eosago';

import ErrorPopup from 'components/primitives/error-popup';

export const ApplicationClientLayout = () => {
  const reduxDispatch = useDispatch();
  const assistanceRef = useRef();
  const history = useHistory();
  const params = useParams();
  const { path } = useRouteMatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(!location.pathname.includes('/create-new'));
  const [showAssistancePopup, setShowAssistancePopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showVideo, setShowVideo] = useState(path.includes('/create-new'));
  const [error, setError] = useState(false);

  const { error: appError } = useSelector((state) => state.app);
  const { dealer: dealerId } = useSelector((state) => state.user);
  const dealer = useSelector((store) => store.user.dealers.find((d) => d.id === store.user.dealer));
  const application = useSelector((state) => state.startPage.application);
  const { carInstance } = useSelector((state) => state);
  const loanId = useSelector(selectDealId);

  const { dealersParams } = carInstance;
  const workSheetId = params.id;

  const isCreateNew = window.location.href.includes('/create-new');
  const isHome = path === '/worksheet/:id/home' && !isCreateNew;
  const isCalc = path === '/worksheet/:id/calculator' && !isCreateNew;
  const isLoan = path === '/worksheet/:id/loan-issue' && !isCreateNew;
  const isAssistance = path === '/worksheet/:id/assistance' && !isCreateNew;
  const isInsurance = path === '/worksheet/:id/insurance' && !isCreateNew;
  const isCreateNewInsurance = path === '/worksheet/:id/insurance' && isCreateNew;
  const isFinalParams = window.location.href.includes('/final-params');
  const isAssistanceAnketa =
    path === '/worksheet/:id/assistance/anketa' ||
    location.pathname === '/worksheet/create-new/assistance/anketa';
  const isInsuranceAnketa =
    path === '/worksheet/:id/insurance/anketa' ||
    location.pathname === '/worksheet/create-new/insurance/anketa';
  const isCreditAnketa = path === '/worksheet/:id/credit/anketa';
  const isCommonPath =
    path === '/worksheet/:id' &&
    !isHome &&
    !isCalc &&
    !isLoan &&
    !isAssistance &&
    !isInsurance &&
    !isFinalParams;
  const showMainLink =
    !isHome &&
    (isAssistanceAnketa ||
      isAssistance ||
      isInsuranceAnketa ||
      isInsurance ||
      isCreditAnketa ||
      isCalc ||
      isLoan ||
      isFinalParams);
  // const isOmodaKaskoBannerShown = isCreateNewCalc || isCalc || isLoan || isCreditAnketa || isHome;
  const assistanceOrders = (application?.orders || []).filter((o) => o.productId === 'assistance');
  // Временно скрываем виджет каско ECCREDIT-3614
  // const kaskoOrders = (application?.orders || []).filter((o) => o.productId === 'insurance');
  const errorStates = ['offers_error', 'issue_error', 'client_decline', 'canceled'];

  const shouldShowAssistanceWidget = useMemo(() => {
    return (
      isAssistance ||
      ((isHome ||
        isCalc ||
        isCreditAnketa ||
        isInsuranceAnketa ||
        isCreateNewInsurance ||
        assistanceOrders.length) &&
        assistanceOrders.filter((o) => !errorStates.includes(o?.body?.applicationState?.sysName))
          .length)
    );
  }, [
    isHome,
    isCalc,
    isAssistance,
    isCreditAnketa,
    isInsuranceAnketa,
    isCreateNewInsurance,
    assistanceOrders,
    errorStates,
  ]);

  const Link = dealer && showMainLink ? <ToMainLink key="to-main" /> : null;
  const leftChildren = [
    Link,
    <UserWidget key="user" />,
    <CarWidget key="car" dataTest="carWidgetCalc" />,
  ];

  const assistanceClickHandler = () => {
    if (workSheetId) {
      history.push(`/worksheet/${workSheetId}/assistance`);
    } else {
      history.push(`/worksheet/create-new/assistance`);
    }
    widgetButtonClickTrack();

    setTimeout(() => {
      if (assistanceRef?.current) {
        animateScrollTo(assistanceRef.current);
      }
    }, 50);
  };

  // Временно скрываем виджет каско ECCREDIT-3614
  // const kaskoClickHandler = () => {
  //   if (workSheetId) {
  //     history.push(`/worksheet/${workSheetId}/insurance`);
  //   } else {
  //     history.push(`/worksheet/create-new/insurance`);
  //   }
  //   widgetButtonClickTrack();
  //
  //   setTimeout(() => {
  //     if (assistanceRef?.current) {
  //       animateScrollTo(assistanceRef.current);
  //     }
  //   }, 50);
  // };

  useEffect(() => {
    if (path === '/worksheet/:id') {
      setIsLoading((prevVal) =>
        !prevVal || (application?.id && !carInstance?.fetch) ? false : true
      );
    } else {
      setIsLoading((prevVal) => (!prevVal || !carInstance?.fetch ? false : true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.id, carInstance?.fetch, path]);

  useEffect(() => {
    if (!workSheetId || workSheetId === 'create-new') {
      return;
    }

    if (!application?.id) {
      // Fix problems OS-524, OS-526
      localStorage.removeItem('worksheet');
      localStorage.removeItem('carinstanceId');
      localStorage.removeItem('customerId');

      getWorksheetById(workSheetId)
        .then((worksheet) => {
          if (worksheet) {
            if (worksheet?.dealer?.id !== dealerId) {
              reduxDispatch(changeActiveDealer(worksheet?.dealer?.id));
            }
            if (worksheet?.carInstance?.id) {
              reduxDispatch(getCarInstanceById(worksheet?.carInstance?.id, worksheet?.id));
            }
            if (worksheet?.client?.customerId) {
              reduxDispatch(getCustomer({ customerId: worksheet?.client?.customerId }));
            }
            reduxDispatch(storeItem(worksheet));
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            setError(true);
          }
        });
    }

    const creditOrder = (application?.orders || []).find((o) => o.productId === 'credit');
    if (creditOrder) {
      reduxDispatch(getProfile(creditOrder?.body?.applicationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.id, workSheetId]);

  useEffect(() => {
    if (application?.id) {
      if (isCommonPath) {
        if (loanId) {
          history.push(`/worksheet/${application.id}/loan-issue`);
        } else if (application?.ids?.credit) {
          history.push(`/worksheet/${application.id}/home`);
        } else if (application?.ids?.assistance && !isFinalParams) {
          history.push(`/worksheet/${application.id}/assistance`);
        } else if (application?.ids?.insurance && !isFinalParams) {
          history.push(`/worksheet/${application.id}/insurance`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.id]);

  useEffect(() => {
    if (path.includes('/create-new')) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [path]);

  useEffect(() => {
    if (dealer?.id) {
      if (!dealersParams.dealerId) {
        reduxDispatch(getDealersParams(dealer?.id));
        return;
      }
    }
    if (dealer && dealer.id) {
      reduxDispatch(getDealersParams(dealer.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, dealer?.id, carInstance?.dealersParams?.dealerId]);

  const rightSideBanner = useMemo(() => {
    if (loanId) {
      return CarCheck.includes(dealerId) ? (
        <ElfSightBannerCarCheck key="dealCarCheckBanner" />
      ) : (
        <ElfSightBannerLoanIssue key="dealHelpBanner" />
      );
    } else if (Cherry.includes(dealerId)) {
      return (
        <ElfSightCherryBanner key="eosagoBanner" onClick={() => setShowAssistancePopup(true)} />
      );
    } else if (FocusGroupPangoCard.includes(dealerId)) {
      return (
        <ElfSightFocusGroupPangoCardBanner
          key="eosagoBanner"
          onClick={() => setShowAssistancePopup(true)}
        />
      );
    } else {
      return (
        <ElfSightBannerCommon key="eosagoBanner" onClick={() => setShowAssistancePopup(true)} />
      );
    }
  }, [loanId, dealerId]);

  const setCloseModal = () => {
    history.push('/applications/list');
    setError(false);
  };

  return (
    <>
      <Layout
        leftChildren={leftChildren}
        rigthChildren={[
          // // Временно скрываем виджет каско ECCREDIT-3614
          // <KaskoWidget key="insurance" onClick={kaskoClickHandler} orders={kaskoOrders} />,
          shouldShowAssistanceWidget ? (
            <AssistanceWidget
              key="assistance"
              onClick={assistanceClickHandler}
              orders={assistanceOrders}
            />
          ) : null,
          // isOmodaKaskoBannerShown ? <OmodaKasko key="omodaKasko" /> : null,
          loanId ? <CreditWidget key="credit" isShowProgress={isLoan} /> : null,
          // CSRV-22 Remove banners
          rightSideBanner,
        ]}
        mainChildren={
          <Client eosagoRef={assistanceRef} onChangeCarModel={() => setShowVideo(false)} />
        }
      />
      {showAssistancePopup && <AssistancePopup onClose={() => setShowAssistancePopup(false)} />}

      {isLoading && <Loader color={'var(--color-white)'} show={true} fixed={true} />}
      {appError && <Alert title="Произошла ошибка">{appError}</Alert>}
      {error && (
        <ErrorPopup
          onClose={setCloseModal}
          errorMessage={
            <p>
              Кажется у Вас нет доступа к этой странице!
              <br />
              Пожалуйста, проверьте адрес страницы (URL) или перейдите на Список заявок
            </p>
          }
          buttonText={'Список заявок'}
        />
      )}
    </>
  );
};

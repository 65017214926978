import styled from 'styled-components';
import { CheckBox } from 'components/primitives/check-box';

export const Switch = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const CheckBoxStyled = styled(CheckBox)`
  margin-right: 12px;
`;

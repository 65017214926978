import { useRef, useState } from 'react';
import * as Clipboard from 'utils/clipboard';

export const useClipboardCopyPresenter = (value, hideTimeout) => {
  const [isShowFilledIcon, setIsShowFilledIcon] = useState(false);
  const showTimeoutId = useRef(null);

  const presenter = {
    isShowFilledIcon,

    clearShowTimeout() {
      if (showTimeoutId.current) {
        clearTimeout(showTimeoutId.current);
        showTimeoutId.current = null;
      }
    },

    handleClick() {
      setIsShowFilledIcon(true);
      if (value) {
        Clipboard.copy(value);
      }
      showTimeoutId.current = setTimeout(() => {
        setIsShowFilledIcon(false);
        presenter.clearShowTimeout();
      }, hideTimeout);
    },
  };

  return presenter;
};

export default {
  company: 'E-Credit',
  defaultTitle: 'еКредит. Весь F&I в одной платформе',
  primary: '#ca0000',
  secondary: '#474b55',
  favicon: require('./favicon.ico'),
  logo: '',

  languages: [{ id: 'ru', name: 'Русский' }],
};

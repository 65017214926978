import styled from 'styled-components';

import { SMSVerification } from 'components/common/SMSVerification';
import * as UK from 'components/primitives/content';
import { Popup } from 'components/uikit/Popup/Popup';

export const StyledPopup = styled(Popup).attrs({ theme: '2' })`
  width: 620px;
  border-radius: 24px;
  box-shadow: none;
`;

export const ContentOfPopup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(UK.Title1)`
  margin-bottom: 12px;
  font: var(--font-heading-large-28);
`;

export const Text = styled(UK.Paragraph)`
  margin-bottom: 24px;
  font: var(--font-regular-15);
`;

export const PhoneVerification = styled(SMSVerification)`
  height: 196px;
`;

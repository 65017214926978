import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const notificationClickTrack = () => isProd && ym('reachGoal', 'notifications');

export const applicationOpenTrack = () => isProd && ym('reachGoal', 'rashlop');

export const dealerFilterTrack = () => isProd && ym('reachGoal', 'search_dc');

export const ksoFilterTrack = () => isProd && ym('reachGoal', 'search_kso');

export const mopFilterTrack = () => isProd && ym('reachGoal', 'search_mop');

export const phoneClickTrack = () => isProd && ym('reachGoal', 'telephone_rashlop');

export const searchTrack = () => isProd && ym('reachGoal', 'search_application');

export const certificateClickTrack = () => isProd && ym('reachGoal', 'certificate');

export const sendClickTrack = () => isProd && ym('reachGoal', 'send_rashlop');

export const lostClickTrack = () => isProd && ym('reachGoal', 'lost_applications');

export const tabListOpenTrack = () => isProd && ym('reachGoal', 'tab_list_applications');

export const applicationRowOpenButtonTrack = () => isProd && ym('reachGoal', 'open');

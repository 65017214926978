import { useState } from 'react';

import { convertCoinsToString } from 'utils/convertPrice';

export const useInfoCardPresenter = (data, labelsObj) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hideBanks, setHideBanks] = useState(true);

  const allUniqueBanks = data.bankIds.filter(
    (bankId, index, bankIds) => index === bankIds.lastIndexOf(bankId)
  );

  const allOnlineBanks = data.onlineBanksIds;

  const notOnlineBanks = allUniqueBanks.filter((item) => allOnlineBanks.indexOf(item) === -1);

  const sortedBanks = [...allOnlineBanks, ...notOnlineBanks];

  const percentsObj = {
    year: data.carApproved.yearDiff > 0 ? labelsObj.red : labelsObj.green,
    creditSum:
      data.creditApproved.creditSumDiffPercent >= -4.99
        ? labelsObj.green
        : data.creditApproved.creditSumDiffPercent >= -14.99 &&
          data.creditApproved.creditSumDiffPercent <= -5
        ? labelsObj.yellow
        : data.creditApproved.creditSumDiffPercent <= -15
        ? labelsObj.red
        : null,
    downPayment:
      data.creditApproved.downPaymentDiffPercent === 0
        ? labelsObj.green
        : data.creditApproved.downPaymentDiffPercent <= 4.99
        ? labelsObj.yellow
        : labelsObj.red,
    monthlyPayment:
      data.creditApproved.monthlyPaymentDiffPercent <= 4.99
        ? labelsObj.green
        : data.creditApproved.monthlyPaymentDiffPercent <= 9.99
        ? labelsObj.yellow
        : labelsObj.red,
    creditRate:
      data.creditApproved.creditRateDiff <= 1
        ? labelsObj.green
        : data.creditApproved.creditRateDiff <= 2.9
        ? labelsObj.yellow
        : labelsObj.red,
  };

  const presenter = {
    sortedBanks,
    percentsObj,
    activeIndex,
    hideBanks,
    setHideBanks,

    modelParams(field) {
      if (field === 'model') {
        return data.carRequested.brand && data.carRequested.model
          ? `${data.carRequested.brand} ${data.carRequested.model}`
          : '';
      }

      if (field === 'requested') {
        return data.carRequested.year >= 0 && data.carRequested.year !== data.carApproved.year
          ? data.carRequested.year
          : '';
      }

      if (field === 'approved') {
        return data.carApproved.year >= 0 ? data.carApproved.year : '-';
      }
    },

    carCostParams() {
      return data.carRequested.cost >= 0
        ? convertCoinsToString(data.carRequested.cost, false)
        : '-';
    },

    creditSumParams(field) {
      if (field === 'requested') {
        return data.creditRequested.creditSum >= 0 &&
          data.creditRequested.creditSum !== data.creditApproved.creditSum
          ? convertCoinsToString(data.creditRequested.creditSum, false)
          : '';
      }

      if (field === 'approved') {
        return data.creditApproved.creditSum >= 0
          ? convertCoinsToString(data.creditApproved.creditSum, false)
          : '-';
      }
    },

    downPaymentParams(field) {
      if (field === 'requested') {
        return data.creditRequested.downPayment >= 0 &&
          data.creditRequested.downPayment !== data.creditApproved.downPayment
          ? convertCoinsToString(data.creditRequested.downPayment, false)
          : '';
      }

      if (field === 'approved') {
        return data.creditApproved.downPayment >= 0
          ? convertCoinsToString(data.creditApproved.downPayment, false)
          : '-';
      }
    },

    monthlyPaymentParams(field) {
      if (field === 'requested') {
        return data.creditRequested.monthlyPayment >= 0 &&
          data.creditRequested.monthlyPayment !== data.creditApproved.monthlyPayment
          ? convertCoinsToString(data.creditRequested.monthlyPayment, false)
          : '';
      }

      if (field === 'approved') {
        return data.creditApproved.monthlyPayment >= 0
          ? convertCoinsToString(data.creditApproved.monthlyPayment, false)
          : '-';
      }
    },

    creditRateParams(field) {
      if (field === 'requested') {
        return data.creditRequested.creditRate >= 0 &&
          data.creditRequested.creditRate !== data.creditApproved.creditRate
          ? `${data.creditRequested.creditRate} %`
          : '';
      }

      if (field === 'approved') {
        return data.creditApproved.creditRate >= 0 ? `${data.creditApproved.creditRate} %` : '-';
      }
    },

    handleButtonClick(index) {
      setActiveIndex(index);
    },
  };

  return presenter;
};

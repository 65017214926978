import React, { useState, useEffect, useMemo, useRef } from 'react';
import { getTextWidth } from 'utils/getTextWidth';

import * as PositionHelper from 'utils/documentPosition';
import * as S from './style';

const CURRENT_FONT = 'normal 12px auto';

const fn = () => {};

const Tooltip = ({
  isShow,
  text,
  minWidth = 0,
  maxWidth = 500,
  width,
  indent = 0,
  tooltipClassName = '',
  onMouseOver = fn,
}) => {
  const ref = useRef();
  const [height, setHeight] = useState(32);
  const textWidth = useMemo(() => {
    const ret = getTextWidth(text, CURRENT_FONT);
    return ret >= minWidth ? ret : minWidth;
  }, [text, minWidth]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const tooltipData = PositionHelper.getNodePosition(ref?.current);

    if (tooltipData?.height) {
      setHeight(tooltipData.height);
    }
  });

  if (!isShow || !text) {
    return null;
  }

  return (
    <S.TooltipPlace>
      <S.Tooltip
        ref={ref}
        height={height}
        indent={indent}
        width={width || textWidth + 25}
        maxWidth={maxWidth}
        onMouseOver={onMouseOver}
        className={tooltipClassName}
      >
        <span>{text}</span>
      </S.Tooltip>
    </S.TooltipPlace>
  );
};

export default Tooltip;

import styled, { css } from 'styled-components';
import { IMaskInput } from 'react-imask';

import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';
import { Icon } from 'components/uikit/Icon/Icon';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  height: 44px;
  border: 1px solid ${(p) => (p.required ? 'var(--color-red-2)' : 'var(--color-grey-4)')};
  position: relative;
  cursor: pointer;
  min-width: 80px;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: var(--color-grey-1);
    `}

  ${(p) =>
    p.isFocused &&
    css`
      display: block;
    `}
`;

export const InputStyled = styled(IMaskInput).withConfig({
  shouldForwardProp: (prop) => !['confidenceErrorNotification', 'isFocused'].includes(prop),
})`
  width: ${(p) => (!p.confidenceErrorNotification ? '100%' : 'calc(100% - 30px)')};
  position: absolute;
  top: 0;
  height: 43px;
  line-height: 43px;
  padding: 25px 9px 9px 9px;
  border-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(p) => (p.disabled ? 'transparent' : 'transparent')};
  color: var(--color-grey-8);
  opacity: ${(p) => (p.isFocused ? 1 : 0)};

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

export const Label = styled.div`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
  width: 100%;
  margin-left: 9px;
  color: ${(p) => (p.required ? 'var(--color-red-2)' : 'var(--color-grey-4)')};

  ${(p) =>
    p.isFocused &&
    css`
      margin-top: 3px;
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    `}
`;

export const Prompt = styled(TextStyle3)`
  width: max-content;
  position: absolute;
  left: 0;
  top: 44px;
  margin: 0;
  color: var(--color-red-2);
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  & p {
    margin: 0;
    margin-bottom: 1px;
  }
`;

export const PulseLoaderStyled = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d8d8d875;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AttentionIcon = styled(Icon).attrs({ theme: '6', sizes: 's' })`
  margin-left: auto;
  margin-right: 7px;
`;

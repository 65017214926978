import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isUsePEP } from 'store/user/selectors';

import { PersonalInfoForm } from './components/PersonalInfo';
import { Work } from './components/Work';
import { SignDocuments } from './components/SignDocuments';
import { SignPEP } from './components/SignPEP';
import { Wrapper } from './style';
import { SpecialСonditions } from './components/SpecialСonditions/SpecialСonditions';
import { getDealersInfo, pickerCheck } from 'data-providers/applicationsProvider';

export const MainAnketa = ({
  className = '',
  data,
  errors,
  params,
  validate,
  firstTimeRender,
  forcedValidation,
  onChange,
  contentHidden = false,
  anketaSave = () => {},
  setFocusAt = () => {},
  onSave = () => {},
}) => {
  const isPEP = useSelector(isUsePEP);
  const { currentPersonId, isPEPSignature, application } = useSelector(
    (state) => state.anketa || {}
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSpecialСonditions, setIsSpecialСonditions] = useState(false);
  const [isPickUp, setIsPickUp] = useState(false);

  const selfAgreement = data?.persons[currentPersonId]?.selfAgreement || false;
  const isShowDocuments = !(selfAgreement || isPEP);

  useEffect(() => {
    if (selfAgreement) {
      setIsDisabled(true);
      return;
    }

    if (isPEP) {
      setIsDisabled(!isPEPSignature);
      return;
    }

    setIsDisabled(false);
  }, [selfAgreement, isPEPSignature, isPEP]);

  useEffect(() => {
    const fetchCheck = async () => {
      const dealersInfo = await getDealersInfo([data.application.dealer.id]);
      if (dealersInfo.data[0]?.specialDistribution) {
        setIsSpecialСonditions(true);
        const check = await pickerCheck(application.id);
        if (check) {
          setIsPickUp(check.data.running);
        }
      }
    };
    fetchCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper className={className}>
      <PersonalInfoForm
        data={data}
        errors={errors}
        params={params}
        validate={validate}
        forcedValidation={forcedValidation}
        onChange={onChange}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        contentHidden={contentHidden}
        disabled={selfAgreement}
      >
        {isPEP && <SignPEP anketaData={data} anketaErrors={errors} onSave={onSave} />}
      </PersonalInfoForm>

      <Work
        data={data}
        errors={errors}
        validate={validate}
        firstTimeRender={firstTimeRender}
        onChange={onChange}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        disabled={isDisabled}
      />
      {isSpecialСonditions && <SpecialСonditions isPickUp={isPickUp} />}
      {isShowDocuments && (
        <SignDocuments data={data} errors={errors} contentHidden={contentHidden} />
      )}
    </Wrapper>
  );
};

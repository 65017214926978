import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './constants';
import * as provider from 'data-providers/ePointsProvider';

function* loadEPointsInfo() {
  try {
    const ePointsUserInfo = yield provider.getEPointsUserInfo();

    if (ePointsUserInfo) {
      yield put({
        type: actions.GET_EPOINTS_MEMBERSHIP,
        payload: ePointsUserInfo.programs?.epoints_digital_deal?.userCompleteRegistration,
      });

      if (ePointsUserInfo.programs?.epoints_digital_deal?.userCompleteRegistration) {
        yield put({
          type: actions.GET_EPOINTS_BALANCE,
          payload: ePointsUserInfo.programs?.epoints_digital_deal?.balance,
        });
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export function* watchLoadEPointsInfo() {
  yield takeLatest(actions.LOAD_EPOINTS_INFO, loadEPointsInfo);
}

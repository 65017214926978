import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as providers from 'data-providers/ePointsProvider';
import * as ePointsStore from 'store/ePoints';
import * as metrica from 'metrika/loyaltyPrograms';

import { onlyNumber } from 'utils/stringUtils';

const initState = {
  fio: '',
  email: '',
  phoneNumber: '',
  acceptTenderOffer: false,
};

const errorsInitState = {
  fio: '',
  email: '',
  phone: '',
  acceptTenderOffer: false,
};

export const usePopupChildrenPresenter = (onClose) => {
  const rootDispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const fullAnketa = useSelector((store) => store.ePoints.ePointsAnketa);
  const { popupFrames, emailValidationError } = useSelector((store) => store.ePoints);

  const [ePointsAnketa, setEPointsAnketa] = useState({ ...initState });
  const [inputErrors, setInputErrors] = useState({ ...errorsInitState });
  const [registrationError, setRegistrationError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isRequested, setIsRequested] = useState(false);

  const presenter = {
    fullAnketa,
    ePointsAnketa,
    inputErrors,
    disabled,
    isRequested,
    popupFrames,
    registrationError,

    onHandleOpened(removed, added) {
      rootDispatch(ePointsStore.setActivePopupFrame({ removed, added }));
    },

    onHandleReset() {
      rootDispatch(ePointsStore.setResetPopupFrame());
    },

    onHandleClosed() {
      onClose();
      presenter.onHandleReset();
    },

    onFioChangeHandler(e) {
      const input = e.target.value;
      const regex = /^[a-zA-Zа-яА-ЯёЁ\s]*$/;

      if (regex.test(input) || input === '') {
        setEPointsAnketa({ ...ePointsAnketa, fio: input });
      }
    },

    onPhoneNumberChangeHandler(e) {
      const currentPhone = onlyNumber(e.target.value);

      if (currentPhone.length <= 11) {
        setEPointsAnketa({ ...ePointsAnketa, phoneNumber: currentPhone });
      }

      setInputErrors({ ...errorsInitState });
      setRegistrationError('');
    },

    onCheckedHandler(e) {
      setEPointsAnketa({ ...ePointsAnketa, acceptTenderOffer: e.target.checked });
    },

    async onConfirmHandler() {
      setIsRequested(true);
      metrica.continiueRegistrationButtonTrack();

      const [lastName, firstName = '', patronymicName = ''] = ePointsAnketa.fio.trim().split(' ');

      rootDispatch(
        ePointsStore.uploadEPointsAnketa({
          firstName,
          lastName,
          patronymicName,
          phoneNumber: ePointsAnketa.phoneNumber,
          email: ePointsAnketa.email,
          acceptTenderOffer: ePointsAnketa.acceptTenderOffer,
        })
      );

      try {
        const res = await providers.ePointsRegistration({
          firstName,
          lastName,
          patronymicName,
          phoneNumber: ePointsAnketa.phoneNumber,
          email: ePointsAnketa.email,
          acceptTenderOffer: ePointsAnketa.acceptTenderOffer,
        });

        if (
          res.code === 200 &&
          !registrationError &&
          !inputErrors.fio &&
          !inputErrors.email &&
          !inputErrors.phone &&
          !inputErrors.acceptTenderOffer
        ) {
          presenter.onHandleOpened('registrationStart', 'smsConfirm');
          setIsRequested(false);
        }
      } catch (err) {
        console.error(err);
        if (err.response.status === 422) {
          const errors = err.response.data.errors;
          const errorData = {};

          errors.forEach(({ property, message }) => {
            switch (property) {
              case 'firstName':
              case 'lastName':
                errorData['fio'] = 'Необходимо заполнить фамилию и имя';
                break;
              case 'email':
                errorData['email'] = message;
                break;
              case 'phoneNumber':
                errorData['phone'] = message;
                break;
              case 'acceptTenderOffer':
                errorData['acceptTenderOffer'] = message;
                break;

              default:
                break;
            }
          });

          setInputErrors((prev) => ({ ...prev, ...errorData }));
        } else if (err.response.data) {
          setRegistrationError(err.response.data.error?.message || err.response.data.error);
        }

        setIsRequested(false);
      }
    },
  };

  useEffect(() => {
    if (
      ePointsAnketa.fio.trim().split(' ').length >= 2 &&
      ePointsAnketa.email &&
      ePointsAnketa.phoneNumber.length === 11 &&
      ePointsAnketa.acceptTenderOffer &&
      !registrationError &&
      !inputErrors.fio &&
      !inputErrors.email &&
      !inputErrors.phone &&
      !inputErrors.acceptTenderOffer
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    ePointsAnketa.fio,
    ePointsAnketa.email,
    ePointsAnketa.phoneNumber,
    ePointsAnketa.acceptTenderOffer,
    registrationError,
    inputErrors.fio,
    inputErrors.email,
    inputErrors.phone,
    inputErrors.acceptTenderOffer,
  ]);

  useEffect(() => {
    if (user?.firstname && user?.lastname && user?.email) {
      setEPointsAnketa({
        ...ePointsAnketa,
        fio: user.middlename
          ? `${user.lastname} ${user.firstname} ${user.middlename}`
          : `${user.lastname} ${user.firstname}`,
        email: user.email,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (emailValidationError) {
      setInputErrors((prev) => ({ ...prev, email: emailValidationError }));
    }
  }, [emailValidationError]);

  return presenter;
};

import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const giveCreditButtonTrack = () => isProd && ym('reachGoal', 'выдай кредит');

export const toMainPageTrack = () => isProd && ym('reachGoal', 'Переход на Главную на лого');

export const toWhatsAppTrack = () => isProd && ym('reachGoal', 'Переход по номеру телефона');

export const applicationNumCopyTrack = () =>
  isProd && ym('reachGoal', 'Копирование номера заявки ');

export const vinNotificationTrack = () => isProd && ym('reachGoal', 'Посмотреть в нотификации');

export const onShowMoreTrack = () => isProd && ym('reachGoal', 'показать еще');

export const onDidgitalDealClickTrack = () =>
  isProd && ym('reachGoal', 'Перейти на Цифровую сделку');

export const checkVinInputTrack = () => isProd && ym('reachGoal', 'ввод VIN-номера');

export const checkVinButtonTrack = () => isProd && ym('reachGoal', 'проверить vin');

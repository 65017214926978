import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from 'components/primitives/loader';
import { loadDeals, dropDeals } from 'store/deals';
import { pickerCheck } from 'data-providers/applicationsProvider';

import * as help from '../../helpers';
import { DealsListContainer, DealsWrapper } from './style';
import { DealsStatusList } from './DealsStatusList';
import { DEALS_STATUS } from './constants';

const DealsList = ({ application }) => {
  const rootDispatch = useDispatch();

  const { id: applicId, carInstance, lastState } = application;
  const applicationBody = { ...application?.orders[0].body, dealerName: application.dealer.name };

  const deals = useSelector((store) => store.deals.dealsStatus[applicId] || {}, shallowEqual);
  const pages = useSelector((store) => store.deals.pages[applicId]);
  const isShowSendButton = lastState.id > 2 && Object.keys(deals).includes('new');
  const [isOpen, setOpen] = useState(false);
  const [pickUpStatus, setPickUpStatus] = useState({});

  const dealsLength = Object.values(deals).flat().length;

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 500);

    return () => {
      rootDispatch(dropDeals({ applicId }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCheck = async () => {
      const check = await pickerCheck(applicationBody.id);
      if (check) {
        setPickUpStatus(check);
      } else {
        setPickUpStatus({ data: { running: false } });
      }
    };
    fetchCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    if (deals.length < pages.total) {
      let page = 1;
      if (pages) {
        page = pages.page;
      }
      rootDispatch(
        loadDeals({ id: application.id, applicId: application?.ids?.credit, page: page + 1 })
      );
    }
  };
  const isLoaded = deals.length === 0 ? true : false;
  const loadingLabel = pages && deals.length < pages.total ? 'Загрузка...' : '';

  if (!isOpen) {
    return null;
  }

  return (
    <DealsWrapper>
      <DealsListContainer id={`deals-list${applicId}`}>
        <InfiniteScroll
          dataLength={dealsLength}
          next={fetchMoreData}
          hasMore={true}
          loader={<h4>{loadingLabel}</h4>}
          scrollableTarget={`deals-list${applicId}`}
        >
          <DealsStatusList
            status={DEALS_STATUS.FUNDED}
            dealsList={help.getFundedBanks(deals)}
            color={'var(--color-green-2)'}
            applicationBody={applicationBody}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.APPROVED}
            dealsList={help.getApprovedBanks(deals)}
            color={'var(--color-green-2)'}
            applicationBody={applicationBody}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.REQUESTED}
            dealsList={help.getRequestedBanks(deals)}
            color={'var(--color-blue-3)'}
            applicationBody={applicationBody}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.QUEUE}
            dealsList={help.getQueueBanks(deals)}
            color={'var(--color-yellow-1)'}
            applicationBody={applicationBody}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.DECLINE}
            dealsList={help.getDeclinedBanks(deals)}
            color={'var(--color-red-2)'}
            applicationBody={applicationBody}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.OPENED}
            isShowSendButton={isShowSendButton}
            pickUpStatus={pickUpStatus}
            dealsList={help.getOpenedBanks(deals)}
            color={'var(--color-white)'}
            carInstance={carInstance}
          />
        </InfiniteScroll>
      </DealsListContainer>
      <Loader show={isLoaded} color="white" />
    </DealsWrapper>
  );
};

export default React.memo(DealsList);

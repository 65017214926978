import React, { useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { GET_FILE_URL } from 'constants/url';
import { uploadQrFiles } from 'data-providers/fileProvider';
import PulseLoader from 'react-spinners/PulseLoader';
import * as S from './style';

export const imgFileFormats = ['image/jpeg'];

const Upload = () => {
  const params = useParams();
  const fileInputRef = useRef();
  const isMultipleUpload = useMemo(() => {
    return params.type !== 'tmplt_foto';
  }, [params]);

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState('');

  const fileChangeHandler = async (e) => {
    setIsLoading(true);
    const files = [...e.target.files];
    setError('');
    const filteredFiles = files.filter((f) => {
      return imgFileFormats.includes(f.type);
    });
    if (filteredFiles.length === 0) {
      setError('Допустимые форматы файлов: JPG');
      setIsLoading(false);
      return;
    }
    uploadQrFiles({
      applicationId: params.id,
      fileType: params.type,
      token: params.token,
      files: filteredFiles,
    })
      .then((data) => {
        setUploadedFile(data.result);
      })
      .catch((e) => {
        setError(
          e?.customDescription || 'Произошла ошибка при загрузке файла. Попробуйте еще раз.'
        );
      })
      .finally(() => {
        setIsLoading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      });
  };

  const clearUploadedFile = () => {
    setUploadedFile(null);
  };

  return (
    <>
      <S.FileInput
        type="file"
        id="fileInput"
        accept={imgFileFormats.join(', ')}
        multiple={isMultipleUpload}
        onChange={fileChangeHandler}
        ref={fileInputRef}
      />
      {!uploadedFile ? (
        <S.Wrapper>
          {isLoading ? (
            <S.LoaderWrapper>
              <PulseLoader size={30} color={'var(--color-grey-4)'} loading={true} />
            </S.LoaderWrapper>
          ) : (
            <S.IconWrapper htmlFor="fileInput">
              <S.CameraIcon key="cameraIcon" />
              {Boolean(error) && <S.Error>{error}</S.Error>}
            </S.IconWrapper>
          )}
        </S.Wrapper>
      ) : (
        <S.FilesList>
          <S.UploadedFile key={uploadedFile} href={GET_FILE_URL(uploadedFile)}>
            <span>Загруженный файл</span>
            <S.CloudIcon />
          </S.UploadedFile>
          <S.UploadMore onClick={clearUploadedFile}>Загрузить повторно</S.UploadMore>
        </S.FilesList>
      )}
    </>
  );
};

export default Upload;

import styled from 'styled-components';

import { TextInput } from 'components/uikit/Form/TextInput/TextInput';
import { Button } from 'components/uikit/Button/Button';
import { AttentionCard } from 'components/uikit/AttentionCard/AttentionCard';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 16px;
  gap: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

export const HistoryWrapper = styled.div``;

export const SubTitleWrapper = styled.div`
  display: flex;
  height: 20px;
  width: max-content;
  gap: 4px;
  border-radius: 4px;
  outline-offset: 7px;

  cursor: pointer;
`;

export const ChevronWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  transform: ${(p) => (p.isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)')};
`;

export const SearchErrorWrapper = styled(AttentionCard)`
  margin-top: 20px;
`;

export const Title = styled.span`
  font: var(--font-heading-large-20_1);
`;

export const HistoryTitle = styled(Title)`
  margin-top: 24px;
`;

export const SubTitle = styled.span`
  color: var(--color-blue-3);

  font: var(--font-large-15);
`;

export const ShowMore = styled(SubTitle)`
  width: max-content;
  height: 20px;
  margin-top: 8px;
  color: ${(p) => p.disabled && 'var(--color-blue-3_50)'};

  cursor: pointer;
`;

export const StyledInput = styled(TextInput)`
  flex-grow: 1;
`;

export const StyledButton = styled(Button)`
  width: 142px;
`;

export const HistoryTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 0px;
  transition: height 0.5s ease;
`;

export const TableItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 52px;
`;

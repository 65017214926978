import { stateList } from './constants';

export const getCopyStateList = (list) =>
  list.map((s) => ({ ...s, sub: s.sub.map((ss) => ({ ...ss })) }));

export const getStateList = (state) => {
  const s = state?.name || '';
  const p = getCopyStateList(stateList);
  const approveState = p[0];
  const prepareState = p[1];
  const agreementState = p[2];
  const formalizationState = p[3];
  const creditState = p[4];
  const closureState = p[5];

  switch (s) {
    case 'new':
      approveState.state = 1;
      approveState.open = true;
      break;
    case 'final_parameters':
    case 'recalculation_desired':
    case 'recalculation_finished': {
      approveState.state = 3;
      prepareState.state = 1;
      prepareState.open = true;
      prepareState.sub[0].state = 3;
      prepareState.sub[1].state = 1;
      break;
    }
    case 'approval_requested':
    case 'reapproval_requested':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.open = true;
      agreementState.sub[0].view = true;
      agreementState.sub[0].timer = true;
      break;
    case 'approval_request_received':
    case 'reapproval_request_received':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.open = true;
      agreementState.sub[0].view = true;
      agreementState.sub[0].state = 3;
      agreementState.sub[1].view = true;
      agreementState.sub[1].timer = true;
      break;
    case 'request_on_approval':
    case 'request_on_reapproval':
    case 'request_on_documents':
    case 'reload_documents_required':
    case 'contract_sign':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.open = true;
      agreementState.sub[0].view = true;
      agreementState.sub[0].state = 3;
      agreementState.sub[1].state = 3;
      agreementState.sub[3].view = true;
      agreementState.sub[3].state = 2;
      break;
    case 'approved':
    case 'documentation_requested':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.timer = true;
      break;
    case 'documentation_received':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.open = true;
      formalizationState.sub[0].state = 3;
      break;
    case 'finance_requested':
    case 'documents_prepared':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.open = true;
      formalizationState.sub[0].state = 3;
      formalizationState.sub[1].state = 3;
      break;
    case 'link_sent':
    case 'contract_requested':
    case 'contract_received':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.state = 3;
      creditState.open = true;
      break;
    case 'request_on_finance':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.open = true;
      formalizationState.sub[0].state = 3;
      formalizationState.sub[1].state = 3;
      formalizationState.sub[3].view = true;
      formalizationState.sub[3].state = 2;
      break;
    case 'bank_decline':
    case 'reapproval_bank_decline':
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.open = true;
      agreementState.sub[0].state = 3;
      agreementState.sub[1].state = 3;
      agreementState.sub[2].view = true;
      agreementState.sub[2].state = 1;
      break;
    case 'financed': {
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.state = 3;
      creditState.state = 3;
      closureState.state = 3;
      closureState.open = true;
      closureState.view = true;
      break;
    }
    case 'closed': {
      approveState.state = 3;
      prepareState.state = 3;
      agreementState.state = 3;
      formalizationState.state = 3;
      creditState.state = 3;
      closureState.state = 3;
      closureState.view = true;
      break;
    }
    default: // do nothing
  }

  return p;
};

import styled from 'styled-components';

import { EPointsActiveLogoMImg, EPointsDisabledLogoSImg } from 'assets/images';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LogoWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledEPointsActiveLog = styled(EPointsActiveLogoMImg)`
  max-width: 16px;
  max-height: 16px;
`;

export const StyledEPointsDisabledLogo = styled(EPointsDisabledLogoSImg)`
  max-width: 16px;
  max-height: 16px;
`;

import React from 'react';
import { useMainPageNotifications } from './MainPageNotifications.presenter';

import { Loader } from 'components/primitives/loader';
import { Notification } from './components/Notification/Notification';
import { EmptyState } from './components/EmptyState/EmptyState';

import * as S from './MainPageNotifications.styles';

export const MainPageNotifications = ({ className }) => {
  const presenter = useMainPageNotifications();

  return (
    <S.Wrapper className={className}>
      <S.SortPanel>
        <S.Title>Одобренные клиенты</S.Title>
      </S.SortPanel>

      {presenter.isLoading ? (
        <Loader color="none" show={true} fixed={false} />
      ) : (
        <S.Container>
          {presenter.sortedList.map((data) => (
            <Notification key={data.id} data={data} />
          ))}

          {presenter.sortedList.length === 0 && <EmptyState>Нет новых уведомлений</EmptyState>}
        </S.Container>
      )}
    </S.Wrapper>
  );
};

import { genderReversMap } from './genderHelper';
import * as MapperHelper from './mapperHelper';

export const frontToBackCustomer = (fc, model = {}) => {
  const genderSysName = genderReversMap[fc.gender]?.toLowerCase() ?? genderReversMap[0];
  const [surname = '', name = '', ...middleName] =
    fc.insurerDadataInfo?.name?.short_with_opf?.split(' ') ?? [];

  model.personalInformation = fc.isLegal
    ? {
        ...model?.personalInformation,
        surname: surname.replaceAll('"', ''),
        name: name.replaceAll('"', ''),
        middleName: middleName.join(' ').replaceAll('"', ''),
        genderSysName,
        birthDate: MapperHelper.dateFormmatter('01012000', true),
      }
    : {
        ...model?.personalInformation,
        ...MapperHelper.fioSplit(fc.fio),
        genderSysName,
        birthDate: MapperHelper.dateFormmatter(fc.bday, true),
      };

  model.contacts = {
    ...model?.contacts,
    mobilePhone: fc.isLegal ? fc.insurerDadataInfo?.phones : fc.insurerPhone,
    email: fc.insurerEmail,
  };

  model.passport = {
    ...model?.passport,
    ...MapperHelper.passportSplit(fc.insurerPassportSerialNum),
    issueDate: MapperHelper.dateFormmatter(fc.insurerPassportDate, true),
    issuerCode: fc.insurerIssuerCode,
    issuer: fc.insurerIssuer,
    birthPlace: fc.insurerBirthPlace,
  };

  model.registrationAddress = {
    ...model?.registrationAddress,
    address: {
      ...model?.registrationAddress?.address,
      ...fc.insurerDadataInfo,
      indexNumber: fc.insurerIndex,
      fiasValue: fc.isLegal ? fc.insurerDadataInfo?.address?.unrestricted_value : fc.insurerAddress,
      buildingNumber: fc.insurerDadataInfo?.building,
    },
  };

  if (fc.isLegal) {
    model.secondDocument = {
      ...model?.secondDocument,
      currentSecondDocumentTypeSysName: '2nd_tax',
      taxRegistration: {
        ...model?.secondDocument?.taxRegistration,
        inn: fc.insurerDadataInfo?.inn,
      },
    };
    model.livingAddressEquals = fc.isLivingAddressEquals;
    model.livingAddress.address.fiasValue = fc.insurerDadataInfo?.address?.unrestricted_value;
  }

  return model;
};

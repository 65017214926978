import React from 'react';
import { DetailCellValue, DetailCellLabel, DetailCellWrapper } from './style';

const DetailItemCell = ({
  label,
  value,
  children,
  border = false,
  align = 'left',
  txtCap = false,
  black = false,
  mediumText = false,
  wordBreak = false,
  noWrap = false,
}) => (
  <DetailCellWrapper border={border} align={align} mediumText={mediumText}>
    {label && <DetailCellLabel>{label}</DetailCellLabel>}
    {value && (
      <DetailCellValue txtCap={txtCap} black={black} wordBreak={wordBreak} noWrap={noWrap}>
        {value}
      </DetailCellValue>
    )}
    {children}
  </DetailCellWrapper>
);

export default DetailItemCell;

import nameplate_9 from './nameplate_9.png';
import nameplate_11 from './nameplate_11.png';
import nameplate_15 from './nameplate_15.png';
import nameplate_17 from './nameplate_17.png';
import nameplate_35 from './nameplate_35.png';
import nameplate_36 from './nameplate_36.png';
import nameplate_49 from './nameplate_49.png';
import nameplate_52 from './nameplate_52.png';
import nameplate_96 from './nameplate_96.png';
import nameplate_112 from './nameplate_112.png';
import nameplate_115 from './nameplate_115.png';
import nameplate_123 from './nameplate_123.png';
import nameplate_141 from './nameplate_141.png';
import nameplate_295 from './nameplate_295.png';
import nameplate_393 from './nameplate_393.png';
import nameplate_408 from './nameplate_408.png';
import nameplate_474 from './nameplate_474.png';
import nameplate_475 from './nameplate_475.png';
import nameplate_479 from './nameplate_479.png';
import nameplate_483 from './nameplate_483.png';
import nameplate_491 from './nameplate_491.png';
import nameplate_492 from './nameplate_492.png';
import nameplate_525 from './nameplate_525.png';
import nameplate_535 from './nameplate_535.png';
import nameplate_552 from './nameplate_552.png';
import nameplate_556 from './nameplate_556.png';
import nameplate_563 from './nameplate_563.png';
import nameplate_565 from './nameplate_565.png';
import nameplate_566 from './nameplate_566.png';
import nameplate_567 from './nameplate_567.png';
import nameplate_568 from './nameplate_568.png';
import nameplate_480 from './nameplate_480.png';
import nameplate_487 from './nameplate_487.png';
import nameplate_527 from './nameplate_527.png';
import nameplate_569 from './nameplate_569.png';
import nameplate_571 from './nameplate_571.png';
import nameplate_572 from './nameplate_572.png';
import nameplate_591 from './nameplate_591.png';
import nameplate_592 from './nameplate_592.png';

export default {
  9: nameplate_9,
  11: nameplate_11,
  15: nameplate_15,
  17: nameplate_17,
  35: nameplate_35,
  36: nameplate_36,
  49: nameplate_49,
  52: nameplate_52,
  96: nameplate_96,
  112: nameplate_112,
  115: nameplate_115,
  123: nameplate_123,
  141: nameplate_141,
  295: nameplate_295,
  393: nameplate_393,
  408: nameplate_408,
  474: nameplate_474,
  475: nameplate_475,
  479: nameplate_479,
  483: nameplate_483,
  491: nameplate_491,
  492: nameplate_492,
  525: nameplate_525,
  535: nameplate_535,
  552: nameplate_552,
  556: nameplate_556,
  563: nameplate_563,
  565: nameplate_565,
  566: nameplate_566,
  567: nameplate_567,
  568: nameplate_568,
  480: nameplate_480,
  487: nameplate_487,
  527: nameplate_527,
  569: nameplate_569,
  571: nameplate_571,
  572: nameplate_572,
  591: nameplate_591,
  592: nameplate_592,
};

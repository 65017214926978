import React from 'react';

import { LeftInfoBlock } from './components/LeftInfoBlock/LeftInfoBlock';
import { RightInfoBlock } from './components/RightInfoBlock/RightInfoBlock';

import * as S from './MainInfoBlock.styles';

export const MainInfoBlock = () => {
  return (
    <S.InfoContainer>
      <LeftInfoBlock />
      <RightInfoBlock />
    </S.InfoContainer>
  );
};

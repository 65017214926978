import React from 'react';

import { DealersBanks } from '../../../DealersBanks/DealersBanks';

import * as S from './RightInfoBlock.styles';

export const RightInfoBlock = () => {
  return (
    <S.Container>
      <DealersBanks />
    </S.Container>
  );
};

import React, { useMemo } from 'react';
import { getDocumentsByHash } from 'data-providers/mainAnketaProvider';
import { useSelector } from 'react-redux';
import { DocLink, DocumentsLinksWrap } from '../styles';

export default function DocumentsLinks() {
  const { otpDocLink, documents, isPEPSignature } = useSelector((state) => state.anketa || {});
  const personalDataDoc = useMemo(() => {
    return documents.find((obj) => obj.type.sysName === 'personal-data');
  }, [documents]);

  const getOtpDocHandler = async (event, hash) => {
    event.stopPropagation();
    event.preventDefault();

    await getDocumentsByHash(hash);
  };

  return (
    <DocumentsLinksWrap>
      {Object.keys(otpDocLink).length > 0 && !otpDocLink['credit-history-data'] ? (
        <>
          {otpDocLink['personal-data'] && (
            <DocLink href="#" onClick={(e) => getOtpDocHandler(e, otpDocLink['personal-data'])}>
              {'Согласие СОПД'}
            </DocLink>
          )}
          {personalDataDoc && (
            <DocLink href="#" onClick={(e) => getOtpDocHandler(e, personalDataDoc.id)}>
              {'Согласие БКИ'}
            </DocLink>
          )}
        </>
      ) : (
        !isPEPSignature &&
        personalDataDoc && (
          <DocLink href="#" onClick={(e) => getOtpDocHandler(e, personalDataDoc.id)}>
            {'СОПД и БКИ'}
          </DocLink>
        )
      )}
    </DocumentsLinksWrap>
  );
}

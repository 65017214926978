import { useEffect, useRef, useState } from 'react';

export const useTooltipCellPresenter = () => {
  const cellRef = useRef();
  const [isTruncated, setIsTruncated] = useState(false);

  const presenter = {
    cellRef,
    isTruncated,
  };

  useEffect(() => {
    const checkTruncation = () => {
      const element = cellRef.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, []);

  return presenter;
};

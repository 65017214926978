// this is true stringify method for arguments with brackets []
export const stringifyPartWithBrackets = (params) =>
  Object.keys(params).reduce((acc, stringKey) => {
    if (stringKey.includes('[') && params[stringKey]) {
      return acc + '&' + stringKey + '=' + params[stringKey];
    }

    return acc;
  }, '');

export const getParamsWithoutArgumentsWithBrackets = (params) =>
  Object.keys(params).reduce(
    (acc, stringKey) => {
      if (stringKey.includes('[')) {
        delete acc[stringKey];
      }

      return acc;
    },
    { ...params }
  );

export const createASWRequestParams = (applicationId, { policyStartDate, ...rest }) => ({
  applicationId,
  policyStartDate: policyStartDate?.split('T')?.[0],
  multidrive: false,
  ...rest,
});

export const createEOWRequestParams = createASWRequestParams;

export const transformRecipientsList = (recipients) => {
  return recipients.map((recipient) => {
    return {
      accountNumber: recipient?.paymentDetails?.account || '',
      bankId: recipient?.accreditation?.bank?.id,
      bankName: recipient?.accreditation?.bank?.name || '',
      bankAccreditationName: recipient?.accreditation?.bank?.name || '',
      bic: recipient?.paymentDetails?.bank?.bic || '',
      carfinId: recipient?.productProvider?.carfinId || '',
      code: recipient?.productProvider?.code || '',
      id: recipient?.id || recipient?.productProviderPaymentDetails?.id || null,
      legalInn: recipient?.legalEntity?.inn || '',
      value: `${recipient?.legalEntity?.opfShort} ${recipient?.legalEntity?.nameShort}`,
      name: recipient?.legalEntity?.nameFull || '',
      nameShort: recipient?.legalEntity?.nameShort || '',
      ndsFlag: recipient?.productProvider?.ndsFlag || false,
      ndsRate: recipient?.productProvider?.ndsRate || 0,
      opfShort: recipient?.legalEntity?.opfShort || '',
      stateBank: recipient?.stateBank || '',
      providerName: recipient?.productProvider?.name,
      description: `Р\\С ${recipient?.paymentDetails?.account}`,
    };
  });
};

import React from 'react';

import { useFinancePopupPresenter } from './FinancedPopup.presenter';
import * as S from './FinancedPopup.styles';

export const FinancedPopup = () => {
  const presenter = useFinancePopupPresenter();

  return (
    <>
      {presenter.showFinancePopup && (
        <S.Container onClose={presenter.onCloseFinancePopupHandler}>
          <S.Title>Финансирование заявки</S.Title>
          <S.Text>{`Для проставления решения по банку ${presenter.bankName} в ${presenter.dealerName} пропишите ID заявки по нумерации банка (ExID). Эту информацию можно найти в мониторе шины`}</S.Text>
          <S.Input
            label={'ExID'}
            value={presenter.inputValue}
            onChange={presenter.onChangeHandler}
            error={presenter.validateError}
            onKeyDown={presenter.onKeyPressHandler}
          />
          <S.Controls>
            <S.ButtonStyled theme={3} onClick={presenter.onCloseFinancePopupHandler}>
              Отмена
            </S.ButtonStyled>
            <S.ButtonStyled onClick={presenter.confirmFinancing}>Профинансировать</S.ButtonStyled>
          </S.Controls>
        </S.Container>
      )}
    </>
  );
};

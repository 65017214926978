import { useDispatch, useSelector } from 'react-redux';
import { specialClientClick } from 'metrika/mainAnketa';
import { setIsSpecialСonditions } from 'store/anketa';

export const useSpecialСonditionsPresenter = () => {
  const { specialСonditions } = useSelector((state) => state.anketa);
  const id = 'SpecialСonditions';
  const rootDispatch = useDispatch();

  const presenter = {
    id,
    specialСonditions,

    setSpecialСonditions: () => {
      specialClientClick();
      rootDispatch(setIsSpecialСonditions(!specialСonditions));
    },
  };
  return presenter;
};

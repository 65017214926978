export const valueLength = 17;
export const visibleStep = 5;
export const searchErrorHight = (searchError) => {
  // 76px высота блока ошибки с отступом
  return searchError ? 76 : null;
};

export const validateErrorMessage = `VIN должен состоять из ${valueLength} символов — цифр и латинских букв`;

export const fetchErrorMessage = `Что-то не то. Проверьте, правилно ли введен VIN`;

export const setVinValidation = (value) => {
  const sanitizedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');

  const isValid = sanitizedValue.length === valueLength;

  const errorMessage =
    sanitizedValue.length > 0 && sanitizedValue.length < valueLength
      ? `VIN должен состоять из ${valueLength} символов — цифр и латинских букв`
      : '';

  return { sanitizedValue, isValid, errorMessage };
};

export const getSearchErrorMessage = (sanitizedValue) => {
  return `Информация по ${sanitizedValue} не найдена. Уточните правильность VIN номера и запустите повторную проверку`;
};

export function isBooleanTrue(value) {
  return value === true || value === 'true';
}

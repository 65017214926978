import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--color-white);
`;

export const MainBlockWraper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  flex: 1 1 auto;
  width: 100%;
  padding: 16px;
`;

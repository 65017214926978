import React, { useState, useEffect } from 'react';

import { sendSmsCode, checkSmsCode } from 'data-providers/mainAnketaProvider';

import { useDispatch, useSelector } from 'react-redux';
import { setOtpId, setTimerStart } from 'store/confirmSMS';
import * as help from '../helpers';
import * as S from './styles';

export const SMSVerification = ({ appId, dealerId, customer, onClose, onSuccess }) => {
  const [isVerification, setIsVerification] = useState(false);
  const { otpId, timerStartTime } = useSelector((store) => store.confirmSMS);
  const rootDispatch = useDispatch();
  const initialDuration = 60;
  const remainingTime = help.calculateRemainingTime(timerStartTime, initialDuration);

  const verificationCodeHandler = async (code) => {
    if (!otpId) {
      return false;
    } else {
      setIsVerification(true);
      const result = await checkSmsCode(otpId, code);
      setIsVerification(false);

      return result;
    }
  };

  const resendCodeHandler = async () => {
    if (appId && dealerId && help.checkCustomer(customer)) {
      const result = await sendSmsCode(appId, dealerId, help.getSmsParams(customer));
      rootDispatch(setOtpId(result?.id || null));
      rootDispatch(setTimerStart(Math.floor(Date.now() / 1000)));
    }
  };

  useEffect(() => {
    const otpRequest = async () => {
      const otpResult = await sendSmsCode(appId, dealerId, help.getSmsParams(customer));
      rootDispatch(setOtpId(otpResult?.id || null));
      rootDispatch(setTimerStart(Math.floor(Date.now() / 1000)));
    };

    if (appId && dealerId && help.checkCustomer(customer) && !remainingTime) {
      otpRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, dealerId, customer]);

  return (
    <S.StyledPopup onClose={onClose}>
      <S.ContentOfPopup>
        <S.Title>Подписание согласия клиента</S.Title>
        <S.Text>
          Код подтверждения отправлен на номер {customer?.contacts?.mobilePhone || ''}
        </S.Text>

        <S.PhoneVerification
          codeSize={6}
          resendTimeout={remainingTime || initialDuration}
          disabled={isVerification}
          onVerification={verificationCodeHandler}
          onResend={resendCodeHandler}
          onSuccess={onSuccess}
        />
      </S.ContentOfPopup>
    </S.StyledPopup>
  );
};

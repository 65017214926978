import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import * as urls from 'constants/url';

export const getNotifications = async (userId, isEmailBank) => {
  try {
    let url = urls.NOTIFICATIONS_GET_URL(userId);

    if (isEmailBank === 0 || isEmailBank === 1) {
      url = urls.NOTIFICATIONS_MAINPAGE_GET_URL(userId, isEmailBank);
    }

    const res = await request
      .get(url, {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return Array.from(res?.data || []);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const readNotificationById = async (id) => {
  try {
    await request
      .post(urls.NOTIFICATIONS_READ_URL(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

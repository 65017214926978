export const defaultMenuItem = {
  id: '',
  className: '',
  children: null,
  title: '',
  tooltip: '',
  theme: '1',
  Icon: null,
  isActive: false,
  dataTest: '',
  onClick: () => {},
};

import React from 'react';

import { VinCheck } from '../../../VinCheck/VinCheck';
import { MainPageNotifications } from '../../../MainPageNotifications/MainPageNotifications';

import * as S from './LeftInfoBlock.styles';

export const LeftInfoBlock = () => {
  return (
    <S.Container>
      <VinCheck />
      <MainPageNotifications />
    </S.Container>
  );
};

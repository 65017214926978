import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFilter, filterTouch } from 'store/filters';
import { isEqualArrays } from 'utils/common';
import { mopFilterTrack } from 'metrika/applications';

import * as help from '../../helpers';
import * as S from '../../style';

export const Mop = ({ isVisible = true }) => {
  const rootDispatch = useDispatch();
  const userId = useSelector((store) => store.user?.id || '');
  const storeMopes = useSelector((store) => store.applicFilters?.mop || []);
  const filtersFetch = useSelector((store) => store.applicFilters.fetch);

  const [isChanged, setIsChanged] = useState(false);

  const mopes = useMemo(() => help.getWorkers(storeMopes, userId), [storeMopes, userId]);

  const changeHandler = (event) => {
    const ids = event['mop'] || [];
    if (isEqualArrays(ids, help.getSelectedIds(mopes))) {
      return;
    }

    setIsChanged(true);

    const newMopes = help.setItemsSelection(storeMopes, ids);
    rootDispatch(setFilter({ filter: 'mop', value: newMopes }));
    rootDispatch(filterTouch());
    mopFilterTrack();
  };

  useEffect(() => {
    if (!filtersFetch) {
      setIsChanged(false);
    }
  }, [filtersFetch]);

  if (!isVisible && !isChanged) {
    return null;
  }

  return (
    <S.Worker
      name="mop"
      label="МОП"
      list={mopes}
      defaultValue={help.getSelectedIds(mopes)}
      multiselect={true}
      search={true}
      checked={true}
      checkedAll={true}
      fetch={!filtersFetch}
      onBlur={changeHandler}
      dataTest="filterSelectMOP"
    />
  );
};

import styled from 'styled-components';

import { LampItem } from '../LampItem';

export const PopoverItem = styled.p`
  margin-bottom: 2px;
`;

export const PopoverTypeItem = styled(PopoverItem)`
  color: var(--color-grey-5);
`;

export const Lamp = styled(LampItem)`
  margin-right: 4px;
`;

import React from 'react';
import { useCelluarInputPresenter } from './CelluarInput.presenter';

import * as S from './CelluarInput.styles';

const fn = () => {};

export const CelluarInput = (props) => {
  const {
    className,
    type = 'text',
    codeSize = 6,
    code = '',
    theme = '1',
    size = 'l',
    disabled = false,
    resended = false,
    error = '',
    dataTest = '',
    onClick = fn,
    setCode = fn,
    onSubmit = fn,
    onBlur = fn,
    onFocus = fn,
  } = props;

  const presenter = useCelluarInputPresenter(code, codeSize, resended, error, setCode, onSubmit);

  return (
    <S.Container className={className} theme={theme} size={size} onClick={onClick}>
      <S.Wrapper error={error} disabled={disabled}>
        {Array(codeSize)
          .fill('')
          .map((_, index) => (
            <S.Cell
              key={index}
              index={index}
              codeSize={codeSize}
              value={code[index] || ''}
              ref={(el) => (presenter.inputRefs.current[index] = el)}
              type={type}
              maxLength="1"
              disabled={disabled}
              data-test={dataTest}
              onKeyDown={(event) => presenter.onHandleKeyDown(event, index)}
              onChange={(event) => presenter.onHandleInputChange(event, index)}
              onPaste={presenter.onHandlePaste}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          ))}
      </S.Wrapper>

      {error && <S.Error>{error}</S.Error>}
    </S.Container>
  );
};

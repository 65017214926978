import React from 'react';
import { BadgeStyled } from './BadgeStatus.style';
import { useBadgeStatusPresenter } from './BadgeStatus.presenter';

export const BadgeStatus = () => {
  const presenter = useBadgeStatusPresenter();
  return (
    <BadgeStyled color={presenter.status.color} fontColor={presenter.status.fontColor}>
      {presenter.status.title}
    </BadgeStyled>
  );
};

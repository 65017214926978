import styled, { keyframes } from 'styled-components';

const themes = {
  1: {
    colorMain: 'var(--color-grey-3)',
    colorSecondary: 'var(--color-grey-2)',
  },
};

const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  50% {
    background-position: 0% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const generateNoise = `
  radial-gradient(circle, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0) 80%) repeat
`;

export const Container = styled.span`
  --color-main: ${(p) => (themes[p.theme] || themes['1']).colorMain};
  --color-secondary: ${(p) => (themes[p.theme] || themes['1']).colorSecondary};

  display: flex;
  width: ${(props) => props.width || '100%'};
  min-height: ${(props) => props.height || '100%'};
  border-radius: 4px;

  background: linear-gradient(
    90deg,
    var(--color-secondary) 30%,
    var(--color-main) 50%,
    var(--color-secondary) 70%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  mask-image: ${generateNoise};
  -webkit-mask-image: ${generateNoise};
  mask-size: 100px 100px;
  -webkit-mask-size: 100px 100px;
  mask-repeat: repeat;
  -webkit-mask-repeat: repeat;

  background-clip: padding-box;
  -webkit-background-clip: padding-box;
`;

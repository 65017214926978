import styled from 'styled-components';
import { FormGridRowBase, FormRowBase, FormHeader } from '../style';
import { CheckBox } from 'components/primitives/check-box';
import { FileManager } from 'components/modules/MainAnketa/components/FileManager';
import { Checkbox } from 'components/uikit/Form/Checkbox/Checkbox';
import { AttentionCard } from 'components/uikit/AttentionCard/AttentionCard';

export const Mgrid = styled(FormGridRowBase)`
  grid-template-areas:
    '. h h h h h h .'
    '. c c c c c c .'
    '. add add add ch1 ch1 ch1 .';
`;

export const Docgrid = styled(FormGridRowBase)``;

export const Title = styled(FormHeader)`
  margin-bottom: 15px;
`;

export const FileManagerStyled = styled(FileManager)`
  margin-bottom: 30px;
  margin-left: calc(100% / 8);
  width: calc(100% - 100% / 8 * 2);
`;

export const FormRowMange = styled(FormRowBase)`
  justify-content: ${({ space }) => (space ? 'space-between' : 'flex-end')};
`;

export const SelfAgreeCheckbox = styled(Checkbox).attrs({ size: 'xs' })`
  grid-area: ch1;
  justify-content: flex-end;
`;

export const Multidrive = styled(CheckBox)`
  margin-left: calc(100% / 8);
  width: 106px;
`;

export const Anchor = styled.div``;

export const DocsHelp = styled.span`
  grid-area: go;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
`;

export const AttentionCardWrapper = styled(FormGridRowBase)`
  grid-template-areas: '. ac ac ac ac ac ac .';
`;

export const StyledAttentionCard = styled(AttentionCard)`
  grid-area: ac;
  margin-top: 40px;
`;

import React from 'react';

import * as S from './Badge.styles';

const fn = () => {};

export const Badge = (props) => {
  const {
    className,
    children,
    Icon = null,
    size = 's',
    theme = '1',
    disabled = false,
    onClick = fn,
    dataTest = '',
    isRound = true,
  } = props;

  return (
    <S.Container
      className={className}
      theme={theme}
      size={size}
      hasIcon={!!Icon}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
      round={isRound}
    >
      {Icon && <S.StyledIcon Icon={Icon} size={size} disabled={disabled} />}
      <S.Title>{children}</S.Title>
    </S.Container>
  );
};

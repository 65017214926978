import React from 'react';

import { Menu } from 'components/uikit/Menu/Menu';
import { LogoutSIcon, SearchMIcon } from 'assets/icons';

import { SubPanel } from '../components/SubPanel/SubPanel';
import { useUserPanelPresenter } from './UserPanel.presenter';
import * as S from './UserPanel.styles';

export const UserPanel = ({ onClose }) => {
  const presenter = useUserPanelPresenter(onClose);
  const menuDealerItems = presenter.searchDealerItems;

  return (
    <SubPanel title="Профиль" dataTest="profileMenu" onClose={onClose}>
      <S.Group>
        <S.GroupTitle>Роль</S.GroupTitle>
        <Menu items={presenter.menuRoleItems} MenuItemComponent={S.SubMenuItem} theme="1" />
      </S.Group>

      <S.DealerGroup>
        <S.GroupTitle>Дилеры</S.GroupTitle>

        <S.SearchInput
          value={presenter.searchValue}
          Icon={SearchMIcon}
          placeholder="Поиск"
          theme="1"
          dataTest="dealerSearchInput"
          onChange={presenter.onSearchValueChange}
        />

        {menuDealerItems.length > 0 ? (
          <S.DealerMenu items={menuDealerItems} MenuItemComponent={S.SubMenuItem} theme="1" />
        ) : (
          <S.SearchInfo>Дилер с таким названием не найден</S.SearchInfo>
        )}
      </S.DealerGroup>

      <S.Group>
        <S.SubMenuItem
          title="Выйти"
          Icon={LogoutSIcon}
          theme="1"
          dataTest="profileLogoutButton"
          onClick={presenter.onLogoutClick}
        />
      </S.Group>
    </SubPanel>
  );
};

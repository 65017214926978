export const FILE_TYPE_PHOTO = 'tmplt_foto';
export const FILE_TYPE_DKP = 'credit-contract-car';
export const FILE_TYPE_PTS = 'pts';
export const FILE_TYPE_APP = 'tmplt_statement';
export const FILE_TYPE_APP_INTERNAL = 'tmplt_statement_internal';
export const FILE_TYPE_MULTIAPP = 'tmplt_multi_page';
export const FILE_TYPE_APP_SCAN = 'tmplt_statement_scan';
export const FILE_TYPE_MULTIAPP_SCAN = 'tmplt_multi_page_scan';
export const FILE_TYPE_WARRANTY = 'bank-guarant-letter-file';
export const FILE_TYPE_PASSPORT = 'passport';
export const FILE_TYPE_DRIVER_LICENCE = 'driving-licence';
export const FILE_TYPE_ANKETA = 'anketa-scan';
export const FILE_TYPE_PERSONAL_DATA = 'personal-data';
export const FILE_TYPE_CHEK_BUY_KASKO_DEALER = 'chek-buy-kasko-dealer';
export const FILE_TYPE_POLIS_KASKO_DEALER = 'polis-kasko-dealer';
export const FILE_TYPE_POLIS_PPI_DEALER = 'polis-ppi-dealer';
export const FILE_TYPE_INVOICE_OTHER = 'invoice-other';
export const FILE_TYPE_INS_OTHER_BILL = 'ins_other_bill';
export const FILE_TYPE_INVOICE_OSTATOK = 'invoice-ostatok';
export const FILE_TYPE_INVOICE_GAP = 'invoice-gap';
export const FILE_TYPE_INVOICE_KASKO = 'invoice-kasko';
export const FILE_TYPE_INVOICE_SG = 'invoice-sg';
export const FILE_TYPE_POLIS_GAP_DEALER = 'polis-gap-dealer';
export const FILE_TYPE_INS_EXT_GUARANTEE_BILL = 'ins_ext_guarantee_bill';
export const FILE_TYPE_INS_SERVICE_CARD_BILL = 'ins_service_card_bill';
export const FILE_TYPE_JOB_INCOME_DOC = 'job-income-docs';
export const FILE_TYPE_INVOICE_CARD = 'invoice-card';
export const FILE_TYPE_INVOICE_EXT_WARRANTY = 'invoice-ext-warranty';
export const FILE_TYPE_INVOICE_DMS = 'invoice-dms';
export const FILE_TYPE_INVOICE_LEGAL_ASSISTANCE = 'invoice-legal-assistance';
export const FILE_TYPE_INVOICE_TELEMEDICINE = 'invoice-telemedicine';
export const FILE_TYPE_INVOICE_SERVICE = 'invoice-service';
export const FILE_TYPE_INVOICE_TO = 'invoice-to';
export const FILE_TYPE_INVOICE_SURETY = 'invoice-surety';
export const FILE_TYPE_INVOICE_SSS = 'invoice-sss';
export const FILE_TYPE_PAYMENT_FIRST_PROOF = 'payment-first-proof';
export const FILE_TYPE_VEHICLE_REG_FORM = 'vehicle-registration-form';
export const FILE_TYPE_INVOICE_OPTIONAL = 'invoice-optional';
export const FILE_TYPE_ELPTS_SCREENSHOT = 'screenshot-elpts';
export const FILE_TYPE_VEHICLE_REGISTRATION_FORM = 'vehicle-registration-form';
export const FILE_TYPE_POLIS_CARD = 'polis-card';
export const FILE_TYPE_POLIS_EXT_WARRANTY = 'polis-ext-warranty';
export const FILE_TYPE_POLIS_DMS = 'polis-dms';
export const FILE_TYPE_POLIS_LEGAL_ASSISTANCE = 'polis-legal-assistance';
export const FILE_TYPE_POLIS_TELEMEDICINE = 'polis-telemedicine';
export const FILE_TYPE_POLIS_SERVICE = 'polis-service';
export const FILE_TYPE_POLIS_TO = 'polis-to';
export const FILE_TYPE_POLIS_SURETY = 'polis-surety';
export const FILE_TYPE_POLIS_OTHER = 'polis-other';

export const SCROLL_SELECTORS = {
  car: 'car-card-scroll-selector',
  requisites: 'requisites-scroll-selector',
  documents: 'car-documents-scroll-selector',
  pts: 'pts-scroll-selector',
  dkp: 'dkp-scroll-selector',
  policy: 'policy-scroll-selector',
};

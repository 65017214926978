import styled from 'styled-components';

import { Icon } from 'components/uikit/Icon/Icon';
import { Button } from 'components/uikit/Button/Button';

export const Container = styled.div`
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 544px;
  min-height: 544px;
  color: var(--color-black);
`;

export const FinalFormWrapper = styled(FormWrapper)`
  height: 348px;
  min-height: 348px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > p:first-child {
    margin-bottom: 16px;
  }
`;

export const Link = styled.a`
  color: var(--color-blue-3);
  text-decoration: none;

  font: var(--font-regular-15);

  &:hover {
    color: var(--color-blue-3);
  }
`;

export const CheckboxWrapper = styled.div`
  margin-top: ${(p) => (p.hasError ? '4px' : '12px')};
`;

export const CodeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SMSInfoWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > p:first-child {
    margin-bottom: 20px;
  }
`;

export const UpperInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 26px;
`;

export const RegIconWrapper = styled.div`
  margin-bottom: 22px;
`;

export const StyledCheckCircle = styled(Icon)`
  --color: var(--color-green-2);
  --height: 68px;
  --width: 68px;
`;

export const StyledButton = styled(Button).attrs({ size: 'l' })`
  width: 100%;
  margin-top: auto;
`;

export const Title = styled.h2`
  margin-bottom: 12px;

  font: var(--font-heading-large-28);
`;

export const Description = styled.p`
  font: var(--font-regular-15);
`;

export const Error = styled.div`
  width: 100%;
  color: var(--color-red-2);

  font: var(--font-regular-13);
`;

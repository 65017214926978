import styled from 'styled-components';

import State from 'components/primitives/state';
import { Tabs } from 'components/primitives/tabs';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
  border-bottom: 1px solid var(--color-blue-4);
`;

export const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% / 2 - 10px);
  justify-content: space-between;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  border-radius: 5px 5px 0px 0px;
  padding: 11px 10px 12px 10px;
  background-color: var(--color-grey-1);
  border: 1px solid var(--color-grey-1);
  border-bottom-width: 0;

  &.active {
    background-color: var(--color-white);
    margin-bottom: -1px;
    border-color: var(--color-blue-4);
    padding: 11px 10px 12px 10px;
  }
`;

export const TabState = styled(State)`
  max-width: 60%;
`;

export const Content = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
`;

export const ServicesWrapper = styled.div`
  grid-area: ca;
  display: flex;
  margin-bottom: 40px;
`;

export const TabsStyled = styled(Tabs)`
  > div:first-child {
    border-bottom: 1px solid var(--color-blue-4);

    > div {
      border-radius: 5px 5px 0px 0px;
      padding: 11px 10px 12px 10px;
      margin-right: 20px;
      background-color: var(--color-grey-1);
      border: 1px solid var(--color-grey-1);
      border-bottom-width: 0;

      &.active {
        background-color: var(--color-white);
        margin-bottom: -1px;
        border-color: var(--color-blue-4);
        padding: 11px 10px 12px 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  > div:not(:first-child) {
    padding-top: 30px;
  }
`;

export const Loader = styled.div`
  grid-area: loader;
  height: 760px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

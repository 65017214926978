import React from 'react';
import { useMainPagePresenter } from './MainPage.presenter';

import { MainInfoBlock } from './components/MainInfoBlock/MainInfoBlock';
import { EPointsBanner } from 'components/common/EPointsBanner/EPointsBanner';
import { AppVersionReloader } from 'components/common/AppVersionReloader/AppVersionReloader';

import * as S from './MainPage.styles';

export const MainPage = () => {
  useMainPagePresenter();

  return (
    <S.Container>
      <S.MainBlockWraper>
        <EPointsBanner />
        <AppVersionReloader />
        <MainInfoBlock />
      </S.MainBlockWraper>
    </S.Container>
  );
};

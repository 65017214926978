export const useReadonlyInputPresenter = (link, disabled) => {
  const presenter = {
    onHandleClick(event) {
      event.stopPropagation();

      if (!disabled) {
        window.open(link, '_blank', 'noopener noreferrer');
      }
    },
  };

  return presenter;
};

import { useState, useEffect, useRef } from 'react';

export const useTooltipClickPresenter = (targetRef, hideTimeout = 5000) => {
  const [isShow, setIsShow] = useState(false);

  const showTimeoutId = useRef(null);

  const presenter = {
    isShow,

    show() {
      setIsShow(true);
    },

    hide() {
      setIsShow(false);
    },

    clearShowTimeout() {
      if (showTimeoutId.current) {
        clearTimeout(showTimeoutId.current);
        showTimeoutId.current = null;
      }
    },

    clickHandler() {
      if (isShow) {
        return;
      }
      presenter.show();

      showTimeoutId.current = setTimeout(() => {
        presenter.clearShowTimeout();
        presenter.hide();
      }, hideTimeout);
    },
  };

  useEffect(() => {
    const targetElement = targetRef?.current;
    const mouseClickHandler = presenter.clickHandler;

    if (!targetElement) {
      return;
    }
    targetElement.addEventListener('click', mouseClickHandler);

    return () => {
      targetElement.removeEventListener('click', mouseClickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return presenter;
};

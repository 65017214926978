import React from 'react';
import { useEPointsPopupPresenter } from './EPointsPopup.presenter';

import { PopupChildren } from './PopupChildren/PopupChildren';

import * as S from './EPointsPopup.styles';

export const EPointsPopup = () => {
  const presenter = useEPointsPopupPresenter();

  return (
    <>
      {presenter.showEPointsPopup && (
        <S.Container onClose={presenter.onCloseEPointsPopupHandler} isCloseByOverlay={false}>
          <PopupChildren onClose={presenter.onCloseEPointsPopupHandler} />
        </S.Container>
      )}
    </>
  );
};

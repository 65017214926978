import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import { saveDealerId, saveRoleId } from 'data-providers/authProvider';

import Layout from './Layout';
import theme from 'theme';

const LayoutWithTheme = ({ ...props }) => {
  const { dealer: dealerId, role: roleId } = useSelector((state) => state.user);

  useEffect(() => {
    if (dealerId) {
      saveDealerId(dealerId);
    }
  }, [dealerId]);

  useEffect(() => {
    if (roleId) {
      saveRoleId(roleId);
    }
  }, [roleId]);

  return (
    <ThemeProvider theme={theme}>
      <Layout {...props} />
    </ThemeProvider>
  );
};

LayoutWithTheme.propTypes = {};

LayoutWithTheme.defaultProps = {};

export default LayoutWithTheme;

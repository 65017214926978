import styled from 'styled-components';

import { Icon } from 'components/uikit/Icon/Icon';

const themes = {
  1: {
    colorBkg: 'transparent',
    colorBkgHover: 'var(--color-grey-11_60)',
    colorBkgChecked: 'var(--color-black)',
    colorBkgError: 'var(--color-red-2)',
    colorBkgDisabled: 'var(--color-grey-10_10)',
    colorBkgCheckedDisabled: 'var(--color-grey-13_16)',
    colorBorder: 'var(--color-grey-12_30)',
    colorBorderHover: 'var(--color-black)',
    colorBorderChecked: 'transparent',
    colorBorderError: 'var(--color-red-2)',
    colorBorderDisabled: 'var(--color-grey-13_16)',
    colorBorderCheckedDisabled: 'transparent',
    colorIcon: 'var(--color-white)',
  },
};

const sizes = {
  xs: { size: '14px', iconSize: '11px', fontSize: 'var(--font-regular-13)' },
  s: { size: '16px', iconSize: '13px', fontSize: 'var(--font-regular-15)' },
  m: { size: '18px', iconSize: '14px', fontSize: 'var(--font-regular-15)' },
  l: { size: '20px', iconSize: '14px', fontSize: 'var(--font-regular-17)' },
};

export const Checkbox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
  border-radius: 4px;

  border: 1px solid
    ${(p) =>
      p.checked && p.disabled
        ? 'var(--color-border-checked-disabled)'
        : p.disabled
        ? 'var(--color-border-disabled)'
        : p.checked && p.error
        ? 'var(--color-border-checked)'
        : p.error
        ? 'var(--color-border-error)'
        : p.checked
        ? 'var(--color-brder-checked)'
        : 'var(--color-border)'};

  background-color: ${(p) =>
    p.checked && p.disabled
      ? 'var(--color-bkg-checked-disabled)'
      : p.disabled
      ? 'var(--color-bkg-disabled)'
      : p.checked && p.error
      ? 'var(--color-bkg-error)'
      : p.checked
      ? 'var(--color-bkg-checked)'
      : 'var(--color-bkg)'};
`;

export const Container = styled.label`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-hover: ${(p) => (themes[p.theme] || themes['1']).colorBkgHover};
  --color-bkg-checked: ${(p) => (themes[p.theme] || themes['1']).colorBkgChecked};
  --color-bkg-error: ${(p) => (themes[p.theme] || themes['1']).colorBkgError};
  --color-bkg-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgDisabled};
  --color-bkg-checked-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgCheckedDisabled};
  --color-border: ${(p) => (themes[p.theme] || themes['1']).colorBorder};
  --color-border-hover: ${(p) => (themes[p.theme] || themes['1']).colorBorderHover};
  --color-border-checked: ${(p) => (themes[p.theme] || themes['1']).colorBorderChecked};
  --color-border-error: ${(p) => (themes[p.theme] || themes['1']).colorBorderError};
  --color-border-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBorderDisabled};
  --color-border-checked-disabled: ${(p) =>
    (themes[p.theme] || themes['1']).colorBorderCheckedDisabled};
  --icon-color: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --size: ${(p) => (sizes[p.size] || sizes['l']).size};
  --icon-size: ${(p) => (sizes[p.size] || sizes['l']).iconSize};
  --font-size: ${(p) => (sizes[p.size] || sizes['l']).fontSize};

  display: flex;
  align-items: center;
  width: auto;
  padding: 2px;
  gap: 8px;

  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};

  &:hover > ${Checkbox}, &:focus-visible > ${Checkbox} {
    border-color: ${(p) =>
      !p.checked && !p.error && !p.disabled
        ? 'var(--color-border-hover)'
        : p.checked && p.error && !p.disabled
        ? 'var(--color-border-checked)'
        : p.error && !p.disabled
        ? 'var(--color-border-error)'
        : p.checked && p.disabled
        ? 'var(--color-border-checked)'
        : p.disabled
        ? 'var(--color-border-disabled)'
        : 'var(--color-border-checked)'};

    background-color: ${(p) => p.checked && !p.error && !p.disabled && 'var(--color-bkg-hover)'};
  }
`;

export const Box = styled(Icon)`
  --color: var(--icon-color);
  --width: var(--icon-size);
  --height: var(--icon-size);
`;

export const InnerCheckbox = styled.input`
  display: none;
  visibility: hidden;
`;

export const Label = styled.span`
  font: var(--font-size);
`;

export const validBubbleTypes = ['modal', 'target'];

export const defaultModalBubbleData = {
  type: 'modal',
  id: '',
  image: null,
  video: null,
  width: 600,
  height: 0,
  title: '',
  text: ``,
  isVisible: true,
};

export const defaultTargetBubbleData = {
  type: 'target',
  id: '',
  target: '',
  title: '',
  text: ``,
  isVisible: true,
  position: 'right',
};

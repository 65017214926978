import styled from 'styled-components';

import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';
import { TextStyle2 } from 'components/primitives/typography/textStyles/textStyle2';
import { MinusSolidSvg } from 'assets/img';

export const Doc = styled.div`
  display: flex;
  padding: 15px 0;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-bottom: ${(p) => (p.defaultDoc ? '10px' : 0)};
`;

export const Img = styled.div`
  width: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > svg {
    margin-top: -2px;
    width: ${(p) => (p.color ? '10px' : 'auto')};
    height: ${(p) => (p.color ? '10px' : 'auto')};
    fill: ${(p) => p.color || 'var(--color-red-2)'};
  }
`;

export const Title = styled(TextStyle1)`
  margin: 0;
  font-weight: normal;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 40px);

  &:hover {
    text-decoration: underline;
  }
`;

export const SizeFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconMinusStyled = styled(MinusSolidSvg)`
  margin-right: auto;
  width: 15px;
  cursor: pointer;

  path {
    fill: var(--color-grey-4);
  }
`;

export const Comment = styled(TextStyle2)`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  color: ${(p) => p.color || 'var(--color-grey-4)'};
  margin: 0;
  padding-left: 32px;
`;

export const PageList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PageError = styled.div`
  font-size: 10px;
  color: ${(p) => (p.color && 'var(--color-red-2)') || 'var(--color-green-1)'};
  background-color: var(--color-blue-5);
  padding: ${(p) => (p.sysName === 'driving-licence' ? '4px 10px' : '4px 5px')};
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

import * as yup from 'yup';

import { MESS_REQUIRED_FIELD } from 'utils/validationUtils/messages';
import { requiredTest } from 'utils/validationUtils/validators';

import { DEALER_DOP_OTHERS_ID } from './Calculator.constants';

export const productScheme = yup.lazy(() =>
  yup.object({
    serviceId: yup.mixed().when(['type'], {
      is: (type) => type?.name === 'dealer_dop',
      then: yup
        .mixed()
        .test('incorrect_field', 'Данную услугу нельзя добавить в сделку', function () {
          const data = this.from?.[this.from.length - 1]?.value;
          return Number(data.serviceId) !== DEALER_DOP_OTHERS_ID;
        }),
      otherwise: yup.mixed(),
    }),
    polisNumber: yup.mixed().when(['inCredit', 'type'], {
      is: (inCredit, type) => {
        return (
          (inCredit && type?.name !== 'optional_equipment') || type?.name === 'insurance_kasko'
        );
      },
      then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      otherwise: yup.mixed(),
    }),
    invoiceNumber: yup.mixed().when(['inCredit', '$bankSpecificRequiredFields'], {
      is: (inCredit, bankSpecificRequiredFields) => {
        return inCredit && bankSpecificRequiredFields.includes('invoiceNumber');
      },
      then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      otherwise: yup.mixed(),
    }),
    invoiceDate: yup.mixed().when(['inCredit', '$bankSpecificRequiredFields'], {
      is: (inCredit, bankSpecificRequiredFields) => {
        return inCredit && bankSpecificRequiredFields.includes('invoiceDate');
      },
      then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      otherwise: yup.mixed(),
    }),
    expiration: yup.mixed(),
    recipient: yup
      .object({
        id: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, function () {
          const data = this.from?.[this.from.length - 1]?.value;
          return data?.inCredit ? requiredTest(data?.recipient?.id) : true;
        }),
        ndsFlag: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, function () {
          const data = this.from?.[this.from.length - 1]?.value;
          const isNdsRequired = ['dealer_dop', 'optional_equipment'].includes(data?.type?.name);
          return isNdsRequired && data?.inCredit ? requiredTest(data?.recipient?.ndsFlag) : true;
        }),
        ndsAmount: yup
          .mixed()
          .when(
            ['ndsFlag', '$bankSpecificRequiredFields'],
            (ndsFlag, bankSpecificRequiredFields, schema, context) => {
              const data = context.from?.[context.from.length - 1]?.value;
              const productType = data?.type?.name;
              const productsToValidate = ['insurance_gap', 'dealer_dop', 'optional_equipment'];
              if (
                data.inCredit &&
                ndsFlag &&
                productsToValidate.includes(productType) &&
                bankSpecificRequiredFields.includes('ndsAmount')
              ) {
                return schema.test('required', MESS_REQUIRED_FIELD, (value) => requiredTest(value));
              } else {
                return schema;
              }
            }
          ),
      })
      .transform((data) => (data === null ? {} : data)),
  })
);

export const halvaScheme = yup.lazy(() =>
  yup.object({
    expiration: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    polisNumber: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
  })
);

export const productShortScheme = yup.object({
  recipient: yup
    .object({
      ndsAmount: yup
        .mixed()
        .when(
          ['ndsFlag', '$bankSpecificRequiredFields'],
          (ndsFlag, bankSpecificRequiredFields, schema, context) => {
            const data = context.from?.[context.from.length - 1]?.value;
            const productType = data?.type?.name;
            const productsToValidate = ['insurance_gap', 'dealer_dop', 'optional_equipment'];
            if (
              data.inCredit &&
              ndsFlag &&
              productsToValidate.includes(productType) &&
              bankSpecificRequiredFields.includes('ndsAmount')
            ) {
              return schema.test('required', MESS_REQUIRED_FIELD, (value) => requiredTest(value));
            } else {
              return schema;
            }
          }
        ),
    })
    .transform((data) => (data === null ? {} : data)),
});

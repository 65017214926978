import { genderToNum } from './genderHelper';
import * as MapperHelper from './mapperHelper';

// firstName(pin):""
// lastName(pin):""
// middleName(pin):""
const getPersonalInfo = (customer) => ({
  fio: customer.personalInformation
    ? MapperHelper.fioJoin(customer.personalInformation)
    : MapperHelper.fioJoin({
        surname: customer.personalInfo.lastName,
        name: customer.personalInfo.firstName,
        middleName: customer.personalInfo.middleName,
      }),
  gender: genderToNum(
    customer?.personalInformation?.genderSysName || customer?.personalInfo?.gender?.sysName
  ),
  bday: MapperHelper.dateFormmatter(
    customer?.personalInformation?.birthDate || customer?.personalInfo?.birthDate
  ),
});

const getContacts = (customer) => ({
  insurerPhone: customer.contacts.mobilePhone,
  insurerEmail: customer.contacts.email,
});

const getPassport = (customer) => ({
  insurerPassportSerialNum: `${customer.passport.series}${customer.passport.number}`,
  insurerPassportDate: MapperHelper.dateFormmatter(customer.passport.issueDate),
  insurerIssuerCode: customer.passport.issuerCode,
  insurerIssuer: customer.passport.issuer,
  insurerBirthPlace: customer.passport.birthPlace,
});

const getAddress = (customer) => ({
  insurerIndex: customer.registrationAddress.address.indexNumber,
  insurerAddress: customer.registrationAddress.address.fiasValue,
  regionWithType: customer.registrationAddress.address.regionWithType,
});

const getDriverLicense = (customer) => {
  const issueDate = customer?.secondDocument?.driverLicense?.issueDate || '';
  const startDate = customer?.secondDocument?.driverLicense?.startDate || '';

  const seriesNumber = `${customer?.secondDocument?.driverLicense?.series || ''}${
    customer?.secondDocument?.driverLicense?.number
      ? ` ${customer?.secondDocument?.driverLicense?.number}`
      : ''
  }`;
  const prevSeriesNumber = `${customer?.secondDocument?.driverLicense?.previousSeries || ''}${
    customer?.secondDocument?.driverLicense?.previousNumber
      ? ` ${customer?.secondDocument?.driverLicense?.previousNumber}`
      : ''
  }`;

  const licenseSeriaNum = seriesNumber;
  const prevLicenseSeriaNum = seriesNumber === prevSeriesNumber ? '' : prevSeriesNumber;

  const licenseDate = MapperHelper.dateFormmatter(issueDate) || '';
  const prevLicenseDate =
    startDate === issueDate ? '' : MapperHelper.dateFormmatter(startDate) || '';
  const isFirstLicense = !(prevLicenseSeriaNum || prevLicenseDate);

  return {
    licenseSeriaNum,
    licenseDate,
    prevLicenseSeriaNum,
    prevLicenseDate,
    isFirstLicense,
  };
};

/* insurer data

	id,

	fio,
	gender,
	bday,

	insurerPhone,
	insurerEmail,

	insurerPassportSerialNum,
	insurerPassportDate,
	insurerIssuerCode,
	insurerIssuer,

	insurerIndex,
	insurerAddress,

	licenseSeriaNum,
	licenseDate,
	prevLicenseSeriaNum,
	prevLicenseDate,
	isFirstLicense,

	isDriver,
*/
export const backToFrontCustomer = ({ customer, isDriver }) => ({
  id: customer.id,
  ...getPersonalInfo(customer),
  ...getContacts(customer),
  ...getPassport(customer),
  ...getAddress(customer),
  ...getDriverLicense(customer),
  isDriver,
});

/* driver data
	
	id,

	fio,
	gender,
	bday,

	licenseSeriaNum,
	licenseDate,
	prevLicenseSeriaNum,
	prevLicenseDate,
	isFirstLicense,
*/
export const backToFrontDriver = ({ customer }) => ({
  id: customer.id,
  ...getPersonalInfo(customer),
  ...getDriverLicense(customer),
});

// roleSysName one of ['owner', 'insurer', 'driver']
const backToFrontMapper = ({ customers, nodes }) => {
  const mappedData = customers.reduce(
    (acc, customer) => {
      const customerNodes = nodes.filter((node) => String(node.customerId) === String(customer.id));
      const customerRoles = customerNodes
        .filter((node) => node.isActive)
        .map((node) => node.roleSysName);

      if (customerRoles.includes('insurer')) {
        return {
          ...acc,
          insurer: backToFrontCustomer({
            customer,
            isDriver: customerRoles.includes('driver'),
          }),
        };
      } else if (customerRoles.includes('driver')) {
        const drivers = [...acc.drivers];

        if (customerNodes.find((node) => node.roleSysName === 'driver').isActive) {
          drivers.push(backToFrontDriver({ customer }));
        }

        return { ...acc, drivers };
      }

      return acc;
    },
    { insurer: {}, drivers: [] }
  );

  return { ...mappedData.insurer, drivers: mappedData.drivers };
};

export default backToFrontMapper;

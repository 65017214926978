import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import { getCarVinInfo } from 'data-providers/mainPageProvider';
import { checkVinButtonTrack, checkVinInputTrack, onShowMoreTrack } from 'metrika/mainpage';

import * as vinCheckStore from 'store/vinCheck';
import * as helpers from './helpers';

export const useVinCheckPresenter = () => {
  const historyRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const rootDispatch = useDispatch();

  const { id: userId, dealer: dealerId } = useSelector((store) => store.user);
  const { isHistoryOpened, vinHistory, loadedPages, totalItems, fetchHistory, fetchError } =
    useSelector((store) => store.vinCheck);

  // eslint-disable-next-line no-unused-vars
  const [_page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [validateError, setValidateError] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [visibleItemsCount, setVisibleItemsCount] = useState(helpers.visibleStep);

  const { sanitizedValue, isValid, errorMessage } = helpers.setVinValidation(inputValue);

  const debouncedUpdateHeight = useCallback(
    debounce((newHeight) => {
      if (historyRef.current) {
        historyRef.current.style.height = `${newHeight}px`;
      }
    }, 0),
    []
  );

  const presenter = {
    inputValue,
    isHistoryOpened,
    vinHistory,
    historyRef,
    visibleItemsCount,
    validateError,
    fetchError,
    searchError,
    isFetching,
    totalItems,
    fetchHistory,

    get emptyHistoryState() {
      return (
        presenter.totalItems === 0 &&
        !presenter.fetchError &&
        !presenter.searchError &&
        !presenter.isFetching
      );
    },

    get fetchErrorState() {
      return (
        presenter.totalItems === 0 &&
        presenter.fetchError &&
        !presenter.searchError &&
        !presenter.isFetching
      );
    },

    get canShowMore() {
      return (
        presenter.isHistoryOpened &&
        !presenter.emptyHistoryState &&
        !presenter.fetchErrorState &&
        (presenter.visibleItemsCount < presenter.totalItems || presenter.fetchHistory)
      );
    },

    onChangeHandler(event) {
      const { sanitizedValue } = helpers.setVinValidation(event.target.value);
      if (sanitizedValue.length <= helpers.valueLength) {
        setInputValue(sanitizedValue);
        setValidateError(null);
      }
    },

    async onCheckHandler() {
      checkVinButtonTrack();
      if (!inputValue.trim() || !isValid) {
        setValidateError(errorMessage || helpers.validateErrorMessage);
        return;
      }
      setInputValue('');
      setValidateError(null);
      presenter.onSearchErrorCloseHandler();

      if (!isFetching && userId && sanitizedValue && dealerId && !fetchError) {
        setIsFetching(true);
        try {
          const res = await getCarVinInfo(userId, sanitizedValue, dealerId);

          if (res.id && res.vin && res.errorInfo) {
            rootDispatch(vinCheckStore.addNewVinItem(res));

            setSearchError(helpers.getSearchErrorMessage(sanitizedValue));

            if (totalItems === 0) {
              setTimeout(() => {
                // 76px высота блока ошибки с отступом
                presenter.getHistoryHeight(76);
              }, 0);
            }
          } else if (res.id && res.vin && !res.errorInfo) {
            rootDispatch(vinCheckStore.addNewVinItem(res));

            if (totalItems === 0) {
              setTimeout(() => {
                // 152px высота всех заголовков таблицы + первый ее элемент
                presenter.getHistoryHeight(152);
              }, 0);
            }
          }
        } catch (err) {
          console.error(err);
          setValidateError(helpers.fetchErrorMessage);
        } finally {
          setIsFetching(false);
          if (!isHistoryOpened) {
            presenter.showHistory();
          }
        }
      } else if (fetchError) {
        if (!isHistoryOpened) {
          presenter.showHistory();
        }
      }
    },

    onKeyPressHandler(event) {
      if (event.key === 'Enter') {
        presenter.onCheckHandler();
      }
    },

    onBlurHandler() {
      checkVinInputTrack();
      setValidateError(errorMessage);
    },

    getHistoryHeight(heightArg) {
      if (isHistoryOpened) {
        if (historyRef.current) {
          const scrollHeight = historyRef.current.scrollHeight;

          if (heightArg === 0) {
            debouncedUpdateHeight(heightArg);
          } else if (heightArg) {
            debouncedUpdateHeight(scrollHeight - heightArg);
          } else {
            debouncedUpdateHeight(scrollHeight);
          }
        }
      }
    },

    onErrorButtonClickHandler() {
      if (fetchError && userId) {
        rootDispatch(vinCheckStore.resetVinFetchError());
        rootDispatch(vinCheckStore.loadVinHistory({ userId }));
      }
    },

    onSearchErrorCloseHandler() {
      setSearchError(null);
      setTimeout(() => {
        presenter.getHistoryHeight(helpers.searchErrorHight(searchError));
      }, 0);
    },

    showHistory() {
      rootDispatch(vinCheckStore.showVinHistory());
      presenter.getHistoryHeight();
    },

    hideHistory() {
      rootDispatch(vinCheckStore.hideVinHistory());
      presenter.getHistoryHeight(0);
    },

    toggleHistory() {
      if (isHistoryOpened) {
        presenter.hideHistory();
        setSearchError(null);
      } else {
        presenter.showHistory();
      }
    },

    onShowMoreClickHandler() {
      onShowMoreTrack();

      setPage((prevPage) => {
        const nextPage = prevPage + 1;

        if (userId && !loadedPages.includes(nextPage)) {
          rootDispatch(
            vinCheckStore.loadVinHistory({
              userId: userId,
              page: nextPage,
              perPage: helpers.visibleStep,
            })
          );
        }

        return nextPage;
      });

      setVisibleItemsCount((prev) => {
        const newCount = prev + helpers.visibleStep;
        return newCount > totalItems ? totalItems : newCount;
      });

      presenter.getHistoryHeight();
    },
  };

  useEffect(() => {
    if (totalItems > helpers.visibleStep) {
      setVisibleItemsCount(helpers.visibleStep);
    } else {
      setVisibleItemsCount(totalItems);
    }
  }, [totalItems]);

  useEffect(() => {
    if (isHistoryOpened) {
      presenter.getHistoryHeight();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isHistoryOpened,
    vinHistory,
    totalItems,
    visibleItemsCount,
    fetchError,
    searchError,
    debouncedUpdateHeight,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramValue = params.get('vinHistoryOpen');
    const isOpen = helpers.isBooleanTrue(paramValue);

    if (isOpen) {
      presenter.showHistory();
      history.replace('/start');
    } else {
      presenter.hideHistory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return presenter;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ToMain } from './style';
import { ChevronSvg } from 'assets/img';

export const ToMainLink = () => {
  const params = useParams();

  const { application = {} } = useSelector((state) => state.startPage);
  const clientId = params.id || application?.orderProduct?.clientId || application?.client?.id;
  const history = useHistory();

  const openCLickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!application) {
      history.push('/applications');
    }

    const url = `/worksheet/${clientId}/home`;

    return history.push(url);
  };

  return (
    <ToMain onClick={openCLickHandler}>
      Главная
      <ChevronSvg />
    </ToMain>
  );
};

import React from 'react';

import { SortHeadCell } from './components/SortHeadCell';
import * as S from '../../style';

export const Header = ({ sortSettings, onSortChange }) => {
  const sortHandler = (field, direction) => {
    onSortChange(field, direction);
  };

  return (
    <S.HeadRow>
      <SortHeadCell field="nameShort" sortSettings={sortSettings} onSort={sortHandler}>
        Получатель денежных средств
      </SortHeadCell>
      <SortHeadCell field="providerName" sortSettings={sortSettings} onSort={sortHandler}>
        Продукт/Поставщик
      </SortHeadCell>
      <SortHeadCell field="legalInn" sortSettings={sortSettings} onSort={sortHandler}>
        ИНН / КПП
      </SortHeadCell>
      <SortHeadCell field="bankName" sortSettings={sortSettings} onSort={sortHandler}>
        Банк - получатель
      </SortHeadCell>
      <S.HeadCell>БИК Банка</S.HeadCell>
      <S.HeadCell>р/с</S.HeadCell>
      {/*<S.HeadCell>к/с</S.HeadCell>*/}
      <SortHeadCell field="bankAccreditationName" sortSettings={sortSettings} onSort={sortHandler}>
        Аккредитован в банке
      </SortHeadCell>
      <S.HeadCell />
    </S.HeadRow>
  );
};

import { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BUBBLE_MARGIN = 15;
const CLASS_HIGHLIGHT = 'highlight-important';

export const useTargetBubblePresenter = (bubbleRef, data, onClose) => {
  const location = useLocation();
  const [targetElement, setTargetElement] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0, right: 'auto' });

  const presenter = {
    position,

    get isShow() {
      return targetElement && (data.title || data.text);
    },

    calculatePosition() {
      if (!targetElement) {
        return;
      }

      const target = targetElement.getBoundingClientRect();
      const bubble = bubbleRef.current.getBoundingClientRect();

      if (data.position === 'right') {
        const position = {
          top: target.y + target.height / 2,
          left: target.x + target.width + BUBBLE_MARGIN,
          right: 'auto',
        };

        if (position.left + bubble.width >= window.innerWidth - BUBBLE_MARGIN) {
          position.left = 'auto';
          position.right = target.x - BUBBLE_MARGIN;
        }

        setPosition(position);
      }

      if (data.position === 'left') {
        const position = {
          top: target.y + target.height / 2,
          left: target.x - bubble.width - BUBBLE_MARGIN,
          right: 'auto',
        };

        if (position.left + bubble.width >= window.innerWidth) {
          position.left = target.x + target.width + BUBBLE_MARGIN;
        }

        setPosition(position);
      }

      if (data.position === 'bottom') {
        const position = {
          top: target.y + target.height + bubble.height / 2 + BUBBLE_MARGIN,
          left: target.x + target.width / 2 - bubble.width / 2,
          right: 'auto',
        };

        if (position.top + bubble.height >= window.innerHeight - BUBBLE_MARGIN) {
          position.top = target.y - bubble.height - BUBBLE_MARGIN;
        }

        setPosition(position);
      }

      if (data.position === 'top') {
        const position = {
          top: target.y - bubble.height / 2 - BUBBLE_MARGIN,
          left: target.x + target.width / 2 - bubble.width / 2,
          right: 'auto',
        };

        if (position.top < BUBBLE_MARGIN) {
          position.top = target.y + target.height + BUBBLE_MARGIN;
        }

        setPosition(position);
      }
    },

    highlightTarget(isHighlight) {
      if (!targetElement) {
        return;
      }

      if (isHighlight) {
        targetElement.classList.add(CLASS_HIGHLIGHT);
      } else {
        targetElement.classList.remove(CLASS_HIGHLIGHT);
      }
    },

    onClose() {
      presenter.highlightTarget(false);
      onClose();
    },
  };

  useEffect(() => {
    const element = document.querySelector(data.target);
    setTargetElement(element || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (presenter.isShow) {
      presenter.calculatePosition();
      presenter.highlightTarget(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presenter.isShow]);

  return presenter;
};

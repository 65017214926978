import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid var(--color-grey-4);
  border-top: 0;
  overflow: hidden;
  overflow-y: ${(p) => (p.showAll ? 'auto' : 'hidden')};
  max-height: ${(p) => (p.showAll ? '205px' : '800px')};
  background-color: var(--color-white);
  z-index: 100 !important;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
`;

export const SelectButton = styled.button`
  width: 100%;
  border: 0;
  padding: 8px 0;
  padding-left: 13px;
  font-size: 16px;
  text-align: left;
  line-height: 150%;
  background-color: var(--color-white);
  cursor: pointer;
  border: 0;
  text-align: left;
  &:focus,
  &:hover {
    background-color: rgba(65, 131, 196, 0.1);
  }
`;

export const PseudoButton = styled.button`
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

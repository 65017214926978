import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export const Popup = ({
  className = '',
  children = '',
  contentStyle = {},
  hideCloseBtn = false,
  dataTest = 'popup',
  onClose = () => {},
}) => {
  return (
    <S.Wrapper className={className}>
      <S.Content style={contentStyle} data-test={dataTest}>
        {!hideCloseBtn && <S.Close onClick={onClose} data-test={`${dataTest}-close`} />}
        {children}
      </S.Content>
    </S.Wrapper>
  );
};

Popup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func,
};

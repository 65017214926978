import styled from 'styled-components';

import { Paragraph } from 'components/primitives/content';
import { Popup } from 'components/uikit/Popup/Popup';
import { Button } from 'components/uikit/Button/Button';
import { TextInput } from 'components/uikit/Form/TextInput/TextInput';

export const Container = styled(Popup).attrs({ theme: 2 })`
  width: 620px;
`;

export const Title = styled.h1`
  font: var(--font-large-28);
  margin-top: 0;
  margin-bottom: 12px;
`;

export const Text = styled(Paragraph)`
  font: var(--font-regular-15);
  margin-bottom: 12px;
`;

export const Controls = styled.div`
  display: flex;
  gap: 12px;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
`;

export const Input = styled(TextInput).attrs({ theme: '2' })`
  margin-bottom: 24px;
`;

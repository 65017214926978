import styled from 'styled-components';
import { FormGridRowBase } from '../../style';
import { Radio } from 'components/primitives/radio';
import { Checkbox } from 'components/uikit/Form/Checkbox/Checkbox';
import { AnketaFio, AnketaCheckBox, AnketaInputMask, AnketaSelect } from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. fio fio fio fio cp cp .'
    '. tgl tgl tgl . . . .'
    '. fio2 fio2 fio2 fio2 fio2 fio2 .'
    '. ge ge . . . . . '
    '. bd bd . . sg sg . '
    '. mrg mrg ch ch ed ed .'
    '. bo bo bu bu . . .'
    '. ym ym . . . . .';
`;

export const FioUser = styled(AnketaFio)`
  grid-area: fio;
`;

export const CellPhone = styled(AnketaInputMask)`
  grid-area: cp;
`;

export const PrevFioUser = styled(AnketaFio)`
  grid-area: fio2;
`;

export const ChangeFioToogle = styled(Checkbox).attrs({ size: 'xs' })`
  position: relative;
  bottom: ${(p) => (p.otherError ? '5px' : '16px')};
  grid-area: tgl;
`;

export const Birthday = styled(AnketaInputMask)`
  grid-area: bd;
`;

export const MaritalStatus = styled(AnketaSelect)`
  grid-area: mrg;
`;

export const ChangeFioReason = styled(AnketaSelect)`
  grid-area: rsn;
`;

export const ChildrenCount = styled(AnketaInputMask)`
  grid-area: ch;
`;

export const DependedCount = styled(AnketaInputMask)`
  grid-area: dp;
`;

export const YearsMarried = styled(AnketaInputMask)`
  grid-area: ym;
`;

export const CheckBoxOrg = styled(AnketaCheckBox)`
  grid-area: bo;
`;

export const CheckBoxUser = styled(AnketaCheckBox)`
  grid-area: bu;
`;

export const ShowGender = styled.span`
  grid-area: sg;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100.4%;
  text-decoration-line: underline;
  color: #b5b5b5;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: right;
`;

export const Gender = styled(Radio)`
  grid-area: ge;
  margin-bottom: 15px;
`;

export const Education = styled(AnketaSelect)`
  grid-area: ed;
`;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import getStore from 'store/store';
import theme from 'theme';

import App from './App';

const StylesComponent = theme.styles;

ReactDOM.render(
  <Provider store={getStore()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {StylesComponent && <StylesComponent />}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

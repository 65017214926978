export const LOAD_DEAL = 'deal/load-deal';
export const RELOAD_DEAL = 'deal/reload-deal';
export const RELOAD_DEAL_DOCUMENTS = 'deal/reload-deal-documents';
export const SET_DEAL = 'deal/set-deal';
export const RESET_DEAL = 'deal/reset-deal';
export const SET_POLICE = 'deal/set-police';
export const SET_EMAIL = 'deal/set-email';
export const SET_POLICE_ERRORS = 'deal/set-police-errors';
export const SET_CREDIT_FIELD = 'deal/set-credit-field';
export const SET_SERVICES = 'deal/set-services';
export const UPDATE_SERVICE = 'deal/update-service';
export const FETCH_START_DEAL = 'deal/fetch-start-deal';
export const FETCH_STOP_DEAL = 'deal/fetch-stop-deal';
export const SET_ERROR_DEAL = 'deal/set-error-deal';
export const ADD_FILE_DEAL = 'deal/add-file-deal';
export const ADD_FILES_DEAL = 'deal/add-files-deal';
export const DEL_FILE_DEAL = 'deal/delete-file-deal';
export const CHANGE_DEAL_ID = 'deal/change-deal-id';
export const RECALCUATION_FINISHED = 'deal/recalculation-finished';
export const APPROVAL_DEAL = 'deal/approval';
export const DECLINE_DEAL = 'deal/decline';
export const CLOSE_DEAL = 'deal/close';
export const REQUEST_DOCUMENT_FINANCED = 'deal/request-document-financed';
export const DEAL_RECALCULATION_DESIRED = 'deal/recalculation-desired';
export const RESET_DEAL_TO_NEW = 'deal/reset-to-new';
export const APPROVE_RECALCULATION = 'deal/recalculation-approve';
export const SAVE_RECALCULATION = 'deal/recalculation-save';
export const SET_DEAL_ERRORS = 'deal/set-errors';
export const SAVE_FINAL_PARAMS = 'deal/save-final-params';

export const CHANGE_PERSONAL_DATA = 'deal/change-personal-data';

export const LOAD_DEAL_SHORT = 'deal/load-short-deal';
export const SET_DEAL_SHORT = 'deal/set-short-deal';
export const RESET_DEAL_SHORT = 'deal/reset-short-deal';
export const FETCH_START_DEAL_SHORT = 'deal/fetch-start-short-deal';
export const FETCH_STOP_DEAL_SHORT = 'deal/fetch-stop-short-deal';
export const SET_ERROR_DEAL_SHORT = 'deal/set-error-short-deal';

export const SAVE_LOCAL_DEAL = 'deal/save-local';
export const RESTORE_LOCAL_DEAL = 'deal/restore-local';

export const TOGGLE_CAMERA_POPUP = 'deal/toggle-camera-popup';
export const TOGGLE_PHOTO_POPUP = 'deal/toggle-photo-popup';
export const SET_TIMERS = 'deal/set-timers';
export const SHOW_VIN_RECOGNITION_POPUP = 'deal/show-vin-recognition-popup';
export const HIDE_VIN_RECOGNITION_POPUP = 'deal/hide-vin-recognition-popup';
export const SET_VIN_RECOGNIZING = 'deal/set-vin-recognizing';
export const SET_CAR_EXTRA_FIELD = 'deal/set-cat-extra-field';

import React, { useMemo, useRef } from 'react';
import { getTextWidth } from 'utils/getTextWidth';

import * as PositionHelper from 'utils/documentPosition';
import * as Helper from './helper';
import * as S from './style';

const fn = () => {};

const Balloon = ({
  componentRef,
  position = 'top', // [top, bottom, left, right]
  indent = 0,
  isShow = false,
  text = '', // string or Array
  maxWidth = 500,
  padding = 10,
  font = 'normal 12px auto', // string or Array, as text
  onMouseOver = fn,
  ...rest
}) => {
  const ref = useRef();
  const textWidth = useMemo(() => {
    if (typeof text === 'string') {
      return getTextWidth(text, font);
    }

    return text
      .map((segment, index) => getTextWidth(segment, font[index]))
      .reduce((acc, width) => acc + width, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const currentText =
    typeof text === 'string' ? (
      <S.Segment font={font}>{text}</S.Segment>
    ) : (
      text.map((segment, index) => {
        return (
          <S.Segment key={(segment[0] || 's') + index} font={font[index]}>
            {segment}
          </S.Segment>
        );
      })
    );

  const { height = 0 } = PositionHelper.getNodePosition(ref.current) || {};
  const { width: componentWidth = 0, height: componentHeight = 0 } =
    PositionHelper.getNodePosition(componentRef.current) || {};

  if (!isShow || !text || !text?.length || !componentRef?.current || !ref?.current) {
    const currentPosition = Helper.getPosition(
      position,
      componentWidth,
      componentHeight,
      textWidth,
      maxWidth,
      height,
      indent,
      padding
    );

    return (
      <S.Tooltip ref={ref} padding={padding} {...currentPosition}>
        {currentText}
      </S.Tooltip>
    );
  }

  const currentPosition = Helper.getPosition(
    position,
    componentWidth,
    componentHeight,
    textWidth,
    maxWidth,
    height,
    indent,
    padding
  );

  return (
    <S.Tooltip
      ref={ref}
      show={true}
      padding={padding}
      {...currentPosition}
      onMouseOver={onMouseOver}
      {...rest}
    >
      {currentText}
    </S.Tooltip>
  );
};

export default Balloon;

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFilter, filterTouch } from 'store/filters';
import { isEqualArrays } from 'utils/common';
import { dealerFilterTrack } from 'metrika/applications';

import * as help from '../../helpers';
import * as S from '../../style';

export const Dealer = ({ isVisible = true }) => {
  const rootDispatch = useDispatch();
  const userDealer = useSelector((store) => store.user?.dealer);
  const storeDealersFilter = useSelector((store) => store.applicFilters?.dealer || []);
  const filtersFetch = useSelector((store) => store.applicFilters.fetch);

  const [isChanged, setIsChanged] = useState(false);

  const userDealers = useMemo(
    () => help.getDealers(storeDealersFilter, userDealer),
    [storeDealersFilter, userDealer]
  );

  const changeHandler = (event) => {
    const ids = event['dealer'] || [];
    if (isEqualArrays(ids, help.getSelectedIds(userDealers))) {
      return;
    }

    setIsChanged(true);

    const newDealers = help.setItemsSelection(storeDealersFilter, ids);
    rootDispatch(setFilter({ filter: 'dealer', value: newDealers }));
    rootDispatch(filterTouch());
    dealerFilterTrack();
  };

  useEffect(() => {
    if (!filtersFetch) {
      setIsChanged(false);
    }
  }, [filtersFetch]);

  if (!isVisible && !isChanged) {
    return null;
  }

  return (
    <S.Dealer
      name="dealer"
      label="Дилер"
      list={userDealers}
      defaultValue={help.getSelectedIds(userDealers)}
      multiselect={true}
      search={true}
      checked={true}
      checkedAll={true}
      fetch={!filtersFetch}
      onBlur={changeHandler}
      dataTest="filterSelectDealer"
    />
  );
};

import { useEffect, useState, useRef } from 'react';

import { getAppVersion } from 'data-providers/versionAppProvider';

const CHECK_TIMEOUT = 1000 * 60 * 2;

export const useAppVersionReloaderPresenter = () => {
  const version = useRef(null);
  const [isShowSoftUpdate, setIsShowSoftUpdate] = useState(false);

  const presenter = {
    isShowSoftUpdate,

    parseVersion(versionStr) {
      if (!versionStr) {
        return null;
      }

      const versionData = versionStr
        .trim()
        .split('.')
        .map((v) => (isNaN(Number(v)) ? null : Number(v)))
        .filter((v) => v !== null);

      return versionData.length < 2 ? null : versionData;
    },

    onRealod() {
      window.location.reload();
    },

    async onCheckVersion() {
      const newVersionData = await getAppVersion();
      const newVersion = presenter.parseVersion(newVersionData?.version || '');
      const oldVersion = version.current;

      if (!newVersion) {
        return;
      }
      if (oldVersion && oldVersion[0] === newVersion[0] && oldVersion[1] === newVersion[1]) {
        return;
      }

      version.current = newVersion;
      if (!oldVersion) {
        return;
      }

      if (oldVersion[0] < newVersion[0]) {
        presenter.onRealod();
        return;
      }

      if (oldVersion[1] < newVersion[1]) {
        setIsShowSoftUpdate(true);
        return;
      }
    },
  };

  useEffect(() => {
    presenter.onCheckVersion();
    const intervalId = setInterval(presenter.onCheckVersion, CHECK_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return presenter;
};

import styled from 'styled-components';
import { Button } from 'components/uikit/Button/Button';
import { Popup } from 'components/uikit/Popup/Popup';
import { Icon } from 'components/uikit/Icon/Icon';

export const StyledPopup = styled(Popup)`
  height: 332px;
  box-shadow: none;
  padding: 24px;
`;

export const UpperInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 26px;
`;

export const RegIconWrapper = styled.div`
  margin-bottom: 22px;
`;

export const StyledCheckCircle = styled(Icon)`
  --color: var(--color-green-2);
  --height: 68px;
  --width: 68px;
`;

export const Title = styled.h2`
  margin-bottom: 12px;

  font: var(--font-heading-large-28);
`;

export const StyledButton = styled(Button).attrs({ size: 'l' })`
  width: 100%;
  margin-top: auto;
`;

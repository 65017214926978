import styled from 'styled-components';

export const Text = styled.p`
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-black);
`;

import React, { useState, useEffect } from 'react';

import { SearchSvg } from 'assets/img';
import { CloseCrossSvg } from 'assets/img';

import * as S from './style';

export const Search = ({
  className,
  val = '',
  name,
  mainPageSearch,
  onSearch = () => {},
  withClearButton = true,
  ...rest
}) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
    // eslint-disable react-hooks/exhaustive-deps
  }, [val]);

  const keyPressHandler = ({ key }) => {
    if (key === 'Enter') {
      onSearch(value);
    }
  };

  const clickHandler = () => {
    onSearch(value);
  };

  const clearHandler = () => {
    setValue('');
  };

  const changeHandler = (event) => {
    setValue(name ? event.name : event);
  };

  return (
    <S.SearchContainer className={className}>
      <S.Input
        val={value}
        name={name}
        onChange={changeHandler}
        onKeyPress={keyPressHandler}
        noSanitaze={true}
        mainPageSearch={mainPageSearch}
        {...rest}
      />
      {withClearButton && (
        <S.CloseButton
          data-test="searchInput-clear"
          mainPageSearch={mainPageSearch}
          onClick={clearHandler}
        >
          <CloseCrossSvg />
        </S.CloseButton>
      )}
      <S.Button
        mainPageSearch={mainPageSearch}
        onClick={() => {
          clearHandler();
          clickHandler();
        }}
        data-test="searchInput-search"
      >
        <SearchSvg />
      </S.Button>
    </S.SearchContainer>
  );
};

import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { getPdfBlob } from 'components/modules/Calculations/components/Calculator/components/Banks/components/Detail/helper';
import { certificateClickTrack } from 'metrika/applications';
import NewFeatureTooltip from 'components/common/NewFeatureTooltip';

import { DealItem } from './DealItem';
import { CREDIT_STATUS_PARAMS } from 'constants/index';
import { downloadDocument, getCertificate } from '../../helpers';

import { CERTIFICATE_BLOCK_BANKS } from './constants';
import {
  DealStatusWrapper,
  StatusElementWraper,
  PrintIco,
  GridWraper,
  StatusWraper,
} from './style';
import { DEALS_STATUS } from './constants';
import SendButton from './SendButton';

const loader = <ClipLoader size={15} color={'#b5b5b5'} loading={true} />;
const SendButtonWithNewFeatureTooltip = NewFeatureTooltip(SendButton, 'left');

export function DealsStatusList({
  status,
  dealsList = [],
  color,
  applicationBody,
  carInstance,
  isShowSendButton = false,
  pickUpStatus = {},
}) {
  const [load, setLoad] = useState(false);
  const [printHiden, setPrintHiden] = useState(false);
  const { fontColor, secondColor } = CREDIT_STATUS_PARAMS[color];
  const isApproved = status === DEALS_STATUS.APPROVED || status === DEALS_STATUS.FUNDED;

  useEffect(() => {
    if (isApproved && dealsList.length && dealsList.length <= CERTIFICATE_BLOCK_BANKS.length) {
      const checkOtherBank = dealsList.some(
        (deal) => !CERTIFICATE_BLOCK_BANKS.includes(deal.bank.id)
      );
      if (!checkOtherBank) {
        setPrintHiden(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsList]);

  if (dealsList?.length === 0) {
    return null;
  }

  const getCertificatePdf = async () => {
    setLoad(true);
    const pdf = await getCertificate(dealsList, carInstance);
    if (!pdf) {
      setLoad(false);
      return;
    }

    const url = window.URL.createObjectURL(getPdfBlob(pdf));
    try {
      window.open(url, '_blank').focus();
    } catch {
      console.log('Всплывающие окна заблокированны');
      downloadDocument(url);
    }

    certificateClickTrack();
    setLoad(false);
  };

  return (
    <GridWraper>
      <DealStatusWrapper color={color} secondColor={secondColor}>
        <StatusWraper color={color} fontColor={fontColor} secondColor={secondColor}>
          {status}
        </StatusWraper>
        {isApproved &&
          (load ? (
            loader
          ) : (
            <StatusElementWraper onClick={getCertificatePdf} printHiden={printHiden}>
              Распечатать <PrintIco />
            </StatusElementWraper>
          ))}
        {status === DEALS_STATUS.OPENED && isShowSendButton && (
          <SendButtonWithNewFeatureTooltip dealsList={dealsList} pickUpStatus={pickUpStatus} />
        )}
      </DealStatusWrapper>

      {dealsList?.map((deal) => (
        <DealItem
          key={deal.id}
          deal={deal}
          carInstance={carInstance}
          applicationBody={applicationBody}
          color={color}
          secondColor={secondColor}
        />
      ))}
    </GridWraper>
  );
}

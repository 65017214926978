import React from 'react';

import { MainMenu } from 'components/modules/MainMenu/MainMenu';

import LayoutWithTheme from './LayoutWithTheme';

const PageLayout = (props) => {
  return <LayoutWithTheme appBar={null} sidebar={MainMenu} {...props} />;
};

export default PageLayout;

import styled from 'styled-components';

import { Icon } from 'components/uikit/Icon/Icon';
import { TooltipHover } from 'components/uikit/Tooltip/TooltipHover/TooltipHover';

export const Cell = styled.div`
  position: relative;
  padding: 4px 12px 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-black);

  font: var(--font-regular-15);
`;

export const StyledVinNumberCell = styled.div`
  flex: 0 0 260px;
  min-width: 260px;
  padding: 4px 12px 4px 0;
  color: var(--color-black);

  font: var(--font-regular-15);
`;

export const StyledModelNameCell = styled(Cell)`
  flex: 1 0 100px;
  min-width: 100px;
  max-width: 400px;
`;

export const StyledModelPriceCell = styled(Cell)`
  flex: 1;
  min-width: 80px;
  white-space: ${(p) => (p.price ? 'nowrap' : 'wrap')};
`;

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledTooltip = styled(TooltipHover)`
  max-width: 250px;
`;

export const StyledTitle = styled.span`
  white-space: pre-wrap;
`;

export const StyledIcon = styled(Icon).attrs({ theme: '5' })`
  cursor: pointer;
`;

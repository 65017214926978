import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import HttpError from 'utils/errors/HttpError';
import {
  removeExtraSpaces,
  firstCharUpper,
  findCarfinIdByCpsId,
  onlyDecimal,
} from 'utils/stringUtils';
import { COLOR, CREDIT_STATUS_PARAMS, restrictedRoles } from 'constants/index';
import { convertPriceToString } from 'utils/convertPrice';
import { handleCommentary } from 'utils/handleCommentary';
import { getInfoByStatus, getStatusListByParams } from 'utils/getStatusInfo';

const { REACT_APP_API_ENTRYPOINT } = process.env;

const getBanksByState = (bankListByState, stateList) => {
  const ret = [];
  stateList.forEach((state) => {
    if (Array.isArray(bankListByState[state])) {
      Array.prototype.push.apply(ret, bankListByState[state]);
    }
  });

  return ret;
};

export const canOpen = (dealer, user) => {
  return dealer && user && !restrictedRoles.includes(Number(user?.role));
};

export const getCustomerName = (customer) => {
  const { name = '', surname = '', middleName = '' } = customer || {};
  return firstCharUpper(removeExtraSpaces(`${surname} ${name} ${middleName}`).toLowerCase());
};

export const getCarName = (carInstance, fullName = false) => {
  const { brandName = '', modelName = '' } = carInstance || {};
  if (fullName) {
    return firstCharUpper(removeExtraSpaces(`${brandName} ${modelName}`).toLowerCase());
  }
  return {
    brand: firstCharUpper(removeExtraSpaces(`${brandName}`).toLowerCase()),
    model: firstCharUpper(removeExtraSpaces(` ${modelName}`).toLowerCase()),
  };
};

export const isShowViewChecker = (i) => i > 0 && i % 17 === 0;

export const getViewCheckerPage = (i) => Math.floor(i / 17) + 1;

export const convertValueToStandartNumber = (value) => {
  return (typeof value === 'number') & !isNaN(value) & (value >= 0)
    ? convertPriceToString(Math.round(value))
    : '—';
};

export const checkStatus = (status) => {
  const statusInfo = getInfoByStatus(status);
  switch (statusInfo.color) {
    case COLOR.green:
      return 'Заявка одобрена';
    case COLOR.blue:
      return 'Предоставить дополнительные документы';
    case COLOR.yellow:
      return 'Заявка отправлена в банк';
    case COLOR.red:
      if (
        statusInfo.params.statusName === 'customer-decline-after' ||
        statusInfo.params.statusName === 'customer-decline-before'
      ) {
        return 'Клиент отказался';
      }
      return 'Отказано';
    case COLOR.white:
      return 'Вероятность одобрения клиента выше на 25%, при рассмотрении заявки всеми банками';
    default:
      return 'Статус неизвестен';
  }
};

export const compareObj = (itemParams, apprData) => {
  if (checkApproveObj(apprData) && itemParams) {
    const result = {};
    const { period, initial_fee_money, price, interest_rate, dealer_dop_sum } = itemParams.data;
    const { term, down_payment, credit_sum, rate } = apprData;
    const ratesValue = interest_rate.split('-').map((rate) => rate.trim());

    result.boldPrice = Math.round(price) > Math.round(credit_sum);
    result.boldInitialMoney = Math.round(initial_fee_money) !== Math.round(down_payment);
    result.boldPeriod = period !== term;
    result.boldDealerSum = Math.round(dealer_dop_sum) !== Math.round(apprData.dealer_dop_sum);
    if (ratesValue.length === 1) {
      result.boldRate = rate !== Number(onlyDecimal(ratesValue[0]));
    } else {
      result.boldRate =
        rate < Number(onlyDecimal(ratesValue[0])) || rate > Number(onlyDecimal(ratesValue[1]));
    }
    return result;
  }

  return {
    boldPrice: false,
    boldInitialMoney: false,
    boldPeriod: false,
    boldRate: false,
    boldDealerSum: false,
  };
};

export const getCertificate = async (dealsList = [], carInstance) => {
  const carName = getCarName(carInstance, true);
  const certificates = dealsList
    .sort(
      (a, b) =>
        a.AdditionalData?.approvData?.data.length - b.AdditionalData?.approvData?.data.length
    )
    .map((deal) => {
      const bankId = findCarfinIdByCpsId(deal.bank.id);
      const loanProgram = deal.product.name;
      const dealApproved = deal.AdditionalData?.approvData?.data[0] || {};
      const approveComment = dealApproved.comment ? handleCommentary(dealApproved.comment) : '';

      if (checkApproveObj(dealApproved)) {
        const dealerDetails = convertObjectValuesToStandartNumber(dealApproved.dealer_details);
        const bankDetails = convertObjectValuesToStandartNumber(dealApproved.bank_details);
        const bankDetailNames = {};

        if (bankDetails && dealApproved.services) {
          Object.keys(bankDetails).forEach((productKey) => {
            const name = dealApproved.services.find((s) => s.product === productKey)?.tariff;
            if (name) {
              bankDetailNames[productKey] = name;
            }
          });
        }

        return {
          car: carName || '—',
          price: `${convertValueToStandartNumber(carInstance?.offerPrice)} ₽`,
          pv: `${convertValueToStandartNumber(dealApproved.down_payment)} ₽`,
          loan_sum: `${convertValueToStandartNumber(dealApproved.credit_sum)} ₽`,
          loan_term: `${convertValueToStandartNumber(dealApproved.term)} мес.`,
          loan_rate: `${dealApproved.rate}%` || '—',
          monthly_payment: `${convertValueToStandartNumber(dealApproved.monthly)} ₽`,
          dealer_services: convertValueToStandartNumber(dealApproved.dealer_dop_sum),
          bank_services: convertValueToStandartNumber(dealApproved.bank_dop_sum),
          residual_payment:
            convertValueToStandartNumber(dealApproved.residual_payment) === '—'
              ? 0
              : convertValueToStandartNumber(dealApproved.residual_payment),
          max_limit:
            convertValueToStandartNumber(dealApproved.max_limit) === '0'
              ? 0
              : convertValueToStandartNumber(dealApproved.max_limit),
          bank_id: bankId || '—',
          bank_name: deal?.bank.name || '—',
          loan_program_name: loanProgram || '—',
          bank_details: bankDetails || '—',
          dealer_details: dealerDetails || '—',
          bank_detail_names: Object.keys(bankDetailNames).length > 0 ? bankDetailNames : '—',
          bank_comment: approveComment,
        };
      } else {
        return {
          bank_id: bankId || '—',
          bank_name: deal.bank.name || '—',
          comment:
            approveComment ||
            handleCommentary(deal.AdditionalData?.statusData?.comment).replaceAll('<br>', ' ') ||
            'Заявка одобрена',
        };
      }
    });

  try {
    const res = await request
      .post(`${REACT_APP_API_ENTRYPOINT}/api/pdf/certificate-approval`, certificates, {
        withCredentials: true,
      })
      .catch(throwCustomError);

    if (res.status !== 201) {
      throw new HttpError(`status ${res.status}`);
    }
    return res?.data?.file || null;
  } catch (err) {
    console.error(err.message);
  }
};

export const downloadDocument = (url) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  const docName = downloadLink.href.split('/');
  downloadLink.setAttribute('download', docName[docName.length - 1]);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getOpenedBanks = (banks) =>
  getBanksByState(banks, getStatusListByParams(COLOR.white, 'baseStatus'));
export const getRequestedBanks = (banks) =>
  getBanksByState(banks, getStatusListByParams(COLOR.blue, 'baseStatus'));
export const getQueueBanks = (banks) =>
  getBanksByState(banks, getStatusListByParams(COLOR.yellow, 'baseStatus'));
export const getDeclinedBanks = (banks) =>
  getBanksByState(banks, getStatusListByParams(COLOR.red, 'baseStatus'));
export const getApprovedBanks = (banks) =>
  getBanksByState(banks, getStatusListByParams(COLOR.green, 'baseStatus'));
export const getFundedBanks = (banks) =>
  getBanksByState(banks, getStatusListByParams(COLOR.green, 'fundedStatus'));

export const convertObjectValuesToStandartNumber = (obj = {}) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = convertValueToStandartNumber(obj[key]);
    return acc;
  }, {});
};

export const checkApproveObj = (apprData = {}) => {
  const checkList = ['credit_sum', 'down_payment', 'monthly', 'max_limit'];
  for (let key in apprData) {
    if (apprData[key] && checkList.includes(key)) {
      return true;
    }
  }
  return false;
};

export const getCreditApps = (app = {}) =>
  (app?.orders || []).filter((o) => o?.productId === 'credit');

export const getInsuranceApps = (app = {}) =>
  (app?.orders || []).filter((o) => o?.productId === 'insurance');

export const getAssistanceApps = (app = {}) =>
  (app?.orders || []).filter((o) => o?.productId === 'assistance');

export const applicationSource = (source = '') => {
  switch (source) {
    case 'ecredit':
      return 'еКредит';
    case 'autohub':
      return 'Автохаб';
    default: // no default
  }
};

export const isShowCarYear = (carInstance, deal = null) => {
  const isUsed = carInstance ? !carInstance.isNew : false;

  if (!isUsed || !deal) {
    return isUsed;
  }

  if (
    !CREDIT_STATUS_PARAMS['var(--color-green-2)']?.status.some(
      (status) => status.statusName === deal?.state?.state
    )
  ) {
    return false;
  }

  return true;
};

export const isFinalParamsState = (state) => {
  return ['client-invited-deal', 'financed', 'closed', 'awaiting_manual_final_params'].includes(
    state
  );
};

export const isApplicationInTheFinalStatus = (state) => {
  return ['financed', 'closed', 'archive', 'archive-financed'].includes(state);
};

import React from 'react';

import { ASSISTANCE_WORKFLOW_STATES } from 'constants/assistance';
import { AssistanceIcon } from 'assets/img';

import { ProductLampItem } from '../../../ProductLampItem';

const visibleStates = [
  ASSISTANCE_WORKFLOW_STATES.offers_requested,
  ASSISTANCE_WORKFLOW_STATES.offers_received,
  ASSISTANCE_WORKFLOW_STATES.offer_selected,
  ASSISTANCE_WORKFLOW_STATES.offers_selected,
  ASSISTANCE_WORKFLOW_STATES.agreement_requested,
  ASSISTANCE_WORKFLOW_STATES.agreement_succeed,
  ASSISTANCE_WORKFLOW_STATES.issue_requested,
  ASSISTANCE_WORKFLOW_STATES.issued,
  ASSISTANCE_WORKFLOW_STATES.offers_error,
  ASSISTANCE_WORKFLOW_STATES.issue_error,
  ASSISTANCE_WORKFLOW_STATES.client_decline,
  ASSISTANCE_WORKFLOW_STATES.cancelation_requested,
  ASSISTANCE_WORKFLOW_STATES.canceled,
  ASSISTANCE_WORKFLOW_STATES.cancelation_error,
  ASSISTANCE_WORKFLOW_STATES.waiting_signing_code,
];

export const AssistanceLampItem = ({ product = {}, source }) => {
  const { applicationState = {}, selectedOffer = {}, policy = {} } = product;

  const viewOffers = [];
  switch (applicationState.sysName) {
    case ASSISTANCE_WORKFLOW_STATES.offer_selected:
    case ASSISTANCE_WORKFLOW_STATES.offers_selected:
    case ASSISTANCE_WORKFLOW_STATES.agreement_requested:
      viewOffers.push({
        type: 'Карта',
        companyName: selectedOffer.insuranceCompanyName || '',
        programName: selectedOffer.title || '',
      });
      break;
    case ASSISTANCE_WORKFLOW_STATES.agreement_succeed:
    case ASSISTANCE_WORKFLOW_STATES.issue_requested:
    case ASSISTANCE_WORKFLOW_STATES.issued:
      viewOffers.push({
        type: 'Карта',
        companyName: policy.insuranceCompanyName || '',
        programName: policy.title || '',
      });
      break;
    default: // no default
  }
  if (source) {
    viewOffers.push({
      type: 'Источник заявки',
      companyName: source,
    });
  }

  return (
    <ProductLampItem
      state={applicationState.sysName}
      title={applicationState.value}
      color={applicationState.color}
      fontColor={applicationState.fontColor}
      secondColor={applicationState.secondColor}
      Icon={AssistanceIcon}
      offers={viewOffers}
      visibleStates={visibleStates}
    />
  );
};

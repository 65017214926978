import styled from 'styled-components';

export const Text = styled.h4`
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
`;

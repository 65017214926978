import { Badge } from 'components/uikit/Badge/Badge';
import styled from 'styled-components';

export const BadgeStyled = styled(Badge).attrs({
  size: 'l',
  isRound: false,
})`
  --color-bkg: ${(p) => p.color};
  --color-text: ${(p) => p.fontColor};
`;

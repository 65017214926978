import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const mainMenuExpandTrack = () => isProd && ym('reachGoal', 'expand');

export const mainMenuCollapseTrack = () => isProd && ym('reachGoal', 'collapse');

export const mainMenuOpenDetailsTrack = () => isProd && ym('reachGoal', 'details');

export const mainMenuOpenCalculationTrack = () => isProd && ym('reachGoal', 'calculation');

export const mainMenuOpenListTrack = () => isProd && ym('reachGoal', 'list');

export const mainMenuOpenReportsTrack = () => isProd && ym('reachGoal', 'reports');

export const mainMenuOpenProfileTrack = () => isProd && ym('reachGoal', 'user_and_dealer');

export const mainMenuChooseRoleTrack = () => isProd && ym('reachGoal', 'choose user');

export const mainMenuChoseDealerTrack = () => isProd && ym('reachGoal', 'choose dealer');

import React, { useEffect, useState } from 'react';

import PulseLoader from 'react-spinners/PulseLoader';
import WhatsapLink from 'components/common/WhatsapLink';
import { ClipboardCopy } from 'components/common/ClipboardCopy/ClipboardCopy';
import { phoneClickTrack } from 'metrika/applications';
import { getShortFio } from 'utils/stringUtils';

import DetailItemCell from './DetailItemCell';
import { DetailItemRow, DetailMopWraper, DetailPhoneWraper } from './style';

const loader = <PulseLoader size={10} color={'#b5b5b5'} loading={true} />;

const DetailItem = ({ application, showOpenButton }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { id: appId, customer, orders = [] } = application;
  const credit = orders.find((o) => o.productId === 'credit');
  const mopName = application.mop ? getShortFio(application.mop, false) : '';
  const id = credit?.body?.applicationId || appId;

  useEffect(() => {
    if (id) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [id]);

  const phoneHandler = () => {
    phoneClickTrack();
  };

  return (
    <DetailItemRow showOpenButton={showOpenButton}>
      {isLoading ? (
        loader
      ) : (
        <DetailItemCell
          align={'end'}
          value={
            <ClipboardCopy value={id} dataTest={'detailItemRow-copy'}>{`ID: ${id}`}</ClipboardCopy>
          }
        />
      )}

      <DetailMopWraper>
        <DetailItemCell align={'center'} txtCap={true} value={mopName ? `МОП: ${mopName}` : '—'} />
      </DetailMopWraper>

      <DetailPhoneWraper>
        <DetailItemCell>
          <WhatsapLink
            phone={customer?.mobilePhone}
            onClick={phoneHandler}
            dataTest={'detailItemRow-phoneNunber'}
          />
        </DetailItemCell>
      </DetailPhoneWraper>
    </DetailItemRow>
  );
};

export default DetailItem;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 32px;
  margin-top: 20px;
  border-bottom: 1px solid var(--color-grey-1);
  gap: 2px;
`;

export const FirstCell = styled.div`
  flex: 0 0 260px;
  min-width: 260px;
  padding: 4px 12px 12px 0;
  color: var(--color-grey-11_60);

  font: var(--font-regular-13);
`;

export const FlexCell = styled.div`
  flex: 1 0 100px;
  min-width: 100px;
  max-width: 400px;
  padding: 4px 12px 12px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-grey-11_60);

  font: var(--font-regular-13);
`;

export const Cell = styled.div`
  flex: 1;
  min-width: 80px;
  padding: 4px 12px 12px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-grey-11_60);

  font: var(--font-regular-13);
`;

export const LastCell = styled.div`
  flex: 0 0 142px;
  min-width: 142px;
  padding: 0;
`;

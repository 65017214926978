import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import * as actions from './constants';
import * as provider from 'data-providers/assistanceCalculationProvider';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { assistanceSetDisabled } from 'store/assistanceAnketa';
import { disableCarInstance } from 'store/carInstance';
import { createASWRequestParams } from 'data-providers/helper';
import { ASW_NAMEPLATES, ASW_STATES, fetchNames } from './resources';
import {
  aswCalcSetApplication,
  aswCalcSetDealerLegals,
  aswCalcSetFetch,
  aswCalcSetMainFetch,
  aswCalcSetPopupData,
  aswCalcUpdApplication,
} from '.';

const fn = () => {};

function* selectOffer({ payload: { selectedOfferData, successCallback = fn } }) {
  try {
    yield put(aswCalcSetFetch(selectedOfferData.applicationId, fetchNames.selectOffer, true));

    const { result, applicationId } = yield call(provider.selectOfferRequest, selectedOfferData);

    yield put(
      aswCalcSetApplication({
        applicationId,
        applicationData: result,
      })
    );

    successCallback();
  } catch (err) {
    if (err?.errorTraefikData) {
      yield put(aswCalcSetPopupData(selectedOfferData.applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.ASSISTANCE_CALC_SET_ERROR,
        payload: {
          key: 'selectOffer',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(aswCalcSetFetch(selectedOfferData.applicationId, fetchNames.selectOffer, false));
  }
}

function* getAgreementData({ payload: { agreementRequestData } }) {
  try {
    yield put(aswCalcSetFetch(agreementRequestData.applicationId, fetchNames.agreement, true));

    // для блокирования анкеты и каринстанса до перехода в agreement_succeed
    yield put(
      aswCalcUpdApplication({
        applicationId: agreementRequestData.applicationId,
        newData: {
          currentWorkflowState: ASW_STATES.agreement_requested,
        },
      })
    );

    const { result, applicationId } = yield call(provider.agreementRequest, agreementRequestData);

    yield put(
      aswCalcSetApplication({
        applicationId,
        applicationData: result,
      })
    );
  } catch (err) {
    yield put(
      aswCalcUpdApplication({
        applicationId: agreementRequestData.applicationId,
        newData: {
          currentWorkflowState: ASW_STATES.offer_selected,
        },
      })
    );

    if (err?.errorTraefikData) {
      yield put(aswCalcSetPopupData(agreementRequestData.applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.ASSISTANCE_CALC_SET_ERROR,
        payload: {
          key: 'agreementDraft',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(aswCalcSetFetch(agreementRequestData.applicationId, fetchNames.agreement, false));
  }
}

function* getPolicy({ payload: { getPolicyRequestData } }) {
  try {
    yield put(aswCalcSetFetch(getPolicyRequestData.applicationId, fetchNames.getPolicy, true));

    const { result, applicationId } = yield call(provider.getPolicyRequest, getPolicyRequestData);

    yield put(
      aswCalcSetApplication({
        applicationId,
        applicationData: result,
      })
    );

    yield put(assistanceSetDisabled());
    yield put(disableCarInstance());
  } catch (err) {
    if (err?.errorTraefikData) {
      yield put(aswCalcSetPopupData(getPolicyRequestData.applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.ASSISTANCE_CALC_SET_ERROR,
        payload: {
          key: 'policyQuery',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(aswCalcSetFetch(getPolicyRequestData.applicationId, fetchNames.getPolicy, false));
  }
}

function* clientDecline({ payload: { applicationId, callback = fn } }) {
  try {
    yield put(aswCalcSetFetch(applicationId, fetchNames.clientDecline, true));

    const {
      result: { state },
      applicationId: responseAppId,
    } = yield provider.aswClientDeclineRequest({ applicationId });

    yield put(
      aswCalcUpdApplication({
        applicationId: responseAppId,
        newData: { state, currentWorkflowState: state.sysName },
      })
    );
  } catch (err) {
    if (err?.errorTraefikData) {
      yield put(aswCalcSetPopupData(applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.ASSISTANCE_CALC_SET_ERROR,
        payload: {
          key: 'clientDecline',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(aswCalcSetFetch(applicationId, fetchNames.clientDecline, false));
    callback();
  }
}

function* cancellation({ payload: { applicationId, successCallback, errorCallback } }) {
  try {
    yield provider.aswCancellationRequest(applicationId);
    successCallback && successCallback();
  } catch (err) {
    console.log('CANCELLATION ERROR ***', err);
    errorCallback && errorCallback();
  }
}

function* createNewAssistanceOrder({ payload }) {
  try {
    yield put(aswCalcSetMainFetch(fetchNames.calculate, true));

    const {
      data: { applicationId },
    } = yield provider.aswAddNewOrder(payload);

    const applications = yield select((store) => store.assistanceCalculation.applications);
    const isLegalEntity = Object.values(applications ?? {}).some(
      (application) => application.applicationParams?.isLegalEntity
    );

    yield provider.aswCalculateRequest(
      createASWRequestParams(applicationId, {
        policyStartDate: new Date().toISOString(),
        isLegalEntity,
      })
    );

    const applicationData = yield provider.getApplicationDataRequest(applicationId);

    yield put(
      aswCalcSetApplication({
        applicationId: applicationData.id,
        applicationData,
      })
    );
  } catch (err) {
    console.error('createNewAssistanceOrder ERROR:\r\n', err);
    yield put({
      type: actions.ASSISTANCE_CALC_SET_ERROR,
      payload: {
        key: 'calulationList',
        value: err.customDescription || DEFAULT_ERROR_MESSAGE,
      },
    });
  } finally {
    yield put(aswCalcSetMainFetch(fetchNames.calculate, false));
  }
}

function* refreshCalc({ payload: { dataForCalculate } }) {
  try {
    yield put(aswCalcSetFetch(dataForCalculate.applicationId, fetchNames.calculate, true));
    yield put(
      aswCalcUpdApplication({
        applicationId: dataForCalculate.applicationId,
        newData: { currentWorkflowState: ASW_STATES.offers_requested },
      })
    );

    const {
      data: { applicationId, result },
    } = yield provider.aswCalculateRequest(dataForCalculate);

    yield put(
      aswCalcUpdApplication({
        applicationId,
        newData: {
          offers: result,
          selectedOffer: null,
          files: [],
          state: ASW_NAMEPLATES.offers_requested,
        },
      })
    );

    yield put(aswCalcSetPopupData(dataForCalculate.applicationId, null));
  } catch (err) {
    console.error(`calculation Error: ${err}`);
    if (err?.errorTraefikData) {
      yield put(aswCalcSetPopupData(dataForCalculate.applicationId, err.errorTraefikData));
    } else {
      yield put({
        type: actions.ASSISTANCE_CALC_SET_ERROR,
        payload: {
          key: 'refreshCalc',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(aswCalcSetFetch(dataForCalculate.applicationId, fetchNames.calculate, false));
  }
}

function* refreshCalculationsByAppsIdsList({
  payload: { applicationsIdsList, callback, isLegalEntity },
}) {
  try {
    const policyStartDate = new Date().toISOString();
    yield all(
      applicationsIdsList.map((applicationId) =>
        call(
          provider.aswCalculateRequest,
          createASWRequestParams(applicationId, { policyStartDate, isLegalEntity })
        )
      )
    );

    callback();
  } catch (err) {
    console.error(`refreshCalculationsByAppsIdsList Error: ${err}`);
    yield put({
      type: actions.ASSISTANCE_CALC_SET_ERROR,
      payload: {
        key: 'refreshCalcByAppsIdsList',
        value: err?.errorTraefikData?.message || err?.customDescription || DEFAULT_ERROR_MESSAGE,
      },
    });
  }
}

function* addASWFile({ payload: { applicationId, fileId, fileType, ext } }) {
  try {
    const file = yield provider.addFileASWRequest(applicationId, fileId, fileType, ext);
    const targetApplicationFiles = yield select(
      (store) => store.assistanceCalculation.applications?.[applicationId]?.files || null
    );

    if (file && targetApplicationFiles) {
      const updatedFiles = [...targetApplicationFiles, { ...file }];
      yield put(aswCalcUpdApplication({ applicationId, newData: { files: updatedFiles } }));
    }
  } catch (err) {
    console.error(`addFileASWRequest Error: ${err}`);
  }
}

function* delASWFile({ payload: { applicationId, fileId } }) {
  try {
    yield provider.delFileASWRequest(fileId);

    const targetApplicationFiles = yield select(
      (store) => store.assistanceCalculation.applications?.[applicationId]?.files || null
    );

    if (targetApplicationFiles) {
      const updatedFiles = targetApplicationFiles.filter((file) => file.id !== fileId);
      yield put(aswCalcUpdApplication({ applicationId, newData: { files: updatedFiles } }));
    }
  } catch (err) {
    console.error(`delFileASWRequest Error: ${err}`);
  }
}

function* updateDealerLegals({ payload: { dealerId } }) {
  try {
    const { dealerLegals = [] } = yield provider.getDealerLegalsRequest(dealerId);
    const mappedLegals = dealerLegals.map((el) => ({ ...el, value: el.name }));
    yield put(aswCalcSetDealerLegals(dealerId, mappedLegals));
  } catch (err) {
    console.error(`updateDealerLegals Error: ${err}`);
  }
}

export function* watchAswSelectOffer() {
  yield takeLatest(actions.ASW_CALC_SELECT_OFFER_REQUEST, selectOffer);
}

export function* watchAswAgreement() {
  yield takeLatest(actions.ASW_CALC_AGREEMENT_REQUEST, getAgreementData);
}

export function* watchAswGetPolicy() {
  yield takeLatest(actions.ASW_CALC_GET_POLICY_REQUEST, getPolicy);
}

export function* watchAswClientDecline() {
  yield takeLatest(actions.ASW_CALC_CLIENT_DECLINE_REQUEST, clientDecline);
}

export function* watchAswRefreshCalc() {
  yield takeLatest(actions.ASW_CALC_REFRESH_CALC_REQUEST, refreshCalc);
}

export function* watchAswRefreshCalculationsByOrders() {
  yield takeLatest(
    actions.ASW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS,
    refreshCalculationsByAppsIdsList
  );
}

export function* watchAswCancellation() {
  yield takeLatest(actions.ASW_CALC_CANCELLATION_REQUEST, cancellation);
}

export function* watchAswAddFile() {
  yield takeLatest(actions.ASW_CALC_ADD_FILE, addASWFile);
}

export function* watchAswDelFile() {
  yield takeLatest(actions.ASW_CALC_DEL_FILE, delASWFile);
}

export function* watchAswAddNewAssistanceOrder() {
  yield takeLatest(actions.ASW_CALC_ADD_ASW_ORDER_REQUEST, createNewAssistanceOrder);
}

export function* watchAswUpdateDealerLegals() {
  yield takeLatest(actions.ASW_CALC_UPD_DEALER_LEGALS, updateDealerLegals);
}

import styled from 'styled-components';

export const Text = styled.h1`
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--color-black);
`;

import React from 'react';

import { EPointsSMSVerification } from 'components/common/EPointsSMSVerification/EPointsSMSVerification';

import { convertTelforInput } from 'utils/convertTelephone';

import * as S from '../PopupChildren.styles';

export const SMSConfirm = ({ presenter }) => {
  return (
    <S.FormWrapper>
      <S.Title>Регистрация в программе лояльности</S.Title>
      <S.SMSInfoWrapper>
        <S.Description>
          Введите 6-значный код. Отправили его повторно
          <br />
          на номер {convertTelforInput(presenter.fullAnketa?.phoneNumber)}
        </S.Description>
        <S.CodeInputWrapper>
          <EPointsSMSVerification onHandleOpened={presenter.onHandleOpened} />
        </S.CodeInputWrapper>
      </S.SMSInfoWrapper>
    </S.FormWrapper>
  );
};

import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import DriversBubbles from '../../../DriversBubbles';
import { CheckBox } from 'components/primitives/check-box';

export const Wrapper = styled.div`
  border: 2px solid var(--color-blue-4);
  width: 100%;
  min-height: 100px;
  position: relative;
  padding: 30px 0;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
`;

export const BubblesWrapper = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  margin-bottom: 22px;
`;

export const Bubbles = styled(DriversBubbles)`
  grid-column: 2 / -2;
`;

export const CheckBoxStyled = styled(CheckBox)`
  margin: 0;
  margin-bottom: 20px;
  font-size: 15px;
`;

export const MultidriveNotification = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #a8a8a8;

  width: 70%;
  margin: 0 auto;
`;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as vinCheckStore from 'store/vinCheck';

export const useMainPagePresenter = () => {
  const rootDispatch = useDispatch();

  const { id: userId } = useSelector((store) => store.user);

  useEffect(() => {
    if (userId) {
      rootDispatch(vinCheckStore.loadVinHistory({ userId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
};

import styled from 'styled-components';

export const ParamsCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 0 0 46%;
  box-sizing: border-box;
  min-height: 60px;
`;
export const ParamsCellLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: var(--color-grey-5);
  white-space: nowrap;
`;
export const ParamsCellValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-grey-7);
  white-space: nowrap;
`;
export const ParamsCellRequested = styled(ParamsCellLabel)`
  font-weight: 500;
  padding-right: 4px;
  white-space: nowrap;
`;

export const RequestedWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

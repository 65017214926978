import React from 'react';
import { usePopupChildrenPresenter } from './PopupChildren.presenter';

import { RegistrationStart } from './RegistrationStart/RegistrationStart';
import { SMSConfirm } from './SMSConfirm/SMSConfirm';
import { RegistrationFinal } from './RegistrationFinal/RegistrationFinal';

import * as S from './PopupChildren.styles';

const fn = () => {};

export const PopupChildren = ({ onClose = fn }) => {
  const presenter = usePopupChildrenPresenter(onClose);

  return (
    <S.Container>
      {presenter.popupFrames.registrationStart && <RegistrationStart presenter={presenter} />}
      {presenter.popupFrames.smsConfirm && <SMSConfirm presenter={presenter} />}
      {presenter.popupFrames.registrationFinal && (
        <RegistrationFinal onClose={presenter.onHandleClosed} />
      )}
    </S.Container>
  );
};

import styled, { css } from 'styled-components';
import CloseButtonPrimitive from 'components/primitives/close-button';

export const Wrapper = styled.div``;

export const CloseButton = styled(CloseButtonPrimitive)`
  position: absolute;
  top: 15px;
  right: 9px;
  z-index: 1000;
  cursor: pointer;
`;

export const BlankWidthSpace = styled.div`
  width: 20px;
`;

export const SubmitButton = styled.button`
  width: 208px;
  height: 60px;
  background-color: var(--color-blue-3);
  color: var(--color-white);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
    `}
  &:hover,
	&:focus {
    filter: brightness(1.1);
    ${(props) =>
      props.disabled &&
      css`
        filter: brightness(1);
      `}
  }
`;

export const SubmitButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

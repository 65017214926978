import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import { APPLICATIONS_URL, DEALER_URL, POST_CAR_INFO, CHECK_PTS_STATUS_URL } from 'constants/url';

export const getCarInstance = async ({ id }) => {
  try {
    const url = `${APPLICATIONS_URL}/${id}/car-instance`;

    const { data } = await request
      .get(url, {
        headers: {
          'content-type': 'application/ld+json',
          'accept': 'application/ld+json',
        },
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('UPDATE_CAR_INSTANCE IN PROVIDER ***', err);
    throw err;
  }
};

export const updateCarInstance = async ({ id, carInstance }) => {
  try {
    const url = `${APPLICATIONS_URL}/${id}/car-instance`;

    const { data } = await request
      .patch(url, carInstance, {
        headers: {
          'content-type': 'application/ld+json',
          'accept': 'application/ld+json',
        },
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('UPDATE_CAR_INSTANCE IN PROVIDER ***', err);
    throw err;
  }
};

export const getBlankCarInstance = async (dealerId) => {
  try {
    const url = `${DEALER_URL}/${dealerId}/car-instance/blank`;

    const { data } = await request
      .get(url, {
        headers: {
          'content-type': 'application/ld+json',
          'accept': 'application/ld+json',
        },
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('UPDATE_CAR_INSTANCE IN PROVIDER ***', err);
    throw err;
  }
};

export const updateCarInstanceCustomer = async ({ id, carInstance }) => {
  try {
    const t = Date.now();
    const url = `api/car-instance/${carInstance.id ?? id}?t=${t}`;
    if (carInstance.engineVolume || carInstance.engineVolume === 0) {
      carInstance.engineVolume = `${carInstance.engineVolume}`;
    }

    const { data } = await request
      .patch(url, carInstance, {
        headers: {
          'content-type': 'application/ld+json',
          'accept': 'application/ld+json',
        },
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('UPDATE_CAR_INSTANCE IN PROVIDER ***', err);
    throw err;
  }
};

export const getCarInstanceById = async (id) => {
  try {
    const t = Date.now();
    const url = `/api/car-instance/${id}?t=${t}`;

    const { data } = await request
      .get(url, {
        headers: {
          'content-type': 'application/ld+json',
          'accept': 'application/ld+json',
        },
      })
      .catch(throwCustomError);

    if (!data || (Array.isArray(data) && !data.length)) {
      return null;
    }

    return data;
  } catch (err) {
    console.log('get car instance by user error ***', err);
    throw err;
  }
};

export const getCarInfo = async (carNumber) => {
  const { data } = await request
    .post(
      POST_CAR_INFO,
      { searchType: 0, carNumber },
      {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      }
    )
    .catch(throwCustomError);

  return data;
};

export const checkPtsStatus = async (eptsNumber) => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${CHECK_PTS_STATUS_URL}`;
    const res = await request
      .post(
        url,
        { eptsNumber },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);

    return res?.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';

export const GridStyled = styled(Grid)`
  margin: 15px;
  grid-template-areas:
    'bn bn bn bn bn bn bn bn bn bn bn bn'
    'lp lp ct ct ct ct ct ct ct ct rp rp'
    'lp lp ct ct ct ct ct ct ct ct rp rp'
    '. . ct ct ct ct ct ct ct ct . .';
`;

export const Sticky = styled.div`
  margin-top: 15px;
  width: ${({ awidth }) => awidth && `${awidth}px;`};
  z-index: 99;
`;

export const StickyRight = styled.div`
  z-index: 1;
  width: ${({ awidth }) => awidth && `${awidth}px;`};
  margin-top: 15px;
`;

export const LeftPanel = styled.div`
  position: sticky;
  top: 0;
  grid-area: lp;
  margin-top: -15px;
`;

export const RightPanel = styled.div`
  position: sticky;
  top: 0;
  grid-area: rp;
  margin-top: -15px;
`;

export const ContentWrapper = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  grid-area: ct;
`;

export const Wrapper = styled.div`
  margin-bottom: 15px;
`;

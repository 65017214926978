import styled, { css } from 'styled-components';

export const Tooltip = styled.div`
  position: absolute;
  z-index: 1001;
  top: ${(props) => props.top}px;
  ${(props) => (props.right ? `right: ${props.right}` : `left: ${props.left}`)}px;
  width: ${(props) => props.width}px;
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  overflow: hidden;
  padding: ${(props) => props.padding}px;
  border-radius: 5px;
  border: 1px solid #a8a8a8;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  line-height: 15px;
  background: #fff;
  color: #000000;
  ${(props) =>
    !props.height &&
    css`
      border-color: transparent;
      background-color: transparent;
      color: transparent;
    `}
  ${(props) =>
    !props.show &&
    css`
      position: fixed;
      left: -9999px;
    `}
	&:after {
    content: '';
    display: block;
    border-style: solid;
    position: absolute;
    margin: 0;
    left: 50%;
    bottom: -14px;
    border-width: 7px;
    border-right-color: transparent;
    border-top-color: #a8a8a8;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`;

export const Segment = styled.span`
  font: ${(props) => props.font};
`;

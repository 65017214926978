import styled from 'styled-components';

import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';

const themes = {
  1: {
    colorBkg: 'var(--color-orange-2)',
    colorText: 'var(--color-black)',
    colorIcon: 'var(--color-orange-1)',
    colorCloseIcon: 'var(--color-grey-11_60)',
  },
  2: {
    colorBkg: 'var(--color-green-4)',
    colorText: 'var(--color-black)',
    colorIcon: 'var(--color-green-2)',
    colorCloseIcon: 'var(--color-grey-11_60)',
  },
};

const sizes = {
  m: {
    fontRowTitle: 'var(--font-heading-large-20_1)',
    fontColumnTitle: 'var(--font-large-17)',
    fontText: 'var(--font-regular-15)',
    iconSize: '20px',
    closeIconSize: '16px',
  },
};

export const Container = styled.div`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --color-icon: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-close-icon: ${(p) => (themes[p.theme] || themes['1']).colorCloseIcon};
  --font-row-title: ${(p) => (sizes[p.size] || sizes['m']).fontRowTitle};
  --font-column-title: ${(p) => (sizes[p.size] || sizes['m']).fontColumnTitle};
  --font-text: ${(p) => (sizes[p.size] || sizes['m']).fontText};
  --icon-size: ${(p) => (sizes[p.size] || sizes['m']).iconSize};
  --close-icon-size: ${(p) => (sizes[p.size] || sizes['m']).closeIconSize};

  position: relative;
  width: ${(p) => (p.direction === 'row' ? '100%' : '328px')};
  height: auto;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--color-bkg);
  color: var(--color-text);
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RowTitle = styled.div`
  white-space: pre-line;

  font: var(--font-row-title);
`;

export const RowCloseCrossWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 4px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const ColumnTextWrapper = styled(RowTextWrapper)`
  gap: 4px;
`;

export const ColumnTitle = styled(RowTitle)`
  font: var(--font-column-title);
`;

export const ColumnCloseCrossWrapper = styled(RowCloseCrossWrapper)`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 8px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

export const Text = styled(RowTitle)`
  font: var(--font-text);
`;

export const AttentionIcon = styled(Icon)`
  --color: var(--color-icon);
  --width: var(--icon-size);
  --height: var(--icon-size);
`;

export const CloseCross = styled(Icon)`
  --color: var(--color-close-icon);

  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  width: max-content;
`;

import React from 'react';

import { useAppVersionReloaderPresenter } from './AppVersionReloader.presenter';
import { UpdateMIcon } from 'assets/icons';
import * as S from './AppVersionReloader.style';

export const AppVersionReloader = () => {
  const presenter = useAppVersionReloaderPresenter();

  if (!presenter.isShowSoftUpdate) {
    return null;
  }

  return (
    <S.Container>
      <S.ImageBox>
        <S.StyledIcon Icon={UpdateMIcon} />
      </S.ImageBox>
      <S.TextWrap>
        <S.Title>Вышло обновление</S.Title>
        <S.Text>Для загрузки обновления сохраните изменения и обновите страницу</S.Text>
      </S.TextWrap>

      <S.ReloadButton onClick={presenter.onRealod}>Обновить</S.ReloadButton>
    </S.Container>
  );
};

import styled, { css } from 'styled-components';

import { Grid } from 'components/primitives/grid';
import { H1 } from 'components/primitives/typography/headings/h1';
import { H2 } from 'components/primitives/typography/headings/h2';
import CarDetail from 'components/modules/Calculations/components/CarDetail';
import CarHeadDetail from 'components/modules/Calculations/components/CarDetail/CarHeadDetail';
import { CollapsePanel } from 'components/primitives/collapse-panel';
import { Loader as LoaderPrimitive } from 'components/primitives/loader';

export const GridStyled = styled(Grid)`
  position: relative;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    'cl cl cl cl cl cl cl cl'
    'ms ms ms sm sm . . .'
    '. . . . . . . .'
    '. . . . . . . .'
    'ca ca ca ca ca ca ca ca'
    'er er er er er er er er';
`;

export const CollapsePanelStyled = styled(CollapsePanel)`
  grid-area: cl;
  margin-bottom: 40px;

  & > div:nth-child(2) > div:first-child,
  & > div:nth-child(2) > div:first-child > div {
    flex: 1;
    min-height: 60px;
  }

  ${(p) =>
    p.isHeaderHidden &&
    css`
      & > div:nth-child(2) {
        visibility: hidden;
      }
    `}
`;

export const Header = styled(H1)`
  text-align: center;
  margin: 0px;
  height: 59px;
  line-height: 59px;
`;

export const SelectedCar = styled.div`
  display: flex;
  align-items: start;
  flex-wrap: nowrap;
`;

export const CarHeader = styled(H1)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const BrandModelCar = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #000000;
  margin: 0px 10px 10px 0px;
`;

export const Version = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100.4%;
  color: var(--color-grey-4);
  margin: 0px 10px 10px 0px;
`;

export const Cost = styled(H2)`
  margin: 0px;
  font-size: 20px;
`;

export const CarDetailStyled = styled(CarDetail)`
  margin-top: 40px;
`;

export const SelectedCarMain = styled.div`
  width: auto;
  max-width: calc(100% - 320px);
`;

export const SelectedCarDetail = styled(CarHeadDetail)`
  margin-left: 20px;
  width: 300px;
`;

export const CarImg = styled.img`
  width: auto;
  height: 350px;
`;

export const CarImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 100%;
  height: 400px;
`;

export const Error = styled.div`
  grid-area: er;
  text-align: center;
  font-size: 24px;
  margin-top: 150px;
`;

export const HideButton = styled.button`
  grid-column: -2 / -1;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  background-color: white;
  border: 0;
  color: #a8a8a8;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.5s ease;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  margin: 0;
`;

export const HideButtonTop = styled.div`
  grid-column: -2 / -1;
  height: 15px;
  ${(props) =>
    props.indent &&
    css`
      height: ${props.indent};
    `}
`;

export const HideButtonBottom = styled.div`
  grid-column: -2 / -1;
  height: 30px;
`;

export const Loader = styled(LoaderPrimitive)`
  height: 400px;
`;

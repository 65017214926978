import React from 'react';

import * as S from './EmptyState.styles';

const fn = () => {};

export const EmptyState = ({ type, onClick = fn }) => {
  return (
    <S.Container>
      {type === 'emptyHistory' && (
        <>
          <S.ImgWrapper>
            <S.NoFoundImg />
          </S.ImgWrapper>
          <S.Title>Здесь будет история проверок VIN</S.Title>
        </>
      )}

      {type === 'fetchError' && (
        <>
          <S.ImgWrapper>
            <S.FetchErrorImg />
          </S.ImgWrapper>
          <S.Title>Не удалось загрузить историю проверок VIN</S.Title>
          <S.StyledButton theme={'3'} onClick={onClick}>
            Обновить
          </S.StyledButton>
        </>
      )}
    </S.Container>
  );
};

import styled from 'styled-components';

import { Icon } from 'components/uikit/Icon/Icon';

const themes = {
  1: {
    colorBkg: 'var(--color-red-2)',
    colorBkgDisabled: 'var(--color-red-2)',
    colorBorder: 'transparent',
    colorBorderDisabled: 'transparent',
    colorText: 'var(--color-white)',
    colorTextDisabled: 'var(--color-grey-11_60)',
    colorIcon: 'var(--color-white)',
    colorIconDisabled: 'var(--color-grey-11_60)',
  },
};

const sizes = {
  s: {
    height: '16px',
    iconSize: '12px',
    font: 'var(--font-regular-11)',
    roundRadius: '8px',
    squareRadius: '4px',
    padding: '2px 6px',
  },
  m: {
    height: '20px',
    iconSize: '14px',
    font: 'var(--font-regular-11)',
    roundRadius: '12px',
    squareRadius: '6px',
    padding: '4px 8px',
  },
  l: {
    height: '24px',
    iconSize: '16px',
    font: 'var(--font-regular-13)',
    roundRadius: '12px',
    squareRadius: '8px',
    padding: '4px 8px',
  },
  xl: {
    height: '32px',
    iconSize: '18px',
    font: 'var(--font-regular-13)',
    roundRadius: '16px',
    squareRadius: '10px',
    padding: '8px 10px',
  },
};

export const Container = styled.span`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgDisabled};
  --color-border: ${(p) => (themes[p.theme] || themes['1']).colorBorder};
  --color-border-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBorderDisabled};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --color-text-disabled: ${(p) => (themes[p.theme] || themes['1']).colorTextDisabled};
  --color-icon: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-icon-disabled: ${(p) => (themes[p.theme] || themes['1']).colorIconDisabled};
  --icon-size: ${(p) => (sizes[p.size] || sizes['m']).iconSize};
  --badge-height: ${(p) => (sizes[p.size] || sizes['s']).height};
  --badge-padding: ${(p) => (sizes[p.size] || sizes['s']).padding};
  --badge-font: ${(p) => (sizes[p.size] || sizes['s']).font};
  --badge-radius: ${(p) =>
    p.round
      ? (sizes[p.size] || sizes['s']).roundRadius
      : (sizes[p.size] || sizes['s']).squareRadius};

  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: var(--badge-height);
  padding: var(--badge-padding);
  border-radius: var(--badge-radius);
  border: 1px solid var(--color-border);
  background-color: var(--color-bkg);
  color: var(--color-text);
  gap: 2px;

  font: var(--badge-font);
  cursor: auto;

  &:disabled {
    border-color: var(--color-border-disabled);
    background-color: var(--color-bkg-disabled);
    color: var(--color-text-disabled);
  }
`;

export const StyledIcon = styled(Icon)`
  --color: ${(p) => (p.disabled ? 'var(--color-icon-disabled)' : 'var(--color-icon)')};
  --width: var(--icon-size);
  --height: var(--icon-size);
`;

export const Title = styled.span`
  white-space: nowrap;
`;

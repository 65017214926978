import styled from 'styled-components';

export const WrapperStyled = styled.div`
  position: ${(p) => p.position || 'absolute'};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  z-index: var(--zindex-modal);
`;

export const BackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black);
  opacity: 0.3;
`;

export const WrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const ContentStyled = styled.div`
  display: ${(p) => (p.position === 'fixed' ? 'flex' : 'block')};
  height: 100vh;
  overflow: auto;
`;

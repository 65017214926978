import React from 'react';

import * as S from './Icon.styles';

export const Icon = (props) => {
  const { className, Icon, theme = '1', size = 'm', dataTest = '' } = props;

  return (
    <S.Container className={className} theme={theme} size={size} data-test={dataTest}>
      <Icon />
    </S.Container>
  );
};

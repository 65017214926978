import styled, { css } from 'styled-components';

import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';
import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  width: 100%;
  height: 60px;
  border: 1px solid ${(p) => (p.required ? 'var(--color-red-2)' : 'var(--color-grey-4)')};
  position: relative;
  padding: 14px 0;
  cursor: pointer;
  min-width: 80px;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: var(--color-grey-1);
    `}

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    & input {
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
      color: #000000;
      ${(p) => (p.disabled ? `background-color: var(--color-grey-1);` : '')}
      padding: 0 10px;
    }
    ${(props) =>
      !props.focused &&
      css`
        font-size: 0;
        height: 0;
        & input {
          font-size: 0;
          height: 0;
        }
      `}
  }

  .react-datepicker {
    border: 1px solid var(--color-grey-4);
    border-radius: 0;
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__month-container {
    padding: 14px;
  }

  .react-datepicker-popper {
    left: -1px;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 0;

    .react-datepicker__triangle {
      display: none;
    }
  }

  .react-datepicker__navigation {
    top: 18px;
  }

  .react-datepicker__header {
    background-color: var(--color-white);
    border-width: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-family: var(--main-font-family);
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .react-datepicker__current-month {
    margin-top: 3px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-family: var(--main-font-family);
    font-weight: normal;
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000;
    border: 1px solid var(--color-grey-1);
    width: 32px;
    height: 32px;
    margin: 15px 0 0 0;
    line-height: 30px;
  }

  .react-datepicker__day--outside-month {
    color: #a3a6b4;
  }

  .react-datepicker__day,
  .react-datepicker__day--keyboard-selected {
    font-family: var(--main-font-family);
    font-weight: normal;
    font-style: normal;
    border: 1px solid var(--color-grey-1);
    border-radius: 0;
    padding: 9px 7px;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    width: 32px;
    background-color: white;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #deeefa;
  }

  .react-datepicker__day--today {
    border-radius: 0;
    background-color: #deeefa;
  }

  .react-datepicker__day--selected {
    border-radius: 0;
    background-color: var(--color-blue-4);
    color: var(--color-black);
  }

  .react-datepicker__day--selected:hover {
    background-color: var(--color-blue-4);
  }

  .react-datepicker__day--disabled {
    color: rgba(0, 0, 0, 0.3);
    cursor: default;
    background-color: white;
  }

  .react-datepicker__day--disabled:hover {
    background-color: white;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  user-select: none;
  border-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(p) => (p.disabled ? 'transparent' : 'transparent')};

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  ${(p) =>
    p.label &&
    css`
      top: 20px;
      height: 20px;
    `}
`;

export const Label = styled(TextStyle1)`
  padding: 0 10px;
  margin: 0;
  margin-bottom: 5px;
  color: ${(p) => (p.error ? 'var(--color-red-2)' : 'var(--color-grey-4)')};

  ${(p) =>
    p.focused &&
    css`
      left: 9px;
      top: 3px;
      font-size: 12px;
      line-height: 14px;
    `}
`;

export const Prompt = styled(TextStyle3)`
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  margin: 0;
  color: var(--color-red-2);
`;

export const CalendarWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
`;

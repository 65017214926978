import request from 'utils/request';
import HttpError from 'utils/errors/HttpError';
import throwCustomError from 'utils/throwCustomError';

import * as urls from 'constants/url';

export const getCarVinInfo = async (frontUserId, vin, dealerId) => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.POST_CAR_VIN_INFO}`;

    const res = await request
      .post(
        url,
        { frontUserId, vin, dealerId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);

    if (res.status !== 202) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getVinHistory = async (userId, params) => {
  try {
    let url = `${process.env.REACT_APP_TRAEFIK_URL}${urls.GET_VIN_HISTORY(
      userId,
      params.page,
      params.perPage
    )}`;

    const res = await request
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

import * as localStorage from 'utils/local-storage';
import { sortArrayByChecked, sortArrayBy } from 'utils/common';
import {
  SET_FILTERS,
  DROP_FILTER,
  LOAD_FILTERS,
  RELOAD_FILTERS,
  FILTERS_CHECKED_BY_NAME,
  FILTERS_DISABLED_BY_NAME,
  ALL_FILTERS_LOADED,
  SET_SEARCH,
  SELECT_ALL_DEALERS,
  DROP_FILTERS,
  SET_SORTING,
  RESET_SORTING,
  SET_FILTER_TOUCH,
  SET_FILTER_UNTOUCH,
  SET_FRONT_PROXY,
  SET_FILTER_SEARCH,
} from './constants';

const initState = {
  dealer: [],
  mop: [],
  ks: [],
  lastState: [],
  frontProxy: '',
  sorting: { created: -1 },
  fetch: false,
  search: '',
  touch: false,
};

const selectAllDealer = (state) => {
  let dealer = state.dealer.map((it) => ({ ...it, checked: true }));

  return { ...state, dealer };
};

const checkByName = (names, filter) => {
  const _filter = filter.map((op) => {
    op.checked = false;
    if (names.includes(op.name)) {
      op.checked = true;
    } else if (names[0] === '*') {
      // все отмечены
      // op.checked = true;
      op.checked = false;
    }
    return { ...op };
  });
  return _filter;
};

const disableByName = (names, filter) => {
  // eslint-disable-next-line array-callback-return
  const _filter = filter.map(() => {});
  return _filter;
};

export default function (state = initState, action) {
  switch (action.type) {
    case SET_FILTERS: {
      if (!action.payload?.value) {
        return state;
      }

      let _sorted = [];
      const selectedOptions = action.payload.value.filter(({ checked }) => checked);

      if (['mop', 'ks', 'lastState'].includes(action.payload.filter)) {
        const otherOptions = action.payload.value.filter(({ checked }) => !checked);

        const sortedSelectedOptions = ['mop', 'ks'].includes(action.payload.filter)
          ? [...selectedOptions].sort(sortArrayBy('surname'))
          : [...selectedOptions].sort(sortArrayBy('id'));

        const sortedOtherOptions = ['mop', 'ks'].includes(action.payload.filter)
          ? [...otherOptions].sort(sortArrayBy('surname'))
          : [...otherOptions].sort(sortArrayBy('id'));

        _sorted = [...sortedSelectedOptions, ...sortedOtherOptions];
      } else {
        _sorted = action.payload.value.slice().sort(sortArrayByChecked);
      }

      localStorage.saveItem(`${action.payload.filter}`, {
        value: selectedOptions,
      });
      return {
        ...state,
        [action.payload.filter]: _sorted,
      };
    }
    case DROP_FILTER:
      return { ...state, [action.payload.filter]: action.payload.value };
    case FILTERS_CHECKED_BY_NAME:
      return {
        ...state,
        [action.payload.filter]: checkByName(action.payload.names, state[action.payload.filter]),
      };
    case FILTERS_DISABLED_BY_NAME:
      return {
        ...state,
        [action.payload.key]: disableByName(action.payload.value, state[action.payload.key]),
      };
    case ALL_FILTERS_LOADED:
      return { ...state, fetch: action.payload };
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case SET_FILTER_SEARCH: {
      const _filtered = state[action.payload.name].filter(
        (op) => op.name.includes(action.payload.value) || op.label.includes(action.payload.value)
      );

      return {
        ...state,
        [action.payload.name]: _filtered,
      };
    }
    case SELECT_ALL_DEALERS:
      return { ...state, ...selectAllDealer(state, action.payload) };
    case DROP_FILTERS:
      return { ...initState };
    case SET_SORTING: {
      return {
        ...state,
        sorting: {
          [String(action.payload.field || '')]: Number(action.payload.direction || 0) || 0,
        },
      };
    }
    case RESET_SORTING: {
      return { ...state, sorting: { ...initState.sorting } };
    }
    case SET_FILTER_TOUCH: {
      return { ...state, touch: true };
    }
    case SET_FILTER_UNTOUCH: {
      return { ...state, touch: false };
    }
    case SET_FRONT_PROXY: {
      return { ...state, frontProxy: action.payload };
    }
    default:
      return state;
  }
}

export function setFilter({ filter, value }) {
  return {
    type: SET_FILTERS,
    payload: { filter, value },
  };
}

export function loadFilters(params) {
  return { type: LOAD_FILTERS, payload: params };
}

export function reloadFilters(params) {
  return { type: RELOAD_FILTERS, payload: params };
}

export function setCheckedByNames({ names, filter }) {
  return {
    type: FILTERS_CHECKED_BY_NAME,
    payload: { names, filter },
  };
}

export function setDisabledByNames({ names, filter }) {
  return {
    type: FILTERS_DISABLED_BY_NAME,
    payload: { names, filter },
  };
}

export function setFilterSearch({ name, value }) {
  return {
    type: SET_FILTER_SEARCH,
    payload: { name, value },
  };
}

export function setSearch(value) {
  return {
    type: SET_SEARCH,
    payload: value,
  };
}

export function selectAllDealers(dealer = null) {
  return {
    type: SELECT_ALL_DEALERS,
    payload: dealer,
  };
}

export function setSortig(field, direction) {
  return { type: SET_SORTING, payload: { field, direction } };
}

export function resetSortig() {
  return { type: RESET_SORTING, payload: {} };
}

export function filterTouch() {
  return { type: SET_FILTER_TOUCH, payload: {} };
}

export function filterUntouch() {
  return { type: SET_FILTER_UNTOUCH, payload: {} };
}

export function setFrontProxy(value) {
  return { type: SET_FRONT_PROXY, payload: value };
}

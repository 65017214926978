import React from 'react';
import { CheckCircleLIcon } from 'assets/icons';
import * as S from '../FinalModal/FinalModal.style';

const fn = () => {};
export function FinalModal({ onClose = fn }) {
  return (
    <S.StyledPopup onClose={onClose}>
      <S.UpperInfo>
        <S.RegIconWrapper>
          <S.StyledCheckCircle Icon={CheckCircleLIcon} />
        </S.RegIconWrapper>
        <S.Title>Документы подписаны</S.Title>
      </S.UpperInfo>
      <S.StyledButton onClick={onClose}>Продолжить заполнение анкеты</S.StyledButton>
    </S.StyledPopup>
  );
}

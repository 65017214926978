import styled, { css } from 'styled-components';

export const CheckBoxStyled = styled.button`
  display: flex;
  flex-direction: row;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  align-items: center;
  font-size: 12px;
`;

export const Box = styled.div`
  margin-right: 10px;
  ${(p) =>
    p.size &&
    css`
      min-width: ${p.size + 1}px;
      min-height: ${p.size + 1}px;
      width: ${p.size + 1}px;
      height: ${p.size + 1}px;
    `}
  ${(p) =>
    p.borderRadius &&
    css`
      border-radius: ${p.borderRadius}px;
    `}
	border: 1px solid var(--color-grey-4);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled || p.withoutClick ? 'not-allowed' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.4' : '1')};

  ${(p) =>
    p.checked &&
    css`
      box-shadow: 0 0 6px 0 var(--color-blue-4);
      border: 1px solid var(--color-blue-4);
    `}

  ${(p) =>
    !p.checked &&
    css`
      border: 1px solid var(--color-grey-4);
    `}

	${(p) =>
    p.required &&
    !p.checked &&
    css`
      border: 1px solid var(--color-red-2);
    `}
`;

export const Label = styled.div`
  min-height: 10px;
  cursor: ${(p) =>
    p.labelClickOff ? 'default' : p.disabled || p.withoutClick ? 'not-allowed' : 'pointer'};
  user-select: none;
  font-family: var(--main-font-family);
  font-weight: normal;
  font-style: normal;
  text-align: left;
  opacity: ${(p) => (p.disabled ? '0.4' : '1')};
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${(p) => (p.rounded ? '8px 15px' : '15px 20px')};
  pointer-events: ${(p) => (p.disabled ? 'none' : null)};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  user-select: none;
  opacity: ${(p) => (p.disabled ? '0.7' : '1')};
  background-color: ${(p) => (p.checked ? 'var(--color-blue-4)' : 'transparent')};
  border: 1px solid ${(p) => (p.checked ? 'var(--color-blue-4)' : 'var(--color-grey-4)')};
  border-radius: ${(p) => (p.rounded ? '40px' : '5px')};
  font-size: ${(p) => (p.rounded ? '12px' : '14px')};
  line-height: ${(p) => (p.rounded ? '14px' : '18px')};
  ${(p) =>
    p.required &&
    !p.checked &&
    css`
      border-color: var(--color-red-2);
    `}
`;

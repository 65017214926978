import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const ePointsRegistrationButtonTrack = () =>
  isProd && ym('reachGoal', 'Нажать на кнопку зарегистрироваться');

export const continiueRegistrationButtonTrack = () => isProd && ym('reachGoal', 'Продолжить');

export const resendSMSButtonTrack = () => isProd && ym('reachGoal', 'Отправить код');

import styled from 'styled-components';
import { CopyFilledSIcon, CopyOutlineSIcon } from 'assets/icons';
import { Icon } from 'components/uikit/Icon/Icon';

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  --icon-margin: 5px;
`;

export const CopyIcoOutline = styled(Icon).attrs({ Icon: CopyOutlineSIcon, theme: 3, size: 's' })``;

export const CopyIcoFilled = styled(Icon).attrs({ Icon: CopyFilledSIcon, theme: 3, size: 's' })``;

export const CopyIcoWrap = styled.div`
  margin-left: var(--icon-margin);
`;

import styled, { css } from 'styled-components';

export const TooltipPlace = styled.div`
  position: absolute !important;
  top: 0 !important;
  left: 50% !important;
  width: 0px !important;
  height: 0px !important;
`;

export const Tooltip = styled.div`
  position: absolute;
  z-index: 1001;
  top: ${(props) => -(props.height + props.indent || props.indent)}px;
  ${({ width, maxWidth }) =>
    maxWidth
      ? css`
          left: ${-(width > maxWidth || !width ? maxWidth : width) / 2}px;
          width: ${width + 25 > maxWidth || !width ? maxWidth : width + 25}px;
        `
      : css`
          left: ${-width / 2}px;
          width: ${width}px;
        `}
  max-height: 200px !important;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #a8a8a8;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  line-height: 15px;
  background: #fff;
  color: #000000;
  ${(props) =>
    !props.height &&
    css`
      border-color: transparent;
      background-color: transparent;
      color: transparent;
    `}
  span {
    position: relative;
    z-index: 3;
  }
  &:before {
    position: absolute;
    ${({ width, maxWidth }) =>
      maxWidth
        ? css`
            left: ${(width > maxWidth || !width ? maxWidth : width) / 2 - 7}px;
          `
        : css`
            left: ${width / 2 - 7}px;
          `}
    bottom: 0;
    z-index: 2;
    content: '';
    background-color: #fff;
    width: 14px;
    height: 14px;
  }
  &:after {
    position: absolute;
    ${({ width, maxWidth }) =>
      maxWidth
        ? css`
            left: ${(width > maxWidth || !width ? maxWidth : width) / 2 - 5}px;
          `
        : css`
            left: ${width / 2 - 5}px;
          `}
    bottom: -5px;
    z-index: 1;
    content: '';
    background-color: #a8a8a8;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
  }
`;

import axios from 'axios';

export const getAppVersion = async () => {
  try {
    const res = await axios.get(`/config/version.json?${new Date().getTime()}`);
    if (res.status !== 200 || !res.data || !res.data.version) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err.message);
    return null;
  }
};

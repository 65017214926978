import { removeExtraSpaces, firstCharUpper } from 'utils/stringUtils';

export const getAppNotification = (data) => {
  if (!data || !data.id || !data?.data || !data.data?.applicationId) {
    return null;
  }

  const onlineBanks = data.allowedOnline ? [String(data.data.bankId)] : [];

  return {
    id: String(data.id),
    userId: data.userId,
    dealerId: data.dealerId,
    applicationId: String(data.data.applicationId),
    dealId: String(data.data.dealId || ''),
    bankIds: [String(data.data.bankId || '')],
    onlineBanksIds: onlineBanks,
    customer: firstCharUpper(removeExtraSpaces(data.data.client || '').toLowerCase()),
    customerPhone: data.data.clientPhone || '',
    isRead: Boolean(data?.read || false),
    date: new Date(data.createdAt || Date.now()),
    allowedOnline: data.allowedOnline,
    dealStatus: data.type,
    carRequested: data.data.carRequested || {},
    creditRequested: data.data.creditRequested || {},
    carApproved: data.data.carApproved || {},
    creditApproved: data.data.creditApproved || {},
  };
};

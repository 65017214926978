import getStore from 'store/store';
import * as userSelectors from 'store/user/selectors';

export const getLoyaltyProgramsMembership = (programName) => {
  const storeState = getStore().getState();

  if (programName === 'ePoints') {
    const dealer = userSelectors.getDealer(storeState);
    const { isEPointsDigitalDealMember } = storeState.ePoints;

    return dealer?.epointsDigitalDealMember && isEPointsDigitalDealMember;
  }
};

import {
  ASSISTANCE_COLOR_STATE,
  INSURANCE_COLOR_STATE,
  CREDIT_STATUS_PARAMS,
} from 'constants/index';

export const getInfoByStatus = (statusName, type = 'credit') => {
  const colorMaps = {
    assistance: ASSISTANCE_COLOR_STATE,
    insurance: INSURANCE_COLOR_STATE,
    credit: CREDIT_STATUS_PARAMS,
  };
  const colorMap = colorMaps[type];

  for (let stateKey in colorMap) {
    const { title, status, secondColor, fontColor } = colorMap[stateKey];
    const foundStatus = status.find((status) => status.statusName === statusName);
    if (foundStatus) {
      return {
        color: stateKey,
        secondColor,
        fontColor,
        params: foundStatus,
        title,
      };
    }
  }

  return {
    color: 'var(--color-grey-4)',
    secondColor: 'var(--color-grey-4)',
    fontColor: 'var(--color-black)',
    params: {},
    title: '',
  };
};
export const getStatusListByParams = (type, param) => {
  const statuses = CREDIT_STATUS_PARAMS[type]?.status || [];

  return statuses.filter((status) => status[param]).map(({ statusName }) => statusName);
};

export const changeColorInState = (state, type) => {
  const colorInfo = getInfoByStatus(state.sysName, type);
  state.color = colorInfo.color;
  state.fontColor = colorInfo.fontColor;
  state.secondColor = colorInfo.secondColor;

  return state;
};

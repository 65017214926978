export const calculatorFiltersType = {
  'С КАСКО': 'filters_kasko',
  'Без КАСКО': 'filters_no_kasko',
  'Два документа': 'two_documents',
  'DIRECT': 'filters_direct',
  'Остаточный платеж': 'filters_remaining payment',
  'С подтверждением дохода': 'filters_confirmed income',
  'Без СЖ банка': 'filters_no_szh',
};

export const viewDocumentsType = {
  'passport': 'viewing_passport',
  'driving-licence': 'viewing_driver_license',
};

export const downloadDocumentsType = {
  'passport': 'download_passport',
  'driving-licence': 'download_driver_license',
};

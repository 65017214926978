import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import animateScrollTo from 'animated-scroll-to';

import Product from 'components/modules/Widgets/Product';

import { loadShortDeal, resetShortDeal, setPoliceErrors, setDealErrors } from 'store/deal';
import * as validation from 'components/modules/Calculations/components/Loan/components/CarCard/CarCard.validation';
import {
  shouldUseTimer,
  getTimer,
} from 'components/modules/Calculations/components/Loan/Loan.helpers';
import { productScheme } from 'components/modules/Calculations/components/Loan/components/Calculator/Calculator.validation';
import * as ValidationHelper from 'components/modules/Calculations/components/CarDetail/validator/validationHelper';
import { updateErrors as updateCarErrors } from 'store/carInstance';

import { getPriceWithPenny } from 'utils/convertPrice';
import { SCROLL_SELECTORS } from 'constants/deal';

import { getCopyStateList, getStateList } from './helpers';
import { getLoyaltyProgramsMembership } from 'utils/loyaltyPrograms';
import { EPointsLogoSImg } from 'assets/images';
import {
  ProgressContainer,
  ProgressTitle,
  Timer,
  StateLine,
  SubStateList,
  ProgressList,
  ListStateItem,
  ListCheckItem,
  DoneImg,
  AwaitImg,
  ErrorImg,
  StyledBadge,
} from './style';

export const Credit = ({ isShowProgress = true }) => {
  const rootDispatch = useDispatch();
  const id = useSelector((store) => store.startPage.application?.ids?.loan);
  const deal = useSelector((store) => store.deal);
  const carInstance = useSelector((store) => store.carInstance.carInstance);

  const [progress, setProgress] = useState(null);
  const [timer, setTimer] = useState(null);

  const shortDeal = deal?.shortInfo || {};
  const dealState = deal?.state || null;
  const cost = `${getPriceWithPenny(shortDeal?.payment || 0).rubles} ₽/мес.`;

  const scrollToBlock = (selector) => {
    const scrollBlock = document.querySelector(selector);

    if (scrollBlock) {
      animateScrollTo(scrollBlock);
    }
  };

  const getCarInstanceErrors = () => {
    try {
      validation.carInstanceScheme.validateSync(carInstance, { abortEarly: false });
      return {};
    } catch (e) {
      return ValidationHelper.collectObjectedErrors(e);
    }
  };

  const getCarExtraDataErrors = () => {
    try {
      validation.carExtraDataScheme.validateSync(deal?.carExtraData, { abortEarly: false });
      return {};
    } catch (e) {
      return ValidationHelper.collectObjectedErrors(e);
    }
  };

  const getServicesErrors = () => {
    const errorList = {};
    deal.services
      .filter((s) => !s.bankProductFlag)
      .forEach((s) => {
        try {
          productScheme.validateSync(s, {
            abortEarly: false,
            context: { bankSpecificRequiredFields: deal?.bankSpecificRequiredFields || [] },
          });
          return {};
        } catch (e) {
          const id = e?.value?.id;
          if (id) {
            errorList[id] = ValidationHelper.collectObjectedErrors(e);
          }
        }
      });
    return errorList;
  };

  const getPolicyErrors = () => {
    try {
      validation.policeScheme.validateSync(deal.credit, {
        abortEarly: false,
        context: { bankSpecificRequiredFields: deal?.bankSpecificRequiredFields || [] },
      });
      return {};
    } catch (e) {
      return ValidationHelper.collectObjectedErrors(e);
    }
  };

  const shouldValidate = useMemo(() => {
    const validateStates = ['final_parameters', 'recalculation_finished', 'recalculation_desired'];
    return validateStates.includes(dealState?.name);
  }, [dealState]);

  const validate = debounce(() => {
    if (deal?.credit && deal?.services && shouldValidate) {
      const updateValidation = (isValid, subItem) => {
        setProgress((prev) => {
          const ret = prev ? getCopyStateList(prev) : null;
          if (ret) {
            ret[1].sub[subItem].state = isValid ? 3 : 1;
          }

          return ret;
        });
      };

      const servicesErrors = getServicesErrors();
      const policyErrors = getPolicyErrors();
      const carErrors = getCarInstanceErrors();
      const carExtraDataErrors = getCarExtraDataErrors();

      updateValidation(isEmpty(carErrors) && isEmpty(carExtraDataErrors), 0);
      if (!isEmpty(servicesErrors) || !isEmpty(policyErrors)) {
        updateValidation(false, 1);
      } else {
        updateValidation(true, 1);
      }
    }
  }, 500);

  const handleStateClick = (state) => {
    if (state.scrollTo) {
      let carInstanceErrors = {};
      let carExtraDataErrors = {};
      let servicesErrors = {};
      let policyErrors = {};

      switch (state.scrollTo) {
        case SCROLL_SELECTORS.car:
          carInstanceErrors = getCarInstanceErrors();
          carExtraDataErrors = getCarExtraDataErrors();

          rootDispatch(updateCarErrors({ ...carInstanceErrors, ...carExtraDataErrors }));
          if (!isEmpty(carInstanceErrors) || !isEmpty(carExtraDataErrors)) {
            scrollToBlock(`.${state.scrollTo}`);
          }
          break;
        case SCROLL_SELECTORS.requisites:
          servicesErrors = getServicesErrors();
          policyErrors = getPolicyErrors();

          rootDispatch(setPoliceErrors(policyErrors));
          rootDispatch(setDealErrors(servicesErrors));

          if (!isEmpty(policyErrors)) {
            scrollToBlock(`.${SCROLL_SELECTORS.policy}`);
          } else if (!isEmpty(servicesErrors)) {
            scrollToBlock(`.service-${Object.keys(servicesErrors)[0]}`);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    rootDispatch(loadShortDeal(id));
    return () => rootDispatch(resetShortDeal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (shouldUseTimer(dealState) && deal.timers.length > 0) {
      const time = getTimer(dealState, deal.timers);
      setTimer({ time });
    } else {
      setTimer(null);
    }
  }, [dealState, id, deal.timers]);

  useEffect(() => {
    if (dealState && isShowProgress) {
      setProgress(getStateList(dealState));
    } else {
      setProgress(null);
      setTimer(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealState, isShowProgress]);

  useEffect(() => {
    if (progress) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, deal.carExtraData, carInstance]);

  if (!shortDeal || !shortDeal.id || shortDeal.fetch || shortDeal.error) {
    return null;
  }

  const getSubStateColor = (subState) => {
    switch (subState.state) {
      case 1:
        return 'var(--color-red-2)';
      case 2:
      case 3:
        return subState.color;
      default:
        return 'var(--color-grey-4)';
    }
  };

  return (
    <>
      <Product title="Кредит" cost={cost} description={shortDeal?.bankName || ''} />

      {progress && (
        <ProgressContainer>
          <ProgressTitle>Сделка</ProgressTitle>
          <ProgressList>
            {progress.map((state, i) => {
              let stateColor = state.state > 0 ? state.color : 'var(--color-grey-5)';
              if (state.open) {
                stateColor = 'var(--color-black)';
              }
              return (
                <React.Fragment key={state.title + i}>
                  {state.view && (
                    <ListStateItem key={state.title} isOpen={state.open}>
                      <StateLine color={stateColor} withTimer={state.timer}>
                        {state.title === 'Выдача кредита' &&
                        getLoyaltyProgramsMembership('ePoints') ? (
                          <>
                            {state.title}
                            <StyledBadge size={'m'} Icon={EPointsLogoSImg}>
                              + 500 баллов
                            </StyledBadge>
                          </>
                        ) : (
                          state.title
                        )}
                        {state.state === 3 && !state.open && <DoneImg />}
                        {state.timer && timer && (
                          <Timer time={timer.time && timer.time > 0 ? timer.time : 0} />
                        )}
                      </StateLine>

                      {state.open && (
                        <SubStateList>
                          {state.sub.map(
                            (subState) =>
                              subState.view && (
                                <ListCheckItem
                                  withTimer={subState.timer}
                                  color={getSubStateColor(subState)}
                                  key={subState.title}
                                  onClick={() => {
                                    handleStateClick(subState);
                                  }}
                                  clickEnabled={shouldValidate && subState.state === 1}
                                >
                                  {subState.title}
                                  {subState.state === 3 && <DoneImg />}
                                  {subState.state === 2 && <AwaitImg />}
                                  {subState.state === 1 && <ErrorImg />}
                                  {subState.timer && state.open && timer && (
                                    <Timer time={timer.time && timer.time > 0 ? timer.time : 0} />
                                  )}
                                </ListCheckItem>
                              )
                          )}
                        </SubStateList>
                      )}
                    </ListStateItem>
                  )}
                </React.Fragment>
              );
            })}
          </ProgressList>
        </ProgressContainer>
      )}
    </>
  );
};

Credit.displayName = 'Credit';

export default Credit;

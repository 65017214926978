import { put, takeLatest, select, all } from 'redux-saga/effects';
import remove from 'lodash/remove';

import { approval } from 'store/calculator';
import * as provider from 'data-providers/dealProvider';
import { updateCarInstanceCustomer } from 'data-providers/carInstanceProvider';
import * as localStorage from 'utils/local-storage';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { FILE_TYPE_PHOTO } from 'constants/deal';
import { getInfoByStatus } from 'utils/getStatusInfo';

import * as actions from './constants';
import { getRecalculationParams } from './mapper';

function* reloadDeal({ payload: { id: dealId, newState } }) {
  const {
    id = null,
    carInstanceId = null,
    dealerId = null,
    state = {},
  } = yield select((store) => store?.deal);

  if (id === dealId && state?.name !== newState && carInstanceId && dealerId) {
    yield put({ type: actions.LOAD_DEAL, payload: { id, carInstanceId, dealerId } });
    yield put({ type: actions.LOAD_DEAL_SHORT, payload: id });
  }
}
function* reloadDealDocuments({ payload: { id: dealId } }) {
  const dealData = yield select((store) => store?.deal);
  if (dealData.id === dealId) {
    const data = yield provider.getDealById(dealData.id);
    yield put({ type: actions.SET_DEAL, payload: { ...dealData, files: data.files } });
  }
}

function* loadDeal({ payload: { id, carInstanceId, dealerId } }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });

    const data = yield provider.getDealById(id);
    const colorInfo = getInfoByStatus(data.state.name);
    const [dealerRecipients, assistRecipients] = yield all([
      provider.getCreditFundsRecipients(dealerId, data.credit?.providerId, 'dealer'),
      provider.getCreditFundsRecipients(dealerId, data.credit?.providerId, 'assist'),
    ]);

    const recipients = [...dealerRecipients, ...assistRecipients];
    (data.credit || {}).recipients = recipients;

    data.services = data.services || [];
    for (let i = 0; i < data.services.length; i++) {
      const s = data.services[i];
      if (s && s?.serviceId && s?.inCredit) {
        s.recipients = recipients;
      }
    }

    data.carInstanceId = carInstanceId;
    data.dealerId = dealerId;
    data.state.color = colorInfo.color;
    data.state.fontColor = colorInfo.fontColor;
    data.state.secondColor = colorInfo.secondColor;

    yield put({ type: actions.SET_DEAL, payload: data });
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* loadShortDeal({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL_SHORT, payload: {} });

    const data = yield provider.getShortDealById(payload);
    const colorInfo = getInfoByStatus(data.state.name);
    data.state.color = colorInfo.color;
    data.state.fontColor = colorInfo.fontColor;
    data.state.secondColor = colorInfo.secondColor;

    yield put({ type: actions.SET_DEAL_SHORT, payload: data });
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL_SHORT,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL_SHORT, payload: {} });
  }
}

function* addFile({ payload }) {
  try {
    const file = yield provider.addFile(payload.id, payload.fileId, payload.typeId, payload.ext);
    const deal = yield select((store) => store?.deal || null);

    if (file && deal) {
      let oldFiles = [...(deal?.files || [])];
      if (file.typeId === FILE_TYPE_PHOTO) {
        const stateList = ['request_on_approval', 'request_on_reapproval', 'request_ on_documents'];
        if (!stateList.includes(deal?.state.name)) {
          oldFiles = oldFiles.filter((f) => f.typeId !== FILE_TYPE_PHOTO);
        }
      }

      const files = [
        ...oldFiles,
        {
          ext: file.ext,
          id: file.id,
          fileId: file.fileId,
          typeId: file.typeId,
          createdAt: file.createdAt,
        },
      ];

      yield put({ type: actions.SET_DEAL, payload: { ...deal, files } });
    }
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  }
}

function* addFiles({ payload }) {
  try {
    const { applicationId, files } = payload;
    const addedFiles = yield provider.addFiles(applicationId, files);
    const deal = yield select((store) => store?.deal || null);

    if (deal && addedFiles?.files && addedFiles?.files?.length > 0) {
      const dealFiles = [...(deal?.files || [])];
      addedFiles.files.forEach((file) => {
        remove(dealFiles, (f) => f.id === file.id);
        dealFiles.push({
          ext: file.ext,
          id: file.id,
          fileId: file.fileId,
          typeId: file.typeId,
          createdAt: file.createdAt,
        });
      });

      yield put({ type: actions.SET_DEAL, payload: { ...deal, files: dealFiles } });
    }
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  }
}

function* deleteFile({ payload = [] }) {
  try {
    const deal = yield select((store) => store?.deal || null);
    if (deal) {
      const files = (deal?.files || []).filter((f) => !payload.includes(f.id));
      yield put({ type: actions.SET_DEAL, payload: { ...deal, files } });
    }

    yield provider.deleteFile(payload);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  }
}

function* approvalDeal({ payload }) {
  const deal = yield select((store) => store?.deal || null);
  const data = getRecalculationParams(payload.data, deal?.bankSpecificRequiredFields);
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });

    const updateCarStates = [
      'request_on_approval',
      'request_on_documents',
      'request_on_finance',
      'request_on_reapproval',
    ];
    if (updateCarStates.includes(payload.state)) {
      if (payload.carData) {
        yield updateCarInstanceCustomer({ id: payload.carData?.id, carInstance: payload.carData });
      }
    }

    switch (payload.state) {
      case 'request_on_documents':
      case 'reload_documents_required':
        yield provider.docApprovalDeal(payload.id, data);
        break;
      case 'request_on_finance':
        yield provider.finApprovalDeal(payload.id, data);
        break;
      case 'request_on_reapproval':
        yield provider.reapprovalDeal(payload.id, data);
        break;
      case 'request_on_approval':
        yield provider.approvalDeal(payload.id, data);
        break;
      case 'documentation_received':
        yield provider.finApprovalDeal(payload.id);
        break;
      case 'documentation_generated':
        yield provider.generateApprovalDealDoc(payload.id);
        break;
      case 'recalculation_desired':
      case 'recalculation_finished':
        yield provider.reapprovalDeal(payload.id);
        break;
      case 'new':
      case 'change_personal_data':
        yield provider.confirmDeal(payload.id);
        break;
      default:
        yield provider.approvalDeal(payload.id);
        break;
    }
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* declineDeal({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });
    yield provider.declineDeal(payload.id, '');
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* dealRecalculationDesired({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });
    yield provider.dealRecalculationDesired(payload.id);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* saveLocalDeal({ payload }) {
  const deal = yield select((store) => store?.deal || null);
  if (deal) {
    const police = deal.credit || {};
    const services = deal.services || [];

    const localData = {
      police: {
        contractNumber: police.contractNumber,
        contractDate: police.contractDate,
        recipient: police.recipient,
      },
      services: services.map((s) => ({
        id: s.id,
        polisNumber: s.polisNumber,
        recipient: s.recipient,
      })),
    };

    localStorage.saveItem(payload.id, localData);
  }
}

function* restoreLocalDeal({ payload }) {
  const deal = yield select((store) => store?.deal || null);
  const localData = localStorage.getItem(payload.id);

  if (deal && localData) {
    const newPolice = { ...(deal.credit || {}) };
    const newServices = [...(deal.services || [])];

    if (localData.police) {
      newPolice.contractNumber = localData.police.contractNumber;
      newPolice.contractDate = localData.police.contractDate;
      newPolice.recipient = localData.police.recipient;
    }

    if (localData.services) {
      for (let loc of localData.services) {
        const old = newServices.find((s) => s.id === loc.id);
        if (old) {
          old.polisNumber = loc.polisNumber;
          old.recipient = loc.recipient;
        }
      }
    }

    yield put({ type: actions.SET_POLICE, payload: newPolice });
    yield put({ type: actions.SET_SERVICES, payload: newServices });
  }
}

function* saveRecalculation({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });

    yield updateCarInstanceCustomer({ id: payload.carData?.id, carInstance: payload.carData });
    yield put(
      approval(payload.calcData, null, (data, worksheet) => {
        put({ type: actions.FETCH_STOP_DEAL, payload: {} });
        if (payload.callback) {
          payload.callback(data, worksheet);
        }
      })
    );
  } catch (err) {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  }
}

function* approveRecalculation({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });

    const deal = yield select((store) => store?.deal || null);
    const state = deal?.state?.name || null;
    const data = getRecalculationParams(payload.data, deal?.bankSpecificRequiredFields);
    let requestMethod;
    if (state === 'final_parameters') {
      requestMethod = provider.confirmFinalParams;
    } else if (state === 'recalculation_desired') {
      requestMethod = provider.confirmRecalculationDesired;
    }

    const res = yield requestMethod(payload.id, payload.dealId, data);
    if (payload.callback && res) {
      payload.callback(res);
    }
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* saveFinalParams({ payload }) {
  try {
    const deal = yield select((store) => store?.deal || null);
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });
    const data = getRecalculationParams(payload.data, deal?.bankSpecificRequiredFields);
    yield provider.saveFinalParams(payload.id, payload.dealId, data);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* closeDeal({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });
    yield provider.closeDeal(payload.id);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* requestDocumentFinanced({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });
    yield provider.requestDocumentFinanced(payload.id);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err?.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

function* changePersonalData({ payload }) {
  try {
    yield put({ type: actions.FETCH_START_DEAL, payload: {} });
    yield provider.changePersonalData(payload.id);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_DEAL,
      payload: err?.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.FETCH_STOP_DEAL, payload: {} });
  }
}

export function* watchLoadDeal() {
  yield takeLatest(actions.LOAD_DEAL, loadDeal);
}

export function* watchReloadDeal() {
  yield takeLatest(actions.RELOAD_DEAL, reloadDeal);
}

export function* watchReloadDealDocuments() {
  yield takeLatest(actions.RELOAD_DEAL_DOCUMENTS, reloadDealDocuments);
}

export function* watchAddFileDeal() {
  yield takeLatest(actions.ADD_FILE_DEAL, addFile);
}

export function* watchAddFilesDeal() {
  yield takeLatest(actions.ADD_FILES_DEAL, addFiles);
}

export function* watchDeleteFileDeal() {
  yield takeLatest(actions.DEL_FILE_DEAL, deleteFile);
}

export function* watchLoadShortDeal() {
  yield takeLatest(actions.LOAD_DEAL_SHORT, loadShortDeal);
}

export function* watchApprovalDeal() {
  yield takeLatest(actions.APPROVAL_DEAL, approvalDeal);
}

export function* watchDeclineDeal() {
  yield takeLatest(actions.DECLINE_DEAL, declineDeal);
}

export function* watchRecalculationDesired() {
  yield takeLatest(actions.DEAL_RECALCULATION_DESIRED, dealRecalculationDesired);
}

export function* watchSaveLocalDeal() {
  yield takeLatest(actions.SAVE_LOCAL_DEAL, saveLocalDeal);
}

export function* watchRestoreLocalDeal() {
  yield takeLatest(actions.RESTORE_LOCAL_DEAL, restoreLocalDeal);
}

export function* watchSaveRecalculation() {
  yield takeLatest(actions.SAVE_RECALCULATION, saveRecalculation);
}

export function* watchApproveRecalculation() {
  yield takeLatest(actions.APPROVE_RECALCULATION, approveRecalculation);
}

export function* watchCloseDeal() {
  yield takeLatest(actions.CLOSE_DEAL, closeDeal);
}

export function* watchRequestDocumentFinanced() {
  yield takeLatest(actions.REQUEST_DOCUMENT_FINANCED, requestDocumentFinanced);
}

export function* watchChangePersonalData() {
  yield takeLatest(actions.CHANGE_PERSONAL_DATA, changePersonalData);
}

export function* watchSaveFinalParams() {
  yield takeLatest(actions.SAVE_FINAL_PARAMS, saveFinalParams);
}

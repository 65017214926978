import { put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './constants';
import * as provider from 'data-providers/mainPageProvider';
import * as vinCheckStore from 'store/vinCheck';

function* loadVinHistory({ payload }) {
  const { page = 1, perPage = 5 } = payload;

  const loadedPages = yield select((state) => state.vinCheck.loadedPages);

  if (loadedPages.includes(page)) {
    return;
  }

  try {
    yield put(vinCheckStore.fetchVinHistory(true));

    const { data: vinHistory, total } = yield provider.getVinHistory(payload.userId, {
      page,
      perPage,
    });

    if (vinHistory) {
      const currentHistory = yield select((state) => state.vinCheck.vinHistory);

      const combinedHistory = [...currentHistory, ...vinHistory];
      const uniqueHistory = Array.from(
        new Map(combinedHistory.map((item) => [item.id, item])).values()
      );

      yield put(vinCheckStore.setVinHistory({ history: uniqueHistory, page, total }));

      yield put(vinCheckStore.fetchVinHistory(false));
    }
  } catch (err) {
    yield put(vinCheckStore.setVinFetchError());
  }
}

export function* watchLoadVinHistory() {
  yield takeLatest(actions.LOAD_VIN_HISTORY, loadVinHistory);
}

import React, { useRef } from 'react';

import { TooltipHover } from 'components/uikit/Tooltip/TooltipHover/TooltipHover';

import * as S from './EPointsMenuItem.styles';

export const EPointsMenuItem = (props) => {
  const { title = '', ePointsLogo = '' } = props;

  const ref = useRef();

  return (
    <S.Container>
      {title}
      <S.LogoWrapper ref={ref}>
        {ePointsLogo === 'active' && <S.StyledEPointsActiveLog />}
        {ePointsLogo === 'disabled' && <S.StyledEPointsDisabledLogo />}
        <TooltipHover targetRef={ref} theme="2" size="s" position="right">
          {ePointsLogo === 'active' && (
            <>
              ДЦ подключен к программе
              <br />
              лояльности еКредит
            </>
          )}
          {ePointsLogo === 'disabled' && (
            <>
              Для подключения программы
              <br />
              лояльности необходимо
              <br />
              зарегистрироваться
            </>
          )}
        </TooltipHover>
      </S.LogoWrapper>
    </S.Container>
  );
};

import React from 'react';
import { useInfoCardPresenter } from './InfoCard.presenter';

import { ParamsCell } from '../ParamsCell/ParamsCell';
import { ScrollContainer } from './components/ScrollContainer';

import * as S from './InfoCard.styles';

export function InfoCard({ data, showedBanks }) {
  const labelsObj = {
    green: (
      <S.LabelWrapper>
        <S.StyledGreenLabel />
      </S.LabelWrapper>
    ),
    yellow: (
      <S.LabelWrapper>
        <S.StyledYellowLabel />
      </S.LabelWrapper>
    ),
    red: (
      <S.LabelWrapper>
        <S.StyledRedLabel />
      </S.LabelWrapper>
    ),
  };

  const presenter = useInfoCardPresenter(data, labelsObj);

  return (
    <S.InfoContainer>
      {presenter.sortedBanks.length > showedBanks ? (
        presenter.hideBanks ? (
          <S.BanksLogoWraper>
            {presenter.sortedBanks.slice(0, showedBanks).map((bank, index) => (
              <S.BanksInfoButton
                key={`${bank}_${index}`}
                active={index === presenter.activeIndex}
                onClick={() => presenter.handleButtonClick(index)}
              >
                <S.LogoIco type="pps" id={bank} />
              </S.BanksInfoButton>
            ))}
            <S.ShowBanksButton onClick={() => presenter.setHideBanks(false)}>
              <S.ShowBanksLogo>+{presenter.sortedBanks.length - showedBanks}</S.ShowBanksLogo>
            </S.ShowBanksButton>
          </S.BanksLogoWraper>
        ) : (
          <ScrollContainer>
            {!presenter.hideBanks &&
              presenter.sortedBanks.map((bank, index) => (
                <S.BanksInfoButton
                  key={`${bank}_${index}`}
                  active={index === presenter.activeIndex}
                  onClick={() => presenter.handleButtonClick(index)}
                >
                  <S.LogoIco type="pps" id={bank} />
                </S.BanksInfoButton>
              ))}
            <S.ShowBanksButton onClick={() => presenter.setHideBanks(true)}>
              <S.ShowBanksLogo>⬅</S.ShowBanksLogo>
            </S.ShowBanksButton>
          </ScrollContainer>
        )
      ) : (
        <S.BanksLogoWraper>
          {presenter.sortedBanks.map((bank, index) => (
            <S.BanksInfoButton
              key={`${bank}_${index}`}
              active={index === presenter.activeIndex}
              onClick={() => presenter.handleButtonClick(index)}
            >
              <S.LogoIco type="pps" id={bank} />
            </S.BanksInfoButton>
          ))}
        </S.BanksLogoWraper>
      )}

      <S.BanksInfo>
        <ParamsCell
          label={'Марка, модель, год авто'}
          model={presenter.modelParams('model')}
          requested={presenter.modelParams('requested')}
          approved={presenter.modelParams('approved')}
          colorLabel={presenter.percentsObj.year}
        />
        <ParamsCell label={'Стоимость авто'} approved={presenter.carCostParams()} />
        <ParamsCell
          label={'Сумма кредита'}
          requested={presenter.creditSumParams('requested')}
          approved={presenter.creditSumParams('approved')}
          colorLabel={presenter.percentsObj.creditSum}
        />
        <ParamsCell
          label={'ПВ'}
          requested={presenter.downPaymentParams('requested')}
          approved={presenter.downPaymentParams('approved')}
          colorLabel={presenter.percentsObj.downPayment}
        />
        <ParamsCell
          label={'Ежемесячный платеж'}
          requested={presenter.monthlyPaymentParams('requested')}
          approved={presenter.monthlyPaymentParams('approved')}
          colorLabel={presenter.percentsObj.monthlyPayment}
        />
        <ParamsCell
          label={'Ставка'}
          requested={presenter.creditRateParams('requested')}
          approved={presenter.creditRateParams('approved')}
          colorLabel={presenter.percentsObj.creditRate}
        />
      </S.BanksInfo>
    </S.InfoContainer>
  );
}

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const after = ({ color }) => css`
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: ${color || 'var(--color-grey-4)'} transparent transparent transparent;
`;

export const Text = styled.span`
  visibility: hidden;
  width: 140px;
  background-color: ${({ color }) => color || 'var(--color-white)'};
  color: ${({ color }) => (color ? 'var(--color-white)' : 'var(--color-black)')};
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid var(--color-grey-4);
  position: absolute;
  z-index: -1;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4);

  ${({ position, color }) => {
    switch (true) {
      case position === 'bottom':
        return css`
          top: 150%;

          &::after {
            ${after({ color })}
            bottom: 100%;
            transform: rotate(180deg);
          }
        `;
      default:
        return css`
          bottom: 150%;

          &::after {
            ${after({ color })}
            top: 100%;
            transform: rotate(0deg);
          }
        `;
    }
  }}
`;

import styled from 'styled-components';

import { Icon } from '../../Icon/Icon';
import { IconButton } from 'components/uikit/IconButton/IconButton';

const themes = {
  1: {
    colorBkg: 'var(--color-grey-9_16)',
    colorBkgHover: 'var(--color-grey-7)',
    colorBkgFocus: 'var(--color-grey-9_16)',
    colorBkgDisabled: 'var(--color-grey-9_16)',
    colorBkgError: 'var(--color-red-4)',
    colorBkgErrorDisabled: 'var(--color-red-4_80)',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderFocus: 'var(--color-white)',
    colorBorderDisabled: 'transparent',
    colorBorderError: 'var(--color-red-2)',
    colorText: 'var(--color-white)',
    colorTextDisabled: 'var(--color-white_68)',
    colorTextError: 'var(--color-red-2)',
    colorIcon: 'var(--color-white_68)',
    colorIconDisabled: 'var(--color-white_68)',
    colorIconError: 'var(--color-red-2)',
    colorLabel: 'transparent',
    colorLabelDisabled: 'transparent',
    colorLabelError: 'transparent',
    colorPlaceholder: 'var(--color-white_68)',
    colorPlaceholderDisabled: 'var(--color-white_68)',
    colorDescription: 'transparent',
    colorDescriptionDisabled: 'transparent',
  },
  2: {
    colorBkg: 'var(--color-grey-10_10)',
    colorBkgHover: 'var(--color-grey-10_80)',
    colorBkgFocus: 'var(--color-grey-10_80)',
    colorBkgDisabled: 'var(--color-grey-10_56)',
    colorBkgError: 'var(--color-red-4)',
    colorBkgErrorDisabled: 'var(--color-red-4_80)',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderFocus: 'var(--color-black)',
    colorBorderDisabled: 'transparent',
    colorBorderError: 'var(--color-red-2)',
    colorText: 'var(--color-black)',
    colorTextDisabled: 'var(--color-grey-11_60)',
    colorTextError: 'var(--color-red-2)',
    colorIcon: 'var(--color-grey-11_60)',
    colorIconDisabled: 'var(--color-grey-11_60)',
    colorIconError: 'var(--color-red-2)',
    colorLabel: 'var(--color-grey-11_60)',
    colorLabelDisabled: 'var(--color-grey-11_60)',
    colorLabelError: 'var(--color-red-2)',
    colorPlaceholder: 'var(--color-grey-11_60)',
    colorPlaceholderDisabled: 'var(--color-grey-11_60)',
    colorDescription: 'var(--color-grey-11_60)',
    colorDescriptionDisabled: 'var(--color-grey-11_60)',
  },
};

const sizes = {
  s: {
    height: '32px',
    padding: '6px 8px',
    paddingWithLabel: '6px 8px',
    paddingWithIcon: '44px',
    topLabel: '-8px',
    leftLabel: '8px',
    font: 'var(--font-regular-15)',
    fontLabel: 'var(--font-regular-11)',
    fontDescription: 'var(--font-regular-13)',
    borderRadius: '8px',
  },
  m: {
    height: '44px',
    padding: '12px 12px',
    paddingWithLabel: '20px 12px 4px 12px',
    paddingWithIcon: '44px',
    topLabel: '14px',
    leftLabel: '12px',
    font: 'var(--font-regular-15)',
    fontLabel: 'var(--font-regular-11)',
    fontDescription: 'var(--font-regular-13)',
    borderRadius: '12px',
  },
  l: {
    height: '56px',
    padding: '16px 16px',
    paddingWithLabel: '22px 16px 10px 16px',
    paddingWithIcon: '44px',
    topLabel: '18px',
    leftLabel: '16px',
    font: 'var(--font-regular-17)',
    fontLabel: 'var(--font-regular-11)',
    fontDescription: 'var(--font-regular-13)',
    borderRadius: '16px',
  },
  xl: {
    height: '64px',
    padding: '20px 16px',
    paddingWithLabel: '26px 16px 14px 16px',
    paddingWithIcon: '44px',
    topLabel: '22px',
    leftLabel: '16px',
    font: 'var(--font-regular-17)',
    fontLabel: 'var(--font-regular-11)',
    fontDescription: 'var(--font-regular-13)',
    borderRadius: '16px',
  },
};

export const Container = styled.div`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-hover: ${(p) => (themes[p.theme] || themes['1']).colorBkgHover};
  --color-bkg-focus: ${(p) => (themes[p.theme] || themes['1']).colorBkgFocus};
  --color-bkg-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgDisabled};
  --color-bkg-error: ${(p) => (themes[p.theme] || themes['1']).colorBkgError};
  --color-bkg-error-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgErrorDisabled};
  --color-border: ${(p) => (themes[p.theme] || themes['1']).colorBorder};
  --color-border-hover: ${(p) => (themes[p.theme] || themes['1']).colorBorderHover};
  --color-border-focus: ${(p) => (themes[p.theme] || themes['1']).colorBorderFocus};
  --color-border-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBorderDisabled};
  --color-border-error: ${(p) => (themes[p.theme] || themes['1']).colorBorderError};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --color-text-disabled: ${(p) => (themes[p.theme] || themes['1']).colorTextDisabled};
  --color-text-error: ${(p) => (themes[p.theme] || themes['1']).colorTextError};
  --color-icon: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-icon-disabled: ${(p) => (themes[p.theme] || themes['1']).colorIconDisabled};
  --color-icon-error: ${(p) => (themes[p.theme] || themes['1']).colorIconError};
  --color-placeholder: ${(p) => (themes[p.theme] || themes['1']).colorPlaceholder};
  --color-placeholder-disabled: ${(p) => (themes[p.theme] || themes['1']).colorPlaceholderDisabled};
  --color-label: ${(p) => (themes[p.theme] || themes['1']).colorLabel};
  --color-label-disabled: ${(p) => (themes[p.theme] || themes['1']).colorLabelDisabled};
  --color-label-error: ${(p) => (themes[p.theme] || themes['1']).colorLabelError};
  --color-description: ${(p) => (themes[p.theme] || themes['1']).colorDescription};
  --color-description-disabled: ${(p) => (themes[p.theme] || themes['1']).colorDescriptionDisabled};
  --height: ${(p) => (sizes[p.size] || sizes['m']).height};
  --padding: ${(p) => (sizes[p.size] || sizes['m']).padding};
  --padding-with-label: ${(p) => (sizes[p.size] || sizes['m']).paddingWithLabel};
  --padding-with-icon: ${(p) => (sizes[p.size] || sizes['m']).paddingWithIcon};
  --top-label: ${(p) => (sizes[p.size] || sizes['m']).topLabel};
  --left-label: ${(p) => (sizes[p.size] || sizes['m']).leftLabel};
  --font: ${(p) => (sizes[p.size] || sizes['m']).font};
  --font-label: ${(p) => (sizes[p.size] || sizes['m']).fontLabel};
  --font-description: ${(p) => (sizes[p.size] || sizes['m']).fontDescription};
  --border-radius: ${(p) => (sizes[p.size] || sizes['m']).borderRadius};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  height: var(--height);
  padding: ${(p) => (p.label ? 'var(--padding-with-label)' : 'var(--padding)')};
  padding-left: ${(p) => p.hasIcon && 'var(--padding-with-icon)'};
  padding-right: ${(p) => p.hasClearButton && 'var(--padding-with-icon)'};
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  background-color: ${(p) => (p.error ? 'var(--color-bkg-error)' : 'var(--color-bkg)')};
  color: var(--color-text);
  z-index: 0;

  font: var(--font);
  cursor: text;

  &::placeholder {
    font: var(--font);
    color: var(--color-placeholder);
  }

  &:hover {
    border-color: ${(p) => (p.error ? 'var(--color-border-error)' : 'var(--color-border-hover)')};
    background-color: ${(p) => (p.error ? 'var(--color-bkg-error)' : 'var(--color-bkg-hover)')};
  }

  &:focus {
    border-color: ${(p) => (p.error ? 'var(--color-border-error)' : 'var(--color-border-focus)')};
    background-color: ${(p) => (p.error ? 'var(--color-bkg-error)' : 'var(--color-bkg-focus)')};
  }

  &:disabled {
    border-color: var(--color-border-disabled);
    background-color: var(--color-bkg-disabled);
    background-color: ${(p) =>
      p.error ? 'var(--color-bkg-error-disabled)' : 'var(--color-bkg-disabled)'};
    color: var(--color-text-disabled);

    cursor: default;
  }

  &:disabled::placeholder {
    color: var(--color-placeholder-disabled);
  }
`;

export const InputIcon = styled(Icon).attrs({ size: 'm' })`
  --color: ${(p) =>
    p.disabled
      ? 'var(--color-icon-disabled)'
      : p.error
      ? 'var(--color-icon-error)'
      : 'var(--color-icon)'};

  position: absolute;
  left: 12px;
  top: 11px;
`;

export const ClearButton = styled(IconButton).attrs({ size: 'm', theme: '3' })`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

export const Label = styled.label`
  position: absolute;
  top: ${(p) => (p.isFocused ? 'var(--top-label)' : '50%')};
  left: ${(p) => (p.hasIcon ? 'var(--padding-with-icon)' : 'var(--left-label)')};
  transform: translateY(-50%);
  transition: all 0.2s ease;
  z-index: 1;
  pointer-events: none;
  color: ${(p) =>
    p.disabled
      ? 'var(--color-label-disabled)'
      : p.error
      ? 'var(--color-label-error)'
      : 'var(--color-label)'};

  font: ${(p) => (p.isFocused ? 'var(--font-label)' : 'var(--font)')};
`;

export const Description = styled.div`
  width: 100%;
  color: ${(p) => (p.disabled ? 'var(--color-description-disabled)' : 'var(--color-description)')};
  margin-top: 8px;

  font: var(--font-description);
`;

export const Error = styled(Description)`
  color: var(--color-text-error);
`;

import styled from 'styled-components';

export const StatusStyled = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  border: 1px solid ${(p) => (p.secondColor ? p.secondColor : 'transparent')};
  color: ${(p) => p.fontColor};
  font-size: 12px;
  white-space: nowrap;
`;

import React, { useMemo } from 'react';
import { useVinCheckPresenter } from './VinCheck.presenter';

import { TableHeader } from './components/TableHeader/TableHeader';
import { TableItem } from './components/TableItem/TableItem';
import { EmptyState } from './components/EmptyState/EmptyState';
import { Icon } from 'components/uikit/Icon/Icon';
import { ChevronDownMIcon, SearchMIcon } from 'assets/icons';

import * as S from './VinCheck.styles';

export const VinCheck = () => {
  const presenter = useVinCheckPresenter();

  const renderEmptyState = useMemo(() => {
    if (presenter.emptyHistoryState) {
      return <EmptyState type="emptyHistory" />;
    }
    if (presenter.fetchErrorState) {
      return <EmptyState type="fetchError" onClick={presenter.onErrorButtonClickHandler} />;
    }
    return null;
  }, [presenter.emptyHistoryState, presenter.fetchErrorState, presenter.onErrorButtonClickHandler]);

  return (
    <S.Container data-test="mainPageVinCheckContainer">
      <S.Title>Проверить автомобиль перед Цифровой сделкой</S.Title>
      <S.Wrapper>
        <S.InputWrapper>
          <S.StyledInput
            name="vin"
            value={presenter.inputValue}
            label={'VIN'}
            theme={'2'}
            Icon={SearchMIcon}
            error={presenter.validateError}
            dataTest={'mainPageVinCheckInput'}
            onChange={presenter.onChangeHandler}
            onBlur={presenter.onBlurHandler}
            onKeyDown={presenter.onKeyPressHandler}
          />
          <S.StyledButton onClick={presenter.onCheckHandler}>Проверить</S.StyledButton>
        </S.InputWrapper>
        <S.HistoryWrapper>
          <S.SubTitleWrapper
            data-test="mainPageVinCheckHistoryOpen"
            onClick={presenter.toggleHistory}
          >
            <S.SubTitle>История проверок</S.SubTitle>
            <S.ChevronWrapper isOpen={presenter.isHistoryOpened}>
              <Icon Icon={ChevronDownMIcon} theme={'4'} />
            </S.ChevronWrapper>
          </S.SubTitleWrapper>
          <S.HistoryTable
            isOpen={presenter.isHistoryOpened}
            ref={presenter.historyRef}
            data-test="mainPageVinCheckHistoryTable"
          >
            <S.HistoryTitle>История проверок</S.HistoryTitle>
            {presenter.searchError && (
              <S.SearchErrorWrapper
                text={presenter.searchError}
                onClick={presenter.onSearchErrorCloseHandler}
              />
            )}
            {presenter.totalItems > 0 && !presenter.fetchError && (
              <>
                <TableHeader />
                <S.TableItems>
                  {presenter.vinHistory.map((data) => (
                    <TableItem
                      key={data.id}
                      data={data}
                      dataTest={'mainPageVinCheckHistoryTableItem'}
                    />
                  ))}
                </S.TableItems>
              </>
            )}
            {renderEmptyState}
          </S.HistoryTable>
        </S.HistoryWrapper>
        {presenter.canShowMore && (
          <S.ShowMore disabled={presenter.fetchHistory} onClick={presenter.onShowMoreClickHandler}>
            Показать еще
          </S.ShowMore>
        )}
      </S.Wrapper>
    </S.Container>
  );
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { InputMask } from 'utils/inputMask';
import { getCalculatorParams } from 'data-providers/calculatorProvider';
import { dropLastCalculationData, setLastCalculationData } from 'store/calculator';
import { convertValueToStandartNumber } from 'components/modules/Applications/helpers';

import * as S from './style';

const profitsApproved = {
  id: 'profits_approved_bank_form',
  sysName: 'profits_approved_bank_form',
};

export const ProfitForm = ({
  onChange,
  errors,
  persona,
  validate,
  firstTimeRender,
  anketaSave,
  creditId,
  disabled = false,
}) => {
  const selectedPrograms = useSelector(
    (store) => store.calculator?.lastCalculationData?.calculationResult
  );

  const keyComp = 'profit';
  const [values, setValues] = useState(null);
  const [error, setError] = useState({});
  const [mainProfitLabel, setMainProfitLabel] = useState('');

  const totalPayment = selectedPrograms
    ? Object.values(selectedPrograms).reduce(
        (acc, program) => acc + (program?.calculation?.payment || 0),
        0
      ) / Object.values(selectedPrograms).length
    : 0;

  const recommendedIncome = totalPayment
    ? convertValueToStandartNumber(totalPayment * 3)
    : 'Не определенный';
  const rootDispatch = useDispatch();

  const mainProfitVariant = {
    small: 'Основной доход',
    large: `${recommendedIncome} - рекомендуемый доход`,
  };

  const changeHandler = (val, withSave = false) => {
    let stateVal = { ...values };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }
    setValues(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyComp });

    if (withSave) anketaSave();
  };

  const validation = (path) => {
    setError({ ...error, [path]: errors[path] || null });
    anketaSave();
    profitBlurHandler();
  };

  const profitFocusHandler = () => {
    setMainProfitLabel(mainProfitVariant.small);
  };

  const profitBlurHandler = () =>
    setMainProfitLabel(values.mainprofit ? mainProfitVariant.small : mainProfitVariant.large);

  const getError = (path) => {
    if (validate && !firstTimeRender) {
      return errors[path];
    }
    return error[path];
  };

  useEffect(() => {
    if (values?.mainprofit) {
      setMainProfitLabel(mainProfitVariant.small);
    } else {
      setMainProfitLabel(mainProfitVariant.large);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrograms]);

  useEffect(() => {
    if (persona && values !== persona[keyComp]) {
      setValues(persona[keyComp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  useEffect(() => {
    if (!validate) {
      setError(
        Object.keys(error).reduce((obj, key) => ({ ...obj, [key]: errors[key] || null }), {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  useEffect(() => {
    getCalculatorParams(creditId).then((data) => {
      rootDispatch(setLastCalculationData(data));
    });

    return () => {
      rootDispatch(dropLastCalculationData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditId]);

  return (
    <>
      {values && (
        <S.FormGridRow>
          <S.MainProfit
            id={`${keyComp}_mainprofit`}
            tabIndex="83"
            required
            name="mainprofit"
            label={mainProfitLabel}
            defaultValue={values.mainprofit}
            val={values.mainprofit}
            mask={InputMask.NUMBER_LOCAL_BUS}
            unmask
            onChange={(val) => changeHandler(val)}
            onFocus={profitFocusHandler}
            onBlur={() => validation(`${keyComp}.mainprofit`)}
            error={getError(`${keyComp}.mainprofit`)}
            disabled={disabled}
          />
          <S.AddedProfit
            id={`${keyComp}_addedprofit`}
            tabIndex="84"
            name="addedprofit"
            label="Дополнительный доход"
            defaultValue={values.addedprofit}
            val={values.addedprofit}
            mask={InputMask.NUMBER_LOCAL_BUS}
            unmask
            onChange={(val) => changeHandler(val)}
            onBlur={() => validation(`${keyComp}.addedprofit`)}
            error={getError(`${keyComp}.addedprofit`)}
            disabled={disabled}
          />
          <S.ProofCheckbox
            tabIndex="110"
            name="profitproof"
            onChange={() => {
              changeHandler(
                {
                  profitproof:
                    values.profitproof?.id !== profitsApproved.id ? profitsApproved : null,
                },
                true
              );
            }}
            value={values.profitproof?.id === profitsApproved.id}
            disabled={disabled}
            dataTest="profitproof"
          >
            Доход подтвержден справкой
          </S.ProofCheckbox>
        </S.FormGridRow>
      )}
    </>
  );
};

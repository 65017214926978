import { initialState } from './initialState';
import { defaultDriver } from './resources';
import { backToFrontDriver } from './backToFrontMapper';
import { GLOBAL_ANKETA_CALC_OPTIONS } from 'constants/index';

import * as assistanceActions from 'store/assistanceAnketa/constants';
import * as actions from './constants';
import * as Helper from './helper';

export default function (state = initialState, action) {
  switch (action.type) {
    case assistanceActions.ASSISTANCE_SET_CUSTOMER: {
      const { mappedInsurer } = action.payload;
      return {
        ...state,
        fio: mappedInsurer?.fio || '',
        bday: mappedInsurer?.bday || '',
        insurerPhone: mappedInsurer?.insurerPhone || '',
      };
    }

    case actions.INS_ANKETA_SET_CUSTOMER: {
      const { mappedInsurer, filledCustomers, applicationId } = action.payload;

      return { ...state, ...mappedInsurer, filledCustomers, applicationId };
    }

    case actions.INS_ANKETA_SET_CUSTOMERS_FETCH: {
      return { ...state, customersFetch: action.payload };
    }

    case actions.INS_ANKETA_SET_INSURER_DADATA_INFO: {
      return {
        ...state,
        insurerDadataInfo: { ...state.insurerDadataInfo, ...action.payload },
      };
    }

    case actions.INS_ANKETA_SET_KASKO_INSURER_DADATA_INFO: {
      return {
        ...state,
        kaskoInsurer: {
          ...state.kaskoInsurer,
          insurerDadataInfo: {
            ...state.kaskoInsurer.insurerDadataInfo,
            ...action.payload,
          },
        },
      };
    }

    case actions.INS_ANKETA_CHANGE_BY_KEY: {
      const { key, value } = action.payload;

      return {
        ...state,
        [key]: value,
      };
    }

    case actions.INS_ANKETA_INSURER_CHANGE_BY_KEY: {
      const { key, value } = action.payload;

      return {
        ...state,
        kaskoInsurer: {
          ...state.kaskoInsurer,
          [key]: value,
        },
      };
    }

    case actions.INS_ANKETA_CHANGE_BY_KEY_WERRORS: {
      const { key, value, errors } = action.payload;

      return { ...state, [key]: value, errors: { ...state.errors, errors } };
    }

    case actions.INS_ANKETA_ADD_DRIVER: {
      const id = Helper.createNewDriverId(state, action.payload.id);
      const driver = Helper.createNewDriver(
        id,
        action.payload.isEmpty,
        action.payload.newDriverData
      );

      return {
        ...state,
        errors: { ...state.errors, [`driver@${id}`]: {} },
        drivers: [...state.drivers, driver],
      };
    }

    case actions.INS_ANKETA_ADD_CONFIDANT: {
      const driver = Helper.createNewDriverFromConfidant(action.payload.confidant);

      return {
        ...state,
        drivers: [...state.drivers, driver],
      };
    }

    case actions.INS_ANKETA_CLEAR_EMPTY_DRIVERS: {
      return { ...state, drivers: state.drivers.filter((item) => !item.empty) };
    }

    case actions.INS_ANKETA_DELETE_DRIVER: {
      const { id } = action.payload;
      const foundDriver = state.drivers.find((d) => d.id === id);

      let drivers;
      if (foundDriver.isNew) {
        drivers = state.drivers.filter((driver) => driver.id !== id);
      } else {
        drivers = state.drivers.map((driver) => {
          if (driver.id === id) {
            return { ...driver, isDeleted: true };
          } else {
            return driver;
          }
        });
      }
      const errors = { ...state.errors };

      delete errors[`driver@${id}`];

      if (!drivers.length && !state.isDriver && !state.kaskoInsurerIsDriver) {
        const id = Helper.createNewDriverId(state);
        const driver = Helper.createNewDriver(id, true);

        return {
          ...state,
          drivers: [...drivers, driver],
          errors: { ...errors, [`driver@${id}`]: {} },
        };
      }

      return { ...state, drivers };
    }

    case actions.INS_ANKETA_DROP_DRIVER: {
      const driverIndex = state.drivers.findIndex((driver) => driver.id === action.payload);
      const drivers = [...state.drivers];
      drivers[driverIndex] = { ...defaultDriver, id: action.payload };

      return { ...state, drivers };
    }

    case actions.INS_ANKETA_CHANGE_DRIVER: {
      const { key, value, id } = action.payload;
      const drivers = Helper.getChangedDrivers(state, { key, value, id });

      return { ...state, drivers };
    }

    case actions.INS_ANKETA_CHANGE_DRIVER_WERRORS: {
      const { key, value, id, errors } = action.payload;
      const drivers = state.drivers;
      const index = drivers.findIndex((it) => it.id === id);
      const driver = drivers[index];
      driver[key] = value;
      drivers[index] = driver;

      return {
        ...state,
        drivers,
        errors: {
          ...state.errors,
          [errors.level]: {
            ...state.errors[errors.level],
            [errors.key]: errors.value,
          },
        },
      };
    }

    case actions.INS_ANKETA_SET_ERROR: {
      const { level, key, value } = action.payload;

      if (!level) {
        return { ...state, errors: { ...state.errors, [key]: value } };
      }

      const levelError = state.errors[level]
        ? { ...state.errors[level], [key]: value }
        : { [key]: value };

      return { ...state, errors: { ...state.errors, [level]: levelError } };
    }

    case actions.INS_ANKETA_INSURER_SET_ERROR: {
      const { level, key, value } = action.payload;

      if (!level) {
        return {
          ...state,
          kaskoInsurer: {
            ...state.kaskoInsurer,
            errors: { ...state.kaskoInsurer.errors, [key]: value },
          },
        };
      }

      const levelError = state.kaskoInsurer.errors[level]
        ? { ...state.kaskoInsurer.errors[level], [key]: value }
        : { [key]: value };

      return {
        ...state,
        kaskoInsurer: {
          ...state.kaskoInsurer,
          errors: { ...state.kaskoInsurer.errors, [level]: levelError },
        },
      };
    }

    case actions.INS_ANKETA_DELETE_ERROR: {
      const { level, key } = action.payload;
      const errors = JSON.parse(JSON.stringify({ ...state.errors }));

      if (!level) {
        delete errors[key];

        return { ...state, errors };
      }

      const levelError = state.errors[level];

      if (levelError) {
        delete levelError[key];
      }

      return { ...state, errors: { ...state.errors, [level]: levelError } };
    }

    case actions.INS_ANKETA_INSURER_DELETE_ERROR: {
      const { level, key } = action.payload;
      const errors = JSON.parse(JSON.stringify({ ...state.kaskoInsurer.errors }));

      if (!level) {
        delete errors[key];

        return { ...state, kaskoInsurer: { ...state.kaskoInsurer, errors } };
      }

      const levelError = state.kaskoInsurer.errors[level];

      if (levelError) {
        delete levelError[key];
      }

      return {
        ...state,
        kaskoInsurer: {
          ...state.kaskoInsurer,
          errors: { ...state.kaskoInsurer.errors, [level]: levelError },
        },
      };
    }

    case actions.INS_ANKETA_DROP_ALL_ERRORS: {
      return { ...state, errors: {}, kaskoInsurer: { ...state.kaskoInsurer, errors: {} } };
    }

    case actions.INS_ANKETA_SET_ERRORS: {
      return { ...state, errors: { ...action.payload.errors } };
    }

    case actions.INS_ANKETA_INSURER_SET_ERRORS: {
      return {
        ...state,
        kaskoInsurer: {
          ...state.kaskoInsurer,
          errors: { ...state.kaskoInsurer.errors, ...action.payload.errors },
        },
      };
    }

    case actions.INS_ANKETA_DROP_ALL: {
      return { ...initialState };
    }

    case actions.INS_ANKETA_SWITCH_OWNER_DRIVER_CHECKBOX: {
      if (!state.drivers.length && state.isDriver) {
        const id = Helper.createNewDriverId(state);
        const driver = Helper.createNewDriver(id, true);

        return {
          ...state,
          drivers: [...state.drivers, driver],
          errors: { ...state.errors, [`driver@${id}`]: {} },
          isDriver: !state.isDriver,
          ownerIsDriver: !state.isDriver,
        };
      }

      return {
        ...state,
        kaskoInsurerIsDriver: false,
        isDriver: !state.isDriver,
        ownerIsDriver: !state.isDriver,
      };
    }

    case actions.INS_ANKETA_SWITCH_OWNER_INSURER_CHECKBOX: {
      if (state.isInsurer) {
        const insurer = state.savedInsurer ? { ...state.savedInsurer } : Helper.createInsurer();

        return {
          ...state,
          kaskoInsurer: insurer,
          isInsurer: false,
          ownerIsInsurer: false,
        };
      }

      return {
        ...state,
        isInsurer: true,
        ownerIsInsurer: true,
        savedInsurer: { ...state.kaskoInsurer },
      };
    }

    case actions.INS_ANKETA_SWITCH_INSURER_IS_DRIVER_CHECKBOX: {
      if (!state.drivers.length && state.kaskoInsurerIsDriver) {
        const id = Helper.createNewDriverId(state);
        const driver = Helper.createNewDriver(id, true);

        return {
          ...state,
          drivers: [...state.drivers, driver],
          errors: { ...state.errors, [`driver@${id}`]: {} },
          kaskoInsurerIsDriver: false,
        };
      }

      if (state.kaskoInsurerIsDriver) {
        return { ...state, kaskoInsurerIsDriver: false };
      }

      return { ...state, isDriver: false, kaskoInsurerIsDriver: true };
    }

    case actions.INS_ANKETA_SET_NODES: {
      return { ...state, nodes: action.payload.nodes };
    }

    case actions.INS_ANKETA_NEW_FILLED_CUSTOMERS: {
      return { ...state, filledCustomers: action.payload.filledCustomers };
    }

    case actions.INS_ANKETA_SET_DRIVERS: {
      return { ...state, drivers: action.payload.drivers };
    }

    case actions.INS_ANKETA_EXTRACT_DRIVER: {
      const { id: driverId } = action.payload;

      const customer = state.filledCustomers.find(({ id }) => id === driverId);

      if (customer) {
        return {
          ...state,
          drivers: [...state.drivers, backToFrontDriver({ customer })],
        };
      }

      return state;
    }

    case actions.INS_ANKETA_SET_CURRENT_SAVED_DATA: {
      return { ...state, currentSavedData: action.payload.currentSavedData };
    }

    case actions.INS_ANKETA_SET_SAVED_INSURER: {
      return { ...state, savedInsurer: { ...state.kaskoInsurer } };
    }

    case actions.INS_ANKETA_SAVE_SET_FETCH: {
      return { ...state, saveFetch: action.payload };
    }

    case actions.INS_ANKETA_SET_NETWORK_ERROR: {
      return { ...state, networkError: action.payload };
    }

    case actions.INS_ANKETA_SCROLL_TO: {
      return { ...state, scrollTo: action.payload.scrollTo };
    }

    case actions.INS_ANKETA_CLEAR_SCROLL_TO: {
      return { ...state, scrollTo: null };
    }

    case actions.INS_ANKETA_SWITCH_CALC_OPTION: {
      if (action.payload) {
        return { ...state, calcOption: action.payload };
      }

      return {
        ...state,
        calcOption:
          GLOBAL_ANKETA_CALC_OPTIONS[Number(state.calcOption === GLOBAL_ANKETA_CALC_OPTIONS[0])],
      };
    }

    case actions.INS_ANKETA_CLEAR_ALL_DRIVERS: {
      return {
        ...state,
        licenseDate: state.currentSavedData?.licenseDate ?? null,
        licenseSeriaNum: state.currentSavedData?.licenseSeriaNum ?? null,
        prevLicenseSeriaNum: state.currentSavedData?.prevLicenseSeriaNum ?? null,
        prevLicenseDate: state.currentSavedData?.prevLicenseDate ?? null,
        drivers: [],
        errors: {
          ...state.errors,
          licenseDate: '',
          licenseSeriaNum: '',
          prevLicenseSeriaNum: '',
          prevLicenseDate: '',
          ...state.drivers.reduce((acc, { id }) => ({ ...acc, [`driver@${id}`]: {} }), {}),
        },
      };
    }

    case actions.INS_ANKETA_RETURN_SAVED_DRIVERS: {
      if (!state.currentSavedData) {
        return { ...state };
      }

      return {
        ...state,
        isDriver: state.currentSavedData.isDriver,
        licenseDate: state.currentSavedData.licenseDate,
        licenseSeriaNum: state.currentSavedData.licenseSeriaNum,
        prevLicenseSeriaNum: state.currentSavedData.prevLicenseSeriaNum,
        prevLicenseDate: state.currentSavedData.prevLicenseDate,
        drivers: JSON.parse(JSON.stringify(state.currentSavedData.drivers)),
      };
    }

    case actions.INS_ANKETA_SET_ENABLED: {
      return { ...state, isDisabled: false };
    }

    case actions.INS_ANKETA_SET_DISABLED: {
      return { ...state, isDisabled: true };
    }

    case actions.EOW_ANKETA_SET_MAIN_FETCH: {
      return { ...state, mainFetch: action.payload };
    }

    default:
      return state;
  }
}

export function eowAnketaSetMainFetch(isFetching) {
  return {
    type: actions.EOW_ANKETA_SET_MAIN_FETCH,
    payload: isFetching,
  };
}

export function insAnketaSetCustomerShort(anketaCustomerId) {
  return {
    type: actions.INS_ANKETA_SET_CUSTOMER_SHORT,
    payload: { anketaCustomerId },
  };
}

export function insAnketaSwitchOwnerDriverCheckbox() {
  return {
    type: actions.INS_ANKETA_SWITCH_OWNER_DRIVER_CHECKBOX,
  };
}

export function insAnketaSwitchOwnerInsurerCheckbox() {
  return {
    type: actions.INS_ANKETA_SWITCH_OWNER_INSURER_CHECKBOX,
  };
}

export function insAnketaSwitchInsurerIsDriverCheckbox() {
  return {
    type: actions.INS_ANKETA_SWITCH_INSURER_IS_DRIVER_CHECKBOX,
  };
}

export function insAnketaSetInsurerDadataInfo(insurerDadataInfo) {
  return {
    type: actions.INS_ANKETA_SET_INSURER_DADATA_INFO,
    payload: insurerDadataInfo,
  };
}

export function insAnketaSetKaskoInsurerDadataInfo(insurerDadataInfo) {
  return {
    type: actions.INS_ANKETA_SET_KASKO_INSURER_DADATA_INFO,
    payload: insurerDadataInfo,
  };
}

export function insAnketaChangeByKey({ key, value }) {
  return {
    type: actions.INS_ANKETA_CHANGE_BY_KEY,
    payload: { key, value },
  };
}

export function insAnketaInsurerChangeByKey({ key, value }) {
  return {
    type: actions.INS_ANKETA_INSURER_CHANGE_BY_KEY,
    payload: { key, value },
  };
}

export function insAnketaChangeByKeyWithErrors({ key, value, errors }) {
  return {
    type: actions.INS_ANKETA_CHANGE_BY_KEY_WERRORS,
    payload: { key, value, errors },
  };
}

export function insAnketaChangeDriver({ key, value, id }) {
  return {
    type: actions.INS_ANKETA_CHANGE_DRIVER,
    payload: { key, value, id },
  };
}

export function insAnketaChangeDriverWithErrors({ key, value, id, errors }) {
  return {
    type: actions.INS_ANKETA_CHANGE_DRIVER_WERRORS,
    payload: { key, value, id, errors },
  };
}

export function insAnketaAddDriver(id, isEmpty = false, newDriverData = {}) {
  return {
    type: actions.INS_ANKETA_ADD_DRIVER,
    payload: { id, isEmpty, newDriverData },
  };
}

export function insAnketaAddConfidant(confidant) {
  return {
    type: actions.INS_ANKETA_ADD_CONFIDANT,
    payload: { confidant },
  };
}

export function insAnketaExtractDriver({ id }) {
  return {
    type: actions.INS_ANKETA_EXTRACT_DRIVER,
    payload: { id },
  };
}

export function insAnketaDeleteDriver({ id }) {
  return {
    type: actions.INS_ANKETA_DELETE_DRIVER,
    payload: { id },
  };
}

export function insAnketaDropDriver(id) {
  return {
    type: actions.INS_ANKETA_DROP_DRIVER,
    payload: id,
  };
}

export function insAnketaDropAll() {
  return {
    type: actions.INS_ANKETA_DROP_ALL,
  };
}

export function insAnketaDropAllErrors() {
  return {
    type: actions.INS_ANKETA_DROP_ALL_ERRORS,
  };
}

export function insAnketaSetErrors({ errors }) {
  return {
    type: actions.INS_ANKETA_SET_ERRORS,
    payload: { errors },
  };
}

export function insAnketaInsurerSetErrors({ errors }) {
  return {
    type: actions.INS_ANKETA_INSURER_SET_ERRORS,
    payload: { errors },
  };
}

export function insAnketaSetError({ id, key, value }) {
  return {
    type: actions.INS_ANKETA_SET_ERROR,
    payload: { level: id, key, value },
  };
}

export function insAnketaInsurerSetError({ id, key, value }) {
  return {
    type: actions.INS_ANKETA_INSURER_SET_ERROR,
    payload: { level: id, key, value },
  };
}

export function insAnketaDeleteError({ id, key }) {
  return {
    type: actions.INS_ANKETA_DELETE_ERROR,
    payload: { level: id, key },
  };
}

export function insAnketaInsurerDeleteError({ id, key }) {
  return {
    type: actions.INS_ANKETA_INSURER_DELETE_ERROR,
    payload: { level: id, key },
  };
}

export function insAnketaGetCustomersId({ applicationId, product, shouldCombineData }) {
  return {
    type: actions.INS_ANKETA_GET_CUSTOMERS_IDS,
    payload: { applicationId, product, shouldCombineData },
  };
}

export function insAnketaUpdateCustomer({ anketaId, isCashCustomer }) {
  return {
    type: actions.INS_ANKETA_PATCH_CUSTOMER,
    payload: { anketaId, isCashCustomer },
  };
}

export function insAnketaSetNetworkError(message = '') {
  return {
    type: actions.INS_ANKETA_SET_NETWORK_ERROR,
    payload: message,
  };
}

export function insAnketaScrollToCar(scrollTo) {
  return {
    type: actions.INS_ANKETA_SCROLL_TO,
    payload: { scrollTo },
  };
}

export function insAnketaClearScroll() {
  return {
    type: actions.INS_ANKETA_CLEAR_SCROLL_TO,
  };
}

export function insAnketaClear() {
  return {
    type: actions.INS_ANKETA_DROP_ALL,
  };
}

export function insAnketaClearEmptyDrivers() {
  return {
    type: actions.INS_ANKETA_CLEAR_EMPTY_DRIVERS,
  };
}

export function insAnketaSwitchCalcOption(calcOption) {
  return {
    type: actions.INS_ANKETA_SWITCH_CALC_OPTION,
    payload: calcOption,
  };
}

export function insAnketaClearAllDrivers() {
  return {
    type: actions.INS_ANKETA_CLEAR_ALL_DRIVERS,
  };
}

export function insAnketaSetCurrentSavedData(currentSavedData) {
  return {
    type: actions.INS_ANKETA_SET_CURRENT_SAVED_DATA,
    payload: { currentSavedData },
  };
}

export function insAnketaReturnSavedDrivers() {
  return {
    type: actions.INS_ANKETA_RETURN_SAVED_DRIVERS,
  };
}

export function insAnketaSetEnabled() {
  return {
    type: actions.INS_ANKETA_SET_ENABLED,
  };
}

export function insAnketaSetDisabled() {
  return {
    type: actions.INS_ANKETA_SET_DISABLED,
  };
}

export function eowAnketaCreateFirstOrder(payload) {
  return {
    type: actions.EOW_ANKETA_CREATE_FIRST_ORDER,
    payload,
  };
}

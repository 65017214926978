import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Icon, Label, RadioButton } from './style';

/**
 * @param name
 * @param checked
 * @param {[
 *   {id, value}
 * ]} items
 * @param {array} disabled
 * @param {boolean} required
 * @param {'default'|'button'|'button-rounded'} type
 * @param className
 * @param onClick
 * @returns {*}
 * @constructor
 */
export const Radio = ({
  name = '',
  checked = null,
  items = null,
  disabled = null,
  disabledIds = [],
  required = false,
  type = 'default',
  onClick = () => {},
  fixed = false,
  useStopPropagation = false,
  ...rest
}) => {
  const [checkedButton, setCheckedButton] = useState(checked);

  if (useStopPropagation) {
    rest.onClick = (evt) => {
      evt.stopPropagation();
    };
  }

  const handleChange = (id) => {
    if (!disabled && !fixed && !disabledIds.includes(id)) {
      setCheckedButton(id);
      onClick(name ? { [name]: id } : id);
    }
  };

  useEffect(() => {
    if (disabledIds.includes(checked)) {
      return;
    }
    if (checked !== null) {
      setCheckedButton(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <Wrapper {...rest} data-test="subsidy-сategories">
      {items &&
        items.map(({ id, value, sysName = '' }, r) => (
          <React.Fragment key={`_r_${r + 1}`}>
            {type === 'default' && (
              <RadioButton
                type={type}
                onClick={() => handleChange(id)}
                disabled={fixed}
                data-test={sysName}
              >
                <Icon
                  checked={checkedButton === id}
                  disabled={(disabled && disabledIds.includes(id)) || disabledIds.includes(id)}
                  required={required}
                />
                <Label>{value}</Label>
              </RadioButton>
            )}
            {['button-rounded', 'button'].indexOf(type) !== -1 && (
              <RadioButton
                type={type}
                checked={checkedButton === id}
                disabled={
                  (disabled && disabledIds.includes(id)) || fixed || disabledIds.includes(id)
                }
                required={required}
                rounded={type === 'button-rounded'}
                onClick={() => handleChange(id)}
                data-test={sysName}
              >
                {value}
              </RadioButton>
            )}
            {!type && (
              <RadioButton
                checked={checkedButton === id}
                onClick={() => handleChange(id)}
                data-test={sysName}
              >
                {value}
              </RadioButton>
            )}
          </React.Fragment>
        ))}
    </Wrapper>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.any,
  items: PropTypes.array,
  disabled: PropTypes.array,
  required: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

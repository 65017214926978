import styled from 'styled-components';

import { TriangleSImg } from 'assets/images';

const themes = {
  1: {
    colorBkg: 'var(--color-black)',
    colorText: 'var(--color-white)',
    margin: '10px',
    taleColor: 'transparent',
  },
  2: {
    colorBkg: 'var(--color-black)',
    colorText: 'var(--color-white)',
    margin: '14px',
    taleColor: 'var(--color-black)',
  },
};

const sizes = {
  s: {
    padding: '8px',
    borderRadius: '8px',
    font: 'var(--font-regular-13)',
  },
  l: {
    padding: '16px',
    borderRadius: '12px',
    font: 'var(--font-regular-15)',
  },
};

const positions = {
  top: { translate: 'translate(-50%, calc(-100% - var(--margin)))' },
  right: { translate: 'translate(var(--margin), -50%)' },
  bottom: { translate: 'translate(-50%, var(--margin))' },
  left: { translate: 'translate(calc(-100% - var(--margin)), -50%)' },
};

const tailPositions = {
  top: {
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%) rotate(180deg)',
  },
  bottom: {
    top: '0',
    left: '50%',
    transform: 'translateX(-50%) translateY(-100%) rotate(0deg)',
  },
  left: {
    right: '-17px',
    top: '50%',
    transform: 'translateY(-50%) rotate(90deg)',
  },
  right: {
    left: '7px',
    top: '50%',
    transform: 'translateX(-100%) translateY(-50%) rotate(-90deg)',
  },
};

export const Container = styled.div`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --margin: ${(p) => (themes[p.theme] || themes['1']).margin};
  --padding: ${(p) => (sizes[p.size] || sizes['s']).padding};
  --border-radius: ${(p) => (sizes[p.size] || sizes['s']).borderRadius};
  --font: ${(p) => (sizes[p.size] || sizes['s']).font};
  --tail-color: ${(p) => (themes[p.theme] || themes['1']).taleColor};
  --tail-top: ${(p) => tailPositions[p.position]?.top || 'auto'};
  --tail-bottom: ${(p) => tailPositions[p.position]?.bottom || 'auto'};
  --tail-left: ${(p) => tailPositions[p.position]?.left || 'auto'};
  --tail-right: ${(p) => tailPositions[p.position]?.right || 'auto'};
  --tail-transform: ${(p) => tailPositions[p.position]?.transform || 'rotate(0deg)'};

  position: fixed;
  max-width: 200px;
  padding: var(--padding);
  border-radius: var(--border-radius);
  background-color: var(--color-bkg);
  color: var(--color-text);
  font: var(--font);
  z-index: 999999999;
  transform: ${(p) => (positions[p.position] || positions['bottom']).translate};
`;

export const Tail = styled(TriangleSImg)`
  position: absolute;
  top: var(--tail-top);
  bottom: var(--tail-bottom);
  left: var(--tail-left);
  right: var(--tail-right);
  transform: var(--tail-transform);
  fill: var(--tail-color);
`;

/* eslint-disable no-undef */
import { put, call, select, take, delay } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { MERCURY_HEARTBEAT } from 'constants/index';
import { addAppNotification } from 'store/notifications';
import {
  reloadDeal,
  reloadDealDocuments,
  showVinRecognitionPopup,
  togglePhotoPopup,
  setVinRecognitionState,
  setErrorDeal,
} from 'store/deal';
import { aswCalcUpdApplication } from 'store/assistanceCalculation';
import { eowCalcUpdApplication } from 'store/insuranceCalculation';
import {
  setCarInstance,
  patchCarInstance,
  openCarInstanceDetails,
  closeCarInstanceDetails,
  dropErrors,
} from 'store/carInstance';
import { fetchAutocode } from 'data-providers/autocode';
import { getModels, getVersions } from 'store/carReference/sagas';
import { reloadApplication } from 'store/applications';
import { userIdObject } from 'store/notifications/userId';
import { createReadyMadeRisks } from 'store/insuranceCalculation/helper';
import { getUser, getDealers } from 'store/user';

import { addRecognizedFile } from '../fileRecognizer/actions';
import * as mapper from './mapper';
import * as dealHelpers from 'components/modules/Calculations/components/Loan/Loan.helpers';
import { shouldUpdateCarInfoVin, isCarInfoEmpty } from './helper';
import { showToast } from 'store/toasts';
import { loadEPointsInfo } from 'store/ePoints';
import { updateVinItem } from 'store/vinCheck';
import { vinNotificationTrack } from 'metrika/mainpage';
import { dealCarCardStepTrack } from 'metrika/deal';

const parseApplicationId = (path) => {
  if (!path) {
    return null;
  }

  return path.replace('/api/applications/', '');
};

let TOKEN_MERCURY = `${process.env.REACT_APP_BASE_MERCURY_TOKEN}`;
if (process.env.REACT_APP_MODE === 'dev') {
  TOKEN_MERCURY = `${process.env.REACT_APP_LOCAL_MERCURY_TOKEN}`;
}

function createFreshJobsChannel() {
  let intervalId = null;
  let es = null;

  const unsubscribe = () => {
    if (es) {
      es.close();
    }
    if (intervalId) {
      clearInterval(intervalId);
    }

    console.log('unsubscribe');
  };

  const subscribe = (emitter) => {
    if (!userIdObject.get()) {
      emitter(END);
      return unsubscribe;
    }

    console.log('subscribe');

    es = new EventSourcePolyfill(`${process.env.REACT_APP_BASE_MERCURY}${userIdObject.get()}`, {
      headers: {
        Authorization: `Bearer ${TOKEN_MERCURY}`,
      },
      heartbeatTimeout: MERCURY_HEARTBEAT,
    });

    es.onopen = () => {
      intervalId = setInterval(() => {
        if (!userIdObject.get()) {
          emitter({ data: '' });
          emitter(END);
        }
      }, 100);
    };

    es.onmessage = (event) => {
      if (userIdObject.get()) {
        emitter(event);
      } else {
        emitter(END);
      }
    };

    return unsubscribe;
  };

  return eventChannel(subscribe);
}

// TODO: вынести методы подготовки данных
export function* freshJobsSaga() {
  let channel = null;
  let isOpen = false;
  let note = null;

  while (true) {
    userIdObject.set(yield select((state) => state?.user?.id || null));

    if (userIdObject.get()) {
      if (!isOpen) {
        channel = yield call(createFreshJobsChannel);
        isOpen = true;
      }

      note = yield take(channel);
    } else {
      isOpen = false;
      yield delay(1000);
      note = { data: '' };
    }

    const { carInstance } = yield select((state) => state.carInstance);
    const { brands } = yield select((state) => state.carReference);

    if (note.data.length) {
      const payload = JSON.parse(note.data);
      const { applicationId } = payload.data;

      console.log('--> MERCURY\n', payload);

      if (payload.category.id === 'change-user-settings') {
        yield put(getUser());
      }

      if (payload.category.id === 'change-dealer-settings') {
        yield put(getDealers());
      }

      if (payload.category.id === 'application_status') {
        yield put(reloadApplication(applicationId));
      }

      if (payload.category.id === 'application_bank_status') {
        yield put(reloadApplication(applicationId));
      }

      if (payload.category.id === 'digital_deal_documents_updated') {
        yield put(reloadDealDocuments(applicationId));
        yield put(togglePhotoPopup(false));
      }

      if (payload.category.id === 'status_approved') {
        yield put(addAppNotification(mapper.getAppNotification(payload)));
      }

      if (
        ['assistance_policy', 'assistance_cancelaton', 'assistance_bill_generated'].includes(
          payload.category.id
        )
      ) {
        const application = JSON.parse(payload.data.application);
        const applicationId = application.id;
        const { applications } = yield select((state) => state.assistanceCalculation);

        if (applications[applicationId]) {
          yield put(
            aswCalcUpdApplication({
              applicationId,
              newData: { ...application },
            })
          );
        }
      }

      if (
        [
          'insurance_agreement',
          'insurance_policy',
          'insurance_cancelaton',
          'documents_reloaded',
          'bill_generated',
          'invoice_has_been_paid',
        ].includes(payload.category.id)
      ) {
        const application = JSON.parse(payload.data.application);
        const applicationId = application.id;
        const { applications } = yield select((state) => state.insuranceCalculation);

        if (applications[applicationId]) {
          // Удаляем offers и selectedOffer из ответа, вместо того чтобы снова маппить их
          // с risks на фронте, т.к. они не меняются начиная с agreement_requested
          // и уже лежат в сторе, смапленные, из синхронных ответов на agreement и policy
          delete application.offers;
          delete application.selectedOffer;

          yield put(
            eowCalcUpdApplication({
              applicationId,
              newData: { ...application },
            })
          );
        }
      }

      if (payload.category.id === 'insurance_offer') {
        if (!payload.data?.offer) {
          continue;
        }

        const offer = JSON.parse(payload.data.offer);
        const parsedApplicationId = parseApplicationId(offer.application);
        const { applications } = yield select((state) => state.insuranceCalculation);
        const applicationData = applications[parsedApplicationId];

        if (!applicationData) {
          continue;
        }

        const receivedRisks = JSON.parse(payload.data.risks ?? []);
        let applicationOffers = [...applicationData.offers];

        if (receivedRisks?.length) {
          const { risks, offersWithRisks } = createReadyMadeRisks(receivedRisks, applicationOffers);
          if (applicationData.risksData) {
            risks.risksHaveChanged = applicationData.risksData.risksHaveChanged ?? false;
          }
          applicationData.risks = receivedRisks;
          applicationData.risksData = risks;
          applicationOffers = offersWithRisks;
        }

        const targetOfferIndex = applicationOffers.findIndex((el) => el.id === offer.id);
        applicationOffers[targetOfferIndex] = { ...applicationOffers[targetOfferIndex], ...offer };

        if (applicationData.selectedOffer) {
          const selectedOfferInOffersList = applicationOffers.find(
            (offer) => offer.id === applicationData.selectedOffer.id
          );
          applicationData.selectedOffer = { ...selectedOfferInOffersList };
        }

        yield put(
          eowCalcUpdApplication({
            applicationId: parsedApplicationId,
            newData: { ...applicationData, offers: applicationOffers },
          })
        );
      }

      if (payload.category.id === 'application_files_upload') {
        yield put(addRecognizedFile(payload.data));
      }

      if (payload.category.id === 'loan_issue_status') {
        yield put(reloadDeal(applicationId, payload.data?.state));
      }

      if (
        payload.category.id === 'car_info' &&
        payload?.data?.carInfo?.carInstanceId === carInstance.id
      ) {
        if (isCarInfoEmpty(payload?.data?.carInfo)) {
          yield put(setErrorDeal('Проверьте корректность введённого VIN'));
        }
        const deal = yield select((state) => state.deal);
        const dealerName = yield select((state) => state.startPage?.application?.dealer?.name);
        const worksheetId = yield select((state) => state.startPage.application?.id);
        let newCarInstance = { ...carInstance };
        let shouldPatch = false;
        let shouldExpandCarDetails = false;
        const carInfo = payload?.data?.carInfo;

        if (payload.data.errorInfo || !payload.data.carInfo) {
          newCarInstance = { ...newCarInstance };
          shouldExpandCarDetails = true;
        } else {
          if (carInfo.brand) {
            const refBrand = brands.find(
              (b) => b.value.toLowerCase() === carInfo.brand.toLowerCase()
            );
            if (refBrand) {
              newCarInstance = { ...newCarInstance, brand: refBrand.value };
              shouldPatch = true;
              if (
                carInstance.brand !== newCarInstance.brand ||
                carInstance.model !== newCarInstance.model
              ) {
                newCarInstance = { ...newCarInstance, version: null };
              }
              if (carInstance.brand !== newCarInstance.brand) {
                yield call(getModels, {
                  payload: {
                    brandId: refBrand.id,
                    vehicleStatus: newCarInstance.isNew ? 'new' : 'old',
                  },
                });
              }
              if (carInfo.model) {
                newCarInstance = { ...newCarInstance, model: carInfo.model };
                shouldPatch = true;
              }
              const { models: newModels } = yield select((state) => state.carReference);
              const refModel = newModels.find(
                (b) => b.value.toLowerCase() === carInfo.model.toLowerCase()
              );
              if (carInstance.model !== newCarInstance.model && refModel) {
                yield call(getVersions, {
                  payload: { brandId: refBrand.id, modelId: refModel.id },
                });
              }
            }
          }
          if (carInfo.versionName) {
            newCarInstance.version = carInfo.versionName;
            shouldPatch = true;
          }
          if (carInfo.enginePower) {
            newCarInstance = { ...newCarInstance, enginePower: carInfo.enginePower };
            shouldPatch = true;
          }
          if (carInfo.engine) {
            newCarInstance = { ...newCarInstance, engineVolume: carInfo.engine };
            shouldPatch = true;
          }
          if (carInfo.engineNumber) {
            newCarInstance = { ...newCarInstance, engineNumber: carInfo.engineNumber };
            shouldPatch = true;
          }
          if (carInfo.bodyNumber) {
            newCarInstance = { ...newCarInstance, bodyNumber: carInfo.bodyNumber };
            shouldPatch = true;
          } else if (carInfo.vin) {
            const deal = yield select((state) => state.deal);
            if (shouldUpdateCarInfoVin(deal?.state)) {
              newCarInstance = { ...newCarInstance, bodyNumber: carInfo.vin };
              shouldPatch = true;
            }
          }
          if (carInfo.glonassNumber) {
            newCarInstance = { ...newCarInstance, glonasNumber: carInfo.glonassNumber };
            shouldPatch = true;
          }
          if (carInfo.maxWeight) {
            newCarInstance = { ...newCarInstance, permittedMaxWeight: carInfo.maxWeight };
            shouldPatch = true;
          }
          if (carInfo.pts && carInfo.pts !== '-') {
            newCarInstance = {
              ...newCarInstance,
              pts: {
                ...newCarInstance.pts,
                number: carInfo.pts,
              },
            };
            shouldPatch = true;
          }
          if (carInfo?.carDocument?.date) {
            newCarInstance = {
              ...newCarInstance,
              pts: {
                ...newCarInstance.pts,
                issueDate: carInfo?.carDocument?.date,
              },
            };
            shouldPatch = true;
          }
          if (carInfo.vin) {
            newCarInstance = { ...newCarInstance, vin: carInfo.vin };
            shouldPatch = true;
          }
          if (carInfo.year) {
            newCarInstance = { ...newCarInstance, year: carInfo.year };
            shouldPatch = true;
          }
          newCarInstance = { ...newCarInstance };
          shouldExpandCarDetails = false;
        }
        const shouldHideCarDetails =
          carInfo.enginePower &&
          carInfo.engineNumber &&
          carInfo.bodyNumber &&
          carInfo.maxWeight &&
          carInfo.engine;
        yield put(setCarInstance(newCarInstance));
        if (shouldExpandCarDetails && !shouldHideCarDetails) {
          yield put(openCarInstanceDetails());
        } else if (shouldHideCarDetails) {
          yield put(closeCarInstanceDetails());
        }
        if (shouldPatch) {
          yield put(dropErrors());
          yield put(patchCarInstance(newCarInstance, carInstance.id, true, [], {}, true));
          dealCarCardStepTrack('all', 'autofill', deal, deal?.credit?.providerName, dealerName);
        }
        if (deal.id && worksheetId) {
          dealHelpers.setLocalDealData(worksheetId, { carInstance: newCarInstance });
        }
      }
      if (payload.category.id === 'pts_recognition_info') {
        const deal = yield select((state) => state.deal);
        const dealerName = yield select((state) => state.startPage?.application?.dealer?.name);
        const { id: worksheetId } = yield select((state) => state.startPage.application);
        let recognizedVin = payload?.data?.result?.data?.vin;
        if (
          carInstance.steeringWheel === 'right' ||
          (recognizedVin && (recognizedVin || '').toLowerCase() === 'отсутствует')
        ) {
          recognizedVin = null;
        }
        if (recognizedVin && deal.id && worksheetId && !carInstance.vin) {
          dealCarCardStepTrack('vin', 'autofill', deal, deal?.credit?.providerName, dealerName);
          const newCarInstance = { ...carInstance, vin: recognizedVin };
          yield put(dropErrors());
          yield put(setCarInstance(newCarInstance));
          yield put(patchCarInstance(newCarInstance, carInstance.id, true, [], {}, true));
          dealHelpers.setLocalDealData(worksheetId, { carInstance: newCarInstance });
          fetchAutocode({
            isNew: newCarInstance.isNew,
            frontUserCredmenId: userIdObject.get(),
            searchType: 1,
            carNumber: newCarInstance.vin,
            carInstanceId: newCarInstance.id,
            brandName: newCarInstance?.brand || '',
          });
          yield put(showVinRecognitionPopup());
        }
        yield put(setVinRecognitionState(false));
      }

      if (payload.category.id === 'epoints-payment-income') {
        if (payload.data?.amount) {
          yield put(
            showToast({
              cardText: `Начислено ${payload.data?.amount} баллов`,
              cardTheme: '2',
              timeout: 3000,
              cardType: 'check',
            })
          );

          yield put(loadEPointsInfo());
        }
      }

      if (payload.category.id === 'car_scoring') {
        if (payload.data?.id) {
          yield put(updateVinItem(payload.data));

          if (payload.data?.errorInfo && payload.data?.requestEnded) {
            yield put(
              showToast({
                cardTitle: `Информация\nпо\u00A0${payload.data.vin}\nне\u00A0найдена`,
                cardText: 'Уточните правильность VIN номера\nи\u00A0запустите повторную проверку',
                cardTheme: '1',
                cardType: 'alert',
                direction: 'column',
                buttonTitle: 'Перейти к поиску',
                buttonTheme: '1',
                dataTest: 'mainPageVinCheckPush',
                onButtonClick: () => {
                  const url = '/start?vinHistoryOpen=true';
                  window.open(url, '_blank');
                },
              })
            );
          } else if (payload.data?.requestEnded) {
            yield put(
              showToast({
                cardTitle: `Загружены данные\nпо\u00A0${payload.data.vin}`,
                cardText: payload.data.model && `Автомобиль ${payload.data.model}`,
                cardTheme: '2',
                cardType: 'check',
                direction: 'column',
                buttonTitle: 'Посмотреть',
                buttonTheme: '1',
                dataTest: 'mainPageVinCheckPush',
                onButtonClick: () => {
                  vinNotificationTrack();

                  const url = '/start?vinHistoryOpen=true';
                  window.open(url, '_blank');
                },
              })
            );
          }
        }
      }
    }
  }
}

import styled from 'styled-components';

import { Button } from 'components/uikit/Button/Button';
import { EmptyNoFoundLImg, EmptyWrongLImg } from 'assets/images';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 32px;
  gap: 8px;
`;
export const Title = styled.div`
  padding-bottom: 32px;
  font: var(--font-heading-large-20_2);
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  min-height: 160px;
`;

export const NoFoundImg = styled(EmptyNoFoundLImg)``;

export const FetchErrorImg = styled(EmptyWrongLImg)``;

export const StyledButton = styled(Button)`
  width: 110px;
  margin: 12px 0 4px 0;
`;

import { useEffect, useRef } from 'react';

export const useCelluarInputPresenter = (code, codeSize, resended, error, setCode, onSubmit) => {
  const inputRefs = useRef([]);

  const presenter = {
    inputRefs,

    onHandleInputChange(event, index) {
      const value = event.target.value.replace(/\D/g, '');

      const newCode = [...code];
      newCode[index] = value;

      setCode(newCode);

      if (value !== '' && index < codeSize - 1) {
        inputRefs.current[index + 1].focus();
      }

      if (newCode.every((digit) => digit !== '') && newCode.length === codeSize) {
        onSubmit(newCode.join(''));
      }
    },

    onHandleKeyDown(event, index) {
      if (event.key === 'Backspace') {
        event.preventDefault();

        const newCode = [...code];

        if (newCode[index] === '' && index > 0) {
          inputRefs.current[index - 1].focus();
          newCode[index - 1] = '';
        } else {
          newCode[index] = '';
        }

        setCode(newCode);
      }
    },

    onHandlePaste(event) {
      event.preventDefault();

      let pastedCode = event.clipboardData.getData('text');
      pastedCode = pastedCode.replace(/\D/g, '').split('');

      const updatedCode = [...code];

      updatedCode.splice(0, codeSize, ...pastedCode.slice(0, codeSize));

      setCode(updatedCode);

      if (updatedCode.every((digit) => digit !== '') && updatedCode.length === codeSize) {
        onSubmit(updatedCode.join(''));
      }

      const lastFilledIndex = updatedCode.findIndex((digit) => digit === '');

      if (lastFilledIndex >= 0 && lastFilledIndex < codeSize) {
        inputRefs.current[lastFilledIndex].focus();
      } else if (lastFilledIndex === -1) {
        setTimeout(() => {
          if (inputRefs.current[codeSize - 1]) {
            inputRefs.current[codeSize - 1].focus();
          }
        }, 0);
      }
    },
  };

  useEffect(() => {
    setTimeout(() => {
      inputRefs.current[0].focus();
    }, 0);
  }, []);

  useEffect(() => {
    if (resended) {
      setCode('');
      setTimeout(() => {
        inputRefs.current[0].focus();
      }, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resended, codeSize]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        if (inputRefs.current[codeSize - 1]) {
          inputRefs.current[codeSize - 1].focus();
        }
      }, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, codeSize]);

  return presenter;
};

import React, { useEffect, useState } from 'react';

import { useTimer } from 'hooks/useTimer';
import { secondsToTimerString } from 'utils/common';

import * as S from './style';

const fn = () => {};

export const ERRORS_LIST = {
  codeError: 'Введён некорректный код',
  otherError: 'Произошла ошибка, обратитесь в чат поддержки',
  limitError: 'Код введён неверно более 5 раз. Запросите SMS повторно по истечении 1 минуты',
};

export const SMSVerification = ({
  className,
  codeSize = 4,
  resendTimeout = 60,
  disabled = false,
  hasError = false,
  onVerification = fn,
  onResend = fn,
  onSuccess = fn,
}) => {
  const [isRequested, setIsRequested] = useState(false);
  const [error, setError] = useState(hasError ? 'codeError' : null);
  const timer = useTimer(resendTimeout);
  const [code, setCode] = useState('');

  const isViewResendButton = timer.seconds === 0;

  const changeHandler = async (value) => {
    setError(null);
    if (value.length === codeSize) {
      setIsRequested(true);
      const result = await onVerification(value);
      setIsRequested(false);

      if (result && typeof result === 'boolean') {
        onSuccess(value);
      } else {
        if (result && result.otherError) {
          setError(result.type);
        } else {
          setError('codeError');
        }
      }
    }
  };

  const resendCodeHandler = (e) => {
    e.preventDefault();
    setError(null);
    timer.start();
    onResend();
  };

  useEffect(() => {
    timer.start();
    return () => timer.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasError) {
      setError('codeError');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return (
    <S.Wrapper className={className}>
      <S.Input
        code={code}
        setCode={setCode}
        codeSize={codeSize}
        disabled={disabled || isRequested}
        onSubmit={changeHandler}
        error={ERRORS_LIST[error]}
      />

      <S.StyledButton disabled={!isViewResendButton || isRequested} onClick={resendCodeHandler}>
        {!isViewResendButton
          ? `Отправить повторно через ${secondsToTimerString(timer.seconds)}`
          : 'Отправить код еще раз'}
      </S.StyledButton>
    </S.Wrapper>
  );
};

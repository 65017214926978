export const BANK_MAX_SUM = [837];

export const CERTIFICATE_BLOCK_BANKS = [290, 851];

export const DEALS_STATUS = {
  FUNDED: 'Профинансировано',
  APPROVED: 'Одобрено',
  REQUESTED: 'Запрос банка',
  QUEUE: 'На рассмотрении',
  DECLINE: 'Отказ',
  OPENED: 'Доступные банки для подачи',
};

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { loadNotifications, resetNotifications } from 'store/notifications';
import theme from 'theme';

export const UserNotifications = () => {
  const rootDispatch = useDispatch();
  const userId = useSelector(({ user }) => user?.id);
  const unreadCount = useSelector(({ notifications }) => notifications?.unreadCount || 0);

  useEffect(() => {
    if (userId) {
      rootDispatch(loadNotifications({ userId }));
    } else {
      rootDispatch(resetNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const title = document.head.getElementsByTagName('title');
    if (title && title[0]) {
      let titleText = theme.config.defaultTitle || 'еКредит.';
      if (unreadCount > 0) {
        titleText = `(${unreadCount}) ${titleText}`;
      }

      title[0].innerText = titleText;
    }

    if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
      if (unreadCount > 0) {
        navigator.setAppBadge(unreadCount);
      } else {
        navigator.clearAppBadge();
      }
    }
  }, [unreadCount]);

  return null;
};

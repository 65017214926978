import styled from 'styled-components';

import { FormGridRowBase } from '../../style';
import { AnketaCheckBox, AnketaInputMask, AnketaFio, AreaInfo } from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. fio fio fio fio ph ph is '
    '. . . . . pi pi . '
    '. spFio spFio spBd spBd spPh spPh spIs '
    '. . . . . spPi spPi . '
    '. rs . . . . . .';
`;

export const FioTrustee = styled(AnketaFio)`
  grid-area: ${(p) => (p.isSpouse ? 'spFio' : 'fio')};
`;

export const CellPhone = styled(AnketaInputMask)`
  grid-area: ${(p) => (p.isSpouse ? 'spPh' : 'ph')};
`;

export const Birthday = styled(AnketaInputMask)`
  grid-area: spBd;
`;

export const RealtionshipStateToogle = styled(AnketaCheckBox)`
  grid-area: rs;
`;

export const PhoneInfo = styled(AreaInfo)`
  display: flex;
  items-justify: space-between;
  grid-area: ${(p) =>
    p.showPhoneInfo ? (p.isSpouse ? 'spPi' : 'pi') : p.isSpouse ? 'spIs' : 'is'};
  margin-top: ${(p) => (p.showPhoneInfo ? '-24px' : '20px')};
  width: ${(p) => (p.showPhoneInfo ? '100%' : '')};
  span {
    font-size: 12px;
    margin-right: auto;
    color: var(--color-red-2);
  }
`;

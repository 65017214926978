import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTimer } from 'hooks/useTimer';

import * as providers from 'data-providers/ePointsProvider';
import * as ePointsStore from 'store/ePoints';
import * as metrica from 'metrika/loyaltyPrograms';

export const useEpointsSMSVerificationPresenter = (codeSize, resendTimeout, onHandleOpened) => {
  const rootDispatch = useDispatch();

  const [code, setCode] = useState('');
  const [isRequested, setIsRequested] = useState(false);
  const [isRecended, setIsRecended] = useState(false);
  const [SMSError, setSMSError] = useState('');

  const timer = useTimer(resendTimeout);

  const presenter = {
    isRequested,
    isRecended,
    SMSError,
    timer,
    code,
    setCode,

    async onSendHandler(value) {
      setSMSError('');
      setIsRecended(false);

      if (value.length === codeSize && !isRequested) {
        setIsRequested(true);

        try {
          const res = await providers.ePointsPhoneNumberConfirm(value);
          if (res.code === 200) {
            onHandleOpened('smsConfirm', 'registrationFinal');
            setSMSError('');
            rootDispatch(ePointsStore.loadEPointsInfo());
            setIsRequested(false);
          }
        } catch (err) {
          console.error(err);
          setSMSError(err.response.data.error?.message || err.response.data.error);
          setIsRequested(false);
        } finally {
          setIsRequested(false);
        }
      }
    },

    async onResendCodeHandler(e) {
      e.preventDefault();
      setSMSError('');
      setIsRecended(true);
      metrica.resendSMSButtonTrack();

      if (!isRequested) {
        timer.start();
        setIsRequested(true);

        try {
          await providers.ePointsPhoneNumberResend();
        } catch (err) {
          console.error(err);
          setSMSError(err.response.data.error?.message || err.response.data.error);
          setIsRequested(false);
        } finally {
          setIsRequested(false);
        }
      }
    },

    handleInputChange(newCode) {
      setCode(newCode);
      presenter.onSendHandler(newCode);
    },
  };

  useEffect(() => {
    timer.start();
    return () => timer.stop();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return presenter;
};

import React from 'react';

import * as S from './IconButton.styles';

export const IconButton = (props) => {
  const {
    className,
    theme = '1',
    size = 'm',
    Icon,
    disabled = false,
    dataTest = '',
    onClick = () => {},
  } = props;

  return (
    <S.Container
      className={className}
      theme={theme}
      size={size}
      disabled={disabled}
      data-test={dataTest}
      onClick={onClick}
    >
      <Icon />
    </S.Container>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import { FieldState } from '../FieldState/FieldState';
import { Skeleton } from 'components/uikit/Skeleton/Skeleton';
import { convertCoinsToString } from 'utils/convertPrice';
import { dateFormat } from 'utils/dateUtils';
import { onDidgitalDealClickTrack } from 'metrika/mainpage';

import * as S from './TableItem.styles';

export const useTableItemPresenter = (data) => {
  const history = useHistory();

  const fieldsStates = {
    skeletonState: <Skeleton height={'20px'} />,
    emptyState: <S.FinallyEmptyState>Нет данных</S.FinallyEmptyState>,
  };

  const presenter = {
    get vinNumber() {
      return data?.vin || '';
    },

    get vinError() {
      return data?.errorInfo || '';
    },

    get modelName() {
      if (data?.model === null && !data?.requestEnded) {
        return fieldsStates.skeletonState;
      }

      if (data?.model === null && data?.requestEnded) {
        return fieldsStates.emptyState;
      }

      return data?.model || 'Ошибка';
    },

    get modelMaxPrice() {
      if (data?.maxPrice === null && !data?.requestEnded) {
        return fieldsStates.skeletonState;
      }

      if (data?.maxPrice === null && data?.requestEnded) {
        return fieldsStates.emptyState;
      }

      return data?.maxPrice ? convertCoinsToString(data?.maxPrice, false) : 'Ошибка';
    },

    pledges() {
      if (data?.pledges === null && !data?.requestEnded) {
        return fieldsStates.skeletonState;
      }

      if (data?.pledges === null && data?.requestEnded) {
        return fieldsStates.emptyState;
      }

      return data?.pledges ? (
        <FieldState
          text={'Да'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nТребуется убрать автомобиль\nиз\u00A0реестра залогов`
          }
          fieldState={data.pledges}
        />
      ) : (
        <FieldState
          text={'Нет'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nАвтомобиль не в залоге\nи\u00A0принадлежит владельцу`
          }
          fieldState={data.pledges}
        />
      );
    },

    taxiAndCarSharing() {
      if ((data?.taxi === null || data?.carsharing === null) && !data?.requestEnded) {
        return fieldsStates.skeletonState;
      }

      if ((data?.taxi === null || data?.carsharing === null) && data?.requestEnded) {
        return fieldsStates.emptyState;
      }

      return data?.taxi || data?.carsharing ? (
        <FieldState
          text={'Да'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nАвтомобиль\nиспользовался в\u00A0такси\nили\u00A0каршеринге`
          }
          fieldState={data.taxi}
        />
      ) : (
        <FieldState
          text={'Нет'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nАвтомобиль\nне\u00A0использовался в\u00A0такси\nили\u00A0каршеринге`
          }
          fieldState={data.taxi}
        />
      );
    },

    restrictions() {
      if (data?.restrictions === null && !data?.requestEnded) {
        return fieldsStates.skeletonState;
      }

      if (data?.restrictions === null && data?.requestEnded) {
        return fieldsStates.emptyState;
      }

      return data?.restrictions ? (
        <FieldState
          text={'Да'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nЕсть ограничения\nна\u00A0данный автомобиль`
          }
          fieldState={data.restrictions}
        />
      ) : (
        <FieldState
          text={'Нет'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nПо данному автомобилю\nнет ограничений`
          }
          fieldState={data.restrictions}
        />
      );
    },

    seriousDamage() {
      if (data?.seriousDamage === null && !data?.requestEnded) {
        return fieldsStates.skeletonState;
      }

      if (data?.seriousDamage === null && data?.requestEnded) {
        return fieldsStates.emptyState;
      }

      return data?.seriousDamage ? (
        <FieldState
          text={'Да'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(data.requestEndedDatetime)}\n\nАвтомобиль был\nучастником ДТП`
          }
          fieldState={data.seriousDamage}
        />
      ) : (
        <FieldState
          text={'Нет'}
          tooltipText={
            data.requestEnded &&
            `Данные от ${dateFormat(
              data.requestEndedDatetime
            )}\n\nАвтомобиль не был\nучастником ДТП`
          }
          fieldState={data.seriousDamage}
        />
      );
    },

    onHandleClick() {
      onDidgitalDealClickTrack();

      history.push('/worksheet/create-new/calculator');
    },
  };

  return presenter;
};

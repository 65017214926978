import styled from 'styled-components';

import { ReadedIcon, UnreadedIcon } from 'assets/img';
import * as UK from 'components/primitives/content';
import BankLogo from 'components/common/BankLogo';

export const Title = styled(UK.Title3)`
  margin-bottom: 8px;
  margin: 0px;
  font-weight: ${(p) => (p.isRead ? 400 : 500)};
  text-transform: capitalize;
  color: ${(p) => (p.isRead ? 'var(--color-grey-5)' : 'var(--color-grey-8)')};
`;

export const Description = styled(UK.Description)`
  margin-bottom: 10px;
  line-height: 1;
`;

export const Wrapper = styled(UK.BorderBlock)`
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  border-color: ${(p) => (p.isRead ? 'var(--color-grey-1)' : 'var(--color-grey-4)')};
  box-shadow: ${(p) => (p.isRead ? 'none' : `0px 1px 3px 0px var(--color-grey-4)`)};

  &:hover,
  &:focus {
    background-color: ${(p) => (p.isRead ? 'var(--color-white)' : 'var(--color-grey-1)')};
    border-color: ${(p) => (p.isRead ? 'var(--color-grey-4)' : 'var(--color-grey-1)')};

    ${Title} {
      color: var(--color-grey-8);
    }
  }
`;

export const BankList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;

  & > * {
    margin-left: 8px;
  }
`;

export const BankItem = styled(BankLogo)`
  width: 23px;
  height: 23px;
  margin-top: 12px;
`;

export const IconReaded = styled(ReadedIcon)`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 20px;
  top: 14px;
`;

export const IconUnreaded = styled(UnreadedIcon)`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 20px;
  top: 15px;
`;

import React, { useRef } from 'react';

import { TooltipHover } from 'components/uikit/Tooltip/TooltipHover/TooltipHover';
import { AlertRoundMIcon } from 'assets/icons';

import * as S from './FieldState.styles';

export const FieldState = ({ text, tooltipText, fieldState }) => {
  const fieldRef = useRef();

  return (
    <S.Container ref={fieldRef}>
      {fieldState ? (
        <>
          <S.TrueTextWrapper>{text}</S.TrueTextWrapper>
          <S.StyledIcon Icon={AlertRoundMIcon} />
        </>
      ) : (
        <S.FalseTextWrapper>{text}</S.FalseTextWrapper>
      )}

      {tooltipText && (
        <TooltipHover
          targetRef={fieldRef}
          theme="2"
          dataTest={'mainPageVinHistoryTableItemTooltip'}
        >
          <S.StyledTooltipTitle>{tooltipText}</S.StyledTooltipTitle>
        </TooltipHover>
      )}
    </S.Container>
  );
};

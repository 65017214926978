import React from 'react';
import styled from 'styled-components';

export const WidgetStyled = styled.div`
  min-width: 182px;
  background-color: var(--color-grey-1);
  border-radius: 5px;
  padding: 20px 10px;
`;

const Widget = ({ children, className, dataTest }) => (
  <WidgetStyled className={`layout--x layout--y2 ${className}`} data-test={dataTest}>
    {children}
  </WidgetStyled>
);

export default Widget;

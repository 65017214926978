import React from 'react';

import { CheckCircleLIcon } from 'assets/icons';

import * as S from '../PopupChildren.styles';

const fn = () => {};

export const RegistrationFinal = ({ onClose = fn }) => {
  return (
    <S.FinalFormWrapper>
      <S.UpperInfo>
        <S.RegIconWrapper>
          <S.StyledCheckCircle Icon={CheckCircleLIcon} />
        </S.RegIconWrapper>
        <S.Title>Вы зарегистрированы</S.Title>
      </S.UpperInfo>
      <S.StyledButton onClick={onClose}>Ура!</S.StyledButton>
    </S.FinalFormWrapper>
  );
};

export const shouldUpdateCarInfoVin = (state) => {
  const dealStates = ['final_parameters', 'recalculation_desired', 'awaiting_manual_final_params'];
  return dealStates.includes(state?.name);
};

export const isCarInfoEmpty = (data) => {
  const excludeFields = [
    'bodyNumber',
    'engine',
    'engineNumber',
    'enginePower',
    'maxWeight',
    'price',
    'pts',
    'ptsIssueDate',
    'year',
  ];
  return excludeFields.every((f) => !data?.[f]);
};

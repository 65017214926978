import React from 'react';

import { CheckboxSIcon } from 'assets/icons';

import * as S from './Checkbox.styles';

const fn = () => {};

export const Checkbox = (props) => {
  const {
    className,
    children = '',
    value = false,
    name = '',
    theme = '1',
    size = 'l',
    error = '',
    disabled = false,
    onClick = fn,
    onChange = fn,
    onBlur = fn,
    onFocus = fn,
    dataTest = '',
  } = props;

  return (
    <S.Container
      className={className}
      theme={theme}
      size={size}
      checked={value}
      error={error}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <S.Checkbox checked={value} error={error} disabled={disabled}>
        {value && <S.Box Icon={CheckboxSIcon} />}
      </S.Checkbox>
      <S.Label error={error}>{children}</S.Label>
      <S.InnerCheckbox
        name={name}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={onChange}
        data-test={dataTest}
      />
    </S.Container>
  );
};

import styled from 'styled-components';

import { Button as UIButton } from 'components/uikit/Button/Button';
import { Button } from 'components/primitives/button';
import { H1 } from 'components/primitives/typography/headings/h1';
import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';

export const Wrapper = styled.div`
  padding: 30px 65px;
  position: relative;
  display: inline-block;
  min-width: 664px;
  margin: auto;
  background-color: var(--color-white);
  border-radius: 5px;
`;

export const PseudoButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  z-index: var(--zindex-top-modal);
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: var(--zindex-top-modal);

  path {
    fill: var(--color-grey-4);
  }
`;

export const Title = styled(H1)`
  margin-bottom: 30px;
  text-align: center;
`;

export const Text = styled(TextStyle1)`
  margin-bottom: 30px;
  text-align: center;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Cancel = styled(Button)`
  width: 94px;
  height: 45px;
  font-size: 14px;
  border: none;
  color: black;
  margin-right: 30px;
  margin-left: -94px;
  text-decoration: underline;
`;

export const Agree = styled(Button)`
  width: 206px;
  height: 60px;
  background: ${(p) => (p.attention ? 'var(--color-red-3)' : 'var(--color-blue-4)')};
  border-radius: 5px;
  color: #000000;
  font-size: 14px;
`;

export const AgreeButton = styled(UIButton)``;

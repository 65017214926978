import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  USER_LOGIN,
  USER_LOGGED_IN,
  USER_LOGOUT,
  USER_LOGGED_OUT,
  USER_LOGIN_ERROR,
  CHANGE_ACTIVE_DEALER,
  CHANGE_ACTIVE_ROLE,
  UPDATE_USER,
  USER_SET_FETCH,
  ACTIVE_DEALER_CHANGED,
  ACTIVE_ROLE_CHANGED,
  GET_USER,
  DEALERS_SET_FETCH,
  GET_DEALERS,
} from './constants';
import { SET_SEARCH } from 'store/filters/constants';
import * as authProvider from 'data-providers/authProvider';
import * as localStorage from 'utils/local-storage';
import { setDealerCookie, setRoleCookie, setAuthCookie, deleteCookies } from 'utils/helpers';
import { dealersSort } from './dealersSort';

function* getUserData() {
  try {
    const userInfo = yield authProvider.getCurrentUser();

    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      localStorage.saveItem('postauth', true);
    }

    setDealerCookie(userInfo.dealer);

    const roles = yield select((store) => store.user.roles);
    let currentRoleName = roles.filter((role) => `${role.id}` === `${userInfo.role}`);

    if (typeof currentRoleName[0] === 'object') {
      setRoleCookie(userInfo.role, currentRoleName[0].sysname);
    } else {
      setRoleCookie(userInfo.role, currentRoleName[0]);
    }

    localStorage.saveItem('user', userInfo);
    yield put({ type: USER_LOGGED_IN, payload: { ...userInfo } });
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function* getDealers() {
  try {
    let dealers;
    let nextPage = 2;
    const perPage = 100;

    yield put({ type: DEALERS_SET_FETCH, payload: true });

    let dealersReq = yield authProvider.getDealersDict({
      page: 1,
      perPage,
      order: 'asc',
    });

    const totalItems = dealersReq['hydra:totalItems'];
    dealers = [...dealersReq['hydra:member']];

    let lastPage = Math.ceil(totalItems / perPage);

    yield put({
      type: UPDATE_USER,
      payload: {
        dealers,
        loadedDealersNumber: dealers.length,
        totalDealersNumber: totalItems,
      },
    });

    do {
      const dealersReq = yield authProvider.getDealersDict({
        page: nextPage,
        perPage,
        order: 'asc',
      });

      nextPage = nextPage + 1;

      dealers = [...dealers, ...dealersReq['hydra:member']];
      dealers.sort(dealersSort);

      yield put({
        type: UPDATE_USER,
        payload: { dealers, loadedDealersNumber: dealers.length },
      });
    } while (nextPage < lastPage + 1);

    yield put({
      type: 'LOAD_DEALERS',
    });
  } catch (err) {
    console.error(err);
    throw err;
  } finally {
    yield put({ type: DEALERS_SET_FETCH, payload: false });
  }
}

// используется login из провайдера
function* login({ login, password }) {
  try {
    yield put({ type: USER_SET_FETCH, payload: true });

    const data = yield authProvider.login({ login, password });
    if (!data) {
      throw new Error('auth response data error');
    }

    const { token, refresh_token: refresh } = data;
    localStorage.saveItem('token', token);
    setAuthCookie(token.token);

    yield call(getUserData, { token, refresh, email: login });
  } catch (error) {
    const errStr = 'Неправильный логин или пароль';
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    yield put({ type: USER_LOGIN_ERROR, payload: { authError: errStr } });
    console.error(error);
  } finally {
    yield put({ type: USER_SET_FETCH, payload: false });
  }
}

function* logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('ks');
  localStorage.removeItem('mop');
  localStorage.removeItem('dealer');
  localStorage.removeItem('lastState');
  localStorage.removeItem('applicFiltersShow');
  yield put({ type: USER_LOGGED_OUT });
  yield put({ type: 'DROP_FILTERS' });
  yield put({ type: 'DROP_APPLICATIONS' });
  yield put({ type: SET_SEARCH, payload: '' });
  deleteCookies();
}

function* changeRole({ payload }) {
  const roles = yield select((store) => store.user.roles);
  const currentRole = roles.filter((role) => role.id === payload);

  setRoleCookie(payload, currentRole[0].sysname);
  const user = localStorage.getItem('user');
  user.role = payload;
  localStorage.saveItem('user', user);

  yield put({ type: ACTIVE_ROLE_CHANGED, payload });
}

function* changeDealer({ payload }) {
  setDealerCookie(payload);
  const user = localStorage.getItem('user');
  user.dealer = payload;
  localStorage.saveItem('user', user);

  yield put({ type: ACTIVE_DEALER_CHANGED, payload });
}

export function* watchLogin() {
  yield takeLatest(USER_LOGIN, login);
}

export function* watchLogout() {
  yield takeLatest(USER_LOGOUT, logout);
}

export function* watchGetUser() {
  yield takeLatest(GET_USER, getUserData);
}

export function* watchChangeRole() {
  yield takeLatest(CHANGE_ACTIVE_ROLE, changeRole);
}

export function* watchChangeDealer() {
  yield takeLatest(CHANGE_ACTIVE_DEALER, changeDealer);
}

export function* watchGetDealers() {
  yield takeLatest(GET_DEALERS, getDealers);
}
/*
export function* watchCheckAuth() {
	yield takeLatest(USER_CHECKAUTH, check);
}
*/

/*
export function* watchSendCode() {
  yield takeLatest(USER_CODE, sendCode);
}
*/

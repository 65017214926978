import React from 'react';
import { useToastManagerPresenter } from './ToastManager.presenter';

import { AttentionCard } from 'components/uikit/AttentionCard/AttentionCard';

import * as S from './ToastManager.styles';

const fn = () => {};

export const ToastManager = (props) => {
  const { className, theme = '1', dataTest = '', onClick = fn } = props;
  const presenter = useToastManagerPresenter(onClick);

  return (
    <S.Container
      theme={theme}
      isVisible={presenter.isAnimating}
      className={className}
      data-test={dataTest}
      onAnimationEnd={presenter.onAnimationEndHandler}
    >
      {presenter.currentToast && (
        <AttentionCard
          theme={presenter.currentToast.cardTheme}
          title={presenter.currentToast.cardTitle}
          text={presenter.currentToast.cardText}
          direction={presenter.currentToast.direction}
          type={presenter.currentToast.cardType}
          buttonTitle={presenter.currentToast.buttonTitle}
          buttonTheme={presenter.currentToast.buttonTheme}
          dataTest={presenter.currentToast.dataTest}
          onButtonClick={presenter.onButtonClickHandler}
          onClick={() => presenter.onCloseHandler(presenter.currentToast.id)}
        />
      )}
    </S.Container>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';

import { COLOR, CREDIT_STATUS_PARAMS } from 'constants/index';
import { statusMapSelector } from 'selectors';

import { LampItem } from '../../../LampItem';
import { SemaphoreWrapper } from './style';
import { getInfoByStatus } from 'utils/getStatusInfo';

const getStateInfo = (application, closeReason) => {
  const lastState = application?.lastState?.state || '';
  const reasonState = closeReason?.state || '';

  const stateName =
    lastState === 'archive' && reasonState === 'financed' ? 'archive-financed' : lastState;

  return getInfoByStatus(stateName);
};

export const Semaphore = ({ application, closeReason, isShowEmpty }) => {
  const statusMap = useSelector(statusMapSelector);

  const { dealStates: data, dealStatesByName } = application;
  const orderLamp = [COLOR.white, COLOR.green, COLOR.blue, COLOR.yellow, COLOR.red];

  if (isShowEmpty) {
    return (
      <>
        <SemaphoreWrapper>
          {Array(5)
            .fill('')
            .map((lamp, i) => (
              <LampItem key={i} color={'var(--color-grey-1)'} />
            ))}
        </SemaphoreWrapper>
      </>
    );
  }

  const lastStateName = application?.lastState?.stateSecondName;
  const lastState = application?.lastState?.state;

  if (!lastState) {
    return null;
  }

  const stateInfo = getStateInfo(application, closeReason);
  const reasonForClosure = statusMap.get(closeReason?.state);

  const showBalls = stateInfo.params.finalStatus;

  return (
    <>
      {!showBalls ? (
        <SemaphoreWrapper>
          {orderLamp.map((color) =>
            data[color].banks.length ? (
              <LampItem
                key={`_lamp_${color}`}
                label={CREDIT_STATUS_PARAMS[color].title}
                color={color}
                fontColor={CREDIT_STATUS_PARAMS[color].fontColor}
                popover={data[color].banks}
                popoverColor={color === COLOR.white ? CREDIT_STATUS_PARAMS[color].secondColor : ''}
              />
            ) : (
              <LampItem key={`_lamp_${color}`} color={'var(--color-grey-1)'} />
            )
          )}
        </SemaphoreWrapper>
      ) : (
        <LampItem
          label={lastStateName}
          color={stateInfo.color}
          fontColor={stateInfo.fontColor}
          popover={
            stateInfo.color.includes('--color-red')
              ? data[stateInfo.color].banks
              : dealStatesByName[lastState]
          }
          pure
          showState
          subLabel={reasonForClosure || ''}
        />
      )}
    </>
  );
};

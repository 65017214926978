import React from 'react';

import { ModalBubble } from './ModalBubble/ModalBubble';
import { TargetBubble } from './TargetBubble/TargetBubble';
import { useInfoBubblesPresenter } from './InfoBubbles.presenter';

export const InfoBubbles = () => {
  const presenter = useInfoBubblesPresenter();

  if (!presenter.bubble) {
    return null;
  }

  return (
    <>
      {presenter.bubble.type === 'modal' && (
        <ModalBubble data={presenter.bubble} onClose={presenter.onCloseBubble} />
      )}

      {presenter.bubble.type === 'target' && (
        <TargetBubble data={presenter.bubble} onClose={presenter.onCloseBubble} />
      )}
    </>
  );
};

import styled, { css } from 'styled-components';

import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';
import { TextStyle2 } from 'components/primitives/typography/textStyles/textStyle2';
import { Button } from 'components/primitives/button';
import { Carousel } from 'components/primitives/carousel';
import { Select } from 'components/primitives/select';
import { DoubleArrowSvg } from 'assets/img';
import Zoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 892px;
  height: calc(100% - 30px);
  margin: auto;
  background-color: var(--color-white);
`;

export const CloseButton = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: var(--zindex-top-modal);

  path {
    fill: var(--color-grey-4);
  }
`;

export const FileWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 0 68px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 25px;
`;

export const Title = styled(TextStyle1)`
  margin-bottom: 15px;
  text-align: center;
`;

export const TitleChangeType = styled(TextStyle1)`
  margin-bottom: 30px;
  text-align: center;
`;

export const ChangeType = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TypesSelect = styled(Select)`
  width: 207px;
  height: 45px;
  margin-right: 32px;
`;

export const SaveType = styled(Button)`
  width: 94px;
  height: 45px;
  font-size: 14px;
  margin-right: 32px;
  background: #7ebfed;
  border-radius: 5px;
  color: #000000;
`;

export const CancelType = styled(Button)`
  width: 94px;
  height: 45px;
  font-size: 14px;
  border: none;
  color: black;
  text-decoration: underline;
`;

export const Desc = styled(TextStyle2)`
  margin-bottom: 0;
`;

export const Controls = styled.div`
  position: absolute;
  width: 100px;
  top: 14px;
  right: 114px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 1;
`;

export const ControlButton = styled(Button)`
  position: relative;
  padding: 0;
  height: 20px;
  margin-left: 20px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--color-grey-4);
    }
  }
`;

export const ControlMenu = styled.div`
  position: absolute;
  width: 138px;
  top: 30px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: var(--color-white);
  border: 1px solid var(--color-grey-4);
  z-index: var(--zindex-top-modal);

  button:last-child {
    margin-bottom: 0;
  }
`;

export const MenuButton = styled(Button)`
  padding: 0;
  height: 20px;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const View = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-4);
  margin-bottom: 15px;
  padding-bottom: 15px;
  height: ${(p) =>
    !p.classify ? (p.other ? 'calc(100% - 235px)' : 'calc(100% - 240px)') : 'calc(100% - 300px)'};
  align-self: center;
`;

export const ControlView = styled.div`
  display: flex;
  width: 14px;
`;

export const List = styled.div``;

export const ImgZoomWrapper = styled.div`
  display: flex;

  > figure {
    width: 100%;
    display: flex;
    justify-content: center;

    > img {
      object-fit: contain;
    }
  }
`;

export const ImgWrapper = styled.div`
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100px;
  height: 110px;
  background-color: var(--color-white);
`;

export const ImgZoom = styled(Zoom)`
  display: block;
`;

export const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
  align-items: center;

  .tns-slider {
    display: flex;
    align-items: center;
    width: 400px;
  }
`;

export const ImgThumb = styled.img`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
`;

export const Loader = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  width: 250px;
`;

const navStyles = css`
  display: flex;
  align-items: center;
  width: 30px;
  padding: 0 5px;
  cursor: pointer;
`;

export const NavLeft = styled.div`
  ${navStyles};
  justify-content: flex-end;
  transform: rotate(180deg);
`;

export const NavRight = styled.div`
  ${navStyles};
  justify-content: flex-end;
`;

export const Icon = styled(DoubleArrowSvg)`
  width: 14px;
  height: 18px;
`;

import React, { useRef } from 'react';

import { useClipboardCopyPresenter } from './useClipboardCopyPresenter';
import { TooltipClick } from 'components/uikit/Tooltip/TooltipClick/TooltipClick';
import * as S from './ClipboardCopy.styles';

const HIDE_TIMEOUT = 1000;

export function ClipboardCopy({ className, value, children, dataTest }) {
  const presenter = useClipboardCopyPresenter(value, HIDE_TIMEOUT);

  const ref = useRef();

  return (
    <S.Wrapper
      className={className}
      onClick={presenter.handleClick}
      ref={ref}
      data-test={`${dataTest}-wrapper`}
    >
      {children}
      <S.CopyIcoWrap>
        {presenter.isShowFilledIcon ? (
          <S.CopyIcoFilled dataTest={`${dataTest}-iconFilled`} />
        ) : (
          <S.CopyIcoOutline dataTest={`${dataTest}-iconOutline`} />
        )}
      </S.CopyIcoWrap>

      <TooltipClick
        targetRef={ref}
        theme="1"
        size="s"
        position="top"
        hideTimeout={HIDE_TIMEOUT}
        dataTest={`${dataTest}-tooltip`}
      >
        Скопировано
      </TooltipClick>
    </S.Wrapper>
  );
}

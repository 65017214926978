import { put, select, takeLatest, call } from 'redux-saga/effects';
import { getInfoByStatus } from 'utils/getStatusInfo';
import { COLOR } from 'constants/index';
import {
  APPLICATIONS_LOAD,
  APPLICATION_RELOAD,
  SEARCH_APPLICATIONS_LOAD,
  SEARCH_APPLICATIONS_LOADED,
  SEARCH_APPLICATIONS_ADDED,
  APPLICATIONS_LOADED,
  APPLICATIONS_ADDED,
  APPLICATIONS_SET_FETCH,
  APPLICATIONS_SET_SEARCH_FETCH,
  EXPAND_APPLICATION,
  RELOAD_EXPANDED_APPLICATION,
  POST_APPLICATION_COMMENT,
  POST_APPLICATION_COMMENT_STORE,
  UPDATE_APPLICATION_ITEM,
  UPDATE_APPLICATION_SEARCH_ITEM,
  APPLICATIONS_CLOSE_REASONS_CATALOG_LOADED,
  GET_DETAIL_PARAMS,
  CHANGE_DETAIL_PARAMS,
  CAR_INSTANCE_DATA,
  CAR_INSTANCE_DATA_LOAD,
} from './constants';
import {
  DEAL_COMMISSIONS_FETCHING,
  DEAL_COMMISSIONS_LOADED,
  DEALS_LOADED,
  DEALS_ADDED,
  DEALS_SET_FETCH,
  LOAD_DEALS,
  SET_PAGINATOR,
  DEALS_ADDITIONAL_DATA_LOADED,
  DEALS_ADDITIONAL_DATA_ADDED,
} from '../deals/constants';
import * as applicationsProvider from 'data-providers/applicationsProvider';

const getSortingParams = (sorting) => {
  return Object.keys(sorting).reduce((acc, field) => {
    if (sorting[field] && sorting[field] !== 0) {
      acc.push({
        property: field,
        direction: sorting[field] < 0 ? 'DESC' : 'ASC',
      });
    }

    return acc;
  }, []);
};

function* loadApplications({ payload }) {
  try {
    yield put({ type: APPLICATIONS_SET_FETCH, payload: true });

    const pageParams = {
      page: payload.page || 1,
      perPage: payload.perPage || 20,
    };

    const filters = { dealer: [], lastState: [] };
    let appFilters = yield select((store) => store.applicFilters);
    appFilters = JSON.parse(JSON.stringify(appFilters));

    if (appFilters.dealer && Array.isArray(appFilters.dealer)) {
      filters.dealer = appFilters.dealer.filter((f) => f?.checked).map((f) => f.name);
    }

    if (appFilters.ks && Array.isArray(appFilters.ks)) {
      filters.ks = appFilters.ks.filter((f) => f?.checked).map((f) => f.name);
    }

    if (appFilters.mop && Array.isArray(appFilters.mop)) {
      filters.mop = appFilters.mop.filter((f) => f?.checked).map((f) => f.name);
    }

    if (appFilters.lastState && Array.isArray(appFilters.lastState)) {
      const optionAll = appFilters.lastState.find(({ name }) => name);
      filters.lastState = optionAll?.isCheckedAllInListStatus ? [] : appFilters.lastState;
      filters.lastState = filters.lastState
        .filter((f) => f.name !== 'all')
        .filter((f) => f.checked)
        .map((f) => f.name);
    }
    if (appFilters.frontProxy) {
      filters.isFrontProxy = appFilters.frontProxy;
    }

    const sortingParams = {};
    const sorting = getSortingParams(appFilters.sorting || {});
    if (sorting.length > 0) {
      sortingParams.sort = JSON.stringify(sorting);
    }

    let catalog = yield select((store) => store.applications.catalog);
    if (!catalog) {
      catalog = yield call(applicationsProvider.getApplicationsCloseReasonsCatalog);
      yield put({ type: APPLICATIONS_CLOSE_REASONS_CATALOG_LOADED, payload: catalog });
    }

    const params = { ...pageParams, ...filters, ...sortingParams };
    const data = yield call(applicationsProvider.getApplications, params);

    const action = Number(payload.page) > 1 ? APPLICATIONS_ADDED : APPLICATIONS_LOADED;
    yield put({ type: action, payload: data['hydra:member'] });
  } catch (err) {
    console.log(err);
  } finally {
    yield put({ type: APPLICATIONS_SET_FETCH, payload: false });
  }
}

function* reloadApplication({ payload }) {
  try {
    const apps = yield select((store) => store.applications?.data || {});
    const appsSearch = yield select((store) => store.applications?.searchData || {});

    const targetApps = Object.keys(appsSearch).length === 0 ? apps : appsSearch;

    const oldApp = Object.values(targetApps).find(
      (a) => a.ids?.credit && String(a.ids.credit) === String(payload.creditAppId)
    );

    if (!oldApp) {
      return;
    }

    const data = yield call(applicationsProvider.getWorksheetById, oldApp.id);
    if (!data?.id) {
      return;
    }

    const actionType =
      Object.keys(appsSearch).length === 0
        ? UPDATE_APPLICATION_ITEM
        : UPDATE_APPLICATION_SEARCH_ITEM;
    yield put({ type: actionType, payload: data });
  } catch (err) {
    console.log(err);
  }
}

function* loadApplicationsSearch({ payload }) {
  try {
    yield put({ type: APPLICATIONS_SET_SEARCH_FETCH, payload: true });

    const pageParams = {
      page: payload.page || 1,
      perPage: payload.perPage || 20,
    };

    let appFilters = yield select((store) => store.applicFilters);
    appFilters = JSON.parse(JSON.stringify(appFilters));

    const searchParams = { search: (appFilters?.search || '').trim() };

    const sortingParams = {};
    const sorting = getSortingParams(appFilters.sorting || {});
    if (sorting.length > 0) {
      sortingParams.sort = JSON.stringify(sorting);
    }

    let catalog = yield select((store) => store.applications.catalog);
    if (!catalog) {
      catalog = yield call(applicationsProvider.getApplicationsCloseReasonsCatalog);
      yield put({ type: APPLICATIONS_CLOSE_REASONS_CATALOG_LOADED, payload: catalog });
    }

    const action =
      Number(payload.page) > 1 ? SEARCH_APPLICATIONS_ADDED : SEARCH_APPLICATIONS_LOADED;

    if (searchParams.search) {
      const params = { ...pageParams, ...searchParams, ...sortingParams };
      const data = yield call(applicationsProvider.getApplications, params);
      yield put({ type: action, payload: data['hydra:member'] });
    } else {
      yield put({ type: action, payload: [] });
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put({ type: APPLICATIONS_SET_SEARCH_FETCH, payload: false });
  }
}

function* loadExt({ payload }) {
  try {
    let application = yield select((store) => store.applications.data[payload.id]);

    if (!(application && application.id)) {
      application = yield select((store) => store.applications.searchData[payload.id]);
    }

    if (!application || !application.ids?.credit) {
      return;
    }

    yield loadDeals({
      payload: {
        id: application.id,
        applicId: application.ids.credit,
        isReload: payload.isReload,
        page: 1,
      },
    });

    yield put({ type: UPDATE_APPLICATION_ITEM, payload: application });
  } catch (err) {
    console.log(err);
  }
}

function* loadDeals({ payload }) {
  const { id, applicId, isReload = false, page = 1 } = payload;

  try {
    yield put({ type: DEALS_SET_FETCH, payload: true });
    let data = yield applicationsProvider.getApplicationsDeals({
      params: { perPage: 100 },
      applicId,
    });
    let deals = data['hydra:member'].map((deal) => ({
      id: deal['@id'],
      ...deal,
    }));

    const dealsList = deals.reduce((acc, deal) => {
      const stateKey = deal.state.state;
      if (!acc[stateKey]) {
        acc[stateKey] = [deal];
      } else {
        acc[stateKey].push(deal);
      }
      return acc;
    }, {});

    const dealsStatusFormat = {};

    for (let key in dealsList) {
      const promises = [];
      const statusInfo = getInfoByStatus(key);
      const isApproved =
        (statusInfo.params.baseStatus || statusInfo.params.fundedStatus) &&
        statusInfo.color === COLOR.green;
      const approvIndex = isApproved ? 1 : 0;

      dealsList[key].forEach((deal) => {
        const otherDealPromise = applicationsProvider.getDealStatus(deal.id);
        promises.push(otherDealPromise);

        if (isApproved) {
          const method = isReload ? 'getDealAprovedInfoPersistently' : 'getDealAprovedInfo';
          promises.push(applicationsProvider[method](deal.id));
        }
      });

      const keyResults = yield Promise.all(promises);

      dealsList[key].forEach((deal, index) => {
        if (!deal.AdditionalData) {
          deal.AdditionalData = {};
        }
        const statusData = keyResults[index * (1 + approvIndex)];
        if (statusData) {
          deal.AdditionalData.statusData = {
            comment:
              statusData.data['hydra:member'][statusData.data['hydra:totalItems'] - 1].comment,
            bank_msg:
              statusData.data['hydra:member'][statusData.data['hydra:totalItems'] - 1].bank_msg,
          };
        }
        if (isApproved) {
          const approvData = keyResults[index * (1 + approvIndex) + 1];
          deal.AdditionalData.approvData = approvData;
        }
      });

      dealsStatusFormat[key] = keyResults;
    }

    yield put({
      type: page <= 1 ? DEALS_ADDITIONAL_DATA_LOADED : DEALS_ADDITIONAL_DATA_ADDED,
      payload: { applicId: id, dealsList },
    });

    yield put({
      type: page <= 1 ? DEALS_LOADED : DEALS_ADDED,
      payload: { applicId: id, deals },
    });

    const paginator = { total: data['hydra:totalItems'], page, perPage: 10 };
    yield put({
      type: SET_PAGINATOR,
      payload: { applicId: id, paginator },
    });
  } catch (err) {
    console.log(err);
    throw err;
  } finally {
    yield put({ type: DEALS_SET_FETCH, payload: false });
  }
}

function* loadDealsCommissions({ payload }) {
  const dealer = yield select((store) => store.user.dealer);

  const { applicId } = payload;

  yield put({ type: DEAL_COMMISSIONS_FETCHING, payload: true });

  try {
    const commissions = yield applicationsProvider.getDealsCommissions({
      dealer,
      payload,
    });

    yield put({
      type: DEAL_COMMISSIONS_LOADED,
      payload: { commissions, applicId },
    });
  } catch (err) {
    console.log(err);
    throw err;
  } finally {
    yield put({ type: DEAL_COMMISSIONS_FETCHING, payload: false });
  }
}

function* postComment({ payload }) {
  try {
    yield applicationsProvider.postComment(payload);
    yield put({
      type: POST_APPLICATION_COMMENT_STORE,
      payload: { ...payload },
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function* getParam({ payload }) {
  try {
    const data = yield call(applicationsProvider.getDetailParams, payload);

    if (data) {
      yield put({ type: CHANGE_DETAIL_PARAMS, payload: { dealId: payload, data: data } });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getCarData({ payload }) {
  try {
    const data = yield call(applicationsProvider.getCarInstanceData, payload);

    if (data) {
      yield put({ type: CAR_INSTANCE_DATA_LOAD, payload: { data: data, dealId: payload } });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetApplications() {
  yield takeLatest(APPLICATIONS_LOAD, loadApplications);
}

export function* watchReloadApplication() {
  yield takeLatest(APPLICATION_RELOAD, reloadApplication);
}

export function* watchGetApplicationsSearch() {
  yield takeLatest(SEARCH_APPLICATIONS_LOAD, loadApplicationsSearch);
}

export function* watchExpandApplication() {
  yield takeLatest(EXPAND_APPLICATION, loadExt);
}

export function* watchReloadExpandedApplication() {
  yield takeLatest(RELOAD_EXPANDED_APPLICATION, loadExt);
}

export function* watchLoadDeals() {
  yield takeLatest(LOAD_DEALS, loadDeals);
}

export function* watchDealsLoaded() {
  yield takeLatest(DEALS_LOADED, loadDealsCommissions);
}

export function* watchDealsAdded() {
  yield takeLatest(DEALS_ADDED, loadDealsCommissions);
}

export function* watchPostApplicationComment() {
  yield takeLatest(POST_APPLICATION_COMMENT, postComment);
}

export function* watchGetDetailParam() {
  yield takeLatest(GET_DETAIL_PARAMS, getParam);
}

export function* watchGetCareInstance() {
  yield takeLatest(CAR_INSTANCE_DATA, getCarData);
}

import { getItem } from 'utils/local-storage';

const handler401 = () => {
  if (!getItem('blockLoginRedirect')) {
    setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('ks');
      localStorage.removeItem('mop');
      localStorage.removeItem('dealer');
      localStorage.removeItem('lastState');
      localStorage.removeItem('applicFiltersShow');
      window.location = '/login';
    }, 500);
  }
};

export default handler401;

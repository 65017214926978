import styled from 'styled-components';

import { Icon } from 'components/uikit/Icon/Icon';

const themes = {
  1: {
    colorBkg: 'var(--color-black)',
    colorBkgHover: 'var(--color-grey-6)',
    colorBkgActive: 'var(--color-black)',
    colorBkgDisabled: 'var(--color-grey-10_10)',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderActive: 'transparent',
    colorBorderDisabled: 'transparent',
    colorText: 'var(--color-white)',
    colorTextHover: 'var(--color-white)',
    colorTextDisabled: 'var(--color-grey-11_60)',
    colorIcon: 'var(--color-white)',
    colorIconDisabled: 'var(--color-grey-11_60)',
  },
  2: {
    colorBkg: 'transparent',
    colorBkgHover: 'var(--color-grey-10_10)',
    colorBkgActive: 'transparent',
    colorBkgDisabled: 'transparent',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderActive: 'transparent',
    colorBorderDisabled: 'transparent',
    colorText: 'var(--color-black)',
    colorTextHover: 'var(--color-black)',
    colorTextDisabled: 'var(--color-grey-11_60)',
    colorIcon: 'var(--color-black)',
    colorIconDisabled: 'var(--color-grey-11_60)',
  },
  3: {
    colorBkg: 'var(--color-grey-10_10)',
    colorBkgHover: 'var(--color-grey-14)',
    colorBkgActive: 'var(--color-grey-10_10)',
    colorBkgDisabled: 'var(--color-grey-10_10)',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderActive: 'transparent',
    colorBorderDisabled: 'transparent',
    colorText: 'var(--color-black)',
    colorTextHover: 'var(--color-black)',
    colorTextDisabled: 'var(--color-grey-11_60)',
    colorIcon: 'var(--color-black)',
    colorIconDisabled: 'var(--color-grey-11_60)',
  },
  4: {
    colorBkg: 'var(--color-red-4)',
    colorBkgHover: 'var(--color-red-2)',
    colorBkgActive: 'var(--color-red-4)',
    colorBkgDisabled: 'var(--color-grey-10_10)',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderActive: 'transparent',
    colorBorderDisabled: 'transparent',
    colorText: 'var(--color-red-2)',
    colorTextHover: 'var(--color-white)',
    colorTextDisabled: 'var(--color-grey-11_60)',
    colorIcon: 'transparent',
    colorIconDisabled: 'transparent',
  },
  5: {
    colorBkg: 'var(--color-white)',
    colorBkgHover: 'var(--color-white_30)',
    colorBkgActive: 'var(--color-white)',
    colorBkgDisabled: 'var(--color-grey-9_16)',
    colorBorder: 'transparent',
    colorBorderHover: 'transparent',
    colorBorderActive: 'transparent',
    colorBorderDisabled: 'transparent',
    colorText: 'var( --color-black)',
    colorTextHover: 'var( --color-black)',
    colorTextDisabled: 'var(--color-white_68)',
    colorIcon: 'transparent',
    colorIconDisabled: 'transparent',
  },
};

const sizes = {
  s: {
    buttonHeight: '32px',
    font: 'var(--font-large-13)',
    borderRadius: '8px',
    iconSize: '16px',
    padding: '0px 16px;',
  },
  m: {
    buttonHeight: '44px',
    font: 'var(--font-large-15)',
    borderRadius: '12px',
    iconSize: '20px',
    padding: '0px 20px;',
  },
  l: {
    buttonHeight: '56px',
    font: 'var(--font-large-17)',
    borderRadius: '16px',
    iconSize: '20px',
    padding: '0px 24px;',
  },
  xl: {
    buttonHeight: '64px',
    font: 'var(--font-large-17)',
    borderRadius: '16px',
    iconSize: '20px',
    padding: '0px 24px;',
  },
};

export const Container = styled.button`
  --color-bkg: ${(p) => (themes[p.theme] || themes['1']).colorBkg};
  --color-bkg-hover: ${(p) => (themes[p.theme] || themes['1']).colorBkgHover};
  --color-bkg-active: ${(p) => (themes[p.theme] || themes['1']).colorBkgActive};
  --color-bkg-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBkgDisabled};
  --color-border: ${(p) => (themes[p.theme] || themes['1']).colorBorder};
  --color-border-hover: ${(p) => (themes[p.theme] || themes['1']).colorBorderHover};
  --color-border-active: ${(p) => (themes[p.theme] || themes['1']).colorBorderActive};
  --color-border-disabled: ${(p) => (themes[p.theme] || themes['1']).colorBorderDisabled};
  --color-text: ${(p) => (themes[p.theme] || themes['1']).colorText};
  --color-text-hover: ${(p) => (themes[p.theme] || themes['1']).colorTextHover};
  --color-text-disabled: ${(p) => (themes[p.theme] || themes['1']).colorTextDisabled};
  --color-icon: ${(p) => (themes[p.theme] || themes['1']).colorIcon};
  --color-icon-disabled: ${(p) => (themes[p.theme] || themes['1']).colorIconDisabled};
  --icon-size: ${(p) => (sizes[p.size] || sizes['m']).iconSize};
  --button-height: ${(p) => (sizes[p.size] || sizes['m']).buttonHeight};
  --font: ${(p) => (sizes[p.size] || sizes['m']).font};
  --border-radius: ${(p) => (sizes[p.size] || sizes['m']).borderRadius};
  --padding: ${(p) => (sizes[p.size] || sizes['m']).padding};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  width: auto;
  min-height: var(--button-height);
  max-height: var(--button-height);
  padding: var(--padding);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  background-color: var(--color-bkg);
  color: var(--color-text);
  font: var(--font);
  cursor: pointer;

  &:hover,
  &:focus-visible {
    border-color: var(--color-border-hover);
    background-color: var(--color-bkg-hover);
    color: var(--color-text-hover);
  }

  &:active {
    border-color: var(--color-border-active);
    background-color: var(--color-bkg-active);
  }

  &:disabled {
    border-color: var(--color-border-disabled);
    background-color: var(--color-bkg-disabled);
    color: var(--color-text-disabled);
    cursor: default;
  }
`;

export const BudgeWrapper = styled.span``;

export const StyledIcon = styled(Icon)`
  --color: ${(p) => (p.disabled ? 'var(--color-icon-disabled)' : 'var(--color-icon)')};
  --width: var(--icon-size);
  --height: var(--icon-size);
`;

export const Title = styled.span`
  white-space: nowrap;
`;

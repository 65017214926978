import { initialState } from './initialState';
import { backToFrontDriver } from './backToFrontMapper';
import { GLOBAL_ANKETA_CALC_OPTIONS } from 'constants/index';

import * as insuranceActions from 'store/insuranceAnketa/constants';
import * as actions from './constants';
import * as Helper from './helper';

export default function (state = initialState, action) {
  switch (action.type) {
    case insuranceActions.INS_ANKETA_SET_CUSTOMER: {
      const { mappedInsurer } = action.payload;
      return {
        ...state,
        fio: mappedInsurer?.fio || '',
        bday: mappedInsurer?.bday || '',
        insurerPhone: mappedInsurer?.insurerPhone || '',
      };
    }

    case actions.ASSISTANCE_SET_CUSTOMER: {
      const { mappedInsurer, filledCustomers, applicationId } = action.payload;

      return { ...state, ...mappedInsurer, filledCustomers, applicationId };
    }

    case actions.ASSISTANCE_SET_CUSTOMERS_FETCH: {
      return { ...state, customersFetch: action.payload };
    }

    case actions.ASSISTANCE_SET_INSURER_DADATA_INFO: {
      return {
        ...state,
        insurerDadataInfo: { ...state.insurerDadataInfo, ...action.payload },
      };
    }

    case actions.ASSISTANCE_CHANGE_BY_KEY: {
      const { key, value } = action.payload;

      return {
        ...state,
        [key]: value,
      };
    }

    case actions.ASSISTANCE_ADD_DRIVER: {
      const id = Helper.createNewDriverId(state, action.payload.id);
      const driver = Helper.createNewDriver(
        id,
        action.payload.isEmpty,
        action.payload.newDriverData
      );

      return {
        ...state,
        errors: { ...state.errors, [`driver@${id}`]: {} },
        drivers: [...state.drivers, driver],
      };
    }

    case actions.ASSISTANCE_ADD_CONFIDANT: {
      const driver = Helper.createNewDriverFromConfidant(action.payload.confidant);

      return {
        ...state,
        drivers: [...state.drivers, driver],
      };
    }

    case actions.ASSISTANCE_CLEAR_EMPTY_DRIVERS: {
      return { ...state, drivers: state.drivers.filter((item) => !item.empty) };
    }

    case actions.ASSISTANCE_DELETE_DRIVER: {
      const { id } = action.payload;

      const drivers = state.drivers.filter((it) => it.id !== id);
      const errors = { ...state.errors };

      delete errors[`driver@${id}`];

      if (!drivers.length && !state.isDriver) {
        const id = Helper.createNewDriverId(state);
        const driver = Helper.createNewDriver(id, true);

        return {
          ...state,
          drivers: [...drivers, driver],
          errors: { ...errors, [`driver@${id}`]: {} },
        };
      }

      return { ...state, drivers };
    }

    case actions.ASSISTANCE_CHANGE_DRIVER: {
      const { key, value, id } = action.payload;
      const drivers = Helper.getChangedDrivers(state, { key, value, id });

      return { ...state, drivers };
    }

    case actions.ASSISTANCE_SET_ERROR: {
      const { level, key, value } = action.payload;

      if (!level) {
        return { ...state, errors: { ...state.errors, [key]: value } };
      }

      const levelError = state.errors[level]
        ? { ...state.errors[level], [key]: value }
        : { [key]: value };

      return { ...state, errors: { ...state.errors, [level]: levelError } };
    }

    case actions.ASSISTANCE_DELETE_ERROR: {
      const { level, key } = action.payload;
      const errors = JSON.parse(JSON.stringify({ ...state.errors }));

      if (!level) {
        delete errors[key];

        return { ...state, errors };
      }

      const levelError = state.errors[level];

      if (levelError) {
        delete levelError[key];
      }

      return { ...state, errors: { ...state.errors, [level]: levelError } };
    }

    case actions.ASSISTANCE_DROP_ALL_ERRORS: {
      return { ...state, errors: {} };
    }

    case actions.ASSISTANCE_SET_ERRORS: {
      return { ...state, errors: { ...action.payload.errors } };
    }

    case actions.ASSISTANCE_DROP_ALL: {
      return { ...initialState };
    }

    case actions.ASSISTANCE_SWITCH_INSURER_DRIVER_CHECKBOX: {
      if (!state.drivers.length && state.isDriver) {
        const id = Helper.createNewDriverId(state);
        const driver = Helper.createNewDriver(id, true);

        return {
          ...state,
          drivers: [...state.drivers, driver],
          errors: { ...state.errors, [`driver@${id}`]: {} },
          isDriver: !state.isDriver,
        };
      }

      return { ...state, isDriver: !state.isDriver };
    }

    case actions.ASSISTANCE_SET_NODES: {
      return { ...state, nodes: action.payload.nodes };
    }

    case actions.ASSISTANCE_EXTRACT_DRIVER: {
      const { id: driverId } = action.payload;

      const customer = state.filledCustomers.find(({ id }) => id === driverId);

      if (customer) {
        return {
          ...state,
          drivers: [...state.drivers, backToFrontDriver({ customer })],
        };
      }

      return state;
    }

    case actions.ASSISTANCE_SET_CURRENT_SAVED_DATA: {
      return { ...state, currentSavedData: action.payload.currentSavedData };
    }

    case actions.ASW_ANKETA_SET_MAIN_FETCH: {
      return { ...state, mainFetch: action.payload };
    }

    case actions.ASSISTANCE_SET_NETWORK_ERROR: {
      return { ...state, networkError: action.payload };
    }

    case actions.ASSISTANCE_SCROLL_TO: {
      return { ...state, scrollTo: action.payload.scrollTo };
    }

    case actions.ASSISTANCE_CLEAR_SCROLL_TO: {
      return { ...state, scrollTo: null };
    }

    case actions.ASSISTANCE_SWITCH_CALC_OPTION: {
      if (action.payload) {
        return { ...state, calcOption: action.payload };
      }

      return {
        ...state,
        calcOption:
          GLOBAL_ANKETA_CALC_OPTIONS[Number(state.calcOption === GLOBAL_ANKETA_CALC_OPTIONS[0])],
      };
    }

    case actions.ASSISTANCE_CLEAR_ALL_DRIVERS: {
      return {
        ...state,
        isDriver: false,
        licenseDate: state.currentSavedData.licenseDate,
        licenseSeriaNum: state.currentSavedData.licenseSeriaNum,
        prevLicenseSeriaNum: state.currentSavedData.prevLicenseSeriaNum,
        prevLicenseDate: state.currentSavedData.prevLicenseDate,
        drivers: [],
        errors: {
          ...state.errors,
          licenseDate: '',
          licenseSeriaNum: '',
          prevLicenseSeriaNum: '',
          prevLicenseDate: '',
          ...state.drivers.reduce((acc, { id }) => ({ ...acc, [`driver@${id}`]: {} }), {}),
        },
      };
    }

    case actions.ASSISTANCE_RETURN_SAVED_DRIVERS: {
      return {
        ...state,
        isDriver: state.currentSavedData.isDriver,
        licenseDate: state.currentSavedData.licenseDate,
        licenseSeriaNum: state.currentSavedData.licenseSeriaNum,
        prevLicenseSeriaNum: state.currentSavedData.prevLicenseSeriaNum,
        prevLicenseDate: state.currentSavedData.prevLicenseDate,
        drivers: JSON.parse(JSON.stringify(state.currentSavedData.drivers)),
      };
    }

    case actions.ASSISTANCE_SET_ENABLED: {
      return { ...state, isDisabled: false };
    }

    case actions.ASSISTANCE_SET_DISABLED: {
      return { ...state, isDisabled: true };
    }

    case actions.ASSISTANCE_ANKETA_SET_INSURER_DADATA_INFO: {
      return {
        ...state,
        insurerDadataInfo: { ...action.payload },
      };
    }

    case actions.ASSISTANCE_ANKETA_UPD_INSURER_DADATA_INFO: {
      return {
        ...state,
        insurerDadataInfo: {
          ...state.insurerDadataInfo,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
}

export function aswAnketaSetMainFetch(isFetching) {
  return {
    type: actions.ASW_ANKETA_SET_MAIN_FETCH,
    payload: isFetching,
  };
}

export function assistanceSwitchInsurerDriverCheckbox() {
  return {
    type: actions.ASSISTANCE_SWITCH_INSURER_DRIVER_CHECKBOX,
  };
}

export function assistanceChangeByKey({ key, value }) {
  return {
    type: actions.ASSISTANCE_CHANGE_BY_KEY,
    payload: { key, value },
  };
}

export function assistanceChangeDriver({ key, value, id }) {
  return {
    type: actions.ASSISTANCE_CHANGE_DRIVER,
    payload: { key, value, id },
  };
}

export function assistanceAddDriver(id, isEmpty = false, newDriverData = {}) {
  return {
    type: actions.ASSISTANCE_ADD_DRIVER,
    payload: { id, isEmpty, newDriverData },
  };
}

export function assistanceAddConfidant(confidant) {
  return {
    type: actions.ASSISTANCE_ADD_CONFIDANT,
    payload: { confidant },
  };
}

export function assistanceExtractDriver({ id }) {
  return {
    type: actions.ASSISTANCE_EXTRACT_DRIVER,
    payload: { id },
  };
}

export function assistanceDeleteDriver({ id }) {
  return {
    type: actions.ASSISTANCE_DELETE_DRIVER,
    payload: { id },
  };
}

export function assistanceDropAllErrors() {
  return {
    type: actions.ASSISTANCE_DROP_ALL_ERRORS,
  };
}

export function assistanceSetErrors({ errors }) {
  return {
    type: actions.ASSISTANCE_SET_ERRORS,
    payload: { errors },
  };
}

export function assistanceSetError({ id, key, value }) {
  return {
    type: actions.ASSISTANCE_SET_ERROR,
    payload: { level: id, key, value },
  };
}

export function assistanceDeleteError({ id, key }) {
  return {
    type: actions.ASSISTANCE_DELETE_ERROR,
    payload: { level: id, key },
  };
}

export function assistanceGetCustomersId({ applicationId, product }) {
  return {
    type: actions.ASSISTANCE_GET_CUSTOMERS_IDS,
    payload: { applicationId, product },
  };
}

export function assistanceSetNetworkError(message = '') {
  return {
    type: actions.ASSISTANCE_SET_NETWORK_ERROR,
    payload: message,
  };
}

export function assistanceScrollToCar(scrollTo) {
  return {
    type: actions.ASSISTANCE_SCROLL_TO,
    payload: { scrollTo },
  };
}

export function assistanceClearScroll() {
  return {
    type: actions.ASSISTANCE_CLEAR_SCROLL_TO,
  };
}

export function assistanceClearAnketa() {
  return {
    type: actions.ASSISTANCE_DROP_ALL,
  };
}

export function assistanceClearEmptyDrivers() {
  return {
    type: actions.ASSISTANCE_CLEAR_EMPTY_DRIVERS,
  };
}

export function assistancePatchCarInstance(anketaId, isCashCustomer = true) {
  return {
    type: actions.ASSISTANCE_PATCH_CAR_INSTANCE,
    payload: { anketaId, isCashCustomer },
  };
}

export function assistanceSwitchCalcOption(calcOption) {
  return {
    type: actions.ASSISTANCE_SWITCH_CALC_OPTION,
    payload: calcOption,
  };
}

export function assistanceClearAllDrivers() {
  return {
    type: actions.ASSISTANCE_CLEAR_ALL_DRIVERS,
  };
}

export function assistanceReturnSavedDrivers() {
  return {
    type: actions.ASSISTANCE_RETURN_SAVED_DRIVERS,
  };
}

export function assistanceSetEnabled() {
  return {
    type: actions.ASSISTANCE_SET_ENABLED,
  };
}

export function assistanceSetDisabled() {
  return {
    type: actions.ASSISTANCE_SET_DISABLED,
  };
}

export function assistanceAnketaSetInsurerDadataInfo(insurerDadataInfo) {
  return {
    type: actions.ASSISTANCE_ANKETA_SET_INSURER_DADATA_INFO,
    payload: insurerDadataInfo,
  };
}

export function assistanceAnketaUpdateInsurerDadataInfo(insurerDadataInfo) {
  return {
    type: actions.ASSISTANCE_ANKETA_UPD_INSURER_DADATA_INFO,
    payload: insurerDadataInfo,
  };
}

export function assistanceAnketaGetLegalData(query, setListFn) {
  return {
    type: actions.ASSISTANCE_ANKETA_GET_LEGAL_DATA,
    payload: { query, setListFn },
  };
}

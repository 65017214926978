import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  expandApplication,
  reloadExpandedApplication,
  getCarInstanceData,
  getDetailParams,
} from 'store/applications';

import DealsList from './DealsList';
import DetailItem from './DetailItem';
import { ApplicComment } from './ApplicComment';
import DetailItemParams from './DetailItemParams';
import { DetailContainer } from './style';

export const ApplicationDetail = ({ application, showOpenButton }) => {
  const isCredit = Boolean(application?.ids?.credit);
  const rootDispatch = useDispatch();
  const isMount = useRef(true);

  useEffect(() => {
    const expandMethod = isMount.current ? expandApplication : reloadExpandedApplication;
    isMount.current = false;

    rootDispatch(expandMethod(application.id));
    rootDispatch(getCarInstanceData(application?.carInstance?.id));
    if (isCredit) {
      rootDispatch(getDetailParams(application?.ids?.credit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  return (
    <DetailContainer data-test="applicationListDealOpen">
      <DetailItem application={application} showOpenButton={showOpenButton} />

      {isCredit && (
        <>
          <DetailItemParams application={application} />
          <DealsList application={application} />
        </>
      )}

      <ApplicComment application={application} />
    </DetailContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import moment from 'moment';

import { characterPositionTypeManager, employerTypeIndividual } from 'store/anketa/mappers/work';
import {
  abstractOrganizationInitialState,
  dadataOrganizationToState,
} from 'store/anketa/mappers/abstractOrganization';
import {
  dadataAddressToState,
  addressToState,
  indexToState,
} from 'store/anketa/mappers/abstractAddress';

import { daDataURL, MAPPER_TYPE_FORM_ORG, getSysNameByOpf } from './constants';
import {
  schemaOrgInn,
  schemaOrgInnOther,
  schemaOrgPhone,
} from '../../../../Application/validation';

import { InputMask } from 'utils/inputMask';
import { getPhoneNumber } from 'utils/stringUtils';
import { isPasportConfidenceCorrect } from 'utils/dataMappers/mappers';
import { ORG_SAME_PHONE_NUMBER } from 'utils/validationUtils/messages';
import { getExperienceTime, getInnByPassport } from 'utils/validationUtils/validators';

import { InfoSvg } from 'assets/img';
import { ReactTooltipStyled } from '../../../style';
import * as S from './style';

const notInListToast =
  'Выберите название организации из выпадающего списка. Это важно, т.к. в банке ЮЛ определяется только по справочнику';

export const WorkForm = ({
  keyComp,
  onChange,
  errors,
  persona,
  validate,
  firstTimeRender,
  anketaSave,
  setFocusAt,
  disabled = false,
}) => {
  const { addToast, removeAllToasts } = useToasts();

  const { documents } = useSelector((state) => state.anketa);
  const {
    legalFormList,
    characterPositions,
    employerNewTypeList,
    employerTypeList,
    companyCountList,
    workExpirience,
  } = useSelector((state) => state.referenceBooks);

  const [values, setValues] = useState(null);
  const [isNotInlist, setIsNotInlist] = useState(false);
  const [error, setError] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [showPhoneInfo, setShowPhoneInfo] = useState(false);
  const [phoneValidForced, setPhoneValidForced] = useState(false);
  const { REACT_APP_DADATA_TOKEN } = process.env;

  const getDadata = async (hid, url, orgType) => {
    const queryData = {
      count: 1,
      query: hid,
    };
    if (orgType) {
      queryData.count = 1;
      queryData.type = orgType;
    }
    const results = await axios.post(url, queryData, {
      headers: {
        'accept': 'application/json',
        'authorization': `Token ${REACT_APP_DADATA_TOKEN}`,
        'content-type': 'application/json',
      },
    });
    if (results && results.status && [200].indexOf(results.status) === -1) {
      return null;
    } else {
      return (results.data.suggestions && results.data.suggestions[0]) || null;
    }
  };

  const getDadataByID = async (hid) => {
    return await getDadata(hid, daDataURL.ID_PATH);
  };

  const getDadataByINN = async (hid) => {
    return await getDadata(hid, daDataURL.INN_PATH);
  };

  const changeHandler = (val, withSave = false, orgPhoneCheckFocus = false) => {
    if (val.organization && val.organization.formType) {
      if (val.organization.formType.id === 746095) {
        if (!val.organization.nameFull) val.organization.nameFull = val.organization.name;
        if (!val.organization.okved) val.organization.okved = '94';
      }
    }
    let stateVal = { ...values };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }
    updatePhoneInfo(stateVal, values);
    setValues(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyComp });

    if (withSave) anketaSave();
    if (orgPhoneCheckFocus) {
      schemaOrgPhone
        .validate(val, { abortEarly: false })
        .then(() => {
          if (
            values.employmentType &&
            [3247].includes(values.employmentType.id) &&
            !values.organization.legalAddress.fiasId
          ) {
            setFocusAt(`${keyComp}_organization_legalAddress_fiasValue_input`);
          } else {
            if (values.employmentType && [3247].includes(values.employmentType.id)) {
              setFocusAt(`${keyComp}_careerStartTime_input`);
            } else {
              if (persona.contacts.mobilePhone !== val.organizationPhone) {
                setFocusAt(`${keyComp}_careerStartTime_input`);
              }
            }
          }
        })
        .catch(() => {});
    }
  };

  const updatePhoneInfo = (state, obj) => {
    if (state.organizationPhone && obj?.employmentType?.id === 3247) {
      setShowPhoneInfo(state.organizationPhone === persona.contacts.mobilePhone && true);
    } else {
      setShowPhoneInfo(false);
    }
  };

  const changeEmTypeHandler = (emType) => {
    if (emType.id === 3247) {
      const orgName = `ИП ${persona.personalInfo?.fullName}`;
      const ibState = {
        employmentType: emType,
        positionName: 'Индивидуальный предприниматель',
        characterPositionType: {
          ...characterPositionTypeManager,
        },
      };
      changeHandler({
        ...ibState,
        organization: {
          ...values.organization,
          //name: orgName,
          nameError: 'notinlist',
        },
      });

      if (showMore === false) {
        setShowMore(true);
      }

      getDadata(orgName, daDataURL.NAME_PATH, 'INDIVIDUAL').then((result) => {
        if (result) {
          getOrgAddress(result, ibState);
        } else {
          addToast(notInListToast, { appearance: 'warning' });
        }
      });
    } else if (emType.id === 3246) {
      innCheck(emType);
    } else {
      changeHandler({ employmentType: emType, positionName: '' });
    }
  };

  const validation = (path) => {
    setError({ ...error, [path]: errors[path] || null });
    anketaSave();
  };

  const getError = (path, forsed = false) => {
    if ((validate || (forsed && phoneValidForced)) && !firstTimeRender) {
      return errors[path];
    }
    return error[path];
  };

  useEffect(() => {
    if (persona && values !== persona[keyComp]) {
      setValues(persona[keyComp]);
      updatePhoneInfo(persona[keyComp], persona[keyComp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  useEffect(() => {
    if (validate) {
      setError(
        Object.keys(error).reduce((obj, key) => ({ ...obj, [key]: errors[key] || null }), {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  useEffect(() => {
    if (
      errors &&
      'work.organization.legalAddress.fiasValue' in errors &&
      values?.organization?.legalAddress?.fiasValue &&
      !showMore
    ) {
      setShowMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (persona.personalInfo.birthDate.length === 10) {
      const eighteenYearsDate = moment(persona.personalInfo.birthDate, 'DD.MM.YYYY').add(18, 'y');
      let experienceTime = getExperienceTime(workExpirience, eighteenYearsDate);
      if (experienceTime.sysName === 'less_then_1_year') {
        experienceTime = workExpirience.find((o) => o.sysName === 'from_1_to_3_years');
      }
      setValues((prev) => ({
        ...prev,
        experienceTime: experienceTime,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona.personalInfo.birthDate]);

  const showWork = () =>
    values &&
    !['unemployedMilitaryRetired', 'unemployedRetiree', 'not_working'].includes(
      values.employmentType?.sysName
    );

  const isWorkAnother = () =>
    values && values.employmentType?.sysName === 'employment-type-another';
  const getInnMask = () => {
    if (
      values &&
      (MAPPER_TYPE_FORM_ORG.new_ind_predprin.includes(values.organization?.formType?.sysName) ||
        ['individual-entrepreneur-3', 'employment-type-another'].includes(
          values.employmentType?.sysName
        ))
    ) {
      return InputMask.INN_IP;
    } else {
      return InputMask.INN_ORGANIZATION;
    }
  };

  const completeOrganizationData = (rawData) => {
    if (!rawData.data.opf) {
      rawData.data.opf = {
        code: '19000',
        short: 'ПКО',
        full: 'Прочие юридические лица, являющиеся коммерческими организациями',
        type: '2014',
      };
    }
    if (!rawData.data.okved) {
      rawData.data.okved = '94';
    }
    const newOrg = dadataOrganizationToState(rawData, { companyCountList });
    if (!newOrg.nameFull) {
      newOrg.nameFull = newOrg.name;
    }
    return newOrg;
  };

  const getOrgAddress = (obj, employmentState = null) => {
    if (obj.data) {
      setIsNotInlist(false);
      if (obj?.data?.state?.status === 'LIQUIDATED') {
        removeAllToasts();
        addToast('Вы не можете выбрать эту организацию, она ликвидирована', {
          appearance: 'warning',
        });
        let liquidatedState = {
          organization: {
            ...values.organization,
            name: '',
            nameError: 'liquidated',
          },
        };
        if (employmentState)
          liquidatedState = {
            ...liquidatedState,
            ...employmentState,
          };
        changeHandler(liquidatedState);
        return;
      }

      let typeType = {};
      let form = legalFormList.find((item) => item.value === obj.data.opf.short);
      if (!form) {
        const sysName = getSysNameByOpf(obj.data.opf?.code ?? null);
        form = legalFormList.find((item) => item.value === sysName);
      }
      Object.keys(MAPPER_TYPE_FORM_ORG).forEach((key) => {
        if (MAPPER_TYPE_FORM_ORG[key].some((m) => m === form?.sysName)) {
          const type = employerNewTypeList.find((emp) => emp.sysName === key);
          typeType =
            type || employerNewTypeList.find((emp) => emp.sysName === 'new_not_commercial');
        }
      });
      getDadataByID(obj.data.hid)
        .then((req) => {
          const data = {
            ...completeOrganizationData(req || obj),
            formType: form || values.organization.formType,
            typeType,
          };
          if (
            values.employmentType.sysName === 'employment' &&
            (form.sysName || values.organization.formType.sysName) === 'legal_new_type_ip'
          ) {
            setShowMore(true);
          }

          const inn = data.inn;
          if (inn) {
            getDadataByINN(inn)
              .then((req) => {
                const phone = req.data.phones ? getPhoneNumber(req.data.phones) : '';
                let orgState = {
                  organization: { ...data, nameError: undefined },
                  organizationPhone: phone,
                };
                schemaOrgPhone
                  .validate(orgState, { abortEarly: false })
                  .then(() => {
                    setFocusAt(`${keyComp}_careerStartTime_input`);
                  })
                  .catch(() => {
                    setFocusAt(`${keyComp}_organizationPhone_input`);
                  });
                if (employmentState) {
                  orgState = {
                    ...orgState,
                    ...employmentState,
                  };
                } else {
                  if (
                    persona.personalInfo &&
                    persona.personalInfo.fullName &&
                    orgState.organization.nameFull.toLowerCase() ===
                      persona.personalInfo.fullName.toLowerCase()
                  ) {
                    orgState = {
                      ...orgState,
                      employmentType: employerTypeIndividual,
                    };
                  }
                }
                IbDataCheck(orgState, req);
                changeHandler(orgState);
              })
              .catch((err) => {
                console.log('Cause of error:: ', err);
                let errState = {
                  organization: {
                    ...values.organization,
                    ...data,
                    nameError: undefined,
                  },
                };
                if (employmentState)
                  errState = {
                    ...errState,
                    ...employmentState,
                  };
                changeHandler(errState);
              });
          } else {
            let errState = {
              organization: {
                ...values.organization,
                ...data,
                nameError: undefined,
              },
            };
            if (employmentState)
              errState = {
                ...errState,
                ...employmentState,
              };
            IbDataCheck(errState, req);
            changeHandler(errState);
          }
        })
        .catch(() => {
          let noOrgInfo = {
            organization: {
              ...values.organization,
              ...dadataOrganizationToState(obj, { companyCountList }),
              formType: form || values.organization.formType,
              typeType,
            },
          };
          if (employmentState)
            noOrgInfo = {
              ...noOrgInfo,
              ...employmentState,
            };
          changeHandler(noOrgInfo);
        });
    } else {
      setIsNotInlist(true);
      checkIsNotInList();
      let nodataState = {
        organization: {
          ...values.organization,
          name: obj.value,
        },
      };
      if (employmentState)
        nodataState = {
          ...nodataState,
          ...employmentState,
        };
      changeHandler(nodataState);
    }
  };

  const IbDataCheck = (state, req) => {
    if (state.employmentType?.id === 3247 || values.employmentType?.id === 3247) {
      let addressCheckOk = true;
      if (
        persona.addressReg &&
        (persona.addressReg.cityName || persona.addressReg.settlementName) &&
        req?.data?.address?.data
      ) {
        let addReg = persona.addressReg.cityName || persona.addressReg.settlementName || '';
        let addData = req?.data?.address?.data.city || req?.data?.address?.data.settlement || '';

        addressCheckOk = addReg === addData;
      }

      if (addressCheckOk) {
        if (req?.data?.state?.registration_date)
          state.careerStartTime = moment(req?.data?.state?.registration_date).format('DD.MM.YYYY');
        state.organization.legalAddress = {
          ...persona.addressReg,
        };
        state.organization.actualAddress = {
          ...persona.addressReg,
        };
      } else {
        state.organization = {
          ...state.organization,
          inn: abstractOrganizationInitialState.inn,
          legalAddress: abstractOrganizationInitialState.legalAddress,
          actualAddress: abstractOrganizationInitialState.actualAddress,
          actualAddressEquals: abstractOrganizationInitialState.actualAddressEquals,
          employeesCountType: abstractOrganizationInitialState.employeesCountType,
          employerIndustryBranchType: abstractOrganizationInitialState.employerIndustryBranchType,
          numYears: abstractOrganizationInitialState.numYears,
          employerManagerFullName: abstractOrganizationInitialState.employerManagerFullName,
          nameError: undefined,
        };
      }
    }
  };

  const checkIsNotInList = () => {
    if (isNotInlist) {
      setIsNotInlist(false);
      addToast(notInListToast, { appearance: 'warning' });
      changeHandler({
        organization: {
          ...values.organization,
          name: '',
          nameError: 'notinlist',
        },
      });
    }
  };

  const positionChangeHandler = (pos) => {
    const posName = pos.positionName.value;
    changeHandler({ positionName: posName });
  };

  function innCheck(emType) {
    if (
      persona &&
      persona.passport &&
      persona.personalInfo &&
      isPasportConfidenceCorrect(documents) &&
      !(errors && Object.keys(errors).some((e) => e.includes('passport')))
    ) {
      changeHandler({ employmentType: emType });
      getInnByPassport(persona).then((inn) => {
        changeHandler({
          employmentType: emType,
          positionName: '',
          organization: {
            ...values.organization,
            inn: inn ? inn : values.organization.inn,
          },
        });
      });
    } else {
      changeHandler({ employmentType: emType });
    }
  }

  const getInnSchema = () => {
    if (values && values.employmentType && [3246, 3247].includes(values.employmentType.id)) {
      return schemaOrgInnOther;
    }
    return schemaOrgInn;
  };

  const getIfOrgPhoneSame = () => {
    return getError(`${keyComp}.organizationPhone`, true) === ORG_SAME_PHONE_NUMBER && true;
  };

  return (
    <>
      {values && (
        <S.FormGridRow showmore={showMore}>
          <S.SocialStatus
            id={`${keyComp}_employmentType_sysName`}
            tabIndex="64"
            required
            name="employmentType"
            onlyid={false}
            label="Тип занятости"
            defaultValue={values.employmentType}
            list={employerTypeList}
            onChange={(val) => {
              changeEmTypeHandler(val.employmentType);
              updatePhoneInfo(values, val);
              if ([3091, 87, 3251, 3252].includes(val.employmentType.id)) {
                setFocusAt(`${keyComp}_positionName_wrapper`, true);
              } else if ([3246, 3250, 3994].includes(val.employmentType.id)) {
                setFocusAt(`${keyComp}_homePhone_input`);
              } else if ([3247].includes(val.employmentType.id)) {
                setFocusAt(`${keyComp}_organizationPhone_input`);
              }
            }}
            onBlur={() => validation(`${keyComp}.employmentType.sysName`)}
            error={getError(`${keyComp}.employmentType.sysName`)}
            disabled={disabled}
          />
          {showWork() ? (
            <>
              {isWorkAnother() ? (
                <>
                  <S.HomePhoneSe
                    id={`${keyComp}_homePhone`}
                    tabIndex="11"
                    required
                    name="homePhone"
                    label="Второй контактный телефон"
                    defaultValue={values.homePhone || ''}
                    val={values.homePhone}
                    mask={InputMask.CELLPHONE}
                    onChange={(val) => changeHandler(val)}
                    onComplete={() => {
                      getInnSchema()
                        .validate(values.organization, { abortEarly: false })
                        .then(() => {
                          setFocusAt(`${keyComp}_careerStartTime_input`);
                        })
                        .catch(() => {
                          setFocusAt(`${keyComp}_organization_inn_input`);
                        });
                    }}
                    onBlur={() => validation(`${keyComp}.homePhone`)}
                    error={getError(`${keyComp}.homePhone`)}
                    disabled={disabled}
                  />
                  <S.OrganisationInnSe
                    id={`${keyComp}_organization_inn`}
                    mask={getInnMask()}
                    unmask
                    tabIndex="12"
                    required
                    name="inn"
                    label="ИНН"
                    defaultValue={values.organization?.inn || ''}
                    val={values.organization?.inn || ''}
                    onChange={(obj) => {
                      changeHandler({
                        organization: { ...values.organization, inn: obj.inn },
                      });
                      getInnSchema()
                        .validate(obj, { abortEarly: false })
                        .then(() => {
                          setFocusAt(`${keyComp}_careerStartTime_input`);
                        })
                        .catch(() => {});
                    }}
                    onBlur={() => validation(`${keyComp}.organization.inn`)}
                    error={getError(`${keyComp}.organization.inn`)}
                    disabled={disabled}
                  />
                  <S.CareerStartTimeSe
                    id={`${keyComp}_careerStartTime`}
                    tabIndex="13"
                    required
                    name="careerStartTime"
                    label="Дата начала работы"
                    defaultValue={values.careerStartTime}
                    val={values.careerStartTime}
                    mask={InputMask.DATE_BEFORE_TODAY}
                    unmask
                    onChange={({ careerStartTime }) => {
                      const date = careerStartTime.length === 2 ? '' : careerStartTime;
                      changeHandler({ careerStartTime: date });
                    }}
                    onBlur={() => validation(`${keyComp}.careerStartTime`)}
                    error={getError(`${keyComp}.careerStartTime`)}
                    disabled={disabled}
                  />
                </>
              ) : (
                <>
                  <S.Organization
                    id={`${keyComp}_organization_name`}
                    tabIndex="65"
                    required
                    unmask
                    val={values.organization ? values.organization.name : ''}
                    type="party"
                    label="Юридическое название места работы или ИНН организации"
                    defaultValue={values.organization ? values.organization.name : ''}
                    payloadModifier={
                      values.employmentType.sysName === 'individual-entrepreneur-3'
                        ? { type: 'INDIVIDUAL' }
                        : null
                    }
                    onChange={(obj) => {
                      if (obj.data) {
                        const orgDadata = completeOrganizationData(obj);
                        changeHandler(orgDadata);
                        getOrgAddress(obj);
                      } else {
                        changeHandler({
                          organization: {
                            ...values.organization,
                            name: obj.value,
                            nameError: 'notinlist',
                            isOrgFromDadata: 0,
                          },
                        });
                      }
                    }}
                    onBlur={() => {
                      checkIsNotInList();
                      validation(`${keyComp}.organization.name`);
                    }}
                    error={getError(`${keyComp}.organization.name`)}
                    dataTest="workOrganization"
                    disabled={disabled}
                  />
                  <>
                    <S.Phone
                      id={`${keyComp}_organizationPhone`}
                      tabIndex="75"
                      required
                      name="organizationPhone"
                      label="Телефон организации"
                      defaultValue={values.organizationPhone}
                      val={values.organizationPhone}
                      mask={InputMask.CELLPHONE}
                      onChange={(val) => {
                        setPhoneValidForced(true);
                        updatePhoneInfo(val, values);
                        changeHandler(val, false, true);
                      }}
                      onBlur={() => validation(`${keyComp}.organizationPhone`)}
                      error={getError(`${keyComp}.organizationPhone`, true)}
                      disabled={disabled}
                    />
                    {(showPhoneInfo || getIfOrgPhoneSame()) && (
                      <S.PhoneInfo showPhoneInfo={true}>
                        <span>
                          {showPhoneInfo ? 'Упс! Банки не смогут рассмотреть заявку' : ''}
                        </span>
                        <InfoSvg data-tip="" data-for="workPhoneInfo" />
                        <ReactTooltipStyled id="workPhoneInfo" place="right">
                          По требованию банков рабочий телефон
                          <br />
                          не должен совпадать с личным мобильным
                        </ReactTooltipStyled>
                      </S.PhoneInfo>
                    )}
                  </>
                  {showMore && (
                    <>
                      <S.OrganisationType
                        id={`${keyComp}_organization_typeType_sysName`}
                        tabIndex="66"
                        required
                        name="typeType"
                        onlyid={false}
                        label="Тип организации"
                        defaultValue={values.organization.typeType}
                        list={employerNewTypeList}
                        onChange={({ typeType }) => {
                          values.organization &&
                            changeHandler({
                              organization: {
                                ...values.organization,
                                typeType,
                              },
                            });
                        }}
                        onBlur={() => validation(`${keyComp}.organization.typeType.sysName`)}
                        error={getError(`${keyComp}.organization.typeType.sysName`)}
                        disabled={disabled}
                      />
                      <S.OrganisationForm
                        id={`${keyComp}_organization_formType_sysName`}
                        tabIndex="67"
                        required
                        name="formType"
                        label="Форма организации"
                        onlyid={false}
                        defaultValue={values.organization.formType}
                        list={legalFormList}
                        onChange={({ formType }) => {
                          values.organization &&
                            changeHandler({
                              organization: {
                                ...values.organization,
                                formType,
                              },
                            });
                        }}
                        onBlur={() => validation(`${keyComp}.organization.formType.sysName`)}
                        error={getError(`${keyComp}.organization.formType.sysName`)}
                        disabled={disabled}
                      />
                      <S.OrganisationInn
                        id={`${keyComp}_organization_inn`}
                        mask={getInnMask()}
                        unmask
                        tabIndex="68"
                        required
                        name="inn"
                        label="ИНН организации"
                        defaultValue={(values.organization && values.organization.inn) || ''}
                        val={(values.organization && values.organization.inn) || ''}
                        onChange={(obj) => {
                          changeHandler({
                            organization: {
                              ...values.organization,
                              inn: obj.inn,
                            },
                          });
                        }}
                        onBlur={() => validation(`${keyComp}.organization.inn`)}
                        error={getError(`${keyComp}.organization.inn`)}
                        disabled={disabled}
                      />
                      {!values.organization.legalAddress.isDadataIndexValue &&
                        values.organization.legalAddress.isDadataValue &&
                        values.organization.legalAddress.houseNumber &&
                        values.organization.legalAddress.fiasValue && (
                          <S.OrganisationIndex
                            id={`${keyComp}_organization_legalAddress_index`}
                            mask={InputMask.INDEX}
                            unmask
                            tabIndex="69"
                            required
                            type="address"
                            label="Индекс"
                            defaultValue={values.organization.legalAddress.index}
                            val={values.organization.legalAddress.index}
                            onChange={(val) =>
                              changeHandler({
                                organization: {
                                  ...values.organization,
                                  legalAddress: {
                                    ...values.organization.legalAddress,
                                    ...indexToState(val),
                                  },
                                },
                              })
                            }
                            onBlur={() => validation(`${keyComp}.organization.legalAddress.index`)}
                            error={getError(`${keyComp}.organization.legalAddress.index`)}
                            disabled={disabled}
                          />
                        )}
                      <S.OrganisationAddress
                        id={`${keyComp}_organization_legalAddress_fiasValue`}
                        tabIndex="70"
                        required
                        type="address"
                        label="Юридический адрес организации"
                        defaultValue={values.organization.legalAddress.fiasValue}
                        onChange={(obj) => {
                          if (obj.data) {
                            changeHandler({
                              organization: {
                                ...values.organization,
                                legalAddress: dadataAddressToState(obj.data, obj.value),
                              },
                            });
                          } else {
                            changeHandler({
                              organization: {
                                ...values.organization,
                                legalAddress: {
                                  ...values.organization.legalAddress,
                                  ...addressToState(obj.value),
                                },
                              },
                            });
                          }
                        }}
                        onBlur={() => validation(`${keyComp}.organization.legalAddress.fiasValue`)}
                        error={getError(`${keyComp}.organization.legalAddress.fiasValue`)}
                        disabled={disabled}
                      />
                      <S.InputWrap>
                        <S.OrganisationAge
                          id={`${keyComp}_organization_numYears`}
                          tabIndex="71"
                          required
                          name="numYears"
                          label="Кол-во лет организации"
                          defaultValue={values.organization.numYears}
                          val={values.organization.numYears}
                          onChange={({ numYears }) => {
                            values.organization &&
                              changeHandler({
                                organization: {
                                  ...values.organization,
                                  numYears,
                                },
                              });
                          }}
                          mask={InputMask.NUMBER_MAX_99}
                          onBlur={() => validation(`${keyComp}.organization.numYears`)}
                          error={getError(`${keyComp}.organization.numYears`)}
                          disabled={disabled}
                        />
                        <S.OrganisationPpl
                          id={`${keyComp}_organization_employeesCountType_sysName`}
                          tabIndex="76"
                          required
                          name="employeesCountType"
                          label="Кол-во работников"
                          onlyid={false}
                          defaultValue={values.organization.employeesCountType}
                          list={companyCountList}
                          onChange={({ employeesCountType }) => {
                            values.organization &&
                              changeHandler({
                                organization: {
                                  ...values.organization,
                                  employeesCountType,
                                },
                              });
                          }}
                          onBlur={() =>
                            validation(`${keyComp}.organization.employeesCountType.sysName`)
                          }
                          error={getError(`${keyComp}.organization.employeesCountType.sysName`)}
                          disabled={disabled}
                        />

                        <S.AddressMatch
                          tabIndex="72"
                          name="actualAddressEquals"
                          onChange={() => {
                            changeHandler(
                              {
                                organization: {
                                  ...values.organization,
                                  actualAddressEquals: !values.organization.actualAddressEquals,
                                },
                              },
                              true
                            );
                          }}
                          value={values.organization.actualAddressEquals}
                          disabled={disabled}
                          dataTest="actualAddressEquals"
                        >
                          Совпадает с фактическим адресом
                        </S.AddressMatch>
                      </S.InputWrap>

                      {!values.organization.actualAddressEquals && (
                        <>
                          {!values.organization.actualAddress.isDadataIndexValue &&
                            values.organization.actualAddress.fiasValue &&
                            values.organization.actualAddress.houseNumber &&
                            values.organization.actualAddress.isDadataValue && (
                              <S.OrganisationIndexFact
                                id={`${keyComp}_organization_actualAddress_index`}
                                mask={InputMask.INDEX}
                                unmask
                                tabIndex="73"
                                required
                                type="address"
                                label="Индекс"
                                defaultValue={values.organization.actualAddress.index}
                                val={values.organization.actualAddress.index}
                                onChange={(val) =>
                                  changeHandler({
                                    organization: {
                                      ...values.organization,
                                      actualAddress: {
                                        ...values.organization.actualAddress,
                                        ...indexToState(val),
                                      },
                                    },
                                  })
                                }
                                onBlur={() =>
                                  validation(`${keyComp}.organization.actualAddress.index`)
                                }
                                error={getError(`${keyComp}.organization.actualAddress.index`)}
                                disabled={disabled}
                              />
                            )}
                          <S.OrganisationAddressFact
                            id={`${keyComp}_organization_actualAddress_fiasValue`}
                            tabIndex="74"
                            required
                            type="address"
                            label="Фактический адрес организации"
                            defaultValue={values.organization.actualAddress.fiasValue}
                            onChange={(obj) => {
                              if (obj.data) {
                                changeHandler({
                                  organization: {
                                    ...values.organization,
                                    actualAddress: dadataAddressToState(obj.data, obj.value),
                                  },
                                });
                              } else {
                                changeHandler({
                                  organization: {
                                    ...values.organization,
                                    actualAddress: {
                                      ...values.organization.actualAddress,
                                      ...addressToState(obj.value),
                                    },
                                  },
                                });
                              }
                            }}
                            onBlur={() =>
                              validation(`${keyComp}.organization.actualAddress.fiasValue`)
                            }
                            error={getError(`${keyComp}.organization.actualAddress.fiasValue`)}
                            disabled={disabled}
                          />
                        </>
                      )}
                    </>
                  )}

                  <S.ShowMoreBtn
                    onClick={() => setShowMore(!showMore)}
                    data-test={`showMoreBtn ${showMore ? 'show' : 'hide'}`}
                  >
                    {!showMore ? 'Подробнее' : 'Скрыть'}
                  </S.ShowMoreBtn>

                  <S.Position
                    name="positionName"
                    label="Название должности"
                    mask={InputMask.ALL}
                    id={`${keyComp}_positionName`}
                    tabIndex="77"
                    required={true}
                    sort={true}
                    onlyid={false}
                    value={values.positionName}
                    list={characterPositions}
                    onChange={(obj) => {
                      positionChangeHandler(obj);
                      setFocusAt(`${keyComp}_organization_name_input`);
                    }}
                    onBlur={() => validation(`${keyComp}.positionName`)}
                    error={getError(`${keyComp}.positionName`)}
                    dataTest="jobTitle"
                    disabled={disabled}
                  />

                  <S.CareerStartTime
                    id={`${keyComp}_careerStartTime`}
                    tabIndex="81"
                    required
                    name="careerStartTime"
                    label="Начало работы в этой организации (месяц, год)"
                    defaultValue={values.careerStartTime.slice(3)}
                    val={values.careerStartTime.slice(3)}
                    mask={InputMask.DATE_START_CAREER}
                    unmask
                    onChange={({ careerStartTime }) => {
                      const date = careerStartTime.length === 1 ? '' : `01.${careerStartTime}`;
                      changeHandler({ careerStartTime: date });
                    }}
                    onComplete={() => {
                      setFocusAt(`${keyComp}_experienceTime_selector`, true);
                    }}
                    onBlur={() => validation(`${keyComp}.careerStartTime`)}
                    error={getError(`${keyComp}.careerStartTime`)}
                    disabled={disabled}
                  />

                  <S.ExperienceTime
                    id={`${keyComp}_experienceTime`}
                    tabIndex="82"
                    required
                    onlyid={false}
                    name="experienceTime"
                    label="Общий трудовой стаж"
                    defaultValue={values.experienceTime}
                    list={workExpirience}
                    onChange={(val) => changeHandler(val)}
                    onBlur={() => validation(`${keyComp}.experienceTime`)}
                    error={getError(`${keyComp}.experienceTime`)}
                    disabled={disabled}
                  />
                </>
              )}
            </>
          ) : (
            <S.HomePhone
              id={`${keyComp}_homePhone`}
              tabIndex="11"
              required
              name="homePhone"
              label="Второй контактный телефон"
              defaultValue={values.homePhone || ''}
              val={values.homePhone}
              mask={InputMask.CELLPHONE}
              onChange={(val) => changeHandler(val)}
              onBlur={() => validation(`${keyComp}.homePhone`)}
              error={getError(`${keyComp}.homePhone`)}
              disabled={disabled}
            />
          )}
        </S.FormGridRow>
      )}
    </>
  );
};

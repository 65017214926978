export const bubbleConditions = {
  currentEPointsMemberDealer: ({ dealer }) => dealer.epointsDigitalDealMember,
  hasSomeEPointsMemberDealer: ({ dealers }) =>
    dealers?.some((dealer) => dealer.epointsDigitalDealMember),
};

export const BUBBLES = [
  {
    type: 'target',
    id: '19.12.2024.002',
    target: '[data-test="mainPageVinCheckHistoryOpen"]',
    title: 'История проверок',
    text: 'Теперь храним историю\nпроверенных вами VIN номеров',
    position: 'right',
  },
];
